import React, { useContext } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { useBreakpoints } from 'hooks/useBreakpoints';

const Footer = () => {
  const { breakpoints } = useBreakpoints();
  const { me } = useContext(UserContext);
  let { displayName, photoURL } = me || {};
  const location = useLocation();
  const [, pathname] = location.pathname.match(/([^/]*)\/?$/);

  return (
    breakpoints.down('md') && (
      <footer className="bg-white sticky-bottom border-top">
        <Nav
          className="navbar-nav-icons ms-auto flex-row align-items-center justify-content-center"
          as="ul"
        >
          <Nav.Item as="li">
            <Nav.Link
              as={Link}
              className={classNames({
                'text-secondary': pathname !== 'assets',
                'active text-primary': pathname === 'assets'
              })}
              to="/assets"
            >
              <Flex direction="column" alignItems="center">
                <FontAwesomeIcon icon="search" size="lg" />
                <span className="fs--2 fw-semi-bold">Explora</span>
              </Flex>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link
              as={Link}
              className={classNames({
                'text-secondary': pathname !== 'favourites',
                'active text-primary': pathname === 'favourites'
              })}
              to="/assets/favourites"
            >
              <Flex direction="column" alignItems="center">
                <FontAwesomeIcon icon="heart" size="lg" />
                <span className="fs--2 fw-semi-bold">Favoritos</span>
              </Flex>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link
              as={Link}
              className={classNames({
                'text-secondary': pathname !== 'account',
                'active text-primary': pathname === 'account'
              })}
              to="/account"
            >
              <Flex direction="column" alignItems="center">
                <Avatar size="l" src={photoURL} name={displayName} />
                <span className="fs--2 fw-semi-bold">Cuenta</span>
              </Flex>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </footer>
    )
  );
};
export default Footer;

import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Button, Card } from 'react-bootstrap';
import CookieNotice from 'components/common/CookieNotice';
import Flex from 'components/common/Flex';
import cookieImg from 'assets/img/icons/cookie-1.png';

const MainCookieNotice = () => {
  const status = Cookies.get('cookieconsent_status');
  const [show, setShow] = useState(status !== 'dismiss');

  const handleDismiss = () => {
    Cookies.set('cookieconsent_status', 'dismiss', { expires: 365 });
    setShow(!show);
  };

  return (
    <>
      <CookieNotice show={show} setShow={setShow}>
        <Card className="shadow-lg">
          <Card.Body>
            <Flex>
              <div className="pe-3">
                <img src={cookieImg} alt="cookie" width={40} />
              </div>
              <div className="">
                <p>
                  Este sitio web utiliza cookies para asegurarse de que obtengas
                  la mejor experiencia en nuestra plataforma. Las cookies nos
                  permiten personalizar contenido, ofrecer funciones de redes
                  sociales y analizar nuestro tráfico.
                </p>
                <Button
                  size="sm"
                  variant="falcon-primary"
                  className="me-3"
                  onClick={handleDismiss}
                >
                  Ya lo sé y acepto
                </Button>
              </div>
            </Flex>
          </Card.Body>
        </Card>
      </CookieNotice>
    </>
  );
};
export default MainCookieNotice;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Image, Ratio, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import img from 'assets/img/e-learning/hero-img.jpg';

const Hero = ({ courses, setCurrentVideo }) => {
  const handleGoToFirstVideo = () => {
    document.querySelector('#course1')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
    setCurrentVideo(courses[0]);
  };

  return (
    <Section
      className="hero overflow-hidden light pt-4 pb-8"
      position="center"
      bgClassName="opacity-25"
    >
      <Row className="justify-content-center align-items-center min-h-500px">
        <Col md={11} lg={8} xl={5} className="text-center text-xl-start py-6">
          <h1 className="text-white fw-light mb-4">
            Gracias por registrarte 🎉
          </h1>
          <p className="fs-2 text-400 mb-4">
            Descubre cómo nuestra plataforma de inversión inmobiliaria impulsada
            por IA 🧠 puede ayudarte a maximizar tus rendimientos 📈
          </p>
          <p className="fs-2 text-400 mb-4">
            Simplificar tus decisiones de inversión 🏡.
          </p>
          <p className="fs-2 text-white m-0">
            👇 Haz clic aquí para ver el primer video
          </p>
          <Button
            variant="outline-primary"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={handleGoToFirstVideo}
          >
            Ver el primer video
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
        <Col xl={{ span: 6, offset: 1 }}>
          <Ratio aspectRatio="4x3">
            <Image
              className="border-4 border-opacity-25 fit-cover shadow-lg"
              rounded
              src={img}
              alt=""
            />
          </Ratio>
        </Col>
      </Row>
    </Section>
  );
};

Hero.propTypes = {
  courses: PropTypes.array.isRequired,
  setCurrentVideo: PropTypes.func.isRequired
};

export default Hero;

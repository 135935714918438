import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { FavouritesContext } from 'context/Context';

const KanbanColumnHeader = ({ column, title, itemCount, help }) => {
  const { id } = column;
  const { updateColumn, kanbanDispatch, removeColumn } =
    useContext(FavouritesContext);
  const [isEditing, edit] = useState(false);
  const [editingTitle, setEditingTitle] = useState('');

  const handleRemoveColumn = () => {
    kanbanDispatch({
      type: 'REMOVE_KANBAN_COLUMN',
      payload: { id }
    });
    removeColumn(id);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!editingTitle) {
      return;
    }
    updateColumn(column, { title: editingTitle });
    setEditingTitle('');
    edit(false);
  };

  return (
    <div className="kanban-column-header">
      {isEditing ? (
        <Form className="w-100" onSubmit={handleSubmit}>
          <Flex>
            <Form.Control
              size="sm"
              placeholder={title}
              value={editingTitle}
              onChange={({ target }) => setEditingTitle(target.value)}
              autoFocus
            />
            <Button
              variant="outline-secondary"
              size="sm"
              className="ms-1"
              onClick={() => edit(false)}
            >
              <FontAwesomeIcon icon="times" />
            </Button>
            <Button type="submit" size="sm" className="ms-1">
              <FontAwesomeIcon icon="check" />
            </Button>
          </Flex>
        </Form>
      ) : (
        <>
          <h5 className="fs-0 mb-0">
            <span onDoubleClick={() => edit(true)}>{title}</span>
            {typeof itemCount !== 'undefined' && (
              <span className="text-500 ms-1">({itemCount})</span>
            )}
          </h5>
          {!['offers', 'candidates'].includes(id) && (
            <Dropdown
              align="end"
              className="font-sans-serif btn-reveal-trigger"
            >
              <Dropdown.Toggle variant="reveal" size="sm" className="py-0 px-2">
                <FontAwesomeIcon icon="ellipsis-h" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-2">
                <Dropdown.Item onClick={() => edit(true)}>
                  Cambiar nombre
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={handleRemoveColumn}
                  href="#!"
                  className="text-danger"
                >
                  Eliminar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {help && (
            <OverlayTrigger placement="top" overlay={<Tooltip>{help}</Tooltip>}>
              <div>
                <FontAwesomeIcon icon="question-circle" className="mx-2" />
              </div>
            </OverlayTrigger>
          )}
        </>
      )}
    </div>
  );
};

KanbanColumnHeader.propTypes = {
  column: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  help: PropTypes.string
};

export default KanbanColumnHeader;

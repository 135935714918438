import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RegistrationForm from 'components/authentication/RegistrationForm';
import { UserContext } from 'context/Context';
import UserProvider from 'components/UserProvider';

const RegisterAndTrial = () => {
  const { getPrices } = useContext(UserContext);
  const { search } = useLocation();
  const [, plan, interval] = search?.match(/\?plan=(.*)&interval=(.*)/) || [];
  const navigate = useNavigate();

  const handleRegister = async user => {
    const prices = await getPrices();
    const { metadata } =
      prices.find(
        ({ product, recurring }) =>
          product.name.toLowerCase() === plan && recurring.interval === interval
      ) || {};
    const { link } = metadata || {};
    if (link) {
      window.self !== window.top
        ? (window.top.location = `${link}?locale=es&prefilled_email=${user?.email}`)
        : (window.location = `${link}?locale=es&prefilled_email=${user?.email}`);
      return;
    }

    window.self !== window.top
      ? (window.top.location = `${process.env.REACT_APP_FRONT_URL}/thanks`)
      : navigate('/thanks');
  };

  return (
    <>
      <Helmet>
        <style>
          {`
            body {
              background-color: transparent;
            }
            input[type="email"], input[type="tel"], input[type="text"], input[type="password"] {
              border-radius: 8px !important;
              background: rgba(208,213,225,0.2) !important;
              border: 1px solid rgba(208,213,225,0.4) !important;
              color: #ffffff !important;
            }
            button {
              border-radius: 8px !important;
            }
            hr {
              opacity: 0 !important;
            }
            .divider-content-center {
              background: transparent !important;
            }
            .divider-content-center > button {
              color: #fff !important;
              opacity: 0.4 !important;
            }
          `}
        </style>
      </Helmet>
      <RegistrationForm
        id="register-landing"
        onRegister={handleRegister}
        open={true}
      />
    </>
  );
};

const RegisterAndTrialWithContext = props => {
  return (
    <UserProvider>
      <RegisterAndTrial {...props} />
    </UserProvider>
  );
};

export default RegisterAndTrialWithContext;

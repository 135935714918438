import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { Button, Form } from 'react-bootstrap';

// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
export const PlaceAutocomplete = ({ className, onPlaceSelect, ...rest }) => {
  const [place, setPlace] = useState();
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary('places');

  const initAutocomplete = () => {
    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };
    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  };

  const handleClearClick = () => {
    setPlace();
    inputRef.current.value = '';
    onPlaceSelect({
      target: inputRef.current,
      value: null
    });
  };

  useEffect(() => {
    if (!places || !inputRef.current) return;
    initAutocomplete();
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace();
      setPlace(place);
      onPlaceSelect({
        target: inputRef.current,
        value: place
      });
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className={classNames('autocomplete-container', className)}>
      <Form.Control
        ref={inputRef}
        className={classNames({ 'pe-5': rest?.defaultValue || place })}
        {...rest}
      />
      {(rest?.defaultValue || place) && (
        <Button
          variant="link"
          className="position-absolute end-0 top-0 text-600 hover-400"
          onClick={handleClearClick}
        >
          <FontAwesomeIcon icon="times" size="lg" />
        </Button>
      )}
    </div>
  );
};

PlaceAutocomplete.propTypes = {
  className: PropTypes.string,
  onPlaceSelect: PropTypes.func
};

export default PlaceAutocomplete;

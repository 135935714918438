import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGooglePlusG,
  faGulp,
  faHotjar,
  faHtml5,
  faInstagram,
  faJs,
  faLinkedin,
  faLinkedinIn,
  faMediumM,
  faNodeJs,
  faPinterest,
  faReadme,
  faSass,
  faStripe,
  faTrello,
  faTwitter,
  faWhatsapp,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faBuilding as farBuilding,
  faCircle as farCircle,
  faClock as farClock,
  faCalendar as farCalendar,
  faCalendarCheck as farCalendarCheck,
  faCalendarTimes as farCalendarTimes,
  faComment as farComment,
  faCommentAlt as farCommentAlt,
  faCopy as farCopy,
  faCreditCard as farCreditCard,
  faDizzy as farDizzy,
  faFile as farFile,
  faFileArchive as farFileArchive,
  faFileAudio as farFileAudio,
  faFileCode as farFileCode,
  faFileImage as farFileImage,
  faFileLines as farFileLines,
  faFilePdf as farFilePdf,
  faFileVideo as farFileVideo,
  faFlag as farFlag,
  faHandshake as farHandshake,
  faHeart as farHeart,
  faImage as farImage,
  faLaugh as farLaugh,
  faLaughBeam as farLaughBeam,
  faLightbulb as farLightbulb,
  faObjectUngroup as farObjectUngroup,
  faPaperPlane as farPaperPlane,
  faQuestionCircle as farQuestionCircle,
  faSmileBeam as farSmileBeam,
  faComments as farComments,
  faStar as farStar,
  faEdit as farEdit,
  faThumbsUp as farThumbsUp
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressBook,
  faAddressCard,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowRotateLeft,
  faArrowUpRightFromSquare,
  faAtlas,
  faBars,
  faArrowsAltV,
  faArrowsToCircle,
  faArrowUp,
  faAt,
  faBan,
  faBell,
  faBirthdayCake,
  faBook,
  faBriefcase,
  faBuilding,
  faBuildingShield,
  faBusinessTime,
  faCalculator,
  faCoins,
  faCommentsDollar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarWeek,
  faCamera,
  faCaretDown,
  faHospitalUser,
  faHourglassEnd,
  faCaretRight,
  faCaretUp,
  faCartPlus,
  faCertificate,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCirclePlus,
  faClock,
  faCodeBranch,
  faCog,
  faComments,
  faCopy,
  faCreditCard,
  faCrown,
  faDisease,
  faDesktop,
  faDollarSign,
  faDoorClosed,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExchangeAlt,
  faExpand,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faEuroSign,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFileSignature,
  faFilter,
  faFolderPlus,
  faGift,
  faGlobeAmericas,
  faGlobeEurope,
  faGlobe,
  faHandHoldingHeart,
  faHandshake,
  faHeart,
  faHeartbeat,
  faHeartBroken,
  faHome,
  faHouseCircleCheck,
  faIcons,
  faIdCard,
  faImage,
  faInbox,
  faInfo,
  faLandmark,
  faLanguage,
  faLaugh,
  faLink,
  faList,
  faListAlt,
  faListOl,
  faListUl,
  faLocationArrow,
  faLock,
  faMagic,
  faWandMagicSparkles,
  faStore,
  faMinus,
  faMapMarkerAlt,
  faMoneyBill,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyBillWave,
  faPalette,
  faPaperclip,
  faPencilAlt,
  faPersonDigging,
  faPeopleRoof,
  faPercentage,
  faPersonShelter,
  faTags,
  faPhone,
  faPlane,
  faPlay,
  faPlug,
  faPlus,
  faPlusSquare,
  faPoll,
  faPrint,
  faPuzzlePiece,
  faQuestionCircle,
  faBookReader,
  faRedo,
  faReply,
  faRightFromBracket,
  faRightToBracket,
  faRss,
  faSearch,
  faShare,
  faShareAlt,
  faShoppingCart,
  faSignInAlt,
  faSitemap,
  faSlidersH,
  faSmile,
  faSmileBeam,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSortNumericDown,
  faSortNumericUp,
  faStar,
  faStarHalfAlt,
  faStream,
  faStreetView,
  faSuitcaseRolling,
  faTag,
  faTh,
  faThumbsUp,
  faThumbsDown,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserPlus,
  faUserTie,
  faUsers,
  faVideo,
  faCross,
  faFlag,
  faThumbtack,
  faTable,
  faChartLine,
  faShapes,
  faMap,
  faFire,
  faFireAlt,
  faLayerGroup,
  faRocket,
  faWrench,
  faSun,
  faMoon,
  faAngleLeft,
  faInfoCircle,
  faSpinner,
  faCapsules,
  faTasks,
  faTree,
  faCloudDownloadAlt,
  faSyncAlt,
  faBug,
  faProjectDiagram,
  faBolt,
  faPaperPlane,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faInfinity,
  faChessRook,
  faCode,
  faFile,
  faMusic,
  faLaptopHouse,
  faMobile,
  faMobileScreenButton,
  faBookOpen,
  faTint,
  faRedoAlt,
  faStopwatch,
  faTrophy,
  faTicketAlt,
  faFileImport,
  faKey,
  faPhoneAlt,
  faObjectUngroup,
  faFileDownload,
  faAppleAlt,
  faEnvelopeOpenText,
  faUserCog,
  faUserGraduate,
  faUserInjured,
  faUserSlash,
  faUserTag,
  faUserTimes,
  faVectorSquare,
  faChalkboardTeacher,
  faGraduationCap,
  faSignOutAlt,
  faDownload,
  faExpandArrowsAlt,
  faFileContract,
  faFileExcel,
  faFileImage,
  faFileInvoiceDollar,
  faFileInvoice,
  faFileMedical,
  faFileUpload,
  faUnlockAlt,
  faGripLines,
  faComment,
  faCommentAlt,
  faHeadphones,
  faClosedCaptioning,
  faMapPin,
  faPause,
  faPauseCircle,
  faPlayCircle,
  faPenNib,
  faAward,
  faBrush,
  faHashtag,
  faBalanceScaleLeft,
  faCommentDollar,
  faCommentMedical,
  faHandHoldingUsd,
  faHandHoldingWater,
  faHandPointUp,
  faCameraRetro,
  faThList,
  faVirus,
  faClipboardList,
  faUtensils,
  faMedal,
  faPen,
  faRankingStar,
  faRoute,
  faSearchLocation,
  faThLarge,
  faUndoAlt,
  faWallet,
  faWind
} from '@fortawesome/free-solid-svg-icons';

library.add(
  // Solid
  faAddressBook,
  faAddressCard,
  faAppleAlt,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowRotateLeft,
  faArrowUpRightFromSquare,
  faAtlas,
  faBars,
  faBusinessTime,
  faCalculator,
  faCoins,
  faCommentsDollar,
  faEnvelopeOpenText,
  faFileDownload,
  farFlag,
  faObjectUngroup,
  faPhoneAlt,
  faPlane,
  faKey,
  faFileImport,
  faTicketAlt,
  faChartPie,
  faCopy,
  faChessRook,
  faCreditCard,
  faSearch,
  faHome,
  faHouseCircleCheck,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faExpand,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faCirclePlus,
  faBan,
  faStream,
  faStreetView,
  faSuitcaseRolling,
  faArrowsAltV,
  faArrowsToCircle,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHandshake,
  faHeart,
  faHeartbeat,
  faHeartBroken,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faArrowRightToBracket,
  faPrint,
  faDesktop,
  faDisease,
  faDollarSign,
  faDoorClosed,
  faInbox,
  faLandmark,
  faLanguage,
  faLaugh,
  faLink,
  faPlus,
  faMagic,
  faWandMagicSparkles,
  faStore,
  faMinus,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faPencilAlt,
  faPersonDigging,
  faPeopleRoof,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faSitemap,
  faBook,
  faBriefcase,
  faBuilding,
  faBuildingShield,
  faBirthdayCake,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faEuroSign,
  faExternalLinkAlt,
  faCartPlus,
  faCertificate,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArchive,
  faEnvelope,
  faClock,
  faStar,
  faReply,
  faRss,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faBookReader,
  faRedo,
  faRightFromBracket,
  faRightToBracket,
  faSlidersH,
  faTrashAlt,
  faFileAlt,
  faFileArchive,
  faFilePdf,
  faFileSignature,
  faEye,
  faEyeSlash,
  faCaretUp,
  faCodeBranch,
  faExclamationTriangle,
  faListOl,
  faListUl,
  faTh,
  faVirus,
  faGift,
  faUsers,
  faGlobeAmericas,
  faGlobeEurope,
  faGlobe,
  faHandHoldingHeart,
  faPlusSquare,
  faCaretDown,
  faHospitalUser,
  faHourglassEnd,
  faUserPlus,
  faUserTie,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  farThumbsUp,
  faThumbsDown,
  faComments,
  farComments,
  faPalette,
  faEdit,
  faTrashAlt,
  faShare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmile,
  faSmileBeam,
  faPoll,
  faPercentage,
  faPersonShelter,
  faTags,
  faIcons,
  faIdCard,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faAt,
  faCalendarAlt,
  faCalendarCheck,
  farCalendar,
  farCalendarCheck,
  farCalendarTimes,
  faCalendarDay,
  faCalendarWeek,
  faMapMarkerAlt,
  faMoneyBill,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyBillWave,
  faCross,
  faFlag,
  faThumbtack,
  faTable,
  faChartLine,
  faShapes,
  faMap,
  faFire,
  faFireAlt,
  faLayerGroup,
  faRocket,
  faWrench,
  faSun,
  faMoon,
  faInfoCircle,
  faSpinner,
  faCapsules,
  faTasks,
  faTree,
  faCloudDownloadAlt,
  faSyncAlt,
  faBug,
  faProjectDiagram,
  faBolt,
  faPaperPlane,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faInfinity,
  faLaptopHouse,
  faMusic,
  faMobile,
  faMobileScreenButton,
  faBookOpen,
  faTint,
  faThList,
  faClipboardList,
  faMedal,
  faPen,
  faFile,
  faFileContract,
  faFileExcel,
  faFileImage,
  faFileInvoiceDollar,
  faFileInvoice,
  faFileMedical,
  faFileUpload,
  faRankingStar,
  faRoute,
  faSearchLocation,
  faThLarge,
  faUndoAlt,
  faWind,

  // Brand
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faPinterest,
  faGulp,
  faNodeJs,
  faReadme,
  faSass,
  faStripe,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,
  faWhatsapp,
  faYoutube,
  faVideo,
  faInfo,
  faPhone,
  faTrello,
  faCode,
  faJs,
  faHtml5,
  faTrophy,

  // Regular
  farBuilding,
  farHandshake,
  farHeart,
  farLightbulb,
  farObjectUngroup,
  farPaperPlane,
  farStar,
  farImage,
  farFileArchive,
  farFilePdf,
  farFile,
  farFileVideo,
  farFileAudio,
  farFileImage,
  farFileLines,
  farFileCode,
  farClock,
  farQuestionCircle,
  farCreditCard,
  farDizzy,
  farLaugh,
  farLaughBeam,
  farSmileBeam,
  farCircle,
  farCopy,
  farComment,
  farCommentAlt,
  farEdit,
  faRedoAlt,
  faStopwatch,
  faUserCog,
  faUserGraduate,
  faUserInjured,
  faUserSlash,
  faUserTag,
  faUserTimes,
  faVectorSquare,
  faChalkboardTeacher,
  faGraduationCap,
  faSignOutAlt,
  faDownload,
  faExpandArrowsAlt,
  faUnlockAlt,
  faGripLines,
  faComment,
  faCommentAlt,
  faHeadphones,
  faClosedCaptioning,
  faMapPin,
  faPause,
  faPauseCircle,
  faPlayCircle,
  faPenNib,
  faAward,
  faBrush,
  faHashtag,
  faBalanceScaleLeft,
  faCommentDollar,
  faCommentMedical,
  faHandHoldingUsd,
  faHandHoldingWater,
  faHandPointUp,
  faCameraRetro,
  faUtensils,
  faWallet
);

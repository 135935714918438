import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Nav, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import AssetBanner from './AssetBanner';
import AssetEconomic from './AssetEconomic';
import AssetPricingPlan from './AssetPricingPlan';
import AssetReform from './AssetReform';
import AssetReformEconomic from './AssetReformEconomic';
import AssetSummary from './AssetSummary';
import SimilarAssets from './SimilarAssets';
import AssetLocation from './AssetLocation';
import AssetProsCons from './AssetProsCons';
import AssetNearbyServices from './AssetNearbyServices';
import { UserContext } from 'context/Context';
import useBreakpoints from 'hooks/useBreakpoints';
import useScrollSpy from 'react-use-scrollspy';

const TopNav = ({ sectionRefs, at = 'asset-detail' }) => {
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -200
  });
  const navRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const scrollableNodeRef = useRef(null);

  useEffect(() => {
    const navItem = navRefs?.[activeSection]?.current;
    scrollableNodeRef.current.scrollLeft = navItem.getBoundingClientRect().x;
  }, [activeSection]);

  return (
    <div
      className={classNames('bg-white dark__bg-1100 border-bottom pt-4', {
        'sticky-sidebar': at === 'asset-detail',
        'sticky-top': at === 'favourites'
      })}
    >
      <SimpleBarReact
        className="asset-top-nav z-index-0"
        scrollableNodeProps={{ ref: scrollableNodeRef }}
      >
        <Nav className="flex-row flex-nowrap" variant="underline">
          <Nav.Link
            ref={navRefs[0]}
            className="pb-3 text-nowrap"
            href="#nearbyservices"
            active={activeSection === 0}
          >
            <span
              className={classNames('fs--1 fw-semi-bold text-500', {
                'text-black': activeSection === 0
              })}
            >
              Servicios cercanos
            </span>
          </Nav.Link>
          <Nav.Link
            ref={navRefs[1]}
            className="pb-3 text-nowrap"
            href="#location"
            active={activeSection === 1}
          >
            <span
              className={classNames('fs--1 fw-semi-bold text-500', {
                'text-black': activeSection === 1
              })}
            >
              Localización
            </span>
          </Nav.Link>
          <Nav.Link
            ref={navRefs[2]}
            className="pb-3 text-nowrap"
            href="#economic"
            active={activeSection === 2}
          >
            <span
              className={classNames('fs--1 fw-semi-bold text-500', {
                'text-black': activeSection === 2
              })}
            >
              Análisis económico
            </span>
          </Nav.Link>
          <Nav.Link
            ref={navRefs[3]}
            className="pb-3 text-nowrap"
            href="#reform"
            active={activeSection === 3}
          >
            <span
              className={classNames('fs--1 fw-semi-bold text-500', {
                'text-black': activeSection === 3
              })}
            >
              Reforma
            </span>
          </Nav.Link>
        </Nav>
      </SimpleBarReact>
    </div>
  );
};

TopNav.propTypes = {
  sectionRefs: PropTypes.array,
  at: PropTypes.string
};

const AssetDetail = ({ asset, at }) => {
  const { hasActiveSubscription } = useContext(UserContext);
  const isSubscribed = hasActiveSubscription();
  const { breakpoints } = useBreakpoints();
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  return (
    <>
      <AssetBanner asset={asset} />
      <Row className="g-lg-4 mt-3 mb-4 position-relative z-index-1">
        <Col lg={8} className="order-1 order-lg-0">
          <div id="summary">
            <AssetSummary asset={asset} />
          </div>
          <div id="prosconsts" className="pt-5 mt-n4">
            <AssetProsCons asset={asset} />
          </div>
          <TopNav sectionRefs={sectionRefs} at={at} />
          <hr className="border-0 my-2" />
          <div ref={sectionRefs[0]} id="nearbyservices" className="pt-5 mt-n4">
            <AssetNearbyServices asset={asset} />
          </div>
          <hr className="my-5" />
          <div ref={sectionRefs[1]} id="location" className="pt-5 mt-n4">
            <AssetLocation asset={asset} />
          </div>
          <hr className="my-5" />
          <div className="position-relative">
            {!isSubscribed && (
              <Flex
                alignItems="center"
                justifyContent="center"
                className="position-absolute start-0 top-0 w-100 h-100"
              >
                <div className="position-sticky sticky-bottom top-50 p-5">
                  <Button>Solicitar informe</Button>
                </div>
              </Flex>
            )}
            <div ref={sectionRefs[2]} id="economic" className="pt-5 mt-n4">
              <AssetEconomic asset={asset} />
            </div>
            <hr className="my-5" />
            <div ref={sectionRefs[3]} id="reform" className="pt-5 mt-n4">
              <AssetReform asset={asset} />
              {!!asset?.reform?.amount && (
                <>
                  <hr className="my-5" />
                  <div className="pt-5 mt-n4">
                    <AssetReformEconomic asset={asset} />
                  </div>
                </>
              )}
            </div>
          </div>

          <Alert variant="secondary" dismissible>
            <Alert.Heading>
              <FontAwesomeIcon icon="exclamation-triangle" className="me-2" />
              Ten en cuenta que...
            </Alert.Heading>
            <p>
              Estos datos son orientativos y están basados en los datos
              extraidos de los portales inmobiliarios y generados con IA.
            </p>
          </Alert>
          <div className="pt-5 mt-n4">
            <SimilarAssets asset={asset} />
          </div>
          {breakpoints.down('md') && <hr className="border-0 pb-5" />}
        </Col>
        <Col lg={4} className="mt-3">
          <div className="position-sticky asset-details-sticky-sidebar mb-lg-7 mt-xl-n8 z-index-1">
            <AssetPricingPlan asset={asset} />
          </div>
        </Col>
      </Row>
    </>
  );
};

AssetDetail.propTypes = {
  asset: PropTypes.object,
  at: PropTypes.string
};

export default AssetDetail;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import KanbanColumnHeader from './KanbanColumnHeader';
import FavouriteCard from './FavouriteCard';
import StrictModeDroppable from './StrictModeDroppable';

const FavouritesColumn = ({ kanbanColumnItem }) => {
  const { id, title, items = [] } = kanbanColumnItem;
  const formViewRef = useRef(null);

  return (
    <div className="kanban-column">
      <KanbanColumnHeader
        column={kanbanColumnItem}
        id={id}
        title={title}
        itemCount={items.length}
      />
      <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
        {provided => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id="Column-favourites"
              className="kanban-items-container border-bottom border-200 border-2 pb-3 scrollbar kanban-column-footer"
            >
              {items.map(({ asset, id }, index) => (
                <FavouriteCard
                  key={`Favourite-${id}`}
                  id={id}
                  index={index}
                  asset={asset}
                />
              ))}
              {provided.placeholder}
              <div ref={formViewRef}></div>
            </div>
          </>
        )}
      </StrictModeDroppable>
    </div>
  );
};

FavouritesColumn.propTypes = {
  kanbanColumnItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string,
    items: PropTypes.arrayOf(FavouriteCard.propTypes.task),
    unit: PropTypes.string
  })
};

export default FavouritesColumn;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

const AssetNearbyServices = ({ asset }) => {
  const { ai } = asset || {};
  const { nearby_services: nearbyServices } = ai || {};

  return (
    <div className="avoid-break-inside">
      <FalconCardHeader className="mb-3" title="Servicios cercanos" />
      <div className="pt-3">
        <Flex className="gap-1" wrap="wrap">
          {nearbyServices?.map?.(service => (
            <SoftBadge key={service}>
              <Flex alignItems="center" className="gap-2">
                <FontAwesomeIcon icon="location-dot" />
                <span>{service}</span>
              </Flex>
            </SoftBadge>
          )) || ''}
        </Flex>
      </div>
    </div>
  );
};

AssetNearbyServices.propTypes = {
  asset: PropTypes.object
};

export default AssetNearbyServices;

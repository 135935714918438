import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import useBreakpoints from 'hooks/useBreakpoints';
import corner from 'assets/img/illustrations/corner-2.png';

const ProfileDropdown = ({ at }) => {
  const { breakpoints } = useBreakpoints();
  const { me } = useContext(UserContext);
  let { data, email, displayName = 'IP', photoURL } = me || {};
  // const { lastRefreshAt } = reloadUserInfo || {};
  const { avatar: dataAvatar } = data || {};
  const avatar = `${dataAvatar || photoURL}`;

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" as={Link} className="p-0 nav-link">
        {at === 'main' && breakpoints.down('xl') ? (
          <button
            className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
            id="burgerMenu"
          >
            <span className="navbar-toggle-icon">
              <span className="toggle-line" />
            </span>
          </button>
        ) : (
          <Avatar
            className="d-block border-0 btn btn-falcon-default p-0"
            src={avatar}
            name={displayName}
          />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end pb-3">
        <div className="bg-white position-relative overflow-hidden rounded-top">
          <Background image={corner} />
          <Dropdown.Header className="p-2">
            <Avatar
              className="border border-200 h-auto max-h-200px min-w-200px w-100"
              rounded="1"
              size="3xl"
              src={avatar}
              name={displayName}
            />
            <Flex alignItems="center">
              <div className="p-2">
                <div className="text-dark fs--1">{displayName}</div>
                <div className="text-600 fs--2 text-400 text-lowercase">
                  {email}
                </div>
              </div>
            </Flex>
          </Dropdown.Header>
        </div>
        <div className="bg-white rounded-2 dark__bg-1000">
          <Dropdown.Divider className="mt-0" />

          {at === 'main' && breakpoints.down('xl') && (
            <>
              <Dropdown.Item as={Link} to="/assets">
                Explora
              </Dropdown.Item>

              <Dropdown.Item as={Link} to="/favourites">
                Favoritos
              </Dropdown.Item>

              <Dropdown.Divider />
            </>
          )}

          <Dropdown.Item as={Link} to="/account">
            Mi perfil
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/subscription">
            Subscripción
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item as={Link} to="/authentication/logout">
            Cerrar sesión
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  at: PropTypes.string
};

export default ProfileDropdown;

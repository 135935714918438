import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginFormWithLink from 'components/authentication/LoginFormWithLink';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/dashboard-alt.jpg';
import bgImgDark from 'assets/img/generic/dashboard-alt-dark.jpg';
import AppContext from 'context/Context';

const Login = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { code } = useParams();
  const { email, name, phone } = JSON.parse(atob(code));
  const image = isDark ? bgImgDark : bgImg;

  return (
    <AuthSplitLayout bgProps={{ image, overlay: '3', position: 'left top' }}>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Bienvenido a Inversor Pro</h5>
      </Flex>
      <LoginFormWithLink defaultValues={{ email, name, phone }} />
    </AuthSplitLayout>
  );
};

export default Login;

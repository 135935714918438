import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import Flex from 'components/common/Flex';
import { CalculatorContext } from 'context/Context';
import ccaa from 'data/ccaa.json';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    minHeight: 38
  }),
  placeholder: base => ({
    ...base,
    minHeight: 38,
    lineHeight: '38px'
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const AreaTax = () => {
  const {
    area: { label, tax, shortName } = {},
    asset,
    setAreaByShortName
  } = useContext(CalculatorContext);
  const { id, areaName, name } = asset || {};
  const currentArea =
    ccaa.find(({ short_name: sn }) => sn === shortName) || null;

  const handleAreaChange = ({ value }) => {
    setAreaByShortName(value);
  };

  return (
    <Card className="border overflow-hidden mt-3">
      <Card.Header className="pb-0">
        {id && (
          <>
            <Card.Title>{name}</Card.Title>
            <Card.Title className="text-400">{areaName}</Card.Title>
            <hr className="border-top border-dashed" />
          </>
        )}
        <Card.Title>
          <small>Impuesto</small>
        </Card.Title>
      </Card.Header>
      <Card.Body className="pt-2">
        <div>
          <Flex alignItems="center" justifyContent="between" className="gap-4">
            {id ? (
              <Card.Text className="m-0">{label}</Card.Text>
            ) : (
              <div className="flex-grow-1 mt-2">
                <Select
                  menuPlacement="auto"
                  options={ccaa.map(({ label, short_name }) => ({
                    label,
                    value: short_name
                  }))}
                  value={currentArea}
                  placeholder="Selecciona la comunidad"
                  menuPortalTarget={document.body}
                  classNamePrefix="react-select"
                  onChange={handleAreaChange}
                  styles={styles}
                />
              </div>
            )}
            <Card.Title className="m-0">{tax || '-'}%</Card.Title>
          </Flex>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AreaTax;

import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import AreaTax from './AreaTax';
import Expenses from './Expenses';
import Incomes from './RentIncomes';
import Mortgage from './Mortgage';
import RentBenefit from './RentBenefit';
import RentRentability from './RentRentability';
import TopNav from './TopNav';
import { CalculatorContext } from 'context/Context';
import useVisibilityObserver from 'hooks/useVisibilityObserver';

const RentContent = () => {
  const { asset } = useContext(CalculatorContext);
  const targetElRef = useRef();
  const { observer } = useVisibilityObserver(targetElRef, '100px');

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Row className="g-lg-4 mt-3 mb-4 position-relative z-index-1">
        <Col lg={8} className="mt-0 order-1 order-lg-0">
          <TopNav />
          <hr className="border-0 mt-0" />
          <Expenses />
          <Incomes />
        </Col>
        <Col lg={4} className="mt-0">
          <div
            className={classNames(
              'position-sticky asset-details-sticky-sidebar z-index-1',
              {
                'sticky-sidebar': asset?.id,
                'sticky-top': !asset?.id
              }
            )}
          >
            <AreaTax />
            <RentBenefit />
            <RentRentability />
            <Mortgage />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RentContent;

import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SubscriptionInfo from 'components/subscription/SubscriptionInfo';
import { sources } from 'data/assets/assetData';
import { UserContext } from 'context/Context';
import { getCurrencyFormat } from 'helpers/utils';

const PricingDefaultCard = ({ button, currentPrice, price, index }) => {
  const { search } = useLocation();
  const buttonRef = useRef();
  const { billingPortalUrl, me } = useContext(UserContext);
  const {
    id,
    unit_amount: centsAmount,
    isFeatured,
    metadata,
    product,
    recurring
  } = price || {};
  const { link } = metadata || {};
  const { name, description, marketing_features: features } = product || {};
  const { interval } = recurring || {};
  const amount =
    interval === 'year' ? centsAmount / 100 / 12 : centsAmount / 100;
  const checkoutLink = me?.uid
    ? link && !currentPrice?.id
      ? `${link}?locale=es&prefilled_email=${me?.email}`
      : billingPortalUrl
    : `/authentication/register${link ? `?checkout=${link}` : ''}`;
  const isCurrent =
    me?.uid &&
    typeof currentPrice !== 'undefined' &&
    currentPrice?.id === price?.id;

  useEffect(() => {
    search === '?edit=true' && billingPortalUrl && buttonRef.current?.click();
  }, [billingPortalUrl]);

  return (
    <Col
      className={classNames({
        'border-start': index,
        'dark__bg-1000 px-4 px-lg-0': isFeatured
      })}
      style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
    >
      <Flex direction="column" className="h-100">
        <Flex direction="column" className="p-4">
          <h3 className="fw-normal flex-grow-1">{name}</h3>
          {!isNaN(amount) ? (
            <h2 className="fw-medium mt-4">
              {getCurrencyFormat(amount, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              <small className="fs--1 text-700">/ mes</small>
            </h2>
          ) : (
            <small className="fs--1 text-700">{interval}</small>
          )}
          <p className="small flex-grow-1">{description}</p>
          {button || (
            <Button
              ref={buttonRef}
              as={!isCurrent && Link}
              className="shadow-none opacity-100 position-relative"
              variant={`${isFeatured || isCurrent ? '' : 'outline-'}${
                isCurrent ? '200' : 'primary'
              }`}
              size="lg"
              disabled={isCurrent || (me?.uid && !amount)}
              to={checkoutLink}
            >
              {isCurrent
                ? 'Plan actual'
                : me?.uid && currentPrice?.id
                ? 'Edita tu suscripción'
                : amount
                ? 'Suscríbete'
                : 'Regístrate gratis'}
            </Button>
          )}
          {isCurrent && (
            <div className="bg-light border border-200 border-top-0 mt-n3 pt-3 rounded-bottom text-start fs--1">
              <SubscriptionInfo />
            </div>
          )}
        </Flex>
        <div className="flex-grow-1">
          <div className="text-start px-4">
            <ul className="list-unstyled mt-3">
              {features?.map(({ name }, index) => {
                const { svg } =
                  sources.find(({ label }) => label === name) || {};
                return (
                  <Flex
                    className="py-1"
                    key={`${id}-feature${index}`}
                    as="li"
                    alignItems="center"
                  >
                    {svg ? (
                      <span className="ps-2">
                        <Avatar src={svg} className="ms-3 me-2" size="s" />
                      </span>
                    ) : (
                      <FontAwesomeIcon
                        icon="check"
                        className="me-2 text-success"
                      />
                    )}{' '}
                    {name}
                  </Flex>
                );
              })}
            </ul>
          </div>
        </div>
      </Flex>
    </Col>
  );
};

PricingDefaultCard.propTypes = {
  button: PropTypes.node,
  currentPrice: PropTypes.object,
  price: PropTypes.object,
  index: PropTypes.number
};

export default PricingDefaultCard;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Dropdown, Image, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import corner2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import chip from 'assets/img/icons/chip.png';
import mastercard from 'assets/img/icons/master-card.png';
import visa from 'assets/img/icons/visa.png';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Background from 'components/common/Background';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';

const cardTypes = {
  mastercard,
  visa
};

const PaymentMethods = () => {
  const { billingPortalUrl, paymentMethods } = useContext(UserContext);
  const method = paymentMethods?.find(({ type }) => type === 'card');
  const { card, id, livemode } = method || {};
  const {
    brand,
    checks,
    exp_month: expMonth,
    exp_year: expYear,
    funding,
    last4
  } = card || {};
  const { cvc_check: cvcCheck } = checks || {};

  return (
    <Card className="h-100">
      <FalconCardHeader
        light
        title="Método de pago"
        titleTag="h6"
        className="py-2"
        endEl={
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item as={Link} to={billingPortalUrl}>
                Cambiar método de pago
              </Dropdown.Item>
            </div>
          </CardDropdown>
        }
      />
      <Card.Body>
        <Row className="g-3 h-100">
          {!card ? (
            <Col
              className="py-4"
              as={Flex}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <p className="fs--1">No hay métodos de pago</p>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={['fab', 'stripe']}
                iconClassName="fs-3 me-2 d-inline align-middle"
                as={Link}
                to={billingPortalUrl}
              >
                <span className="d-none d-sm-inline align-middle">
                  Crear método de pago
                </span>
              </IconButton>
            </Col>
          ) : (
            <>
              <Col sm={6} lg={12}>
                <Card className="border border-200 position-relative rounded-4">
                  <Background
                    image={corner2}
                    className="p-x1 bg-card rounded-4"
                  />
                  <Card.Body className="p-3 pt-5">
                    <Image
                      fluid
                      width={30}
                      src={chip}
                      alt="mastercard chip"
                      className="mb-3"
                    />
                    <h6 className="text-primary font-base lh-1 mb-1">
                      **** **** **** {last4}
                    </h6>
                    <h6 className="fs--2 fw-semi-bold text-facebook mb-3">
                      {expMonth}/{expYear}
                    </h6>
                    {brand && (
                      <Image
                        fluid
                        width={70}
                        src={cardTypes[brand]}
                        alt={`${brand} logo`}
                        className="position-absolute end-0 bottom-0 mb-2 me-2"
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} lg={12}>
                <Table
                  borderless
                  className="fw-medium font-sans-serif fs--1 mb-2"
                >
                  <tbody>
                    <tr>
                      <td className="p-1" style={{ width: '35%' }}>
                        Tipo:
                      </td>
                      <td className="p-1 text-600 text-uppercase">{brand}</td>
                    </tr>
                    <tr>
                      <td className="p-1" style={{ width: '35%' }}>
                        Fecha de exp.:
                      </td>
                      <td className="p-1 text-600 text-uppercase">
                        {dayjs(`${expYear}-${expMonth}`).format('MMM YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1" style={{ width: '35%' }}>
                        Fondos:
                      </td>
                      <td className="p-1 text-600">{funding}</td>
                    </tr>
                    <tr>
                      <td className="p-1" style={{ width: '35%' }}>
                        ID:
                      </td>
                      <td className="p-1 text-600">
                        <span className="d-block text-truncate max-w-180px">
                          {id}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {cvcCheck && (
                  <SoftBadge bg="success" pill className="me-2">
                    Verified
                    <FontAwesomeIcon icon="check" className="ms-1" />
                  </SoftBadge>
                )}
                {!livemode && (
                  <SoftBadge bg="warning" pill className="me-2">
                    Non Billable
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="ms-1"
                    />
                  </SoftBadge>
                )}
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PaymentMethods;

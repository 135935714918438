import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    paddingLeft: '1.25rem'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  input: base => ({ ...base, boxShadow: 'none' })
};

const MultiSelect = forwardRef(({ options, placeholder, ...rest }, ref) => {
  return (
    <Select
      ref={ref}
      closeMenuOnSelect={false}
      isMulti
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select"
      styles={styles}
      {...rest}
    />
  );
});

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string
};

export default MultiSelect;

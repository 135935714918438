import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import AssetImages from './AssetImages';
import AssetInformationForm from './AssetInformationForm';
import AssetPreviewVideo from './AssetPreviewVideo';
import AssetPricing from './AssetPricing';
import AssetProfit from './AssetProfit';
import AssetFeatures from './AssetFeatures';
import AssetLocation from './AssetLocation';
import CreateAssetHeader from './CreateAssetHeader';
import CreateAssetFooter from './CreateAssetFooter';
import PublishAsset from './PublishAsset';
import ScheduleDiscountModal from './ScheduleDiscountModal';
import { AssetsContext } from 'context/Context';

const CreateAsset = () => {
  const { createAsset, publishAsset, updateAsset } = useContext(AssetsContext);
  const navigate = useNavigate();
  const { source, assetId } = useParams();
  const assetRef = doc(
    useFirestore(),
    source && assetId ? 'assets' : 'none',
    source && assetId ? `${source}-${assetId}` : 'none'
  );
  const { data } = useFirestoreDocData(assetRef);
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const defaultValues = {
    id: `inversorpro-${Date.now()}_${Math.random()
      .toString(16)
      .replace('0.', '')}`,
    rooms: 2,
    bathrooms: 1,
    locationHidden: false,
    private: false,
    source: 'inversorpro',
    sponsored: false,
    type: 'flat'
  };
  const { register, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: { ...defaultValues, ...data }
  });
  const asset = watch();

  const onSubmit = async data => {
    reset(defaultValues);
    await publishAsset(data);
    navigate(assetId ? '/account' : '/assets/asset-create');
  };

  const saveDraft = async () => {
    await (assetId
      ? updateAsset(asset, {
          ...asset,
          areaId: null,
          status: 'archived'
        })
      : createAsset({ ...asset, status: 'draft' }));

    reset(defaultValues);
    navigate('/account');
  };

  useEffect(() => {
    if (typeof data !== 'object') {
      return;
    }
    reset({ ...defaultValues, ...data });
  }, [data]);

  return (
    <>
      <Form className="my-3" onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-lg-3">
          <Col lg={8}>
            <CreateAssetHeader saveDraft={saveDraft} />
            <AssetInformationForm asset={asset} register={register} />
            <AssetLocation asset={asset} setValue={setValue} />
            <AssetFeatures asset={asset} setValue={setValue} />
            <CreateAssetFooter
              asset={asset}
              onSubmit={onSubmit}
              saveDraft={saveDraft}
            />
          </Col>
          <Col lg={4}>
            <AssetPricing
              asset={asset}
              register={register}
              isOpenScheduleModal={isOpenScheduleModal}
              setIsOpenScheduleModal={setIsOpenScheduleModal}
            />
            <AssetImages asset={asset} setValue={setValue} />
            <AssetPreviewVideo register={register} />
            <AssetProfit asset={asset} register={register} />
            <PublishAsset asset={asset} register={register} />
          </Col>
        </Row>
      </Form>
      {/* Modal */}
      <ScheduleDiscountModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
      />
    </>
  );
};

export default CreateAsset;

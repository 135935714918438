import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';

const types = {
  young: {
    label: 'Joven profesional',
    value: 'young',
    description: 'Empezando a invertir'
  },
  saver: {
    label: 'Ahorrador',
    value: 'saver',
    description: 'Buscando alternativas'
  },
  noob: {
    label: 'Inversor novato',
    value: 'noob',
    description: 'Diversificando cartera'
  },
  tech: {
    label: 'Amante de la tecnología',
    value: 'tech',
    description: 'Atraídos por las TI'
  },
  millennial: {
    label: 'Millennial',
    value: 'millennial',
    description: 'Valores y estilo de vida'
  },
  foreigner: {
    label: 'Inversor extranjero',
    value: 'foreigner',
    description: 'Buscando oportunidades'
  },
  retired: {
    label: 'Jubilado',
    value: 'retired',
    description: 'Buscando ingresos pasivos'
  },
  entrepreneur: {
    label: 'Emprendedor',
    value: 'entrepreneur',
    description: 'Con potencial de crecimiento'
  },
  family: {
    label: 'Familia',
    value: 'family',
    description: 'Asegurando el futuro'
  },
  experienced: {
    label: 'Inversor experimentado',
    value: 'experienced',
    description: 'Buscando un bajo riesgo'
  },
  realestate: {
    label: 'Inmobiliaria',
    value: 'realestate',
    description: 'Ofreciendo inmuebles'
  }
};

const InvestorType = () => {
  const { me, updateData } = useContext(UserContext);
  const { data } = me || {};
  const { type = 'noob' } = data || {};
  const { label, description } = types[type] || {};

  const handleChange = ({ target }) => {
    const { value: type } = target;
    updateData({ type });
  };

  return (
    <Card className="font-sans-serif">
      <Card.Header className="pb-0">
        <h6>
          Tipo de inversor
          <FontAwesomeIcon icon="angle-down" className="ms-2" />
        </h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <Form.Select
          className="opacity-0 position-absolute cursor-pointer top-0 start-0 end-0 bottom-0"
          value={type}
          onChange={handleChange}
        >
          {Object.values(types).map(({ label, value }) => (
            <option key={`InvestorType-${value}`} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>

        <div>
          <h4 className="text-700 lh-1 mb-1">{label}</h4>
          <SoftBadge bg="secondary" pill className="fs--2">
            {description}
          </SoftBadge>
        </div>
        <div className="p-2 pt-0">
          <FontAwesomeIcon icon="user-tag" size="3x" />
        </div>
      </Card.Body>
    </Card>
  );
};

export default InvestorType;

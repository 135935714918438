import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import NavbarTop from 'components/navbar/top/NavbarTop';
import Flex from 'components/common/Flex';
import LogoLoader from 'components/common/LogoLoader';
import Footer from 'components/footer/Footer';
import AssetsProvider from 'components/AssetsProvider';
import UpgradeModal from 'components/UpgradeModal';
import UserProvider from 'components/UserProvider';
import AppContext, { UserContext } from 'context/Context';
import useBreakpoints from 'hooks/useBreakpoints';

const whiteBgPages = ['assets'];
const grayBgPages = ['asset-create'];

const MainLayout = () => {
  const { breakpoints } = useBreakpoints();
  const {
    config: { isFluid },
    setConfig
  } = useContext(AppContext);
  const {
    upgradeModalContent,
    setUpgradeModalContent,
    upgradeModalShow,
    setUpgradeModalShow,
    subscription
  } = useContext(UserContext);
  const location = useLocation();
  const { hash, pathname } = location;
  const isAssetsPage = !!pathname.match(/\/assets(\/)?$/);
  const isFluidPage = !!pathname.match(/\/(assets|favourites)(\/)?$/);
  const user = JSON.parse(Cookies.get('__fbu') || '{}');
  const isSignedIn = !!user.uid;
  const [, page] = pathname.match(/\/([^/]*)/) || [];
  const [, subpage] = pathname.match(/\/[^/]*\/([^/]*)/) || [];

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    !hash && window.scrollTo(0, 0);
    setConfig('isFluid', isFluidPage);
    setConfig('navbarPosition', isAssetsPage ? 'double-top' : 'top');
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', pathname]);
    _hsq.push(['trackPageView']);
  }, [pathname]);

  useEffect(() => {
    if (!hash) {
      return;
    }
    setTimeout(() => {
      document.querySelector(hash)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }, 500);
  }, [hash]);

  if (!isSignedIn) {
    return (
      <Navigate
        to={{
          ...location,
          pathname: '/authentication/login',
          state: { from: location }
        }}
        replace={true}
      />
    );
  }

  if (typeof subscription === 'undefined') {
    return <LogoLoader className="min-vh-100 mt-n6" />;
  }

  return (
    <Flex
      direction="column"
      className={classNames('content p-0', {
        'bg-200':
          (!whiteBgPages.includes(page) && !whiteBgPages.includes(subpage)) ||
          grayBgPages.includes(subpage)
      })}
    >
      <AssetsProvider>
        <NavbarTop at={page} />
        <div
          className={classNames({
            'container-fluid': isFluid || breakpoints.down('md'),
            'p-0 h-0 flex-grow-1 d-flex flex-column': isFluid,
            container: !isFluid && breakpoints.up('md')
          })}
        >
          <UpgradeModal
            show={upgradeModalShow}
            setShow={setUpgradeModalShow}
            setContent={setUpgradeModalContent}
          >
            {upgradeModalContent}
          </UpgradeModal>
          <Outlet />
        </div>
        <Footer />
      </AssetsProvider>
    </Flex>
  );
};

const MainLayoutWithUser = props => {
  return (
    <UserProvider>
      <MainLayout {...props} />
    </UserProvider>
  );
};

export default MainLayoutWithUser;

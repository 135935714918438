import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import AppContext, { AssetsContext } from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import TrialBanner from 'components/subscription/TrialBanner';
import SearchBox from './SearchBox';
import AssetFilters from 'pages/assets/AssetFilters';
import AssetHeader from 'pages/assets/AssetHeader';
import { topNavbarBreakpoint } from 'config';
import SourceLoading from './SourceLoading';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useBreakpoints } from 'hooks/useBreakpoints';

const SearchBoxWrapper = () => {
  return <SearchBox at="assets" />;
};

const AssetsPageHeader = () => {
  const { breakpoints } = useBreakpoints();
  const {
    config: { isFluid, navbarCollapsed }
  } = useContext(AppContext);
  const { showFilterOffcanvas, setShowFilterOffcanvas } =
    useContext(AssetsContext);
  return (
    <>
      <hr className="mt-0 mb-2 d-none d-lg-block" />
      <div
        className={classNames('d-block px-3', {
          'container-fluid': isFluid || breakpoints.down('md'),
          container: !isFluid && breakpoints.up('md')
        })}
      >
        <Navbar.Collapse in={navbarCollapsed} className="scrollbar">
          <AssetHeader setShowFilterOffcanvas={setShowFilterOffcanvas} />
          {breakpoints.down('xl') && (
            <Offcanvas
              show={showFilterOffcanvas}
              onHide={() => setShowFilterOffcanvas(false)}
              placement="start"
              className="offcanvas offcanvas-filter-sidebar"
            >
              <Offcanvas.Header closeButton>
                <h6 className="fs-0 mb-0 fw-semi-bold">Filtros</h6>
              </Offcanvas.Header>
              <Offcanvas.Body className="scrollbar scrollbar-none-xl">
                <AssetFilters
                  isOffcanvas={true}
                  setShow={setShowFilterOffcanvas}
                />
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </Navbar.Collapse>
      </div>
      <hr className="border-0 mt-2 mb-0 d-none d-lg-block" />
    </>
  );
};

const NavbarTop = () => {
  const { breakpoints } = useBreakpoints();
  const { pathname } = useLocation();
  const {
    config: { isFluid, showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);
  const isAssetsPage = !!pathname.match(/\/assets(\/)?$/);
  const handleBurgerMenu = () => {
    (navbarPosition === 'top' || navbarPosition === 'double-top') &&
      setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const burgerMenuRef = useRef();

  return (
    <Navbar
      className={classNames(
        'm-0 p-0 px-0 bg-white dark__bg-1100 border-bottom fs--1 navbar-top sticky-kit',
        {
          'sticky-top': breakpoints.down('md')
        }
      )}
      expand={
        navbarPosition === 'top' ||
        navbarPosition === 'combo' ||
        navbarPosition === 'double-top'
          ? topNavbarBreakpoint
          : true
      }
    >
      <div className="w-100">
        <div
          className={classNames('d-block px-3', {
            'container-fluid': isFluid || breakpoints.down('md'),
            container: !isFluid && breakpoints.up('md')
          })}
        >
          <Nav className="d-flex flex-row flex-between-center">
            <NavbarTopElements
              navbarCollapsed={navbarCollapsed}
              navbarPosition={navbarPosition}
              handleBurgerMenu={handleBurgerMenu}
              burgerMenuRef={burgerMenuRef}
            />
          </Nav>
        </div>
        {isAssetsPage && <AssetsPageHeader />}
        <TrialBanner />
      </div>
    </Navbar>
  );
};

NavbarTop.propTypes = {
  at: PropTypes.string
};

const NavbarTopElements = () => {
  const { breakpoints } = useBreakpoints();
  const { pathname } = useLocation();
  const { setShowFilterOffcanvas } = useContext(AssetsContext);
  return (
    <>
      <Logo
        at="navbar-top"
        height={breakpoints.down('lg') ? 20 : 25}
        id="topLogo"
        to={pathname !== '/assets' ? '/assets' : undefined}
      />

      {breakpoints.up('md') && <SourceLoading />}

      <Nav.Item
        as="li"
        className={classNames({
          'position-absolute start-0 end-0 z-index-1': breakpoints.up('md'),
          'w-0 flex-grow-1': breakpoints.down('md')
        })}
      >
        <Flex justifyContent="center">
          <SearchBoxWrapper />
          {breakpoints.down('lg') && (
            <Button
              onClick={() => setShowFilterOffcanvas(true)}
              className="position-relative p-0"
              size="sm"
              variant="link"
            >
              <FontAwesomeIcon icon="filter" className="fs-1 text-700 ms-3" />
            </Button>
          )}
        </Flex>
      </Nav.Item>

      {breakpoints.up('md') && <TopNavRightSideNavItem />}
    </>
  );
};

NavbarTopElements.propTypes = {
  navbarPosition: PropTypes.string,
  handleBurgerMenu: PropTypes.func
};
export default NavbarTop;

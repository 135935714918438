import React, { useContext, useEffect } from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import bgImg from 'assets/img/generic/dashboard-alt.jpg';
import bgImgDark from 'assets/img/generic/dashboard-alt-dark.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import AppContext, { UserContext } from 'context/Context';

const LogoutWithUser = () => {
  const { signOut } = useContext(UserContext);

  useEffect(() => {
    signOut();
  }, []);

  return (
    <div className="text-center">
      <LogoutContent layout="split" titleTag="h3" />
    </div>
  );
};
const Logout = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const image = isDark ? bgImgDark : bgImg;

  return (
    <AuthSplitLayout bgProps={{ image, overlay: '3', position: 'left top' }}>
      <LogoutWithUser />
    </AuthSplitLayout>
  );
};

export default Logout;

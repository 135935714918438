import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';
import {
  clearSelection,
  getCurrencyFormat,
  selectElementContents
} from 'helpers/utils';

const Budget = () => {
  const input = useRef();
  const { me, updateData } = useContext(UserContext);
  const { data } = me || {};
  const { budget } = data || {};
  const { amount, prev } = budget || {};
  let percent = 0;
  if (prev) {
    percent = parseInt((Math.abs(amount - prev) * 100) / prev, 10);
  }

  const handleCardClick = () => {
    input.current.focus();
    selectElementContents(input.current);
  };

  const handleBlur = ({ target }) => {
    clearSelection();
    const { textContent } = target;
    const newAmount = parseInt(
      textContent.trim().replace(/[.]/g, '').replace(',', '.'),
      10
    );
    if (newAmount === amount) {
      return;
    }
    const prevData = {};
    amount && (prevData.prev = amount);
    const budget = { amount: newAmount, ...prevData };
    updateData({ budget });
  };

  const handleKeyDown = event => {
    const { key, target } = event;
    if (key === 'Enter') {
      event.preventDefault();
      target.blur();
    }
  };

  return (
    <Card className="font-sans-serif cursor-pointer" onClick={handleCardClick}>
      <Card.Header className="pb-0">
        <h6>Presupuesto</h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h4 className="text-700 lh-1 mb-1 d-flex">
            <div
              ref={input}
              contentEditable={true}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              suppressContentEditableWarning={true}
            >
              {
                getCurrencyFormat(amount || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }).split(' ')[0]
              }
            </div>
            <span className="ms-2">€</span>
          </h4>
          <SoftBadge
            bg={classNames({
              danger: prev && prev > amount,
              success: prev && prev < amount,
              secondary: !prev
            })}
            pill
            className="fs--2"
          >
            <FontAwesomeIcon
              icon={classNames({
                'caret-down': prev > amount,
                'caret-up': prev < amount,
                minus: !prev
              })}
              className="me-1"
            />
            {!!percent && `${percent}%`} Último presupuesto
          </SoftBadge>
        </div>
        <div className="p-2 pt-0">
          <FontAwesomeIcon icon="hand-holding-usd" size="3x" />
        </div>
      </Card.Body>
    </Card>
  );
};

export default Budget;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form } from 'react-bootstrap';
// import { free }s from 'components/UserProvider';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
// import AppContext, { UserContext } from 'context/Context';

const PublishAsset = ({ asset, register }) => {
  // const { subscription } = useContext(UserContext);
  // const { plan } = subscription || {};
  // const { product } = subscription === null ? free : plan || {};
  // const { metadata } = product || {};
  // const { limit } = metadata || {};
  const {
    locationHidden = false,
    private: isPrivate = false,
    sponsored = false
  } = asset || {};

  return (
    <Card className="sticky-sidebar">
      <Card.Header className="d-flex flex-between-center">
        <h5 className="mb-0">Opciones de publicación</h5>
      </Card.Header>
      <Card.Body className="bg-light">
        <Form.Group>
          <Flex className="gap-2">
            <FontAwesomeIcon icon="lock" className="mt-1" />
            <div className="flex-grow-1">
              <Flex
                className="gap-2"
                alignItems="center"
                justifyContent="between"
              >
                <Form.Label htmlFor="private" className="m-0">
                  Privado:
                  <p className="fs--1 fw-normal m-0">
                    El inmueble será accesible sólo para quien tú quieras{' '}
                    {/* {!!limit && (
                        <SoftBadge bg="warning">Coste de 3 token</SoftBadge>
                      )} */}
                    <SoftBadge>Próximamente</SoftBadge>
                  </p>
                </Form.Label>
                <Form.Switch
                  id="private"
                  checked={isPrivate}
                  disabled={true}
                  {...register('private')}
                />
              </Flex>
            </div>
          </Flex>
        </Form.Group>
        <Form.Group className="border-top mt-2 pt-2">
          <Flex className="gap-2" alignItems="start">
            <FontAwesomeIcon icon="star" className="mt-1" />
            <div className="flex-grow-1">
              <Flex
                className="gap-2"
                alignItems="center"
                justifyContent="between"
              >
                <Form.Label htmlFor="sponsored" className="m-0">
                  Destacado:
                  <p className="fs--1 fw-normal m-0">
                    El anuncio del inmueble saldrá como destacado en los
                    resultados de la búsqueda{' '}
                    {/* {!!limit && (
                        <SoftBadge bg="warning">Coste de 3 token</SoftBadge>
                      )} */}
                    <SoftBadge>Próximamente</SoftBadge>
                  </p>
                </Form.Label>
                <Form.Switch
                  id="sponsored"
                  checked={sponsored}
                  disabled={true}
                  {...register('sponsored')}
                />
              </Flex>
            </div>
          </Flex>
        </Form.Group>
        <Form.Group className="border-top mt-2 pt-2">
          <Flex className="gap-2" alignItems="start">
            <FontAwesomeIcon icon="eye-slash" className="mt-1" />
            <div className="flex-grow-1">
              <Flex
                className="gap-2"
                alignItems="center"
                justifyContent="between"
              >
                <Form.Label htmlFor="locationHidden" className="m-0">
                  Oculta la posición exacta:
                  <p className="fs--1 fw-normal m-0">
                    Evita que se muestre la posición exacta del inmueble en el
                    mapa{' '}
                    {/* {!!limit && (
                        <SoftBadge bg="warning">Coste de 3 token</SoftBadge>
                      )} */}
                    <SoftBadge>Próximamente</SoftBadge>
                  </p>
                </Form.Label>
                <Form.Switch
                  id="locationHidden"
                  checked={locationHidden}
                  disabled={true}
                  {...register('locationHidden')}
                />
              </Flex>
            </div>
          </Flex>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

PublishAsset.propTypes = {
  asset: PropTypes.object,
  register: PropTypes.func.isRequired
};

export default PublishAsset;

import { useCallback } from 'react';
import { useFirestore } from 'reactfire';

import {
  collection,
  getCountFromServer,
  query,
  where
} from 'firebase/firestore';

const assetsCollection = 'assets';
const path = `areaId`;
const operator = '==';

function useFetchAssetsCount() {
  const firestore = useFirestore();

  return useCallback(
    ({ areaId, filteredSources }) => {
      // create default constraints
      const constraints = [
        where(path, operator, areaId),
        !!filteredSources?.length &&
          where(
            'source',
            'in',
            filteredSources.map(({ value }) => value)
          )
      ].filter(constraint => constraint);

      const collectionRef = collection(firestore, assetsCollection);

      const assetsQuery = query(collectionRef, ...constraints);
      return getCountFromServer(assetsQuery);
    },
    [firestore]
  );
}

export default useFetchAssetsCount;

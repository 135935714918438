import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import { Outlet } from 'react-router-dom';
import UserProvider from 'components/UserProvider';
import Section from 'components/common/Section';
import NavbarStandard from 'components/landing/NavbarStandard';
import FooterStandard from 'components/landing/FooterStandard';
import AppContext from 'context/Context';
import { useBreakpoints } from 'hooks/useBreakpoints';
import bg from 'assets/img/generic/home3.jpg';

const LegalLayout = () => {
  const { breakpoints } = useBreakpoints();
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <>
      <Background
        image={breakpoints.up('lg') && bg}
        overlay={isDark ? '0' : false}
        position={
          breakpoints.up('lg') ? 'center bottom -27vw' : 'center bottom -23vw'
        }
        className="position-fixed"
        style={{ backgroundColor: '#e9e8ed' }}
      />
      <UserProvider>
        <NavbarStandard />
      </UserProvider>
      <Section className="py-0">
        <Row className="flex-center min-vh-100">
          <Col sm={11} md={9}>
            <Outlet />
          </Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default LegalLayout;

import React, { useContext } from 'react';
import FavouritesProvider from './FavouritesProvider';
import CRMContainer from './CRMContainer';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import Logo from 'components/common/Logo';
import SoftBadge from 'components/common/SoftBadge';
import NotAvailable from 'components/common/NotAvailable';
import { UserContext } from 'context/Context';

const CrmWithProvider = props => {
  const { loading, subscription } = useContext(UserContext);
  const isAvailable =
    subscription?.plan?.product?.marketing_features.some(({ name }) =>
      name.includes('favoritos')
    ) || false;

  if (typeof subscription === 'undefined' || loading) {
    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="min-vw-100 mt-n6 min-vh-100 text-center"
      >
        <div>
          <Logo width={256} />
          <Flex
            alignItems="center"
            justifyContent="center"
            className="w-100 h-100"
          >
            <div className="py-3">
              <Loader />
            </div>
          </Flex>
        </div>
      </Flex>
    );
  }

  if (!isAvailable) {
    return (
      <NotAvailable className="min-vh-100 mt-n6">
        El CRM de favoritos está disponible a partir del{' '}
        <SoftBadge bg="400">Plan Estándar</SoftBadge>
      </NotAvailable>
    );
  }

  return (
    <FavouritesProvider>
      <CRMContainer {...props} />
    </FavouritesProvider>
  );
};

export default CrmWithProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import giftImg from 'assets/img/icons/spot-illustrations/gifts.png';

const Thanks = ({ titleTag: TitleTag }) => {
  return (
    <div>
      <Helmet>
        {/* <!-- Meta Pixel Code --> */}
        <script>{`fbq?.('track', 'Thanks');`}</script>
        <noscript>
          {`
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=867541995424383&ev=Thanks&noscript=1"
          />
          `}
        </noscript>
        {/* <!-- End Meta Pixel Code --> */}
      </Helmet>
      <img
        className="d-block mx-auto my-4"
        src={giftImg}
        alt="gift"
        height={400}
      />
      <Row className="g-0 justify-content-center">
        <Col lg={9} xl={8} className="col-xxl-4 text-center">
          <TitleTag>¡Gracias por registrarte!</TitleTag>
          <Button
            as={Link}
            color="primary"
            size="sm"
            className="mt-3"
            to="/assets"
          >
            <FontAwesomeIcon
              icon="search"
              transform="shrink-4 down-1"
              className="me-1"
            />
            Explora inmuebles
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Thanks.propTypes = {
  titleTag: PropTypes.string
};

Thanks.defaultProps = {
  titleTag: 'h4'
};

export default Thanks;

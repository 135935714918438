import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import { topNavbarBreakpoint } from 'config';

const NavbarStandard = () => {
  return (
    <Navbar
      variant="dark"
      expand={topNavbarBreakpoint}
      className="bg-dark navbar-standard navbar-theme py-4"
    >
      <Container>
        <Flex className="w-100" alignItems="center" justifyContent="center">
          <Logo id="logo" at="navbar-top" isDark={true} to={null} />
        </Flex>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import Loader from 'components/common/Loader';
import { UserContext } from 'context/Context';
import { getCurrencyFormat } from 'helpers/utils';

const SubscriptionInfo = ({ className }) => {
  const {
    billingPortalUrl,
    me,
    subscription,
    upcomingInvoice: upcomingInvoiceProp
  } = useContext(UserContext);
  const { reloadUserInfo } = me || {};
  const { createdAt } = reloadUserInfo || {};
  const userCreatedAt = createdAt && new Date(parseInt(createdAt));
  const {
    canceled_at: canceledAt,
    current_period_end: currentPeriodEnd,
    current_period_start: currentPeriodStart,
    start_date: startDate
  } = subscription || {};
  const [upcomingInvoice, setUpcomingInvoice] = useState({ loading: true });

  useEffect(() => {
    setUpcomingInvoice(upcomingInvoiceProp);
  }, [upcomingInvoiceProp]);

  const { now = {}, loading: loadingInvoice = false } = upcomingInvoice || {};
  const { total: nowTotal = 0 } = now;

  return (
    <Card.Body className={className}>
      {subscription === null ? (
        <>
          <h6>Usuario desde</h6>
          <p className="m-0">
            {dayjs(userCreatedAt).format('dddd, DD MMMM YYYY')}
          </p>
        </>
      ) : (
        <>
          <h6>Suscrito desde</h6>
          <p>
            {startDate
              ? dayjs(startDate * 1000).format('dddd, DD MMMM YYYY')
              : '-'}
          </p>

          <h6>Inicio del periodo de facturación</h6>
          <p>
            {currentPeriodStart
              ? dayjs(currentPeriodStart * 1000).format('dddd, DD MMMM YYYY')
              : '-'}
          </p>

          <h6>Tu próxima factura se emite</h6>
          <p>
            {canceledAt ? (
              <span className="text-danger">
                Suscripción cancelada
                <br />
                {dayjs(canceledAt * 1000).format('dddd, DD MMMM YYYY')}
              </span>
            ) : currentPeriodEnd ? (
              dayjs(currentPeriodEnd * 1000).format('dddd, DD MMMM YYYY')
            ) : (
              '-'
            )}
          </p>

          {canceledAt ? (
            <Button
              as={Link}
              target="_blank"
              className="fs-0 w-100"
              to={billingPortalUrl}
            >
              Renovar suscripción
            </Button>
          ) : (
            <>
              <h6>Lo que pagas al mes</h6>
              <h5 className="fw-normal">
                {loadingInvoice ? (
                  <Loader />
                ) : (
                  getCurrencyFormat(Math.max(0, nowTotal) / 100)
                )}
              </h5>
            </>
          )}
        </>
      )}
    </Card.Body>
  );
};

SubscriptionInfo.propTypes = {
  className: PropTypes.string
};

export default SubscriptionInfo;

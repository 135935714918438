import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { AssetsContext, UserContext } from 'context/Context';
import useAssets from 'hooks/useAssets';

const AssetSort = ({ isOffcanvas }) => {
  const {
    assetsState: { order, sortBy }
  } = useContext(AssetsContext);
  const { subscription } = useContext(UserContext);
  const isFree = subscription === null;
  const { handleIsAscClick, handleSortByChange } = useAssets();
  return (
    <InputGroup
      className={classNames('justify-content-start', {
        'w-auto': !isOffcanvas,
        'mb-3': isOffcanvas
      })}
    >
      <FloatingLabel
        label="Ordenar por"
        style={isOffcanvas ? {} : { maxWidth: '8rem' }}
      >
        <Form.Select
          defaultValue={isFree ? null : sortBy}
          onChange={({ target }) => handleSortByChange(target.value)}
        >
          {isFree && <option value="">-</option>}
          <option value="price">Precio</option>
          <option value="rating">Rating</option>
          <option value="rooms">Habitaciones</option>
          <option value="size">Tamaño</option>
        </Form.Select>
      </FloatingLabel>
      <FloatingLabel
        label="Dirección"
        style={isOffcanvas ? {} : { maxWidth: '12rem' }}
      >
        <Form.Select
          defaultValue={order}
          onChange={({ target }) => handleIsAscClick(target.value === 'asc')}
        >
          <option value="asc">Ascendente</option>
          <option value="desc">Descendente</option>
        </Form.Select>
      </FloatingLabel>
    </InputGroup>
  );
};

AssetSort.propTypes = {
  isOffcanvas: PropTypes.bool
};

export default AssetSort;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import { UserContext } from 'context/Context';

const Activity = ({ activity: { title, description, icon, time }, isLast }) => {
  return (
    <div className="timeline-item position-relative">
      <Row className="g-0 align-items-center">
        <Col xs="auto" className="d-flex align-items-center">
          <h6 className="timeline-item-date fs--2 text-500 mb-0 me-1 w-100px">
            {time}
          </h6>
          <div className="position-relative">
            <div className="icon-item icon-item-md rounded-7 shadow-none bg-200">
              <FontAwesomeIcon icon={icon} className="text-primary" />
            </div>
          </div>
        </Col>
        <Col className="ps-3 fs--1 text-500">
          <div className="py-x1">
            <h5 className="fs--1">{title}</h5>
            <p className="mb-0">{description}</p>
          </div>
          {!isLast && <hr className="my-0 text-200" />}
        </Col>
      </Row>
    </div>
  );
};

const RecentActivities = () => {
  const [period, setPeriod] = useState('week');
  const { me, views } = useContext(UserContext);
  const { metadata } = me || {};
  let { createdAt } = metadata || {};
  createdAt = new Date(parseInt(createdAt, 10)).toISOString();
  const from = new Date();
  if (period === 'week') {
    from.setDate(from.getDate() - 7);
  } else if (period === 'month') {
    from.setMonth(from.getMonth() - 1);
  } else {
    from.setFullYear(from.getFullYear() - 1);
  }

  const data = [
    ...views.map(({ NO_ID_FIELD, assetId, createdAt }) => {
      return {
        id: `Event-View-${NO_ID_FIELD}`,
        createdAt,
        title: 'Inmueble visto',
        description: (
          <Link to={`/assets/asset-detail/${assetId.replace('-', '/')}`}>
            {assetId}
          </Link>
        ),
        time: dayjs(createdAt).format('D MMM. YYYY'),
        icon: 'eye'
      };
    }),
    {
      id: 'Event-User-Creation',
      createdAt,
      title: 'Usuario creado',
      description: '',
      time: dayjs(createdAt).format('D MMM. YYYY'),
      icon: 'user'
    }
  ]
    .sort((v1, v2) => (v1.createdAt > v2.createdAt ? -1 : 1))
    .filter(({ createdAt }) => createdAt >= from.toISOString());

  const handlePeriodChange = event => {
    const { target } = event;
    const { value } = target;
    setPeriod(value);
  };

  return (
    <Card className="h-100">
      <FalconCardHeader light title="Actividad reciente" titleTag="h6" />
      <SimpleBarReact className="flex-1 h-200px">
        <Card.Body className="py-0">
          <div className="timeline-simple">
            {data.map((activity, index) => (
              <Activity
                key={`Event-${activity.id}`}
                activity={activity}
                isLast={index === data.length - 1}
              />
            ))}
          </div>
        </Card.Body>
      </SimpleBarReact>
      <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col></Col>
          <Col xs="auto" className="text-end">
            <Form.Select
              defaultValue={period}
              size="sm"
              onChange={handlePeriodChange}
            >
              <option value="week">Últimos 7 días</option>
              <option value="month">Último mes</option>
              <option value="year">Último año</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

Activity.propTypes = {
  activity: PropTypes.object,
  isLast: PropTypes.bool
};

RecentActivities.propTypes = {
  data: PropTypes.arrayOf(Activity.propTypes.activity)
};

export default RecentActivities;

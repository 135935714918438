import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/plain.css';
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Form,
  Image,
  Modal,
  Table
} from 'react-bootstrap';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import AppContext, { UserContext } from 'context/Context';
import SOCIALS from 'data/socials.json';

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.locale('es');

const AccountImage = () => {
  const { deleteAvatar, me, updateData } = useContext(UserContext);
  const { data, displayName, photoURL, reloadUserInfo } = me || {};
  const { lastRefreshAt } = reloadUserInfo || {};
  const { avatar: dataAvatar } = data || {};
  const avatar = dataAvatar || `${photoURL}?lra=${lastRefreshAt}`;
  const inputRef = useRef();

  const handleSelectImage = () => {
    inputRef.current.click();
  };

  const handleFileChange = event => {
    const { target } = event;
    const { files } = target;
    const [avatar] = [...files];
    avatar && updateData({ avatar });
  };

  const handleDeleteAvatar = () => {
    deleteAvatar();
  };

  return (
    <>
      <Flex alignItems="center" className="position-relative">
        <Image
          className="fit-contain mt-3"
          src={avatar}
          alt={displayName}
          rounded="1"
          width="112"
        />

        <input
          ref={inputRef}
          type="file"
          className="visually-hidden"
          onChange={handleFileChange}
        />
        <ButtonGroup className="position-absolute top-0 end-0 bg-white me-n3 mt-n2">
          <Button
            variant="falcon-primary"
            size="sm"
            className="p-0"
            onClick={handleSelectImage}
          >
            <Tooltip title="Editar">
              <FontAwesomeIcon
                icon="pen"
                className="me-1 ps-2 pt-2 pb-1 pe-1"
              />
            </Tooltip>
          </Button>
          {dataAvatar && dataAvatar !== photoURL && (
            <Button
              variant="falcon-danger"
              size="sm"
              className="p-0"
              onClick={handleDeleteAvatar}
            >
              <Tooltip title="Eliminar">
                <FontAwesomeIcon
                  icon="trash"
                  className="me-1 ps-2 pt-2 pb-1 pe-2"
                />
              </Tooltip>
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </>
  );
};

const Description = ({ value }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { updateData } = useContext(UserContext);
  const [isEditing, setEdit] = useState(false);
  const [description, setDescription] = useState(value);

  const handleChange = async ({ target: { value } }) => {
    setDescription(value);
  };

  const handleClose = async () => {
    setDescription(value);
    setEdit(false);
  };

  const handleSave = async () => {
    await updateData({ description });
    setEdit(false);
  };

  return (
    <tr>
      <td className="p-1 w-200px">
        <Form.Label>Descripción</Form.Label>
      </td>
      <td className="p-1">
        <p>
          {value ? value : '-'}
          <Button
            variant="link"
            size="sm"
            className="py-0 px-1 mt-n1"
            onClick={() => setEdit(true)}
          >
            <Tooltip title="Editar">
              <FontAwesomeIcon icon="pen" className="me-1" />
            </Tooltip>
          </Button>
        </p>
      </td>
      <Modal show={isEditing} size="lg" onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="px-x1 border-0"
        >
          <Modal.Title>Descripción</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Form.Control
            as="textarea"
            className="resize-none"
            defaultValue={value}
            onChange={handleChange}
            rows={8}
          />
        </Modal.Body>
        <Modal.Footer className="px-x1 py-2 border-0">
          <Button
            variant="falcon-default"
            type="button"
            onClick={handleClose}
            className="me-3"
          >
            <FontAwesomeIcon icon="times" className="me-2 fs--1" />
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSave}
            className="mx-0"
          >
            <FontAwesomeIcon icon="check" className="me-2 fs--1" />
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

Description.propTypes = {
  value: PropTypes.string
};

const Socials = ({ value: obj }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { updateData } = useContext(UserContext);
  const [isEditing, setEdit] = useState(false);
  const [socials, setSocials] = useState(obj);

  const handleChange = async ({ target: { name, value } }) => {
    setSocials(obj => ({ ...obj, [name]: value }));
  };

  const handleClose = async () => {
    setEdit(false);
  };

  const handleSave = async () => {
    await updateData({ socials });
    setEdit(false);
  };

  return (
    <tr>
      <td className="p-1 w-200px">
        <Form.Label>Redes sociales</Form.Label>
      </td>
      <td className="p-1">
        <Flex className="gap-2" wrap="wrap">
          {obj
            ? SOCIALS.map(({ key, url, icon, title }) => {
                const value = socials?.[key];
                return (
                  value && (
                    <Button
                      key={`Social-${title}`}
                      variant="outline-300"
                      className="text-800"
                      size="sm"
                      as={Link}
                      target="_blank"
                      to={`${url}${value}`}
                    >
                      {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
                      {title}
                    </Button>
                  )
                );
              }).filter(data => data)
            : '-'}
          <Button
            variant="link"
            size="sm"
            className="py-0 px-1 mt-n1"
            onClick={() => setEdit(true)}
          >
            <Tooltip title="Editar">
              <FontAwesomeIcon icon="pen" className="me-1" />
            </Tooltip>
          </Button>
        </Flex>
      </td>
      <Modal show={isEditing} onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="px-x1 border-0"
        >
          <Modal.Title>Social</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Table>
            <tbody>
              {SOCIALS.map(social => {
                const { key, icon, title } = social;
                const value = obj?.[key] || '';
                return (
                  <tr key={`Social-${title}`}>
                    <td className="w-150px">
                      <div>
                        <FontAwesomeIcon icon={icon} className="me-2" />
                        {title}
                      </div>
                    </td>
                    <td>
                      <Form.Control
                        className="d-inline-block w-250px"
                        defaultValue={value}
                        name={key}
                        onChange={handleChange}
                        size="sm"
                      />
                    </td>
                  </tr>
                );
              }).filter(data => data)}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="px-x1 py-2 border-0">
          <Button
            variant="falcon-default"
            type="button"
            onClick={handleClose}
            className="me-3"
          >
            <FontAwesomeIcon icon="times" className="me-2 fs--1" />
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSave}
            className="mx-0"
          >
            <FontAwesomeIcon icon="check" className="me-2 fs--1" />
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

Socials.propTypes = {
  value: PropTypes.object
};

const PhoneNumber = ({ value }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { updateData } = useContext(UserContext);
  const [isEditing, setEdit] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(value);

  const handleChange = async value => {
    setPhoneNumber(value);
  };

  const handleClose = async () => {
    setPhoneNumber(value);
    setEdit(false);
  };

  const handleSave = async () => {
    await updateData({ phoneNumber });
    setEdit(false);
  };

  return (
    <>
      <Flex alignItems="center">
        {value ? (
          <>
            <Link to={`tel:${value}`} className="text-600">
              {value}
            </Link>
            {/* <SoftBadge
              bg="primary"
              pill
              className="d-none d-md-inline-block ms-2"
            >
              2-step verification
              <FontAwesomeIcon icon="link" className="ms-1" />
            </SoftBadge> */}
          </>
        ) : (
          '-'
        )}
        <Button
          variant="link"
          size="sm"
          className="py-0 my-n1"
          onClick={() => setEdit(true)}
        >
          <Tooltip title="Editar">
            <FontAwesomeIcon icon="pen" className="me-1" />
          </Tooltip>
        </Button>
      </Flex>
      <Modal show={isEditing} onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="px-x1 border-0"
        ></Modal.Header>
        <Modal.Body className="p-x1">
          <PhoneInput
            country="es"
            localization={es}
            buttonClass="border-0 ps-2"
            inputClass="ps-6"
            dropdownClass="rounded"
            value={value}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer className="px-x1 py-2 border-0">
          <Button
            variant="falcon-default"
            type="button"
            onClick={handleClose}
            className="me-3"
          >
            <FontAwesomeIcon icon="times" className="me-2 fs--1" />
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSave}
            className="mx-0"
          >
            <FontAwesomeIcon icon="check" className="me-2 fs--1" />
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

PhoneNumber.propTypes = {
  value: PropTypes.string
};

const UserInfo = () => {
  const { me, sendEmailVerification } = useContext(UserContext);
  const { data, email, emailVerified, phoneNumber, reloadUserInfo } = me || {};
  const { description, socials } = data || {};
  const { createdAt, lastRefreshAt } = reloadUserInfo || {};

  return (
    <Card className="font-sans-serif h-100">
      <Card.Body className="d-flex gap-3 flex-column flex-sm-row align-items-start">
        <AccountImage />
        <div className="fs--1 mb-0 ms-4 w-100">
          <Table borderless>
            <tbody>
              <tr>
                <td className="p-1 w-200px">
                  <Form.Label>Último inicio de sesión</Form.Label>
                </td>
                <td className="p-1 text-600">
                  {dayjs(lastRefreshAt)
                    .calendar?.(null, {
                      sameDay: `[${dayjs().to(dayjs(lastRefreshAt))}]`,
                      lastDay: '[ayer], HH:mm',
                      lastWeek: 'dddd, HH:mm',
                      sameElse: 'ddd, D MMM YYYY, HH:mm'
                    })
                    .replace('.', '')}
                </td>
              </tr>
              <tr>
                <td className="p-1 w-200px">
                  <Form.Label>Fecha de registro</Form.Label>
                </td>
                <td className="p-1 text-600">
                  {dayjs(parseInt(createdAt))
                    .calendar?.(null, {
                      sameDay: `[${dayjs().to(dayjs(parseInt(createdAt)))}]`,
                      lastDay: '[ayer], HH:mm',
                      lastWeek: 'dddd, HH:mm',
                      sameElse: 'ddd, D MMM YYYY, HH:mm'
                    })
                    .replace('.', '')}
                </td>
              </tr>
              <tr>
                <td className="p-1 w-200px">
                  <Form.Label>Email</Form.Label>
                </td>
                <td className="p-1">
                  <Link to={`mailto:${email}`} className="text-600">
                    {email}
                  </Link>
                  {emailVerified ? (
                    <SoftBadge
                      bg="success"
                      pill
                      className="d-none d-md-inline-block ms-2"
                    >
                      Verified
                      <FontAwesomeIcon icon="check" className="ms-1" />
                    </SoftBadge>
                  ) : (
                    <>
                      <SoftBadge
                        bg="danger"
                        pill
                        className="d-none d-md-inline-block ms-2"
                      >
                        Por verificar
                      </SoftBadge>
                      <div
                        className="my-3"
                        onClick={() => sendEmailVerification()}
                      >
                        <Button size="sm">Enviar email de verificación</Button>
                      </div>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td className="p-1 w-200px">
                  <Form.Label>Teléfono</Form.Label>
                </td>
                <td className="p-1">
                  <PhoneNumber value={phoneNumber} />
                </td>
              </tr>
              <Description value={description} />
              <Socials value={socials} />
            </tbody>
          </Table>
        </div>
        <div className="position-absolute top-0 end-0 m-3">
          <CardDropdown iconClassName="fs--1" drop="bottom">
            <div className="py-2">
              <Dropdown.Item
                href="#!"
                className="text-danger"
                as={Link}
                to="/authentication/logout"
              >
                Logout
              </Dropdown.Item>
            </div>
          </CardDropdown>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UserInfo;

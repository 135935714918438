import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import defaultImage from 'assets/img/illustrations/corner-4.png';

const AssetGallery = ({ asset }) => {
  const { images: imagesRaw = [], source } = asset || {};
  let images = imagesRaw.map(image => {
    return source === 'fotocasa'
      ? `${image.split('?')[0]}?rule=web_1200x0`
      : image;
  });
  images = images?.length ? [...new Set(images)] : [defaultImage];

  return (
    <div className="mt-3">
      <FalconCardHeader className="mb-3" title="Fotos" />
      <div className="position-relative mt-3">
        <Row className="g-1">
          <Col xs={12}>
            <Image className="w-100 fit-cover" src={images[0]} fluid />
          </Col>
          {images.slice(1).map(image => (
            <Col
              className="avoid-break-inside min-w-50 overflow-hidden"
              key={`Image-${image}`}
            >
              <Image
                className="min-w-100 min-h-100 fit-cover"
                src={image}
                fluid
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

AssetGallery.propTypes = {
  asset: PropTypes.objectp
};

export default AssetGallery;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Dropdown, ProgressBar } from 'react-bootstrap';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Avatar from 'components/common/Avatar';
import Confirm from 'components/common/Confirm';
import IconButton from 'components/common/IconButton';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { AssetsContext, UserContext } from 'context/Context';
import { getCurrencyFormat } from 'helpers/utils';
import logo from 'assets/img/logos/logo_min.svg';

const statusLabels = {
  archived: { color: 'warning', label: 'Archivado' },
  draft: { label: 'Borrador' },
  public: { color: 'success', label: 'Público' },
  private: { label: 'Privado' }
};

const keys = [
  'age',
  'bathrooms',
  'description',
  'geocode',
  'images',
  'name',
  'orientation',
  'price',
  'rooms',
  'size',
  'type'
];

const columns = [
  {
    accessor: 'name',
    Header: 'Inmueble',
    cellProps: {
      className: 'white-space-nowrap'
    },
    Cell: rowData => {
      const { id, images, name, status } = rowData.row.original;
      const DetailLink = status === 'draft' ? 'div' : Link;
      return (
        <Flex alignItems="center" className="gap-3 position-relative">
          <Avatar
            className="rounded-1 border"
            mediaClass="bg-200"
            src={images?.[0] || logo}
          />
          <DetailLink
            className="text-truncate stretched-link"
            to={
              status !== 'draft'
                ? `/assets/asset-details/${id.replace('-', '/')}?from=account`
                : ''
            }
          >
            {name}
          </DetailLink>
        </Flex>
      );
    }
  },
  {
    accessor: 'location',
    Header: 'Localización',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData => {
      const { geocode, id, status } = rowData.row.original;
      const DetailLink = status === 'draft' ? 'div' : Link;
      return (
        <DetailLink
          id={id}
          className="text-truncate"
          to={
            status !== 'draft'
              ? `/assets/asset-details/${id.replace('-', '/')}?from=account`
              : ''
          }
        >
          {geocode?.formatted_address}
        </DetailLink>
      );
    }
  },
  {
    accessor: 'price',
    Header: 'Precio',
    headerProps: {
      className: 'fw-medium text-center'
    },
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      const { price } = rowData.row.original;
      return isNaN(price)
        ? '-'
        : getCurrencyFormat(price, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
    }
  },
  {
    accessor: 'progress',
    Header: 'Progreso',
    disableSortBy: true,
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData => {
      const { progress } = rowData.row.original;
      return (
        <ProgressBar
          className="me-3 bg-200 rounded-pill"
          now={progress}
          style={{ width: 80, height: 6 }}
          variant={progress === 100 ? 'success' : 'primary'}
        />
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Estado',
    headerProps: {
      className: 'fw-medium text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => (
      <SoftBadge bg={statusLabels[rowData.row.original.status]?.color}>
        {statusLabels[rowData.row.original.status]?.label || '-'}
      </SoftBadge>
    )
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { deleteAsset, publishAsset, updateAsset, ...asset } =
        rowData.row.original;
      const { id, progress, status } = asset || {};
      return (
        <CardDropdown iconClassName="fs--1" drop="start">
          <div className="py-2">
            {status === 'draft' && (
              <Dropdown.Item
                disabled={progress !== 100}
                onClick={() => publishAsset(asset)}
              >
                Publicar
              </Dropdown.Item>
            )}
            {status === 'archived' && (
              <Dropdown.Item
                className="text-success"
                disabled={progress !== 100}
                onClick={() => publishAsset(asset)}
              >
                Desarchivar
              </Dropdown.Item>
            )}
            <Dropdown.Item
              to={`/assets/asset-details/${id.replace('-', '/')}`}
              disabled={status === 'draft'}
              as={Link}
            >
              Ver
            </Dropdown.Item>
            <Dropdown.Item
              to={`/assets/asset-create/${id.replace('-', '/')}`}
              as={Link}
            >
              Editar
            </Dropdown.Item>
            <Dropdown.Divider as="div" />
            {['public', 'private'].includes(status) ? (
              <Dropdown.Item
                className="text-warning"
                onClick={() =>
                  updateAsset(asset, {
                    areaId: null,
                    status: 'archived'
                  })
                }
              >
                Archivar
              </Dropdown.Item>
            ) : (
              <Confirm
                as={Dropdown.Item}
                className="text-danger"
                callback={() => deleteAsset(asset)}
                content={`Deseas eliminar el inmueble "${asset?.name}"`}
                buttonText="Eliminar inmueble"
                variant="danger"
              >
                Eliminar
              </Confirm>
            )}
          </div>
        </CardDropdown>
      );
    }
  }
];

const MyAssets = ({ perPage = 10 }) => {
  const { deleteAsset, publishAsset, updateAsset } = useContext(AssetsContext);
  const { me } = useContext(UserContext);
  const db = useFirestore();
  let areasQuery = query(collection(db, 'none'));
  if (me?.uid) {
    areasQuery = query(
      collection(db, 'assets'),
      where('createdBy', '==', me.uid)
    );
  }
  const { data = [] } = useFirestoreCollectionData(areasQuery);

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data.map(asset => {
        const progress =
          (Object.entries(asset).filter(
            ([key, value]) =>
              keys.includes(key) &&
              (Array.isArray(value) ? value.length : !!value)
          ).length *
            100) /
          keys.length;
        return { ...asset, deleteAsset, progress, publishAsset, updateAsset };
      })}
      sortable
      pagination
      perPage={perPage}
    >
      <Card id="MyAssets" className="h-100 mb-3">
        <Card.Header className="d-flex flex-between-center">
          <h5 className="mb-0">Mis inmuebles</h5>
          <div>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              className="me-2"
              as={Link}
              to="/assets/asset-create"
            >
              <span className="d-none d-sm-inline-block">Añadir inmueble</span>
            </IconButton>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {data?.length ? (
            <AdvanceTable
              table
              headerClassName="bg-light text-900 fw-medium font-sans-serif white-space-nowrap"
              rowClassName="btn-reveal-trigger align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden fw-semi-bold min-h-200px'
              }}
            />
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '6.875rem' }}
            >
              <SoftBadge bg="secondary">
                No has subido ningún inmueble
              </SoftBadge>
            </Flex>
          )}
        </Card.Body>
        <Card.Footer />
      </Card>
    </AdvanceTableWrapper>
  );
};

MyAssets.propTypes = {
  perPage: PropTypes.number
};

export default MyAssets;

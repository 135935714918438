import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { getCurrencyFormat } from 'helpers/utils';

const AssetReform = ({ asset }) => {
  const { price: purchaisePrice, reform } = asset || {};
  const { amount: reformAmount, items } = reform || {};
  const reformPrice = reformAmount;

  return (
    <div className="avoid-break-inside">
      <FalconCardHeader className="mb-3" title="Reforma" />
      <div>
        <div className="py-3">
          <Flex alignItems="end">
            <h3 className="text-warning m-0">
              {reformPrice
                ? getCurrencyFormat(reformPrice, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : '-'}
            </h3>
            {!!reformPrice && (
              <Flex direction="column" className="ms-4">
                <small className="fw-semi-bold">
                  Precio de compra + reforma
                </small>
                <span className="text-400">
                  {getCurrencyFormat(purchaisePrice, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}{' '}
                  +{' '}
                  {getCurrencyFormat(reformPrice, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}{' '}
                  ={' '}
                  {getCurrencyFormat(purchaisePrice + reformPrice, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                </span>
              </Flex>
            )}
          </Flex>
        </div>
        {!!items?.length && (
          <div>
            <Flex className="gap-1" wrap="wrap">
              {items?.map?.(service => (
                <SoftBadge key={service} bg="warning">
                  <Flex alignItems="center" className="gap-2">
                    <FontAwesomeIcon icon="person-digging" />
                    <span>{service}</span>
                  </Flex>
                </SoftBadge>
              ))}
            </Flex>
          </div>
        )}
      </div>
    </div>
  );
};

AssetReform.propTypes = {
  asset: PropTypes.object
};

export default AssetReform;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, Modal } from 'react-bootstrap';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { AssetsContext } from 'context/Context';
import AssetDetail from './AssetDetail';
import Logo from 'components/common/Logo';
import { sources } from 'data/assets/assetData';

const DeletedModal = ({ asset }) => {
  const { source } = asset || {};
  const sourceObj = sources.find(({ value }) => value === source);
  return (
    <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-5">
        <Logo className="w-200px mx-auto" />
        <div className="text-center">
          <h5>El inmueble ya no está disponible en {sourceObj?.label}</h5>
          <Button as={Link} color="primary" className="mt-3" to="/assets">
            Volver
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DeletedModal.propTypes = {
  asset: PropTypes.object
};

const AssetReport = () => {
  const db = useFirestore();
  const { formatAsset } = useContext(AssetsContext);
  const { source, assetId } = useParams();
  const id = `${source}-${assetId}`;
  const assetRef = doc(db, 'assets', id);

  const { data } = useFirestoreDocData(assetRef);
  const asset = formatAsset(data);

  // console.log('ASSET >>>', asset);

  return (
    <>
      {!!asset?.id && (
        <>
          <Helmet>
            <title>{asset.name} | Inversor Pro</title>
          </Helmet>
          {asset?.deleted && <DeletedModal asset={asset} />}
          <hr className="border-0 mb-0" />
          <AssetDetail asset={asset} />
        </>
      )}
    </>
  );
};

export default AssetReport;

import React, { useContext } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import AreaTax from './AreaTax';
import Expenses from './Expenses';
import FlippingHouseBenefit from './FlippingHouseBenefit';
import FlippingHouseIncomes from './FlippingHouseIncomes';
import FlippingHouseRentability from './FlippingHouseRentability';
import TopNav from './TopNav';
import { CalculatorContext } from 'context/Context';

const FlippingHouseContent = () => {
  const { asset } = useContext(CalculatorContext);

  return (
    <>
      <Row className="g-lg-4 mt-3 mb-4 position-relative z-index-1">
        <Col lg={8} className="mt-0 order-1 order-lg-0">
          <TopNav />
          <hr className="border-0 mt-0" />
          <Expenses />
          <FlippingHouseIncomes />
        </Col>
        <Col lg={4} className="mt-0">
          <div
            className={classNames(
              'position-sticky asset-details-sticky-sidebar z-index-1',
              {
                'sticky-sidebar': asset?.id,
                'sticky-top': !asset?.id
              }
            )}
          >
            <AreaTax />
            <FlippingHouseBenefit />
            <FlippingHouseRentability />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FlippingHouseContent;

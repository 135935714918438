import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const AssetInformationForm = ({ asset, register }) => {
  const { name } = asset;

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-light">
        Información del inmueble
      </Card.Header>
      <Card.Body>
        <Row className="g-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Piso en el centro"
                defaultValue={name}
                {...register('name', { required: true })}
              />
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group>
              <Form.Label>Tipo</Form.Label>
              <Form.Select {...register('type', { required: true })} required>
                <option value="">Selecciona el tipo de inmueble</option>
                <option value="flat">Piso</option>
                <option value="house">Casa</option>
                <option value="other">Otro</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>Tamaño</Form.Label>
              <Flex alignItems="center">
                <Form.Control
                  type="number"
                  min={0}
                  className="pe-5 me-n4"
                  {...register('size', { required: true, valueAsNumber: true })}
                />
                <span className="ms-n2">m²</span>
              </Flex>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label>Habitaciones</Form.Label>
              <Form.Control
                type="number"
                min={0}
                className="pe-0"
                {...register('rooms', { required: true, valueAsNumber: true })}
              />
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label>Baños</Form.Label>
              <Form.Control
                type="number"
                min={0}
                className="pe-0"
                {...register('bathrooms', {
                  required: true,
                  valueAsNumber: true
                })}
              />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Orientación</Form.Label>
              <Form.Select {...register('orientation')}>
                <option value="">Selecciona la orientación</option>
                <option value="north">Norte</option>
                <option value="south">Sur</option>
                <option value="east">Este</option>
                <option value="west">Oeste</option>
                <option value="north-west">Noroeste</option>
                <option value="north-east">Noreste</option>
                <option value="south-west">Suroeste</option>
                <option value="south-east">Sureste</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Select {...register('state', { required: true })} required>
                <option value="">Selecciona el estado</option>
                <option value="new">Casi nuevo</option>
                <option value="very-good">Muy bien</option>
                <option value="good">Bien</option>
                <option value="reformed">Reformado</option>
                <option value="to-reform">A reformar</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Antigüedad</Form.Label>
              <Form.Select {...register('age', { required: true })} required>
                <option value="">Selecciona la antigüead</option>
                <option value="0-1">Menos de 1 año</option>
                <option value="1-5">1 a 5 años</option>
                <option value="5-10">5 a 10 años</option>
                <option value="10-20">10 a 20 años</option>
                <option value="20-30">20 a 30 años</option>
                <option value="30-50">30 a 50 años</option>
                <option value="50-70">50 a 70 años</option>
                <option value="70-100">70 a 100 años</option>
                <option value="100+">Más de 100 años</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <div className="create-course-description-textarea">
                <Form.Control
                  as="textarea"
                  className="resize-none"
                  name="courseTitle"
                  required
                  placeholder="Apartamento de 3 habitaciones y 2 baños en el centro de la ciudad. Cuenta con cocina moderna, amplio salón con mucha luz natural y balcón con vistas espectaculares. El edificio cuenta con estacionamiento subterráneo. Ideal para familias o profesionales."
                  rows={3}
                  {...register('description', { required: true })}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

AssetInformationForm.propTypes = {
  asset: PropTypes.object,
  register: PropTypes.func.isRequired
};

export default AssetInformationForm;

import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Nav } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { free } from 'components/UserProvider';
import AppContext, { UserContext } from 'context/Context';
import { useBreakpoints } from 'hooks/useBreakpoints';

const ProButton = () => {
  const { getAvailableViews, subscription } = useContext(UserContext);
  const { plan } = subscription || {};
  const { product } = subscription === null ? free : plan || {};
  const { metadata } = product || {};
  const { limit } = metadata || {};
  const availableViews = getAvailableViews();

  return (
    limit && (
      <Nav.Item as="li" className="me-2">
        <Nav.Link as={Link} to="/pricing">
          <Tooltip placement="bottom" title="Tokens disponibles">
            <Button
              className="bg-200 pe-5 me-n4 shadow-none border"
              variant="falcon-default"
              size="sm"
            >
              <Flex alignItems="center">
                <FontAwesomeIcon icon="coins" className="me-2" />
                <span className="fw-semi-bold">{availableViews}</span>
              </Flex>
            </Button>
          </Tooltip>
          <Button variant="falcon-warning" size="sm">
            <Flex alignItems="center">
              <FontAwesomeIcon icon="crown" className="me-2" />
              <span className="fw-semi-bold">Hazte Pro</span>
            </Flex>
          </Button>
        </Nav.Link>
      </Nav.Item>
    )
  );
};

const MenuTop = () => {
  const location = useLocation();
  const [, pathname] = location.pathname.match(/([^/]*)\/?$/);
  return (
    <Nav.Item as="li" className="mt-3 px-3">
      <Nav
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
        variant="underline"
      >
        <Nav.Item as="li">
          <Nav.Link
            as={Link}
            className={classNames('pb-2', {
              active: pathname === 'assets'
            })}
            to="/assets"
          >
            <Flex direction="column" alignItems="center">
              <FontAwesomeIcon icon="home" className="fs--2" />
              <span className="fs--1 fw-semi-bold">Explora</span>
            </Flex>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            as={Link}
            className={classNames('pb-2', {
              active: pathname === 'favourites'
            })}
            to="/favourites"
          >
            <Flex direction="column" alignItems="center">
              <FontAwesomeIcon icon="heart" className="fs--2" />
              <span className="fs--1 fw-semi-bold">Favoritos</span>
            </Flex>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Nav.Item>
  );
};

const ThemeColor = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  const { me = {} } = useContext(UserContext);
  const { settings = {} } = me;
  const { colorScheme = 'auto' } = settings;

  const handleAutoChange = ({ matches }) => {
    isDark !== matches && setConfig('isDark', matches);
  };

  useEffect(() => {
    if (colorScheme !== 'auto') {
      setConfig('isDark', colorScheme === 'dark');
      return;
    }
    const { matches: preferDark } = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    setConfig('isDark', isDark || preferDark);
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleAutoChange);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleAutoChange);
    };
  }, [colorScheme]);

  return (
    <Nav.Item as="li">
      <Nav.Link
        className="theme-control-toggle"
        onClick={() => setConfig('isDark', !isDark)}
      >
        <Tooltip
          key="left"
          placement="bottom"
          title={isDark ? 'Cambiar al tema claro' : 'Cambiar al tema oscuro'}
        >
          <div className="theme-control-toggle-label">
            <FontAwesomeIcon icon={isDark ? 'sun' : 'moon'} className="fs-0" />
          </div>
        </Tooltip>
      </Nav.Link>
    </Nav.Item>
  );
};

const TopNavRightSideNavItem = () => {
  const { breakpoints } = useBreakpoints();
  return (
    (breakpoints.down('md') && null) || (
      <Nav
        className="navbar-nav-icons ms-auto flex-row align-items-center z-index-2"
        as="ul"
      >
        <ProButton />
        {breakpoints.up('xl') && (
          <>
            <MenuTop />
            <ThemeColor />
          </>
        )}
        <ProfileDropdown />
      </Nav>
    )
  );
};

export default TopNavRightSideNavItem;

import React from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';
import NotAvailable from 'components/common/NotAvailable';

const UpgradeModal = ({ children, show, setContent, setShow }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onExited={() => setContent()}
      contentClassName="border-0"
      centered
    >
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
        <CloseButton
          onClick={handleClose}
          className="btn btn-sm btn-circle d-flex flex-center transition-base"
        />
      </div>
      <Modal.Body>
        <NotAvailable onButtonClick={handleClose}>{children}</NotAvailable>
      </Modal.Body>
    </Modal>
  );
};

UpgradeModal.propTypes = {
  children: PropTypes.node,
  setContent: PropTypes.func,
  setShow: PropTypes.func,
  show: PropTypes.bool
};

export default UpgradeModal;

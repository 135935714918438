import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Divider from 'components/common/Divider';
import { UserContext } from 'context/Context';
import SocialAuthButtons from './SocialAuthButtons';

const LoginFormContent = ({ id, hasLabel }) => {
  const { signIn } = useContext(UserContext);
  const { search } = useLocation();
  const [, prefilledEmail = ''] = search?.match(/\?email=(.*)/) || [];

  const [formData, setFormData] = useState({
    email: prefilledEmail,
    password: '',
    remember: false
  });
  const { email, password, remember } = formData;
  const handleSubmit = async e => {
    e.preventDefault();
    await signIn(email, password, {
      ...(remember
        ? {
            expires: 14
          }
        : {}),
      to: '/assets'
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form id={id} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email' : ''}
          value={email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Contraseña' : ''}
          value={password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Recuérdame
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to="/authentication/forgot-password">
            ¿Has olvidado la contraseña?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!email || !password}
        >
          Inicia sesión
        </Button>
      </Form.Group>
    </Form>
  );
};

const LoginForm = props => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SocialAuthButtons />
      <Divider className="mt-4">
        <Button
          variant="link"
          className="p-0 fs--1"
          onClick={() => setOpen(open => !open)}
          aria-controls="login-form"
          aria-expanded={open}
        >
          o inicia sesión con email y contraseña
        </Button>
      </Divider>
      <Collapse in={open}>
        <div id="login-form" className="pt-3">
          <LoginFormContent {...props} />
        </div>
      </Collapse>
    </>
  );
};

LoginFormContent.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginFormContent.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;

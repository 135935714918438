import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import IconGroup from 'components/common/icon/IconGroup';
import Logo from 'components/common/Logo';
import createMarkup from 'helpers/createMarkup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { menuList1 } from 'data/footer';
import { bgWhiteIcons } from 'data/socialIcons';

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ className, list }) => (
  <ul className={classNames('list-unstyled', className)}>
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="text-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired
};

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-0 mb-0">
          <Link className="text-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <Section className="bg-100 pt-8 pb-4">
        <div
          className="bg-100 position-absolute btn-back-to-top cursor-pointer"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-400"
          />
        </div>
        <Row className="gx-10">
          <Col lg={6}>
            <Logo className="justify-content-start" />
            <p className="text-600">
              Con estos servicios, Inversor.pro te ofrece una experiencia de
              inversión integral, manejando todos los aspectos técnicos y
              operativos para que tú puedas centrarte en lo más importante: ver
              crecer tu inversión.
            </p>
            <IconGroup className="mt-4" icons={bgWhiteIcons} />
          </Col>
          <Col lg={6}>
            <FooterList
              className="d-flex gap-4 fw-semi-bold"
              list={menuList1}
            />
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default FooterStandard;

import React, { useContext } from 'react';
import classNames from 'classnames';
import { Card, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { CalculatorContext } from 'context/Context';
import { getCurrencyFormat } from 'helpers/utils';

const Mortgage = () => {
  const {
    mortgage: form,
    recurringExpenses,
    setMortgage,
    totalRentIncomes
  } = useContext(CalculatorContext);
  const { mortgage, entry, years, interest } = form || {};
  const amount = new Intl.NumberFormat('es-ES', { maximumFractionDigits: 1 });
  const principal = (mortgage || 0) - (entry || 0);
  const lengthOfLoan = 12 * (years || 0);
  const percentageRate = (interest || 0) / 1200;
  // Formula M = P[i(1+i)^n]/[(1+i)^n -1]
  let cuote =
    (principal * percentageRate) /
    (1 - Math.pow(1 + percentageRate, lengthOfLoan * -1));
  cuote = !isNaN(cuote) ? cuote * 12 : 0;

  const cashflow = totalRentIncomes - cuote - recurringExpenses;
  const roe = entry ? cashflow / entry : '-';

  const handleSwitch = ({ target }) => {
    const { checked } = target;
    setMortgage(checked ? {} : null);
  };

  const handleChange = ({ target }) => {
    let { name, value } = target;
    value = !value || isNaN(value) ? 0 : parseInt(value);
    setMortgage(form => ({ ...form, [name]: value }));
  };

  return (
    <Card className="border overflow-hidden mt-3">
      <Card.Header className={classNames({ 'pb-0': !!form })}>
        <Flex alignItems="center" className="gap-2">
          <Card.Title>
            <small>Compra con hipoteca</small>
          </Card.Title>
          <Form.Switch checked={!!form} onChange={handleSwitch} />
        </Flex>
      </Card.Header>
      {!!form && (
        <>
          <Card.Body className="pt-2">
            <Form.Group className="mb-1">
              <Flex
                alignItems="center"
                justifyContent="between"
                className="gap-4"
              >
                <Card.Text className="m-0">Hipoteca</Card.Text>
                <Flex alignItems="center" className="position-relative">
                  <Form.Control
                    className="text-end pe-5 me-n4"
                    type="text"
                    placeholder="0"
                    name="mortgage"
                    value={mortgage}
                    onChange={handleChange}
                  />
                  <span className="position-absolute end-0 me-3">€</span>
                </Flex>
              </Flex>
            </Form.Group>
            <Form.Group className="mb-1">
              <Flex
                alignItems="center"
                justifyContent="between"
                className="gap-4"
              >
                <Card.Text className="m-0">Entrada</Card.Text>
                <Flex alignItems="center" className="position-relative">
                  <Form.Control
                    className="text-end pe-5 me-n4"
                    type="text"
                    placeholder="0"
                    name="entry"
                    value={entry}
                    onChange={handleChange}
                  />
                  <span className="position-absolute end-0 me-3">€</span>
                </Flex>
              </Flex>
            </Form.Group>

            <hr className="border-top border-dashed" />

            <Card.Title>
              <small>Cuotas</small>
            </Card.Title>
            <Form.Group className="mb-1">
              <Flex
                alignItems="center"
                justifyContent="between"
                className="gap-4"
              >
                <Card.Text className="m-0">Plazo en años</Card.Text>
                <Flex alignItems="center" className="position-relative">
                  <Form.Control
                    className="text-end pe-5 me-n4"
                    type="text"
                    placeholder="0"
                    name="years"
                    value={years}
                    onChange={handleChange}
                  />
                  <small className="position-absolute end-0 me-3">años</small>
                </Flex>
              </Flex>
            </Form.Group>
            <Form.Group className="mb-3">
              <Flex
                alignItems="center"
                justifyContent="between"
                className="gap-4"
              >
                <Card.Text className="m-0">Intereses anuales</Card.Text>
                <Flex alignItems="center" className="position-relative">
                  <Form.Control
                    className="text-end pe-5 me-n4"
                    type="text"
                    placeholder="0"
                    name="interest"
                    value={interest}
                    onChange={handleChange}
                  />
                  <span className="position-absolute end-0 me-3">%</span>
                </Flex>
              </Flex>
            </Form.Group>
            <Form.Group>
              <Flex
                alignItems="center"
                justifyContent="between"
                className="gap-4"
              >
                <Card.Title>
                  <small>Cuota anual</small>
                </Card.Title>
                <Card.Title>
                  {getCurrencyFormat(cuote, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                </Card.Title>
              </Flex>
            </Form.Group>
          </Card.Body>
          <Card.Footer className="bg-200 border-top">
            <Flex
              alignItems="center"
              justifyContent="between"
              className="gap-4"
            >
              <Card.Title>
                <small>Cashflow</small>
              </Card.Title>
              <Card.Title>
                {getCurrencyFormat(cashflow, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </Card.Title>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="between"
              className="gap-4"
            >
              <Card.Title>
                <small>ROE</small>
              </Card.Title>
              <Card.Title>
                {!isNaN(roe) ? `${amount.format(roe)}%` : '-'}{' '}
              </Card.Title>
            </Flex>
          </Card.Footer>
        </>
      )}
    </Card>
  );
};

export default Mortgage;

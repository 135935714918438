import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import MultiSelect from 'components/common/MultiSelect';

const options = [
  'Con ascensor',
  'Fachada exterior',
  'Armarios empotrados',
  'Aire acondicionado',
  'Terraza',
  'Balcón',
  'Trastero',
  'Piscina',
  'Zona verde'
];

const AssetFeatures = ({ asset, setValue }) => {
  const { features: featuresLabel } = asset || {};
  const features = featuresLabel?.map(label => ({ label, value: label }));

  const handleChange = value => {
    setValue(
      'features',
      value.map(({ value }) => value)
    );
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-light">
        Características
      </Card.Header>
      <Card.Body>
        <MultiSelect
          options={options?.map(label => ({ label, value: label }))}
          onChange={handleChange}
          placeholder="Añade características del inmueble"
          value={features || []}
        />
      </Card.Body>
    </Card>
  );
};

AssetFeatures.propTypes = {
  asset: PropTypes.object,
  setValue: PropTypes.func
};

export default AssetFeatures;

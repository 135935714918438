import React, { useContext } from 'react';
import routes from 'routes/routes';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
import AppContext from 'context/Context';

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      {flatRoutes(routes).map(route => (
        <Nav.Item
          key={route.name}
          as={Link}
          className={route.active ? 'link-600' : 'text-500'}
          to={route.to}
          onClick={handleDropdownItemClick}
        >
          {route.name}
        </Nav.Item>
      ))}
    </>
  );
};

export default NavbarTopDropDownMenus;

export const bgWhiteIcons = [
  {
    icon: ['fab', 'instagram'],
    color: 'instagram',
    bg: 'white',
    transform: 'grow-8',
    href: 'https://www.instagram.com/inversorpro.app/'
  },
  {
    icon: ['fab', 'facebook-f'],
    color: 'facebook',
    bg: 'white',
    href: 'https://www.facebook.com/profile.php?id=61561017658883'
  }
];
export default [
  {
    icon: ['fab', 'instagram'],
    color: 'instagram',
    transform: 'grow-8',
    href: 'https://www.instagram.com/inversorpro.app/'
  },
  {
    icon: ['fab', 'facebook-f'],
    color: 'facebook',
    href: 'https://www.facebook.com/profile.php?id=61561017658883'
  }
];

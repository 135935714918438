import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Background from 'components/common/Background';
import { Card, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import UserProvider from 'components/UserProvider';

const AuthSplitLayout = ({ children, bgProps, content }) => {
  return (
    <UserProvider>
      <Container fluid>
        <Row
          className={classNames('min-vh-100', {
            'bg-100': window.self === window.top
          })}
        >
          {window.self === window.top && (
            <Col xs={6} className="d-none d-lg-block position-relative">
              {bgProps && <Background {...bgProps} />}
              {content && (
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  className="position-absolute top-0 left-0 w-100 h-100"
                >
                  {content}
                </Flex>
              )}
            </Col>
          )}
          <Col
            sm={10}
            md={6}
            className="px-sm-0 align-self-center mx-auto py-5"
          >
            <div className="mb-3">
              <Link
                className="text-white light font-sans-serif fw-bolder fs-4 z-index-1"
                to="/"
              >
                {window.self === window.top && <Logo height={30} />}
              </Link>
            </div>
            <Row className="g-0 justify-content-center">
              <Col lg={9} xl={8} className="col-xxl-6">
                <Card
                  className={classNames({
                    'shadow-none': window.self === window.top
                  })}
                >
                  <Card.Body className="p-4">{children}</Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </UserProvider>
  );
};

AuthSplitLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bgProps: PropTypes.shape(Background.propTypes).isRequired,
  content: PropTypes.node
};

export default AuthSplitLayout;

import { useCallback } from 'react';
import { useFirestore } from 'reactfire';

import {
  collection,
  getCountFromServer,
  query,
  where
} from 'firebase/firestore';

function useFetchAssetsByUserCount() {
  const firestore = useFirestore();

  return useCallback(
    userId => {
      if (!userId) {
        return 0;
      }

      const constraints = [
        userId && where('createdBy', '==', userId),
        where('status', '==', 'public')
      ].filter(constraint => constraint);

      const collectionRef = collection(firestore, 'assets');
      const assetsQuery = query(collectionRef, ...constraints);
      return getCountFromServer(assetsQuery);
    },
    [firestore]
  );
}

export default useFetchAssetsByUserCount;

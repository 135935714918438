import course1 from 'assets/img/e-learning/courses/course1.png';
import course2 from 'assets/img/e-learning/courses/course2.png';
import course3 from 'assets/img/e-learning/courses/course3.png';
import course4 from 'assets/img/e-learning/courses/course4.png';
import video1 from 'assets/video/e-learning/video1.mp4';

export const courses = [
  {
    title: 'No pierdas el tiempo',
    description:
      'Con inversor.pro puedes buscar en varios portales a la vez y tener la información en un mismo sitio.',
    image: course1,
    src: video1
  },
  {
    title: 'Analiza la viabilidad con datos',
    description:
      'Ten acceso al detalle de cada inmueble donde puedes ver un análisis económico, de amortización y posibles reformas a realizar.',
    image: course2,
    src: video1
  },
  {
    title: 'Accede a ofertas de bancos',
    description:
      'Ten el privilegio de enterarte de súper ofertas de bancos muy interesantes con rentabilidad inmediata.',
    image: course3,
    src: video1
  },
  {
    title: 'No te pierdas nada',
    description:
      'La IA desgrana información del inmueble y extrae pros y contras de la inversión, tanto en los inmuebles como en la zona en si.',
    image: course4,
    src: video1
  }
];

export default courses;

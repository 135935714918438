import fotocasa from 'assets/img/logos/fotocasa.png';
import habitaclia from 'assets/img/logos/habitaclia.png';
import idealista from 'assets/img/logos/idealista.png';
import indomio from 'assets/img/logos/indomio.png';
import pisos from 'assets/img/logos/pisos.png';
import inversorpro from 'assets/img/logos/inversorpro.png';
// import yaencontre from 'assets/img/logos/yaencontre.png';

export const sources = [
  {
    color: '#5ac2e5',
    label: 'Inversor Pro',
    svg: inversorpro,
    type: 'checkbox',
    value: 'inversorpro',
    name: 'source'
    // url: 'https://www.fotocasa.es/'
  },
  {
    color: '#303ab2',
    label: 'Fotocasa',
    svg: fotocasa,
    type: 'checkbox',
    value: 'fotocasa',
    name: 'source',
    url: 'https://www.fotocasa.es/'
  },
  {
    color: '#ff6600',
    label: 'Habitaclia',
    svg: habitaclia,
    type: 'checkbox',
    value: 'habitaclia',
    name: 'source',
    url: 'https://www.habitaclia.com/'
  },
  {
    color: '#dffa44',
    label: 'Idealista',
    svg: idealista,
    type: 'checkbox',
    value: 'idealista',
    name: 'source',
    url: 'https://www.idealista.com/'
  },
  {
    color: '#2B6482',
    label: 'Indomio',
    svg: indomio,
    type: 'checkbox',
    value: 'indomio',
    name: 'source',
    url: 'https://www.indomio.es/'
  },
  {
    color: '#41b6e6',
    label: 'Pisos.com',
    svg: pisos,
    type: 'checkbox',
    value: 'pisos',
    name: 'source',
    url: 'https://www.pisos.com/'
  }
  // {
  //   color: '#FF005C',
  //   label: 'Yaencontre',
  //   svg: yaencontre,
  //   type: 'checkbox',
  //   value: 'yaencontre',
  //   name: 'source',
  //   url: 'https://www.yaencontre.com/'
  // }
];

export const assetFilters = [
  {
    label: 'Portales',
    name: 'sources',
    options: sources
  },
  {
    label: 'Tipo de inmueble',
    options: [
      {
        label: 'Casa',
        icon: 'home',
        iconShrink: true,
        type: 'checkbox',
        value: 'house',
        name: 'type'
      },
      {
        label: 'Piso',
        icon: 'building',
        type: 'checkbox',
        value: 'flat',
        name: 'type'
      },
      {
        label: 'Local',
        icon: 'store',
        iconShrink: true,
        type: 'checkbox',
        value: 'store',
        name: 'type'
      }
    ]
  },
  {
    label: 'Rating',
    options: [
      {
        label: '90 +',
        icon: 'star',
        type: 'radio',
        name: 'rating',
        value: 90
      },
      {
        label: '75 +',
        icon: 'star',
        type: 'radio',
        name: 'rating',
        value: 75
      },
      {
        label: '50 +',
        icon: 'star',
        type: 'radio',
        name: 'rating',
        value: 50
      },
      {
        label: '25 +',
        icon: 'star',
        type: 'radio',
        name: 'rating',
        value: 25
      },
      {
        label: 'Todo',
        icon: 'star',
        type: 'radio',
        name: 'rating',
        value: 0
      }
    ]
  },
  {
    label: 'Precio',
    type: 'range',
    options: [
      {
        label: '0 €',
        icon: 'money-bill',
        name: 'price',
        value: 0
      },
      {
        label: '500.000 € +',
        icon: 'money-bill',
        name: 'price',
        value: 500000
      }
    ]
  },
  {
    label: 'Tamaño',
    type: 'range',
    unit: 'm²',
    options: [
      {
        label: '0m²',
        icon: 'expand',
        name: 'size',
        value: 0
      },
      {
        label: '150m² +',
        icon: 'expand',
        name: 'size',
        value: 150
      }
    ]
  },
  {
    label: 'Habitaciones',
    options: [
      {
        label: '5 +',
        icon: 'door-closed',
        type: 'radio',
        name: 'rooms',
        value: 5
      },
      {
        label: '4 +',
        icon: 'door-closed',
        type: 'radio',
        name: 'rooms',
        value: 4
      },
      {
        label: '3 +',
        icon: 'door-closed',
        type: 'radio',
        name: 'rooms',
        value: 3
      },
      {
        label: '2 +',
        icon: 'door-closed',
        type: 'radio',
        name: 'rooms',
        value: 2
      },
      {
        label: 'Todo',
        icon: 'door-closed',
        type: 'radio',
        name: 'rooms',
        value: 0
      }
    ]
  },
  { label: 'Ordenar', type: 'sort' }
];

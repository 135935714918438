import React from 'react';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SearchBox from 'components/navbar/top/SearchBox';

const Search = () => {
  return (
    <>
      {window.self !== window.top && (
        <Helmet
          style={[
            {
              cssText: `
            body {
                background-color: transparent;
            }
        `
            }
          ]}
        />
      )}
      <Flex
        className="flex-grow-1 w-100"
        alignItems="start"
        justifyContent="center"
      >
        <Card className="p-4 shadow-lg">
          <Card.Body className="text-center">
            <h5 className="text-center">
              Busca inmuebles para invertir por localidad
            </h5>
            <Flex className="mt-4" alignItems="center" justifyContent="center">
              <SearchBox size="xl" />
            </Flex>
          </Card.Body>
        </Card>
      </Flex>
    </>
  );
};

export default Search;

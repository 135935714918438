import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';
import { getCurrencyFormat } from 'helpers/utils';

const status = {
  draft: { color: 'gray', title: 'Borrador' },
  open: { color: 'warning', title: 'Pendiente' },
  paid: { color: 'success', title: 'Pagada' },
  uncollectible: { color: 'danger', title: 'Denegada' },
  void: { color: 'dark', title: '-' }
};

const columns = [
  {
    accessor: 'total',
    Header: 'Importe',
    headerProps: {
      className: 'fw-medium'
    },
    cellProps: {
      className: 'fw-bold text-dark py-3'
    },
    Cell: rowData =>
      getCurrencyFormat(rowData.row.original.total, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
  },
  {
    accessor: 'number',
    Header: 'Factura',
    headerProps: {
      className: 'fw-medium'
    },
    cellProps: {
      className: 'pe-6 py-3'
    },
    Cell: rowData => {
      const isRecurrent = [
        'subscription_create',
        'subscription_cycle'
      ].includes(rowData.row.original.billing_reason);
      return (
        <>
          #${rowData.row.original.number}{' '}
          {isRecurrent && <SoftBadge>Recurrente</SoftBadge>}
        </>
      );
    }
  },
  {
    accessor: 'account_name',
    disableSortBy: true,
    cellProps: {
      className: 'pe-6 py-3'
    },
    Cell: rowData => `${rowData.row.original.account_name}`
  },
  {
    accessor: 'created',
    Header: 'Fecha de creación',
    headerProps: {
      className: 'fw-medium'
    },
    cellProps: {
      className: 'py-3'
    },
    Cell: rowData =>
      dayjs(rowData.row.original.created * 1000).format('D MMM. YYYY, HH:mm')
  },
  {
    accessor: 'status',
    Header: 'Estado',
    headerProps: {
      className: 'fw-medium'
    },
    cellProps: {
      className: 'py-3 font-sans-serif fw-medium'
    },
    Cell: rowData => (
      <span className={`text-${status[rowData.row.original.status].color}`}>
        {status[rowData.row.original.status].title}
      </span>
    )
  },
  {
    accessor: 'actions',
    disableSortBy: true,
    cellProps: {
      className: 'py-3 font-sans-serif fw-medium'
    },
    Cell: rowData =>
      rowData.row.original.status === 'payed' && (
        <Link to={rowData.row.original.hosted_invoice_url} target="_blank">
          <FontAwesomeIcon icon="arrow-up-right-from-square" />
        </Link>
      )
  }
];

const PaymentHistory = ({ perPage = 5 }) => {
  const { billingPortalUrl, getBillingPortalUrl, invoices, me } =
    useContext(UserContext);

  useEffect(() => {
    if (!me?.uid || billingPortalUrl) {
      return;
    }
    getBillingPortalUrl();
  }, [me?.uid]);

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={invoices || []}
      sortable
      pagination
      perPage={perPage}
    >
      <Card className="h-100">
        <Card.Header className="d-flex flex-between-center">
          <h5 className="mb-0 text-nowrap py-2 py-xl-0">Historial de pagos</h5>
          <div>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={['fab', 'stripe']}
              iconClassName="fs-3 me-2 d-inline align-middle"
              as={Link}
              to={billingPortalUrl}
            >
              <span className="d-none d-sm-inline align-middle">
                Ver historial completo
              </span>
            </IconButton>
          </div>
        </Card.Header>
        {!invoices?.length ? (
          <Card.Body
            as={Flex}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <p className="fs--1">No hay pagos</p>
          </Card.Body>
        ) : (
          <>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-light text-900 fw-medium font-sans-serif"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  className: 'fs--1 mb-0 overflow-hidden fw-semi-bold'
                }}
              />
            </Card.Body>
            <Card.Footer className="bg-light d-flex align-items-center justify-content-end py-2">
              <AdvanceTableFooter
                rowCount={invoices?.length}
                table
                rowInfo
                perPage={perPage}
                viewAllBtn
              />
            </Card.Footer>
          </>
        )}
      </Card>
    </AdvanceTableWrapper>
  );
};

PaymentHistory.propTypes = {
  perPage: PropTypes.number
};

export default PaymentHistory;

import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import PlaceAutocomplete from './PlaceAutocomplete';
import Flex from 'components/common/Flex';
import Circle from './components/Circle';
import ClusteredMarkers from './components/ClusteredMarkers';
import AppContext, { AssetsContext } from 'context/Context';
import googleMapStyles from 'helpers/googleMapStyles';

const ZOOM_LEVEL_FOR_SINGLE_MARKER = 10;

const GoogleMapContent = ({
  assets,
  at,
  className,
  children,
  circle,
  darkStyle,
  disableDefaultUI = true,
  initialCenter,
  initialZoom = 18,
  mapStyle,
  mapTypeId,
  mapClassName,
  onSearch,
  options: optionsProp,
  autocompleteClassName,
  searchProps,
  showInfoWindow,
  style,
  tilt,
  ...rest
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const {
    assetsState: { searchedText },
    getPosition
  } = useContext(AssetsContext);
  const { pathname } = useLocation();
  const [address, setAddress] = useState();
  const [results, setResults] = useState([]);
  const [mapStyles, setMapStyles] = useState(mapStyle);
  const styles = googleMapStyles[mapStyles];
  const positions = assets?.map(({ position }) => position);
  const map = useMap();
  const options = {
    mapTypeId,
    mapTypeControl: true,
    streetViewControl: true,
    fullscreenControl: true,
    styles,
    ...(optionsProp || {})
  };

  const fitMapToBounds = ({ positions, bounds }) => {
    if (!map || (!positions && !bounds)) {
      return;
    }
    if (bounds) {
      map.fitBounds(bounds);
      return;
    }
    const markers = positions.map((marker, index) => ({
      key: index,
      position: { lat: marker.lat, lng: marker.lng },
      city: marker.city,
      address: marker.address
    }));
    if (markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach(marker => bounds.extend(marker.position));
      map.fitBounds(bounds);
      if (markers.length === 1) {
        map.setZoom(ZOOM_LEVEL_FOR_SINGLE_MARKER);
      }
    } else {
      !searchedText && getPosition();
    }
  };

  const searchOnMap = async _address => {
    if (at !== 'AssetMap') {
      return;
    }
    const geocoder = new window.google.maps.Geocoder();
    let _results = results;
    if (!results?.length || address !== _address) {
      const { results: newResults } =
        (await geocoder.geocode({ address: _address })) || {};
      const filteredNewResults = newResults.filter(
        ({ formatted_address: address }) => address.match(/(España|Spain)/)
      );
      setResults(filteredNewResults);
      _results = filteredNewResults;
    }
    const [result] = _results || [];
    const { geometry } = result || {};
    const { bounds } = geometry || {};
    fitMapToBounds({ bounds });
    setAddress(_address);
  };

  const centerMap = () => {
    if (!map) {
      return;
    }
    if (!searchedText) {
      fitMapToBounds({ positions });
      return;
    }
    searchOnMap(searchedText);
  };

  useEffect(() => {
    centerMap();
  }, [map, pathname, searchedText]);

  useEffect(() => {
    if (!map || !positions?.length) {
      return;
    }
    fitMapToBounds({ positions });
  }, [map, positions]);

  useEffect(() => {
    if (darkStyle && isDark) setMapStyles(darkStyle);
    else setMapStyles(mapStyle);
  }, [isDark]);

  return (
    <Flex
      className={`h-100 position-relative ${className}`}
      direction="column"
      style={style}
    >
      {onSearch && (
        <PlaceAutocomplete
          className={autocompleteClassName}
          onPlaceSelect={onSearch}
          {...(searchProps || {})}
        />
      )}
      <Map
        key={`Map-${JSON.stringify({
          initialCenter,
          initialZoom,
          tilt,
          styles
        })}`}
        defaultZoom={initialZoom}
        options={options}
        defaultCenter={initialCenter}
        tilt={tilt}
        mapTypeId={mapTypeId}
        disableDefaultUI={disableDefaultUI}
        className={classNames(
          'd-grid flex-grow-1 w-100 h-100 overflow-hidden',
          mapClassName
        )}
        styles={styles}
        {...rest}
      >
        {circle && <Circle {...circle} />}
        {!!assets?.length && (
          <ClusteredMarkers
            assets={assets}
            selectedAsset={showInfoWindow ? assets[0] : undefined}
          />
        )}
      </Map>
      {children}
    </Flex>
  );
};

const GoogleMap = props => {
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMapContent {...props} />
    </APIProvider>
  );
};

GoogleMapContent.propTypes = {
  mapTypeId: PropTypes.string,
  mapStyle: PropTypes.oneOf([
    'Default',
    'Gray',
    'Midnight',
    'Hopper',
    'Beard',
    'AssassianCreed',
    'SubtleGray',
    'Tripitty',
    'Cobalt'
  ]),
  darkStyle: PropTypes.oneOf([
    'Default',
    'Gray',
    'Midnight',
    'Hopper',
    'Beard',
    'AssassianCreed',
    'SubtleGray',
    'Tripitty',
    'Cobalt'
  ]),
  assets: PropTypes.array,
  at: PropTypes.string,
  className: PropTypes.string,
  disableDefaultUI: PropTypes.bool,
  children: PropTypes.node,
  circle: PropTypes.object,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  initialZoom: PropTypes.number,
  mapClassName: PropTypes.string,
  onSearch: PropTypes.func,
  options: PropTypes.object,
  autocompleteClassName: PropTypes.string,
  searchProps: PropTypes.object,
  showInfoWindow: PropTypes.bool,
  style: PropTypes.object,
  tilt: PropTypes.number
};

export default GoogleMap;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SOCIALS from 'data/socials.json';

const UserIntro = ({ profile }) => {
  const { description, socials } = profile || {};
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="flex-between-center">
          <Col xxl={9} className="text-1000">
            {description || '-'}
          </Col>
          <Col xxl={3}>
            <Flex justifyContent="center" className="mt-4 mt-xxl-0">
              <ul className="list-unstyled mb-0 d-flex flex-wrap flex-xxl-column gap-3 justify-content-center">
                {SOCIALS.map(({ key, url, icon, title }) => {
                  const value = socials?.[key];
                  return (
                    value && (
                      <li key={title}>
                        <Button
                          className="text-800 hover-primary fw-semi-bold font-base"
                          variant="link"
                          as={Link}
                          target="_blank"
                          to={`${url}${value}`}
                        >
                          <FontAwesomeIcon icon={icon} className="me-2" />
                          {title}
                        </Button>
                      </li>
                    )
                  );
                }).filter(data => data)}
              </ul>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

UserIntro.propTypes = {
  profile: PropTypes.object
};

export default UserIntro;

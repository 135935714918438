import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const AssetsContext = createContext({ assets: [], primaryAssets: [] });

export const CalculatorContext = createContext({});

export const FavouritesContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});

export const UserContext = createContext({ me: {} });

export default AppContext;

import { useContext } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import {
  collection,
  limit,
  startAfter,
  // orderBy,
  query,
  where
} from 'firebase/firestore';
import { UserContext } from 'context/Context';

function useFetchAssets(
  areaId,
  { cursor, filteredSources, itemsPerPage, order } = {}
) {
  const firestore = useFirestore();
  const { subscription } = useContext(UserContext);
  const isAvailable = !!subscription;
  // collection path
  const assetsCollection = 'assets';

  // create default constraints
  const constraints = [
    where('areaId', '==', areaId),
    !!filteredSources?.length &&
      where(
        'source',
        'in',
        filteredSources.map(({ value }) => value)
      ),
    // orderBy('sponsored', 'desc'),
    order,
    limit(itemsPerPage)
  ].filter(constraint => constraint);
  // if cursor is not undefined (e.g. not initial query)
  // we pass it as a constraint
  if (isAvailable && cursor) {
    constraints.push(startAfter(cursor));
  }

  const collectionRef = collection(firestore, assetsCollection);

  const assetsQuery = query(collectionRef, ...constraints);

  return useFirestoreCollection(assetsQuery, {
    idField: 'id'
  });
}

export default useFetchAssets;

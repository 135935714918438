import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Overlay, Popover as BSPopover } from 'react-bootstrap';

const Popover = ({
  children,
  className,
  placement = 'top',
  content,
  popoverClass,
  show: showProp = false,
  ...rest
}) => {
  const [show, setShow] = useState(showProp);
  const target = useRef();

  return (
    <>
      <div
        ref={target}
        className={classNames('d-inline-block', className)}
        onClick={() => setShow(show => !show)}
      >
        {children}
      </div>
      <Overlay
        rootClose
        onHide={() => setShow(false)}
        container={() => document.body}
        placement={placement}
        show={show && !!content}
        target={target.current}
        className="z-index-1030"
      >
        {props => (
          <BSPopover
            body
            className={classNames('max-w-100', popoverClass)}
            {...props}
            {...rest}
          >
            {content}
          </BSPopover>
        )}
      </Overlay>
    </>
  );
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  content: PropTypes.node,
  popoverClass: PropTypes.string,
  show: PropTypes.string
};

Popover.Header = BSPopover.Header;
Popover.Body = BSPopover.Body;

export default Popover;

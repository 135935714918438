import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import useVisibilityObserver from 'hooks/useVisibilityObserver';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Bottombar from './Bottombar';
import {
  CapitalGain,
  FlippingHouse,
  LongTermRentability,
  RoomRentability,
  VacationRentability,
  Rate
} from 'components/assets/Asset';
import AppContext, { UserContext } from 'context/Context';
import { stripePaymentLinks } from 'helpers/defines';
import { getColor, getCurrencyFormat, hexToRgba } from 'helpers/utils';
import corner from 'assets/img/illustrations/corner-4.png';

const AssetPricingPlan = ({ asset }) => {
  const targetElRef = useRef();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { me } = useContext(UserContext);
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '100px'
  );
  const { link, price, rooms, size, source } = asset;
  const color = isDark
    ? hexToRgba(getColor('primary'), 0.15)
    : getColor('primary');

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="border mb-3">
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Rate
                {...asset}
                className="w-150px h-150px mx-auto"
                circleClass="w-100 h-100"
                showText={true}
              />
            </Col>
            <Col xs={12}>
              <hr className="border-top border-dashed" />
              <h6 className="fw-bold">Rentabilidad</h6>
              <Flex
                alignItems="center"
                justifyContent="around"
                className="gap-2 mt-2"
              >
                <LongTermRentability
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
                <RoomRentability
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
                <VacationRentability
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
              </Flex>
            </Col>
            {!!asset?.reform?.amount && (
              <Col xs={12}>
                <hr className="border-top border-dashed" />
                <h6 className="fw-bold">Rentabilidad tras reforma</h6>
                <Flex
                  alignItems="center"
                  justifyContent="around"
                  className="gap-2 mt-2"
                >
                  <LongTermRentability
                    {...asset}
                    rentability={asset?.reform?.rentability}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                  <RoomRentability
                    {...asset}
                    rentability={asset?.reform?.rentability}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                  <VacationRentability
                    {...asset}
                    rentability={asset?.reform?.rentability}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                </Flex>
              </Col>
            )}
            <Col xs={12}>
              <hr className="border-top border-dashed" />
              <h6 className="fw-bold">Ganancia</h6>
              <Flex
                alignItems="center"
                justifyContent="around"
                className="gap-2 mt-2"
              >
                <CapitalGain
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
                {!!asset?.reform?.amount && (
                  <FlippingHouse
                    {...asset}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                )}
              </Flex>
            </Col>
            <Col xs={12}>
              <hr className="border-top border-dashed" />
              <h6 className="fw-bold">Precio</h6>
              <Flex alignItems="center">
                <h5 className="fw-normal m-0">
                  {getCurrencyFormat(price, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                </h5>
                <SoftBadge className="ms-2">
                  {getCurrencyFormat(price / size, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}{' '}
                  / m²
                </SoftBadge>
              </Flex>
              <Button
                className="mt-3"
                as={Link}
                to={`${stripePaymentLinks.negotiate}?locale=es&prefilled_email=${me?.email}&client_reference_id=${me?.data?.stripeId}&utm_source=webapp&utm_content=detailPrice`}
                target="_blank"
              >
                Negociamos por ti
              </Button>
            </Col>
            <Col xs={12}>
              <hr className="border-top border-dashed" />
              <h6 className="fw-bold">Características del inmueble</h6>
              <Flex alignItems="center" className="gap-2">
                {!!rooms && <SoftBadge bg="secondary">{rooms} hab.</SoftBadge>}
                {!!size && <SoftBadge bg="secondary">{size} m²</SoftBadge>}
              </Flex>
            </Col>
            <Col xs={12}>
              <hr className="border-top border-dashed" />
              <h6 className="fw-bold">Portal inmobiliario</h6>
              <a
                className="fw-normal m-0 text-capitalize"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {source}
              </a>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="border overflow-hidden mt-xl-n1 mb-3">
        <Background image={corner} />
        <Card.Header
          className="pb-8 mb-n8 z-index-2"
          style={{
            background: `linear-gradient(180deg, ${color}, transparent)`
          }}
        >
          <h5>En qué te podemos ayudar</h5>
        </Card.Header>
        <Card.Body className="z-index-2">
          <Flex wrap="wrap" className="gap-1">
            <Button
              size="sm"
              variant="falcon-primary"
              as={Link}
              to={`${stripePaymentLinks.report}?locale=es&prefilled_email=${me?.email}&client_reference_id=${me?.data?.stripeId}&utm_source=webapp&utm_content=servicesBox`}
              target="_blank"
            >
              Solicitar informe
            </Button>
            <Button size="sm" variant="falcon-primary">
              Solicitar hipoteca <SoftBadge>Próximamente</SoftBadge>
            </Button>
            <Button
              size="sm"
              variant="falcon-primary"
              as={Link}
              to={`${stripePaymentLinks.expert}?locale=es&prefilled_email=${me?.email}&client_reference_id=${me?.data?.stripeId}&utm_source=webapp&utm_content=servicesBox`}
              target="_blank"
            >
              Solicitar la ayuda de un experto
            </Button>
            <Button
              size="sm"
              variant="falcon-primary"
              as={Link}
              to={`${stripePaymentLinks.negotiate}?locale=es&prefilled_email=${me?.email}&client_reference_id=${me?.data?.stripeId}&utm_source=webapp&utm_content=servicesBox`}
              target="_blank"
            >
              Negociamos por ti
            </Button>
            <Button
              size="sm"
              variant="falcon-primary"
              as={Link}
              to={`${stripePaymentLinks.evaluate}?locale=es&prefilled_email=${me?.email}&client_reference_id=${me?.data?.stripeId}&utm_source=webapp&utm_content=servicesBox`}
              target="_blank"
            >
              Lo visitamos por ti
            </Button>
          </Flex>
        </Card.Body>
      </Card>
      <Bottombar asset={asset} inViewport={inViewport} />
    </>
  );
};

AssetPricingPlan.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    link: PropTypes.string,
    price: PropTypes.number,
    reform: PropTypes.object,
    rooms: PropTypes.number,
    size: PropTypes.number,
    source: PropTypes.string
  })
};

export default AssetPricingPlan;

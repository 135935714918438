import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
// import IconButton from 'components/common/IconButton';
import AppContext, { UserContext } from 'context/Context';

const DisplayName = ({ value }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { updateData } = useContext(UserContext);
  const [isEditing, setEdit] = useState(false);
  const [name, setDisplayName] = useState(value);

  const handleChange = async ({ target: { value } }) => {
    setDisplayName(value);
  };

  const handleClose = async () => {
    setDisplayName(value);
    setEdit(false);
  };

  const handleSave = async () => {
    await updateData({ name });
    setEdit(false);
  };

  return (
    <>
      <Flex alignItems="center">
        {value ? (
          <>
            <h5 className="mb-0">{value}</h5>
            {/* <SoftBadge
              bg="primary"
              pill
              className="d-none d-md-inline-block ms-2"
            >
              2-step verification
              <FontAwesomeIcon icon="link" className="ms-1" />
            </SoftBadge> */}
          </>
        ) : (
          '-'
        )}
        <Button
          variant="link"
          size="sm"
          className="py-0 my-n1"
          onClick={() => setEdit(true)}
        >
          <Tooltip title="Editar">
            <FontAwesomeIcon icon="pen" className="me-1" />
          </Tooltip>
        </Button>
      </Flex>
      <Modal show={isEditing} onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="px-x1 border-0"
        ></Modal.Header>
        <Modal.Body className="p-x1">
          <Form.Control defaultValue={value} onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer className="px-x1 py-2 border-0">
          <Button
            variant="falcon-default"
            type="button"
            onClick={handleClose}
            className="me-3"
          >
            <FontAwesomeIcon icon="times" className="me-2 fs--1" />
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSave}
            className="mx-0"
          >
            <FontAwesomeIcon icon="check" className="me-2 fs--1" />
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

DisplayName.propTypes = {
  value: PropTypes.string
};

const PageHeader = () => {
  const { me } = useContext(UserContext);
  const { displayName } = me || {};

  return (
    <Card className="mt-3 mb-3">
      <Card.Body className="d-flex gap-2 flex-wrap flex-between-center">
        <div>
          <h6 className="text-primary">Inversor</h6>
          <DisplayName value={displayName} />
        </div>
        {/* <div>
          <IconButton
            variant="primary"
            size="md"
            icon="envelope"
            iconClassName="me-sm-1"
            className="me-2"
          >
            <span className="d-none d-sm-inline-block">Message</span>
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="md"
            icon="users"
            iconClassName="me-sm-1"
          >
            <span className="d-none d-sm-inline-block">Followers</span>
          </IconButton>
        </div> */}
      </Card.Body>
    </Card>
  );
};

export default PageHeader;

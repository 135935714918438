import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { CalculatorContext } from 'context/Context';
import allExpenses from 'data/expenses.json';
import { getCurrencyFormat } from 'helpers/utils';
import useVisibilityObserver from 'hooks/useVisibilityObserver';

const NoOptionsMessage = ({ className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.NoOptionsMessage
      {...rest}
      className={classNames(className, { 'p-0': size === 'sm' })}
    >
      Añade un gasto custom
    </components.NoOptionsMessage>
  );
};

NoOptionsMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem'
  }),
  placeholder: base => ({
    ...base,
    padding: '6px 0 6px 0'
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const Expense = ({ expense, index }) => {
  const {
    deleteExpense,
    expenses = [],
    setExpenses
  } = useContext(CalculatorContext);
  const { amount, label, recurring, required } = expense || {};

  const handleChange = ({ target }) => {
    const { value } = target;
    const newExpenses = [...expenses];
    newExpenses[index].amount = isNaN(value) ? 0 : parseFloat(value);
    setExpenses(newExpenses);
  };

  return (
    <tr>
      <td valign="middle">
        {label}
        {recurring && <SoftBadge className="ms-2">Anual</SoftBadge>}
      </td>
      <td valign="middle">
        <Flex alignItems="center" justifyContent="end" className="gap-2">
          <Flex alignItems="center" className="position-relative">
            <Form.Control
              className="text-end pe-5 me-n4"
              type="text"
              placeholder="0"
              value={amount}
              onChange={handleChange}
            />
            <span className="position-absolute end-0 me-3">€</span>
          </Flex>
          {!required && (
            <Button
              variant="falcon-danger"
              size="sm"
              onClick={() => deleteExpense(index)}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          )}
        </Flex>
      </td>
    </tr>
  );
};

Expense.propTypes = {
  expense: PropTypes.object,
  index: PropTypes.number
};

const AddExpense = () => {
  const { addExpense, expenses = [] } = useContext(CalculatorContext);
  const options = allExpenses
    .filter(({ label }) => !expenses.some(e => e.label === label))
    .sort((e1, e2) => (e1.label < e2.label ? -1 : 1));
  const creatable = useRef();

  const handleExpenseChange = expense => {
    if (!expense) {
      return;
    }
    addExpense(expense?.value);
    setTimeout(() => {
      creatable.current?.clearValue();
      creatable.current?.blur();
    }, 10);
  };

  const handleCreateExpense = newExpense => {
    addExpense(newExpense);
  };

  return (
    <tr>
      <td className="border-0 p-0" colSpan={2} valign="middle">
        <Flex alignItems="center" className="p-3 gap-2 w-100">
          <div className="flex-grow-1">
            <Creatable
              ref={creatable}
              menuPlacement="auto"
              options={options?.map(({ label, ...rest }) => ({
                label,
                ...rest,
                value: label
              }))}
              components={{ NoOptionsMessage }}
              placeholder="Añade un gasto"
              menuPortalTarget={document.body}
              classNamePrefix="react-select"
              onChange={handleExpenseChange}
              onCreateOption={handleCreateExpense}
              styles={styles}
            />
          </div>
        </Flex>
      </td>
    </tr>
  );
};

const Totals = () => {
  const { menu } = useParams();
  const { recurringExpenses = 0, totalExpenses = 0 } =
    useContext(CalculatorContext);
  const fixedExpenses = totalExpenses - recurringExpenses;

  return (
    <tr>
      <td
        className="bg-200 border-0 border-top shadow-none text-end"
        colSpan={2}
        valign="middle"
      >
        <Flex alignItems="center" justifyContent="end" className="gap-4">
          {menu === 'rent' && (
            <div>
              <Card.Title>
                <small>Gastos anuales</small>
              </Card.Title>
              <Card.Title
                className={classNames({
                  'text-500': fixedExpenses === 0,
                  'text-warning': fixedExpenses > 0
                })}
              >
                <FontAwesomeIcon className=" me-2" icon="caret-down" />
                {getCurrencyFormat(recurringExpenses, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </Card.Title>
            </div>
          )}
          <div>
            <Card.Title>
              <small>Total gastos</small>
            </Card.Title>
            <Card.Title
              className={classNames({
                'text-500': fixedExpenses === 0,
                'text-danger': totalExpenses > 0
              })}
            >
              <FontAwesomeIcon className=" me-2" icon="caret-down" />
              {getCurrencyFormat(fixedExpenses, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
            </Card.Title>
          </div>
        </Flex>
      </td>
    </tr>
  );
};

Totals.propTypes = {
  value: PropTypes.string
};

const Expenses = () => {
  const targetElRef = useRef();
  const { expenses = [] } = useContext(CalculatorContext);
  const { observer } = useVisibilityObserver(targetElRef, '100px');

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="border mb-3">
        <Card.Body className="p-0 overflow-hidden rounded">
          <Table className="m-0" striped>
            <thead>
              <tr>
                <th className="bg-200 border-bottom" colSpan={2}>
                  Gastos
                </th>
              </tr>
            </thead>
            <tbody>
              {expenses.map((expense, index) => (
                <Expense
                  key={`Expense-${index}-${expense?.label}`}
                  expense={expense}
                  index={index}
                />
              ))}
              <AddExpense />
              <Totals />
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Expenses;

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Overlay, Popover as BSPopover } from 'react-bootstrap';

const Confirm = ({
  as: Trigger = 'div',
  buttonText,
  callback,
  children,
  className,
  placement = 'top',
  content,
  popoverClass,
  show: showProp = false,
  variant,
  ...rest
}) => {
  const [show, setShow] = useState(showProp);
  const target = useRef();

  const handleConfirm = () => {
    setShow(false);
    callback?.();
  };

  return (
    <>
      <Trigger
        ref={target}
        className={classNames('d-inline-block', className)}
        onClick={event => {
          event.stopPropagation();
          setShow(show => !show);
        }}
      >
        {children}
      </Trigger>
      <Overlay
        rootClose
        onHide={() => setShow(false)}
        container={() => document.body}
        placement={placement}
        show={show && !!content}
        target={target.current}
        className="z-index-1030"
      >
        {props => (
          <BSPopover
            body
            className={classNames('max-w-100', popoverClass)}
            {...props}
            {...rest}
          >
            {content}
            <div className="d-grid mt-3">
              <Button variant={variant} onClick={handleConfirm}>
                {buttonText}
              </Button>
            </div>
          </BSPopover>
        )}
      </Overlay>
    </>
  );
};

Confirm.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonText: PropTypes.string,
  callback: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  content: PropTypes.node,
  popoverClass: PropTypes.string,
  show: PropTypes.string,
  variant: PropTypes.string
};

Confirm.Header = BSPopover.Header;
Confirm.Body = BSPopover.Body;

export default Confirm;

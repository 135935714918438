import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import Section from 'components/common/Section';
import bg from 'assets/img/generic/home1.jpg';

const Cta = () => (
  <Section
    overlay="3"
    image={bg}
    bgClassName="opacity-25"
    position="center top"
    className="light bg-dark"
  >
    <Row className="justify-content-center text-center">
      <Col lg={8}>
        <p className="fs-3 fs-sm-4 fw-bold text-white">
          ¿Cansado de perder oportunidades?
        </p>
        <p className="fs-3 fs-sm-4 text-white">
          Únete a la comunidad de inversores que ya está viendo crecer sus
          inversiones
        </p>
        <Button
          variant="outline-light"
          size="lg"
          className="border-2 rounded-pill mt-4 fs-0 py-2"
          as={Link}
          to="/authentication/register"
        >
          Regístrate gratis
        </Button>
      </Col>
    </Row>
  </Section>
);

export default Cta;

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const Loader = ({ className, dotsClassName, style }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={classNames('w-100 h-100', className)}
    >
      <div>
        <Spinner
          animation="grow"
          className={dotsClassName}
          size="sm"
          style={{ animationDelay: '-0.1s', ...style }}
        />
      </div>
      <div className="mx-1">
        <Spinner
          animation="grow"
          className={dotsClassName}
          size="sm"
          style={style}
        />
      </div>
      <div>
        <Spinner
          animation="grow"
          className={dotsClassName}
          size="sm"
          style={{ animationDelay: '0.1s', ...style }}
        />
      </div>
    </Flex>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  dotsClassName: PropTypes.string,
  style: PropTypes.object
};

export default Loader;

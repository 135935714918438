import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useCountdown from 'hooks/useCountdown';
import Flex from 'components/common/Flex';

export const Countdown = ({ date, className, itemClassName }) => {
  const [days, hours, minutes, seconds] = useCountdown(date);

  return (
    <Flex className={className}>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction="column"
        alignItems="center"
      >
        <h5 className="text-inherit">{days}</h5>
        <span className="fs--2">días</span>
      </Flex>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction="column"
        alignItems="center"
      >
        <h5 className="text-inherit">{hours}</h5>
        <span className="fs--2">horas</span>
      </Flex>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction="column"
        alignItems="center"
      >
        <h5 className="text-inherit">{minutes}</h5>
        <span className="fs--2">minutos</span>
      </Flex>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction="column"
        alignItems="center"
      >
        <h5 className="text-inherit">{seconds}</h5>
        <span className="fs--2">segundos</span>
      </Flex>
    </Flex>
  );
};

Countdown.propTypes = {
  date: PropTypes.object,
  className: PropTypes.string,
  itemClassName: PropTypes.string
};

export default Countdown;

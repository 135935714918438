import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getTextColor } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';
import { getUnique, groupBy } from 'helpers/utils';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => `
    <div>
      <p class='mb-2 text-600'>
      ${
        dayjs(params[0].axisValue).isValid()
          ? dayjs(params[0].axisValue).format('MMMM YYYY')
          : params[0].axisValue
      }
      </p>
      ${params
        .map(
          ({ seriesName, value, color }) =>
            `<div class="dot d-inline-block" style="background-color: ${color}"></div>
            <span class='text-600'>
              ${seriesName} : <strong>${value || 0}</strong>
            </span>`
        )
        .join('<br />')}
    </div>`;

const getOptions = ({ labels = [], data = [], sources }) => ({
  color: sources.map(({ color }) => color),
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
    padding: [10, 16],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('primary') },
    borderWidth: 1,
    borderRadius: 20,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  toolbox: {
    feature: {
      magicType: {
        type: ['stack', 'tiled']
        // type: ['tiled']
      }
    },
    right: 0
  },
  legend: {
    data: sources.map(({ label }) => label),
    textStyle: {
      color: getColor('gray-600')
    },
    left: 0,
    show: false
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-500')
    },
    splitLine: {
      lineStyle: {
        show: true,
        color: getColor('gray-200')
      }
    }
  },
  xAxis: {
    type: 'category',
    data: labels,
    axisLine: {
      lineStyle: {
        show: true,
        color: getColor('gray-300')
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-500'),
      formatter: value => dayjs(value).format('MMM YYYY')
    }
  },
  series: sources.map(({ color, label }, index) => ({
    name: label,
    type: 'bar',
    stack: 'total',
    label: {
      show: true,
      color: getTextColor(color)
    },
    emphasis: {
      focus: 'series'
    },
    itemStyle: {
      borderRadius: 0,
      width: 16
    },
    distance: {
      min: 20,
      max: 100
    },
    data: data.map(monthData => monthData[index] || null)
  })),
  grid: {
    right: 15,
    left: 5,
    bottom: 5,
    top: 15,
    containLabel: true
  }
});

const onChartReadyCallback = chart => {
  chart?.resize();
};

const BrowsedAssets = () => {
  const { getAvailableSources, views } = useContext(UserContext);
  const chart = useRef();
  const availableSources = getAvailableSources();
  const raw = views.map(view => ({
    ...view,
    createdAtMonth: view.createdAt.match(/\d{4}-\d{2}/)?.[0],
    source: view.assetId.split('-')[0]
  }));
  const byMonth = groupBy(raw, 'createdAtMonth');
  const labels = Object.keys(byMonth).map(label => `${label}-01`);
  const data = Object.values(byMonth).map(views => {
    const uniqueViews = getUnique(views, 'assetId');
    return availableSources.map(
      ({ value }) =>
        uniqueViews.filter(({ source }) => source === value)?.length || 0
    );
  });

  const handleLegendToggle = (event, name) => {
    chart.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
    event.target.closest('button').classList.toggle('opacity-50');
  };

  useEffect(() => {
    onChartReadyCallback(chart.current);
  }, []);

  return (
    <Card className="h-100">
      <Card.Header className="bg-light">
        <h6 className="mb-0">Inmuebles analizados</h6>
      </Card.Header>
      <Card.Body>
        {labels.length ? (
          <ReactEChartsCore
            className="h-100"
            ref={chart}
            echarts={echarts}
            option={getOptions({ labels, data, sources: availableSources })}
            style={{ minHeight: '21.875rem' }}
            onChartReady={onChartReadyCallback}
          />
        ) : (
          <Flex
            className="w-100 h-100"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '21.875rem' }}
          >
            <SoftBadge bg="secondary">No hay datos</SoftBadge>
          </Flex>
        )}
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Flex className="flex-wrap gap-md-2">
              {availableSources.map(({ label, svg, value }) => (
                <Button
                  key={`MonthlyViewsBySource-${value}`}
                  variant="text"
                  size="sm"
                  className="d-flex align-items-center p-0 shadow-none"
                  onClick={event => handleLegendToggle(event, label)}
                >
                  <Avatar
                    className="p-1 bg-transparent"
                    name={label}
                    src={svg}
                    size="m"
                  />
                  <small className="text-600">{label}</small>
                </Button>
              ))}
            </Flex>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

BrowsedAssets.propTypes = {
  data: PropTypes.shape({
    onSaleAsset: PropTypes.array,
    regularPaidAsset: PropTypes.array
  })
};

export default BrowsedAssets;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import debounce from 'just-debounce-it';
import imageCompression from 'browser-image-compression';
import { getAnalytics, logEvent } from '@firebase/analytics';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  linkWithPopup,
  linkWithRedirect,
  onAuthStateChanged,
  sendEmailVerification as sendFirebaseEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signOut as firebaseSignOut,
  unlink,
  updatePassword,
  updateProfile,
  PhoneAuthProvider,
  RecaptchaVerifier,
  updatePhoneNumber
} from '@firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from '@firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes
} from '@firebase/storage';
import {
  useAuth,
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useStorage
} from 'reactfire';
import { toast } from 'react-toastify';
import { sources } from 'data/assets/assetData';
import { UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';
import dayjs from 'dayjs';

export const free = {
  product: {
    name: 'Gratis',
    marketing_features: [
      { name: '3 fichas de inmuebles al mes' },
      { name: 'Algunos portales disponibles:' },
      { name: 'Pisos.com' },
      { name: 'Indomio' }
    ],
    metadata: {
      limit: 3
    }
  },
  unit_amount: 0
};

const getProvider = providerId => {
  let provider;
  switch (providerId) {
    case 'email':
    case 'password':
      provider = new EmailAuthProvider();
      break;
    case 'facebook.com':
      provider = new FacebookAuthProvider();
      break;
    case 'google.com':
      provider = new GoogleAuthProvider();
      break;
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
  return provider;
};

const UserProvider = ({ children }) => {
  const location = useLocation();
  const [upgradeModalContent, setUpgradeModalContent] = useState(false);
  const [upgradeModalShow, setUpgradeModalShow] = useState(false);
  const { state: stateProp, search } = location;
  const { from = '/' } = stateProp || {};
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const auth = useAuth();
  const db = useFirestore();
  const storage = useStorage();

  const [isWidgetsModalOpened, openWidgetsModal] = useState(false);
  const [state, setState] = useState({});
  let { prices, user = {}, isSignedOut } = state || {};

  let myRef = doc(db, 'none', 'none');
  let favouritesQuery = query(collection(db, 'none'));
  let followingQuery = query(collection(db, 'none'));
  let viewsQuery = query(collection(db, 'none'));
  const lastYear = new Date();
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  if (user?.uid) {
    myRef = doc(db, 'users', user.uid);
    favouritesQuery = query(
      collection(db, 'favourites'),
      where('userId', '==', user.uid)
    );
    followingQuery = query(
      collection(db, 'followers'),
      where('followerId', '==', user.uid)
    );
    viewsQuery = query(
      collection(db, 'views'),
      where('createdAt', '>', dayjs(lastYear).format('YYYY-MM')),
      where('userId', '==', user.uid)
    );
  }
  const { data, status } = useFirestoreDocData(myRef);
  const { data: favourites = [] } = useFirestoreCollectionData(favouritesQuery);
  const { data: following = [] } = useFirestoreCollectionData(followingQuery);
  const { data: views = [], status: viewsStatus } =
    useFirestoreCollectionData(viewsQuery);

  const me = useMemo(
    () => ({ ...user, data, favourites, following, status }),
    [user, data, favourites, following, status]
  );

  const hasActiveSubscription = () => {
    return !!state.subscription;
  };

  const goToCheckout = useCallback(
    async ({ plan, interval }) => {
      if (!prices) {
        prices = await getPrices();
      }
      const price =
        prices.find(
          ({ product, recurring }) =>
            product.name.toLowerCase() === plan.toLowerCase() &&
            recurring.interval === interval
        ) || {};
      const { metadata: { link } = {} } = price;
      const checkOutUrl = `${link}?locale=es&prefilled_email=${user?.email}`;
      const a = document.createElement('a');
      a.href = checkOutUrl;
      a.click();
    },
    [user?.email, prices]
  );

  const resetPassword = useCallback(async email => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const sendEmailVerification = useCallback(async () => {
    try {
      if (auth?.currentUser?.emailVerified) {
        return;
      }
      await sendFirebaseEmailVerification(auth.currentUser);
    } catch (error) {
      console.error(error);
    }
  }, [auth]);

  const setUser = useCallback(async authUser => {
    if (!authUser) {
      signOut();
    }
    getStripeInfo(authUser);
    setState(state => ({
      ...state,
      user: authUser,
      loading: !state?.subscription
    }));
  }, []);

  const socialSignIn = async (
    providerId,
    { onRegister, to = from || '/' } = {}
  ) => {
    let data = {};
    const provider = getProvider(providerId);
    providerId = provider?.providerId;

    try {
      if (state?.user?.uid) {
        data = await linkWithPopup(auth.currentUser, provider);
      } else {
        data = await signInWithPopup(auth, provider);
      }

      const { user } = data;
      try {
        Cookies.set('__fbu', JSON.stringify(user), { expires: 14 });
      } catch (error) {
        console.error(error);
      }
      onRegister ? await onRegister(user) : navigate(`${to}${search}`);
      setUser(user);
      return true;
    } catch (error) {
      // console.error(error);

      if (error.code === 'auth/credential-already-in-use') {
        navigate(`${to}${search}`);
      }
      if (
        error.customData?._tokenResponse &&
        error.code === 'auth/credential-already-in-use'
      ) {
        try {
          await unlink(auth.currentUser, providerId);
        } catch (error) {
          console.error(error);
        }
        if (error?.customData?.email !== auth?.currentUser?.email) {
          const data = await linkWithRedirect(auth, provider);
          const { user } = data;
          try {
            Cookies.set('__fbu', JSON.stringify(user), { expires: 14 });
          } catch (error) {
            console.error(error);
          }
          setUser(user);
          navigate(`${to}${search}`);
        }
      }
      if (
        error.customData?._tokenResponse &&
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        const { email } = error.customData;
        if (state?.user?.uid) {
          provider.setCustomParameters({ login_hint: email });
          const result = await linkWithRedirect(auth.currentUser, provider);
          return result;
        }
        const data = await linkWithRedirect(auth, provider);
        const { user } = data;
        try {
          Cookies.set('__fbu', JSON.stringify(user), { expires: 14 });
        } catch (error) {
          console.error(error);
        }
        setUser(user);
        navigate(`${to}${search}`);
      }
    }
  };

  const createSearch = debounce(
    async searchValue => {
      if (!searchValue) {
        return;
      }
      const createdAt = new Date().toISOString();
      await addDoc(collection(db, 'search'), {
        searchValue,
        userId: user?.uid,
        createdAt
      });
      logEvent(analytics, 'asset_search', {
        searched_text: searchValue,
        user_id: me?.uid,
        user_email: me?.email,
        user_name: me?.displayName
      });
      // window?.gtag?.('event', 'asset_search', {
      //   searched_text: searchValue,
      //   user_id: me?.uid,
      //   user_email: me?.email,
      //   user_name: me?.displayName
      // });
    },
    10000,
    true
  );

  const createView = debounce(
    async assetId => {
      const now = new Date().toISOString();

      if (!assetId) {
        return;
      }

      await addDoc(collection(db, 'views'), {
        assetId,
        userId: user?.uid,
        createdAt: now
      });
    },
    1000,
    false
  );

  const linkSocialSignIn = async providerId => {
    try {
      const provider = getProvider(providerId);
      await linkWithPopup(auth.currentUser, provider);
    } catch (error) {
      console.error(error);
    }
  };

  const unlinkSocialSignIn = async providerId => {
    try {
      await unlink(auth.currentUser, providerId);
    } catch (error) {
      console.error(error);
    }
  };

  const register = useCallback(
    async (email, password, { name, phone, onRegister, to = from } = {}) => {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const { user } = userCredential;
        if (name) {
          await updateProfile(auth.currentUser, {
            displayName: name
          });
          user.displayName = name;
        }
        try {
          Cookies.set('__fbu', JSON.stringify(user));
        } catch (error) {
          console.error(error);
        }
        toast.success(`Successfully registered as ${name}`, {
          theme: 'colored'
        });
        onRegister
          ? await onRegister(user, { phone })
          : navigate(`${to}${search}`);

        if (!user.emailVerified) {
          sendFirebaseEmailVerification(auth.currentUser);
        }
        setUser(user);
        return true;
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          toast.error(`Email en uso`, {
            theme: 'colored'
          });
        }
      }
    },
    []
  );

  const signIn = useCallback(
    async (email, password, { to = from, ...options } = {}) => {
      setState({ loading: true });
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const { user } = userCredential;
        try {
          Cookies.set('__fbu', JSON.stringify(user), options);
        } catch (error) {
          console.error(error);
        }
        navigate(`${to}${search}`);
      } catch (error) {
        if (
          [
            'auth/invalid-email',
            'auth/wrong-password',
            'auth/user-not-found'
          ].includes(error.code)
        ) {
          toast.error(`Email y/o contraseña incorrectos`, {
            theme: 'colored'
          });
        }
        console.error(error);
      }
    },
    []
  );

  const signInWithEmail = useCallback(
    async (email, password, { to = from, ...options } = {}) => {
      setState({ loading: true });
      try {
        const userCredential = await signInWithEmailLink(
          auth,
          email,
          window.location.href
        );
        const { user } = userCredential;
        await updatePassword(user, password);
        try {
          Cookies.set('__fbu', JSON.stringify(user), options);
        } catch (error) {
          console.error(error);
        }
        navigate(`${to}${search}`);
      } catch (error) {
        console.error(error);
        if (error.code === 'auth/invalid-action-code') {
          navigate(`/authentication/login?email=${email}`);
        }
      }
    },
    []
  );

  const signOut = useCallback(async () => {
    await firebaseSignOut(auth);
    Cookies.remove('__fbu');
    isSignedOut && navigate('/authentication/login');
    setState({ isSignedOut: true });
  }, []);

  async function toggleFavorite(asset) {
    try {
      const { id: assetId } = asset;
      const { uid: userId } = user;

      const key = `${userId}-${assetId}`;
      const isFavourite = favourites.some(
        ({ NO_ID_FIELD }) => NO_ID_FIELD === key
      );

      !isFavourite
        ? await setDoc(doc(db, 'favourites', key), {
            assetId,
            userId
          })
        : await deleteDoc(doc(db, 'favourites', key));
    } catch (error) {
      console.error(error);
    }
  }

  const getBillingPortalUrl = async () => {
    try {
      const { error, url: billingPortalUrl } = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/getBillingPortalUrl`,
          {
            headers: {
              authorization: `Bearer ${me.accessToken}`
            }
          }
        )
      ).json();
      if (error) {
        throw Error(error);
      }
      setState(state => ({ ...state, loading: false, billingPortalUrl }));
    } catch (error) {
      setState(state => ({ ...state, loading: false, billingPortalUrl: null }));
    }
  };

  const getPrices = useCallback(async () => {
    try {
      const expand = ['data.product'];
      const { data: prices, error } = await (
        await fetch(
          `${
            process.env.REACT_APP_FIREBASE_URL
          }/getPrices?type=recurring&expand=${JSON.stringify(expand)}`,
          {
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${me.accessToken}`
            }
          }
        )
      ).json();
      if (error) {
        throw Error(error);
      }
      setState(state => ({ ...state, loading: false, prices }));
      return prices;
    } catch (error) {
      setState(state => ({ ...state, loading: false, prices: null }));
      return [];
    }
  }, []);

  const createInitSubscription = async (
    user = me,
    { phone = '', plan = 'pro', interval = 'month' } = {}
  ) => {
    try {
      const { subscription } = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/createInitSubscription`,
          {
            method: 'POST',
            body: JSON.stringify({ phone, plan, interval }),
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${user.accessToken}`
            }
          }
        )
      ).json();

      setState(state => ({
        ...state,
        loading: false,
        subscription
      }));
      return subscription;
    } catch (error) {
      console.error(error);
      setState(state => ({
        ...state,
        loading: false,
        subscription: null
      }));
    }
    return null;
  };

  const getStripeInfo = async (user = me) => {
    try {
      const { subscriptions, ...info } = await (
        await fetch(`${process.env.REACT_APP_FIREBASE_URL}/getStripeInfo`, {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${user.accessToken}`
          }
        })
      ).json();
      const subscription =
        subscriptions?.find(({ status }) =>
          ['active', 'trialing'].includes(status)
        ) || null;
      setState(state => ({
        ...state,
        ...info,
        loading: false,
        subscription
      }));
    } catch (error) {
      setState(state => ({
        ...state,
        customer: null,
        loading: false,
        invoices: null,
        paymentMethods: null,
        subscription: null
      }));
    }
  };

  const getAvailableSources = () => {
    const { marketing_features: features = [] } =
      state.subscription?.plan?.product || free?.product || {};
    return sources.filter(({ label }) =>
      [...features, { name: 'Inversor Pro' }]?.some(
        ({ name }) => name === label
      )
    );
  };

  const getAvailableViews = () => {
    const { plan = free } = state.subscription || {};
    const { product } = plan || {};
    const { metadata } = product || {};
    const { limit = -1 } = metadata || {};
    const monthViews = views.filter(
      ({ createdAt }) => createdAt > dayjs(new Date()).format('YYYY-MM')
    );
    const uniqViews = getUnique(monthViews, 'assetId');
    const availableViews =
      limit < 0 ? limit : limit - Object.values(uniqViews).length;
    return availableViews;
  };

  const uploadImage = async (file, path) => {
    const imageRef = ref(storage, path);
    await uploadBytes(imageRef, file);
  };

  const deleteAvatar = async () => {
    try {
      const avatarRef = ref(storage, `users/${user?.uid}/avatar.png`);
      await deleteObject(avatarRef);
    } catch (error) {
      // console.error(error);
    }
    await updateDoc(doc(db, 'users', user?.uid), { avatar: null });
  };

  const followUser = async ({ id: followingId }) => {
    const createdAt = new Date().toISOString();
    await addDoc(collection(db, 'followers'), {
      followerId: user?.uid,
      followingId,
      createdAt
    });
  };

  const unfollowUser = async ({ id: followingId }) => {
    const { docs } = await getDocs(
      collection(db, 'followers'),
      where('followerId', '==', user?.uid),
      where('followingId', '==', followingId)
    );
    await deleteDoc(doc(db, 'followers', docs[0]?.id));
  };

  const updateData = async (params = {}) => {
    const newData = {
      updatedAt: new Date().toISOString(),
      ...params
    };
    if (params.phoneNumber) {
      const recaptchaContainer = window.document.createElement('div');
      window.document.body.appendChild(recaptchaContainer);
      const recaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainer,
        { size: 'invisible' },
        auth
      );
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const id = await phoneAuthProvider.verifyPhoneNumber(
        params.phoneNumber,
        recaptchaVerifier
      );
      const code = window.prompt('Pon el código que te enviaron al teléfono');
      const credential = PhoneAuthProvider.credential(id, code);
      await updatePhoneNumber(auth.currentUser, credential);
      window.document.body.removeChild(recaptchaContainer);
    }

    if (params.avatar) {
      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920
      };
      const path = `users/${user?.uid}/avatar.png`;
      const compressedImage = await imageCompression(params.avatar, options);
      await uploadImage(compressedImage, path);
      newData.avatar = await getDownloadURL(ref(storage, path));
    }

    if (params.name) {
      await updateProfile(auth.currentUser, {
        displayName: params.name
      });
      user.displayName = params.name;
    }

    await updateDoc(doc(db, 'users', user?.uid), newData);
    return { ...data, ...newData };
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser);
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!me?.uid) {
      return;
    }
    const _hsq = (window._hsq = window._hsq || []);
    const { phone } = state?.customer || {};
    const { data, displayName: name, email, uid: id } = me || {};
    const {
      assetLocation,
      budget: budgetObj,
      type: inversorType = 'Por especificar'
    } = data || {};
    const { amount: budget } = budgetObj || {};
    const { geocode } = assetLocation || {};
    const { formatted_address: favouriteLocation } = geocode || {};
    const [firstname, lastname] = name?.split(' ') || [];
    const params = { email, id, firstname, lastname };
    budget && (params.budget = budget);
    favouriteLocation && (params.favouriteLocation = favouriteLocation);
    inversorType && (params.inversorType = inversorType);
    phone && (params.phone = phone);
    // console.log('identify >>>', params);
    _hsq.push(['identify', params]);
    _hsq.push(['trackPageView']);
  }, [me?.uid, state?.customer]);

  useEffect(() => {
    if (
      !user?.uid ||
      status !== 'success' ||
      (data?.id && typeof data?.avatar === 'string' && data?.name)
    ) {
      return;
    }
    getDoc(myRef).then(doc => {
      const avatar = data?.avatar || user?.photoURL || '';
      const dataToSave = { id: user?.uid, avatar, name: user?.displayName };
      if (!doc.exists()) {
        setDoc(myRef, dataToSave);
      } else {
        updateDoc(myRef, dataToSave);
      }
    });
  }, [myRef, data, status]);

  // console.log('USER >>>', me, state);

  return (
    <UserContext.Provider
      value={{
        ...state,
        me,
        createInitSubscription,
        createSearch,
        createView,
        deleteAvatar,
        hasActiveSubscription,
        goToCheckout,
        getAvailableSources,
        getAvailableViews,
        getBillingPortalUrl,
        getPrices,
        getStripeInfo,
        resetPassword,
        register,
        sendEmailVerification,
        signIn,
        signInWithEmail,
        signOut,
        socialSignIn,
        linkSocialSignIn,
        unlinkSocialSignIn,
        toggleFavorite,
        upgradeModalShow,
        setUpgradeModalShow,
        upgradeModalContent,
        setUpgradeModalContent,
        isWidgetsModalOpened,
        openWidgetsModal,
        updateData,
        views,
        viewsStatus,
        followUser,
        unfollowUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserProvider;

import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import Bottombar from './Bottombar';
import Asset from 'components/assets/Asset';
import AppContext from 'context/Context';
import useVisibilityObserver from 'hooks/useVisibilityObserver';

const CreateAssetFooter = ({ asset, onSubmit, saveDraft }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [isOpenPreviewModal, setOpenPreviewModal] = useState(false);
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '0px'
  );

  const handleClosePreview = () => {
    setOpenPreviewModal(false);
  };

  const handlePreviewButton = () => {
    setOpenPreviewModal(true);
  };

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <h5 className="mb-0">Publica tu inmueble</h5>
          <Button
            variant="link"
            size="sm"
            className="px-0 fw-medium text-secondary "
            onClick={handlePreviewButton}
          >
            <span className="d-lg-none d-xxl-inline-block">Previsualizar</span>
            <FontAwesomeIcon icon="external-link-alt" className="ms-2 fs--2" />
          </Button>
        </Card.Header>
        <Card.Footer ref={targetElRef} className="py-2">
          <Row className="flex-center g-3">
            <Col xs="auto">
              <Button
                variant="link"
                size="md"
                className="fw-medium text-secondary p-0"
                onClick={saveDraft}
              >
                {status === 'draft' ? 'Guardar borrador' : 'Guardar y archivar'}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                size="lg"
                variant="primary"
                className="px-xxl-5 px-4"
                type="submit"
                onClick={event => {
                  event.preventDefault();
                  onSubmit(asset);
                }}
              >
                Publicar
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Bottombar inViewport={inViewport} saveDraft={saveDraft} />
      <Modal centered show={isOpenPreviewModal} onHide={handleClosePreview}>
        <Modal.Header closeButton closeVariant={isDark ? 'white' : undefined}>
          <Modal.Title as="h5">Previsualización del anuncio</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-6">
          <Asset asset={asset} at="preview" />
        </Modal.Body>
      </Modal>
    </>
  );
};

CreateAssetFooter.propTypes = {
  asset: PropTypes.object,
  onSubmit: PropTypes.func,
  saveDraft: PropTypes.func
};

export default CreateAssetFooter;

import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import CalculatorWrapper from 'components/assets/rentability-calculator/CalculatorWrapper';

const RentabilityCalculator = () => {
  return (
    <>
      <Helmet>
        <title>Calculadora de rentabilidad | Inversor Pro</title>
        <meta
          name="title"
          content="Calculadora de rentabilidad | Inversor Pro"
        />
      </Helmet>
      <Container>
        <h2 className="mt-4 mb-n3">Calculadora de rentabilidad</h2>
        <CalculatorWrapper />
      </Container>
    </>
  );
};

export default RentabilityCalculator;

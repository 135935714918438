import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { orderBy } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Asset from 'components/assets/Asset';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import useFetchAssetsByUser from 'hooks/useFetchAssetsByUser';

const UserAssets = ({ assetsCount, profile }) => {
  const { id, name } = profile || {};
  const [sortBy, setSortBy] = useState('price');
  const [isAsc, setIsAsc] = useState(true);
  const [itemsPerPage] = useState(9);
  const [currentPage, setPage] = useState(0);
  const { data: dataAssets } = useFetchAssetsByUser(id, {
    itemsPerPage,
    order: sortBy && orderBy(sortBy, isAsc ? 'asc' : 'desc'),
    page: currentPage
  });
  const paginatedAssets = dataAssets?.docs?.map(doc => doc.data()) || [];
  const paginationArray = [
    ...new Array(parseInt(assetsCount / itemsPerPage) + 1)
  ].map((none, index) => index);
  const canPreviousPage = currentPage > 0;
  const canNextPage = currentPage < parseInt(assetsCount / itemsPerPage);

  const prevPage = () => {
    setPage(page => Math.max(page - 1, 0));
  };
  const nextPage = () => {
    setPage(page => Math.min(page + 1, parseInt(assetsCount / itemsPerPage)));
  };
  const goToPage = page => {
    setPage(page);
  };

  return (
    <Card>
      <Card.Header as={Flex} className="flex-between-center bg-light py-2">
        <h6 className="mb-0">
          Inmuebles de {name} <SoftBadge>{assetsCount}</SoftBadge>
        </h6>
        <div>
          <InputGroup size="sm">
            <Form.Select
              className="pe-5"
              defaultValue="price"
              onChange={({ target }) => setSortBy(target.value)}
            >
              <option value="price">Precio</option>
              <option value="rating">Rating</option>
            </Form.Select>
            <InputGroup.Text
              as={Button}
              variant="link"
              className="border border-300 text-700"
              onClick={() => setIsAsc(!isAsc)}
            >
              <FontAwesomeIcon
                icon={isAsc ? 'sort-amount-down-alt' : 'sort-amount-down'}
              />
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Card.Header>
      <Card.Body className="pb-0">
        <Row className="g-3">
          {paginatedAssets.map(asset => (
            <Col md={6} xxl={4} key={`ProfileAsset-${asset.id}`}>
              <Asset asset={asset} />
            </Col>
          ))}
        </Row>
      </Card.Body>
      <Card.Footer as={Flex} justifyContent="end" className="bg-light py-2">
        <div>
          <Tooltip title="Página previa">
            <Button
              variant="falcon-default"
              size="sm"
              disabled={!canPreviousPage}
              onClick={prevPage}
              className="me-2"
              trigger="focus"
            >
              <FontAwesomeIcon icon="chevron-left" />
            </Button>
          </Tooltip>
        </div>

        <ul className="pagination mb-0">
          {paginationArray.map(page => (
            <li
              key={`User-Assets-Page-${page}`}
              className={classNames({ active: currentPage === page })}
            >
              <Button
                size="sm"
                variant="falcon-default"
                className="page me-2"
                onClick={() => goToPage(page)}
              >
                {page + 1}
              </Button>
            </li>
          ))}
        </ul>
        <div>
          <Tooltip title="Página siguiente">
            <Button
              variant="falcon-default"
              size="sm"
              disabled={!canNextPage}
              onClick={nextPage}
              trigger="focus"
            >
              <FontAwesomeIcon icon="chevron-right" />
            </Button>
          </Tooltip>
        </div>
      </Card.Footer>
    </Card>
  );
};

UserAssets.propTypes = {
  assetsCount: PropTypes.number,
  profile: PropTypes.object
};

export default UserAssets;

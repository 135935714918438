import React, { useContext } from 'react';
import classNames from 'classnames';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { CalculatorContext } from 'context/Context';

const TopNav = () => {
  const { pathname, search } = useLocation();
  const { assetId, menu = 'rent', source } = useParams();
  const { asset } = useContext(CalculatorContext);
  const [, from] = search.match(/from=(.*)/) || [];
  const { id } = asset || {};
  let backHref = from
    ? `/${from}#${id}`
    : `/${pathname?.split('/')?.[1] || ''}#${id}`;
  const rentHref = id
    ? `/assets/rentability-calculator/rent/${source}/${assetId}${search}`
    : '/rentability-calculator/rent';
  const flippingHouseHref = id
    ? `/assets/rentability-calculator/flipping-house/${source}/${assetId}${search}`
    : '/rentability-calculator/flipping-house';

  return (
    <>
      {id && (
        <Flex alignItems="center" className="gap-2 mb-3">
          <IconButton
            variant="falcon-default"
            icon="arrow-left"
            size="sm"
            to={backHref}
            as={Link}
          >
            Volver
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="calculator"
            as={Link}
            to={`/assets/asset-details/${source}/${assetId}${search}`}
          >
            Información
          </IconButton>
        </Flex>
      )}
      <div
        className={classNames('bg-white dark__bg-1100 border-bottom pt-3', {
          'sticky-sidebar': id,
          'sticky-top': !id
        })}
      >
        <SimpleBarReact className="asset-top-nav z-index-0">
          <Nav className="flex-row flex-nowrap" variant="underline">
            <Nav.Link
              className="pb-3 text-nowrap"
              to={rentHref}
              active={menu === 'rent'}
              as={Link}
            >
              <span
                className={classNames('fs--1 fw-semi-bold text-500', {
                  'text-black': menu === 'rent'
                })}
              >
                Alquiler
              </span>
            </Nav.Link>
            <Nav.Link
              className="pb-3 text-nowrap"
              to={flippingHouseHref}
              active={menu === 'flipping-house'}
              as={Link}
            >
              <span
                className={classNames('fs--1 fw-semi-bold text-500', {
                  'text-black': menu === 'flipping-house'
                })}
              >
                Flipping house
              </span>
            </Nav.Link>
          </Nav>
        </SimpleBarReact>
      </div>
    </>
  );
};

export default TopNav;

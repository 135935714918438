import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

const AssetProsCons = ({ asset }) => {
  const { ai } = asset || {};
  const { cons, pros } = ai || {};

  return (
    <div className="mt-3">
      <Row className="g-3">
        <Col>
          <div className="h-100 overflow-hidden">
            <FalconCardHeader title="Puntos a favor" />
            <div className="pt-3">
              {!pros?.length && (
                <div>
                  <SoftBadge bg="secondary">
                    <Flex alignItems="center" className="gap-2">
                      <span>Sin especificar</span>
                    </Flex>
                  </SoftBadge>
                </div>
              )}
              {pros?.map?.(pro => (
                <div key={pro} className="mb-1 w-max-content max-w-100">
                  <SoftBadge bg="success" className="d-block text-start">
                    <Flex alignItems="center" className="gap-2">
                      <FontAwesomeIcon icon="thumbs-up" />
                      <span className="text-wrap">{pro}</span>
                    </Flex>
                  </SoftBadge>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col>
          <div className="h-100 overflow-hidden text-end">
            <FalconCardHeader title="Puntos en contra" />
            <div className="pt-3">
              {!cons?.length && (
                <div>
                  <SoftBadge bg="secondary">
                    <Flex alignItems="center" className="gap-2">
                      <span>Sin especificar</span>
                    </Flex>
                  </SoftBadge>
                </div>
              )}
              {cons?.map?.(cons => (
                <div
                  key={cons}
                  className="mb-1 ms-auto w-max-content max-w-100"
                >
                  <SoftBadge bg="danger" className="d-block text-start">
                    <Flex alignItems="center" className="gap-2">
                      <FontAwesomeIcon icon="thumbs-down" />
                      <span className="text-wrap">{cons}</span>
                    </Flex>
                  </SoftBadge>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

AssetProsCons.propTypes = {
  asset: PropTypes.object
};

export default AssetProsCons;

import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Container, Collapse, Row } from 'react-bootstrap';
import Countdown from 'components/common/Countdown';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';

const TrialBanner = () => {
  const [open, setOpen] = useState(false);
  const {
    billingPortalUrl,
    getBillingPortalUrl,
    getStripeInfo,
    me,
    subscription
  } = useContext(UserContext);
  const { pathname } = useLocation();
  const { status, trial_end: trialEnd } = subscription || {};
  const endDate = trialEnd * 1000;

  useEffect(() => {
    if (!me?.uid || billingPortalUrl) {
      return;
    }
    getBillingPortalUrl();
    getStripeInfo();
  }, []);

  useEffect(() => {
    setOpen(status === 'trialing');
  }, [status]);

  return (
    <Collapse in={open}>
      <Alert
        show={open}
        className="bg-line-chart-gradient border-0 card rounded-0 text-white m-0 px-0 shadow-none"
        dismissible
        closeVariant="white"
        onClose={() => setOpen(false)}
      >
        <Container
          className={classNames({
            'container-fluid': pathname.match(/\/assets/)
          })}
        >
          <Row>
            <Col
              xs={12}
              lg={true}
              className="justify-content-center justify-content-lg-start"
              as={Flex}
            >
              <FontAwesomeIcon
                className="me-3 border border-3 border-white rounded-5 p-2 w-32px h-32px"
                icon="crown"
                size="xl"
              />
              <div>
                <Alert.Heading className="m-0">
                  Tu prueba terminará en{' '}
                  {dayjs(endDate).diff(new Date(), 'days') + 1} días
                </Alert.Heading>
                <p className="mb-0">
                  Cuando tu período de prueba gratuita finalice, este plan ya no
                  continuará hasta que se añada un método de pago.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              lg="auto"
              className="mt-3 mt-lg-0"
              alignItems="center"
              justifyContent="center"
              as={Flex}
            >
              <Countdown
                className="mx-3"
                date={new Date(endDate)}
                itemClassName="fs--1 text-white"
              />
              <Button
                variant="falcon-default"
                className="mx-lg-3 rounded-pill text-nowrap"
                as={Link}
                to={billingPortalUrl}
                target="_blank"
              >
                Activa tu plan
              </Button>
            </Col>
          </Row>
        </Container>
      </Alert>
    </Collapse>
  );
};
export default TrialBanner;

import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { CalculatorContext } from 'context/Context';
import { getCurrencyFormat } from 'helpers/utils';

const RentBenefit = () => {
  const { recurringExpenses = 0, totalRentIncomes: totalIncomes = 0 } =
    useContext(CalculatorContext);
  const amount = totalIncomes - recurringExpenses;

  return (
    <Card className="border overflow-hidden mt-3">
      <Card.Header className="pb-0">
        <Card.Title>
          <small>Beneficio anual</small>
        </Card.Title>
      </Card.Header>
      <Card.Body className="pt-2">
        <Flex alignItems="center" justifyContent="between" className="gap-4">
          <div />
          <Card.Title className="m-0">
            {!isNaN(amount)
              ? getCurrencyFormat(amount || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })
              : '-'}
          </Card.Title>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default RentBenefit;

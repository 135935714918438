import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import AssetGallery from './AssetGallery';
import AssetBanner from './AssetBanner';
import AssetEconomic from './AssetEconomic';
import AssetReform from './AssetReform';
import AssetReformEconomic from './AssetReformEconomic';
import AssetSummary from './AssetSummary';
import AssetLocation from './AssetLocation';
import AssetProsCons from './AssetProsCons';
import AssetImages from './AssetImages';
import AssetNearbyServices from './AssetNearbyServices';

const AssetDetail = ({ asset }) => {
  return (
    <>
      <AssetGallery asset={asset} />
      <AssetBanner asset={asset} />
      <Alert variant="400" className="mt-4">
        <Alert.Heading>
          <FontAwesomeIcon icon="exclamation-triangle" className="me-2" />
          Ten en cuenta que...
        </Alert.Heading>
        <p>
          Estos datos son orientativos y están basados en los datos extraidos de
          los portales inmobiliarios y generados con IA.
        </p>
      </Alert>
      <hr className="border-0 m-0 break-after" />
      <AssetSummary asset={asset} />
      <hr className="border-0 m-0 break-after" />
      <AssetProsCons asset={asset} />
      <hr className="my-5" />
      <AssetNearbyServices asset={asset} />
      <hr className="my-5" />
      <AssetLocation asset={asset} />
      <hr className="border-0 m-0 break-after" />
      <AssetEconomic asset={asset} />
      <hr className="border-0 m-0 break-after" />
      <AssetReform asset={asset} />
      {!!asset?.reform?.amount && (
        <>
          <hr className="my-5" />
          <AssetReformEconomic asset={asset} />
        </>
      )}
      <hr className="border-0 m-0 break-after " />
      <AssetImages asset={asset} />
      <hr className="border-0 my-5" />
    </>
  );
};

AssetDetail.propTypes = {
  asset: PropTypes.object
};

export default AssetDetail;

import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import {
  collection,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';

function useFetchFollowersCount(userId) {
  const db = useFirestore();
  const [count, setCount] = useState(0);

  const handleChange = async snapshot => {
    setTimeout(async () => {
      const result = await getCountFromServer(snapshot.query);
      const { count } = result.data();
      setCount(count);
    }, 100);
  };

  useEffect(() => {
    let followersQuery = query(collection(db, 'none'));
    if (userId) {
      followersQuery = query(
        collection(db, 'followers'),
        where('followingId', '==', userId),
        orderBy('createdAt', 'desc'),
        limit(1)
      );
    }
    const unsubscribe = onSnapshot(followersQuery, handleChange);
    return () => unsubscribe();
  }, [userId]);

  return count;
}

export default useFetchFollowersCount;

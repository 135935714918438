import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';

const AssetPricing = ({
  asset,
  register
  // isOpenScheduleModal,
  // setIsOpenScheduleModal
}) => {
  const { discountedPrice: discountedPriceProp, price: priceProp } =
    asset || {};
  const discountedPrice = isNaN(discountedPriceProp) ? '' : discountedPriceProp;
  const price = isNaN(priceProp) ? '' : priceProp;
  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">Precio</Card.Header>
        <Card.Body className="bg-light">
          <Row className="g-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  Precio base
                  <Tooltip title="Precio del inmueble">
                    <span className="ms-2 text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </Tooltip>
                </Form.Label>
                <Flex alignItems="center">
                  <Form.Control
                    type="text"
                    name="price"
                    className="pe-5 me-n4"
                    required
                    {...register('price', {
                      required: true,
                      valueAsNumber: true
                    })}
                    value={price}
                  />
                  <span className="fw-semi-bold text-400">€</span>
                </Flex>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  Precio rebajado <small>(opcional)</small>
                </Form.Label>
                <Flex alignItems="center">
                  <Form.Control
                    type="text"
                    name="discountedPrice"
                    className="pe-5 me-n4"
                    {...register('discountedPrice', { valueAsNumber: true })}
                    value={discountedPrice}
                  />
                  <span className="fw-semi-bold text-400">€</span>
                </Flex>
              </Form.Group>
            </Col>
          </Row>
          {/* <div className="text-end">
            <Button
              variant="link"
              size="sm"
              className="fw-medium fs--1"
              onClick={() => {
                setIsOpenScheduleModal(!isOpenScheduleModal);
              }}
            >
              Schedule Discount
            </Button>
          </div> */}
        </Card.Body>
      </Card>
    </>
  );
};

AssetPricing.propTypes = {
  asset: PropTypes.object,
  register: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool,
  setIsOpenScheduleModal: PropTypes.func
};

export default AssetPricing;

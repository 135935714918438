import dayjs from 'dayjs';
import { getCurrencyFormat } from 'helpers/utils';

export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

export const tooltipCurrencyFormatter = (params, options = {}) => {
  const { avg: showAvg = true } = options;
  const values = params.map(el => el.value);
  const avg = values.reduce((sum, value) => sum + value, 0) / values.length;
  let tooltipItem = ``;
  if (Array.isArray(params)) {
    params?.forEach(el => {
      tooltipItem =
        tooltipItem +
        `<div class='mt-3'>
            <h6><div class="dot me-1 fs--2 d-inline-block" style="background-color:${
              el.borderColor ? el.borderColor : el.color
            }"></div>${el.seriesName}:</h6>
            <div class="ps-3">
              <span class="fs-1 text-black">${getCurrencyFormat(el.value, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}</span>
              ${
                showAvg
                  ? `<span class="badge badge-soft-${
                      el.value < avg ? 'danger' : 'success'
                    } fs--2 ms-2">
                ${el.value - avg > 0 ? '+' : '-'}${getCurrencyFormat(
                      Math.abs(el.value - avg),
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }
                    )}
              </span>`
                  : ''
              }</div>
            </div>`;
    });
  }
  return `<div>
            <h6 class='mb-2 text-500 text-capitalize'>${params[0].axisValue}</h6>
            ${tooltipItem}
          </div>`;
};

export const tooltipFormatter = params => {
  let tooltipItem = ``;
  if (Array.isArray(params)) {
    params?.forEach(el => {
      tooltipItem =
        tooltipItem +
        `<div class='ms-1'> 
      <h6 class="text-700">
      <div class="dot me-1 fs--2 d-inline-block" style="background-color:${
        el.borderColor ? el.borderColor : el.color
      }"></div>
      ${el.seriesName} : ${
          typeof el.value === 'object' ? el.value[1] : el.value
        }
      </h6>
      </div>`;
    });
  }
  return `<div>
            <h5 class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </h5>
            ${tooltipItem}
          </div>`;
};

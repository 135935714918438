import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { free } from 'components/UserProvider';
import Loader from 'components/common/Loader';
import PricingDefaultCard from './PricingDefaultCard';
import { UserContext } from 'context/Context';

const PlansTable = ({ interval }) => {
  const { getPrices, me, prices, subscription } = useContext(UserContext);
  const { plan = subscription } = subscription || {};
  const currentPrice = plan !== null ? plan : me?.uid && free;
  useEffect(() => {
    if (prices?.length) {
      return;
    }
    getPrices();
  }, [me?.uid]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="g-0 justify-content-center">
          {!prices ? (
            <Col xs={12} className="p-9">
              <Loader />
            </Col>
          ) : (
            <>
              {prices
                ?.filter(({ recurring }) => recurring.interval === interval)
                .sort((p1, p2) => (p1.unit_amount < p2.unit_amount ? -1 : 1))
                .map((price, index) => (
                  <PricingDefaultCard
                    key={`Price-$${price.id}`}
                    currentPrice={currentPrice}
                    price={price}
                    index={index}
                  />
                ))}
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

PlansTable.propTypes = {
  interval: PropTypes.string
};

export default PlansTable;

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Overlay, Tooltip as BSTooltip } from 'react-bootstrap';

const Tooltip = ({
  as: Component = 'div',
  children,
  className,
  placement = 'top',
  title,
  tooltipClass,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const target = useRef();
  const baseProps = {};

  if (Component && typeof Component === 'function') {
    baseProps.innerRef = target;
  } else {
    baseProps.ref = target;
  }

  return (
    <>
      <Component
        className={classNames('d-inline-block', className)}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...baseProps}
      >
        {children}
      </Component>
      <Overlay
        container={() => document.body}
        placement={placement}
        show={show && !!title}
        target={target.current}
        {...props}
      >
        <BSTooltip
          className={classNames('position-absolute', tooltipClass)}
          placement="auto"
        >
          {title}
        </BSTooltip>
      </Overlay>
    </>
  );
};

Tooltip.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.node,
  tooltipClass: PropTypes.string
};

export default Tooltip;

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import IconGroup from 'components/common/icon/IconGroup';
import Logo from 'components/common/Logo';
import createMarkup from 'helpers/createMarkup';
import { bgWhiteIcons } from 'data/socialIcons';

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ className, list }) => (
  <ul className={classNames('list-unstyled', className)}>
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="text-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired
};

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-0 mb-0">
          <Link className="text-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  return (
    <>
      <Section className="bg-dark py-4">
        <Row className="gx-10">
          <Col lg={6}>
            <Logo className="justify-content-start" isDark={true} />
            <IconGroup className="mt-4" icons={bgWhiteIcons} />
          </Col>
          <Col lg={6}>
            <FooterList
              className="d-flex justify-content-end gap-4 fw-semi-bold"
              list={[
                { title: 'Política de privacidad', to: '/privacy-policy' }
              ]}
            />
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default FooterStandard;

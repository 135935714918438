import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import ReportLayout from './ReportLayout';
import LandingLayout from './LandingLayout';
import UnregisteredLandingLayout from './UnregisteredLandingLayout';
import LegalLayout from './LegalLayout';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import AssetNotFound from 'components/errors/AssetNotFound';

import PrivacyPolicy from 'components/legal/PrivacyPolicy';
import Terms from 'components/legal/Terms';

import AppContext from 'context/Context';

import Account from 'pages/account';
import Assets from 'pages/assets/Assets';
import AssetDetails from 'pages/assets/asset-details';
import AssetReport from 'pages/assets/asset-report';
import CreateAsset from 'pages/assets/create-an-asset';
import Profile from 'pages/profile';
import AssetRentabilityCalculator from 'pages/assets/rentability-calculator';
import Favourites from 'pages/favourites';
import Pricing from 'pages/pricing';
import Subscription from 'pages/subscription';
import Learning from 'pages/learning';
import RentabilityCalculator from 'pages/rentability-calculator';

import Search from 'components/landing/Search';
import Login from 'components/authentication/split/Login';
import LoginWithLink from 'components/authentication/split/LoginWithLink';
import Logout from 'components/authentication/split/Logout';
import Registration from 'components/authentication/split/Registration';
import RegisterAndTrial from 'components/authentication/RegisterAndTrial';
import ThanksForRegister from 'pages/thanks';
import ForgetPassword from 'components/authentication/split/ForgetPassword';
import PasswordReset from 'components/authentication/split/PasswordReset';
import ConfirmMail from 'components/authentication/split/ConfirmMail';
import LockScreen from 'components/authentication/split/LockScreen';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (window.self !== window.top) {
      HTMLClassList.add('iframe');
    }
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
          <Route path="errors/not-found" element={<AssetNotFound />} />
        </Route>

        <Route element={<LegalLayout />}>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
        </Route>

        {/*- ------------- Authentication ---------------------------  */}

        <Route path="authentication/login" element={<Login />} />
        <Route path="authentication/register" element={<Registration />} />
        <Route path="/register-and-trial" element={<RegisterAndTrial />} />
        <Route path="authentication/logout" element={<Logout />} />
        <Route
          path="authentication/welcome/:code"
          element={<LoginWithLink />}
        />
        <Route
          path="authentication/forgot-password"
          element={<ForgetPassword />}
        />
        <Route
          path="authentication/reset-password"
          element={<PasswordReset />}
        />
        <Route path="authentication/confirm-mail" element={<ConfirmMail />} />
        <Route path="authentication/lock-screen" element={<LockScreen />} />

        {/*- ------------- Assets ---------------------------  */}

        <Route element={<MainLayout />}>
          <Route path="/thanks" element={<ThanksForRegister />} />

          <Route path="account" element={<Account />} />

          <Route path="profile" element={<Account />} />
          <Route path="profile/:userId" element={<Profile />} />

          <Route path="assets" element={<Assets />} />

          <Route path="favourites" element={<Favourites />} />

          <Route path="a/:source/:assetId" element={<AssetDetails />} />
          <Route
            path="assets/asset-details/:source/:assetId"
            element={<AssetDetails />}
          />

          <Route path="assets/asset-create" element={<CreateAsset />} />
          <Route
            path="assets/asset-create/:source/:assetId"
            element={<CreateAsset />}
          />

          <Route path="account" element={<Account />} />
          <Route
            path="assets/rentability-calculator/:menu/:source/:assetId"
            element={<AssetRentabilityCalculator />}
          />
          <Route
            path="assets/rentability-calculator/:source/:assetId"
            element={<AssetRentabilityCalculator />}
          />
          <Route path="subscription" element={<Subscription />} />
        </Route>

        <Route element={<ReportLayout />}>
          <Route path="r/:source/:assetId" element={<AssetReport />} />
        </Route>

        {/*- ------------- Landings ---------------------------  */}

        <Route element={<LandingLayout />}>
          <Route path="/" element={<Navigate to="/assets" replace />} />
          <Route path="pricing" element={<Pricing />} />
        </Route>

        <Route element={<UnregisteredLandingLayout />}>
          <Route path="learning" element={<Learning />} />
          <Route
            path="rentability-calculator"
            element={<RentabilityCalculator />}
          />
          <Route
            path="rentability-calculator/:menu"
            element={<RentabilityCalculator />}
          />
        </Route>

        <Route path="search" element={<Search />} />

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_RIGHT}
      />
    </>
  );
};

export default Layout;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PageHeader from 'components/account/PageHeader';
import Budget from 'components/account/Budget';
import UserInfo from 'components/account/UserInfo';
import InvestorType from 'components/account/InvestorType';
import AssetLocationPreference from 'components/account/AssetLocationPreference';
import AssetPreferences from 'components/account/AssetPreferences';
import BrowsedAssets from 'components/account/BrowsedAssets';
import PaymentMethods from 'components/account/PaymentMethods';
import PaymentHistory from 'components/account/PaymentHistory';
import FavouritesColumns from 'components/account/FavouritesColumns';
import RecentActivities from 'components/account/RecentActivities';
import MyAssets from 'components/account/MyAssets';
import FavouritesProvider from 'pages/favourites/FavouritesProvider';

const Account = () => {
  return (
    <>
      <PageHeader />

      <Row className="g-3 mb-3">
        <Col xxl={8}>
          <Row className="g-3">
            <Col xs={12}>
              <UserInfo />
            </Col>
            <Col md={6}>
              <Budget />
            </Col>
            <Col md={6}>
              <InvestorType />
            </Col>
            <Col md={6}>
              <AssetPreferences />
            </Col>
            <Col md={6}>
              <AssetLocationPreference />
            </Col>
          </Row>
        </Col>
        <Col xxl={4}>
          <RecentActivities />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col md={6} lg={8} xxl={8}>
          <BrowsedAssets />
        </Col>
        <Col md={6} lg={4} xxl={4}>
          <FavouritesProvider>
            <FavouritesColumns />
          </FavouritesProvider>
        </Col>
      </Row>

      <MyAssets />

      <Row className="g-3 mb-3">
        <Col md={12} lg={4} xxl={3}>
          <PaymentMethods />
        </Col>
        <Col md={12} lg={8} xxl={9}>
          <PaymentHistory />
        </Col>
      </Row>
    </>
  );
};

export default Account;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import {
  CapitalGain,
  FlippingHouse,
  LongTermRentability,
  RoomRentability,
  VacationRentability,
  Rate
} from 'components/assets/Asset';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { getCurrencyFormat } from 'helpers/utils';

const AssetSummary = ({ asset }) => {
  const {
    price: purchaisePrice,
    profit,
    link,
    price,
    reform,
    rooms,
    size,
    source
  } = asset || {};
  const { amount: reformPrice, profit: reformProfit } = reform || {};
  const { longTerm } = profit || {};
  const { longTerm: reformLongTerm } = reformProfit || {};
  let info = { longTerm: 0 };
  let sum = { longTerm: 0 };
  const cost = purchaisePrice;
  const reformCost = purchaisePrice + reformPrice;
  let amortYears = 1;
  let reformAmortYears = 1;

  if (longTerm) {
    while (!info.longTerm) {
      sum.longTerm =
        amortYears * (longTerm + longTerm * ((amortYears - 1) * 0.03));
      if (!info.longTerm && sum.longTerm > cost) {
        info.longTerm = amortYears;
      }
      amortYears++;
    }
  }
  info = { longTerm: 0 };
  sum = { longTerm: 0 };
  if (reformLongTerm) {
    while (!info.longTerm) {
      sum.longTerm =
        reformAmortYears *
        (reformLongTerm + reformLongTerm * ((reformAmortYears - 1) * 0.03));
      if (!info.longTerm && sum.longTerm > reformCost) {
        info.longTerm = reformAmortYears;
      }
      reformAmortYears++;
    }
  }

  return (
    <div>
      <FalconCardHeader className="mb-3" title="Resumen" titleTag="h5" />

      <Rate
        {...asset}
        className="avoid-break-inside w-150px h-150px mx-auto"
        circleClass="w-100 h-100"
        showText={true}
      />

      <Row className="avoid-break-inside rounded bg-light g-3 mt-3 pb-3">
        <Col>
          <div className="badge badge-transparent w-100 h-100">
            <Flex direction="column" justifyContent="between" className="h-100">
              <h1>
                <FontAwesomeIcon icon="money-bill" />
              </h1>
              <h6 className="text-wrap">Ingresos anuales aprox.</h6>
              <h5>
                {longTerm
                  ? getCurrencyFormat(longTerm, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                  : '-'}
              </h5>
            </Flex>
          </div>
        </Col>
        <Col>
          <div className="badge badge-transparent w-100 h-100">
            <Flex direction="column" justifyContent="between" className="h-100">
              <h1>
                <FontAwesomeIcon icon="hourglass-end" />
              </h1>
              <h6 className="text-wrap">Tiempo de amortización aprox.</h6>
              <h5>{longTerm ? `${amortYears} años` : '-'}</h5>
            </Flex>
          </div>
        </Col>
        <Col>
          <div className="badge badge-transparent w-100 h-100">
            <Flex direction="column" justifyContent="between" className="h-100">
              <h1>
                <FontAwesomeIcon icon="person-digging" />
              </h1>
              <h6 className="text-wrap">Coste de reforma</h6>
              <h5>
                {reformPrice
                  ? getCurrencyFormat(reformPrice, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                  : '-'}
              </h5>
            </Flex>
          </div>
        </Col>
        {!!reformPrice && (
          <>
            <Col>
              <div className="badge badge-transparent w-100 h-100">
                <Flex
                  direction="column"
                  justifyContent="between"
                  className="h-100"
                >
                  <h1>
                    <FontAwesomeIcon icon="money-bill" />
                  </h1>
                  <h6 className="text-wrap">
                    Ingresos anuales aprox. tras reforma
                  </h6>
                  <h5>
                    {reformLongTerm
                      ? getCurrencyFormat(reformLongTerm, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      : '-'}
                  </h5>
                </Flex>
              </div>
            </Col>
            <Col>
              <div className="badge badge-transparent w-100 h-100">
                <Flex
                  direction="column"
                  justifyContent="between"
                  className="h-100"
                >
                  <h1>
                    <FontAwesomeIcon icon="hourglass-end" />
                  </h1>
                  <h6 className="text-wrap">Tiempo de amortización aprox.</h6>
                  <h5>{longTerm ? `${reformAmortYears} años` : '-'}</h5>
                </Flex>
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row className="avoid-break-inside mt-5">
        <Col xs={12}>
          <h6 className="fw-bold">Rentabilidad</h6>
          <Row className="g-2">
            <Col>
              <Flex
                className="w-100 h-100"
                alignItems="center"
                justifyContent="center"
              >
                <LongTermRentability
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
              </Flex>
            </Col>
            <Col>
              <Flex
                className="w-100 h-100"
                alignItems="center"
                justifyContent="center"
              >
                <RoomRentability
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
              </Flex>
            </Col>
            <Col>
              <Flex
                className="w-100 h-100"
                alignItems="center"
                justifyContent="center"
              >
                <VacationRentability
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
              </Flex>
            </Col>
          </Row>
        </Col>
        {!!asset?.reform?.amount && (
          <Col xs={12}>
            <hr className="border-top border-dashed" />
            <h6 className="fw-bold">Rentabilidad tras reforma</h6>
            <Row className="g-2 mt-2">
              <Col>
                <Flex
                  className="w-100 h-100"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LongTermRentability
                    {...asset}
                    rentability={asset?.reform?.rentability}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                </Flex>
              </Col>
              <Col>
                <Flex
                  className="w-100 h-100"
                  alignItems="center"
                  justifyContent="center"
                >
                  <RoomRentability
                    {...asset}
                    rentability={asset?.reform?.rentability}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                </Flex>
              </Col>
              <Col>
                <Flex
                  className="w-100 h-100"
                  alignItems="center"
                  justifyContent="center"
                >
                  <VacationRentability
                    {...asset}
                    rentability={asset?.reform?.rentability}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                </Flex>
              </Col>
            </Row>
          </Col>
        )}
        <Col xs={12}>
          <hr className="border-top border-dashed" />
          <h6 className="fw-bold">Ganancia</h6>
          <Row className="g-2">
            <Col>
              <Flex
                className="w-100 h-100"
                alignItems="center"
                justifyContent="center"
              >
                <CapitalGain
                  {...asset}
                  showIcon={true}
                  showText={true}
                  valueClass="fs-1"
                />
              </Flex>
            </Col>
            {!!asset?.reform?.amount && (
              <Col>
                <Flex
                  className="w-100 h-100"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FlippingHouse
                    {...asset}
                    showIcon={true}
                    showText={true}
                    valueClass="fs-1"
                  />
                </Flex>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={12}>
          <hr className="border-top border-dashed" />
          <h6 className="fw-bold">Precio</h6>
          <Flex alignItems="center">
            <h5 className="fw-normal m-0">
              {getCurrencyFormat(price, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
            </h5>
            <SoftBadge className="ms-2">
              {getCurrencyFormat(price / size, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}{' '}
              / m²
            </SoftBadge>
          </Flex>
        </Col>
        <Col xs={12}>
          <hr className="border-top border-dashed" />
          <h6 className="fw-bold">Características del inmueble</h6>
          <Flex alignItems="center" className="gap-2">
            {!!rooms && <SoftBadge bg="secondary">{rooms} hab.</SoftBadge>}
            {!!size && <SoftBadge bg="secondary">{size} m²</SoftBadge>}
          </Flex>
        </Col>
        <Col xs={12}>
          <hr className="border-top border-dashed" />
          <h6 className="fw-bold">Portal inmobiliario</h6>
          <a
            className="fw-normal m-0 text-capitalize"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {source}
          </a>
        </Col>
      </Row>
    </div>
  );
};

AssetSummary.propTypes = {
  asset: PropTypes.object
};

export default AssetSummary;

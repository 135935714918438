import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import AssetFilters from './AssetFilters';
import useBreakpoints from 'hooks/useBreakpoints';
import { AssetsContext } from 'context/Context';

const AssetHeader = () => {
  const { breakpoints } = useBreakpoints();
  const {
    assetsState: { filters },
    setFilterOptions
  } = useContext(AssetsContext);

  const resetFilters = () => {
    setFilterOptions([]);
  };

  return (
    breakpoints.up('md') && (
      <div className="flex-grow-1">
        <Row className="g-3 align-items-center justify-content-center">
          <Col>
            <AssetFilters />
            {!!filters?.length && (
              <div className="position-absolute end-0 top-0 p-1 pe-3">
                <Button
                  onClick={resetFilters}
                  variant="falcon-default"
                  size="sm"
                >
                  <FontAwesomeIcon className="me-2" icon="arrow-rotate-left" />
                  Reset
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    )
  );
};

AssetHeader.propTypes = {
  layout: PropTypes.string,
  isAsc: PropTypes.bool,
  setIsAsc: PropTypes.func,
  setSortBy: PropTypes.func,
  searchedText: PropTypes.string,
  handleSearch: PropTypes.func
};

export default AssetHeader;

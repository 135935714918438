import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Slider from 'react-slick';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import Asset from 'components/assets/Asset';
import { AssetsContext } from 'context/Context';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const SimilarAssets = ({ asset }) => {
  const { assetsState } = useContext(AssetsContext);
  const { assets = [] } = assetsState;
  const filteredAssets = assets.filter(
    ({ id, rooms, size, price }) =>
      id !== asset.id &&
      rooms === asset.rooms &&
      Math.abs(size - asset.size) < 20 &&
      Math.abs(price - asset.price) < 20000
  );

  return !filteredAssets?.length ? null : (
    <Card className="border mb-3 mt-6 overflow-hidden">
      <FalconCardHeader className="bg-light" title="Similar Assets" />
      <Card.Body className="p-0">
        <Slider
          {...sliderSettings}
          className="full-height-slider slick-slider-arrow-inner"
        >
          {filteredAssets.map(asset => (
            <div key={asset.id} className="mx-2 px-3">
              <Asset asset={asset} />
            </div>
          ))}
        </Slider>
      </Card.Body>
      <Card.Footer className="bg-light text-end py-2">
        <FalconLink
          title="Todos los inmuebles"
          to="/assets"
          icon="external-link-alt"
          className="fw-medium"
        />
      </Card.Footer>
    </Card>
  );
};

SimilarAssets.propTypes = {
  asset: PropTypes.object
};

export default SimilarAssets;

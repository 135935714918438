import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Logo from 'components/common/Logo';
import Footer from 'components/footer/Footer';
import AssetsProvider from 'components/AssetsProvider';

const ReportLayout = () => {
  return (
    <AssetsProvider>
      <Helmet>
        <style>{`
          #hubspot-messages-iframe-container {
            display: none;
            visibility: hidden;
          }
        `}</style>
      </Helmet>
      <div className="container-fluid">
        <div className="my-6">
          <Logo />
        </div>
        <Outlet />
      </div>
      <Footer />
    </AssetsProvider>
  );
};

export default ReportLayout;

export const routes = [
  {
    name: 'Explora',
    icon: 'search',
    to: '/assets',
    active: true
  },
  {
    name: 'Precios',
    icon: 'flag',
    to: '/pricing',
    active: true
  }
];

export default routes;

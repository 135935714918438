import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form } from 'react-bootstrap';
const AssetPreviewVideo = ({ register }) => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0 d-inline-block">Video del inmueble</h5>
      </Card.Header>
      <Card.Body className="bg-light">
        <Form.Group>
          <Form.Label>
            Pega aquí el link de youtube <small>(opcional)</small>
          </Form.Label>
          <div className="position-relative">
            <Form.Control
              type="url"
              name="courseYoutubeLink"
              placeholder="youtu.be/xXxxXxXXxxX"
              {...register('video')}
            />
            <div className="position-absolute top-50 end-0 translate-middle-y lh-1 me-2">
              <FontAwesomeIcon icon="link" className="text-400" />
            </div>
          </div>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

AssetPreviewVideo.propTypes = {
  register: PropTypes.func.isRequired
};

export default AssetPreviewVideo;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import google from 'assets/img/nav-icons/google.png';
// import facebook from 'assets/img/nav-icons/facebook.png';

const socialLinks = [
  {
    title: 'Google',
    image: google,
    provider: 'google.com'
  }
  // {
  //   title: 'Facebook',
  //   image: facebook,
  //   provider: 'facebook.com'
  // }
];

const SocialAuthButtons = ({ className, onRegister }) => {
  const { socialSignIn } = useContext(UserContext);
  return (
    <Form.Group className={className}>
      {socialLinks.map(({ title, image, provider }) => (
        <Button
          key={`SocialLogin-${provider}`}
          variant="falcon-default"
          size="lg"
          className="my-2 w-100"
          onClick={() =>
            socialSignIn(provider, { onRegister, to: !onRegister && '/assets' })
          }
        >
          <Flex alignItems="center" justifyContent="center">
            <img src={image} alt={title} width={23} className="me-2" />
            <h6 className="mb-0">{title}</h6>
          </Flex>
        </Button>
      ))}
    </Form.Group>
  );
};

SocialAuthButtons.propTypes = {
  className: PropTypes.string,
  onRegister: PropTypes.func
};

export default SocialAuthButtons;

import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { AssetsContext } from 'context/Context';
import { sources } from 'data/assets/assetData';

const sizes = {
  s: 'w-20px h-20px',
  m: 'w-24px h-24px',
  l: 'w-28px h-28px',
  xl: '',
  '2xl': 'w-40px h-40px',
  '3xl': 'w-56px h-56px',
  '4xl': 'w-100px h-100px',
  '5xl': 'w-170px h-170px'
};
const SourceLoading = ({ size = 's' }) => {
  const { loadingSources } = useContext(AssetsContext);

  return (
    <Flex alignItems="center" className="gap-1 h-auto">
      {sources
        .filter(({ value }) => loadingSources[value])
        .map(({ color, label, svg, value }) => {
          return (
            <Tooltip
              key={`loading-${value}`}
              title={`Buscando inmuebles en ${label}`}
              className="z-index-2"
              placement="bottom"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                className="position-relative"
              >
                <Avatar src={svg} size={size} className="p-1" />
                <Spinner
                  className={classNames(
                    'position-absolute opacity-75',
                    sizes[size]
                  )}
                  style={{ color }}
                />
              </Flex>
            </Tooltip>
          );
        })}
    </Flex>
  );
};

SourceLoading.propTypes = {
  size: PropTypes.string
};

export default SourceLoading;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';

const AssetProfit = ({ asset, register }) => {
  const { profit } = asset || {};
  const {
    longTerm: longTermProp,
    rooms: roomsProp,
    vacation: vacationProp
  } = profit || {};
  const longTerm = isNaN(longTermProp) ? '' : longTermProp;
  const rooms = isNaN(roomsProp) ? '' : roomsProp;
  const vacation = isNaN(vacationProp) ? '' : vacationProp;

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">Ingresos</Card.Header>
        <Card.Body className="bg-light">
          <Row className="g-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>
                  Alquiler a largo plazo{' '}
                  <Tooltip title="Si el inmueble ya tiene ingresos mensuales por alquiler a largo plazo puedes ponerlo aquí">
                    <span className="text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </Tooltip>
                </Form.Label>
                <Flex alignItems="center">
                  <Form.Control
                    type="text"
                    className="pe-7 me-n6"
                    {...register('profit.longTerm', {
                      required: true,
                      valueAsNumber: true
                    })}
                    value={longTerm}
                  />
                  <span className="fw-semi-bold text-400">€/mes</span>
                </Flex>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>
                  Alquiler por habitaciones{' '}
                  <Tooltip title="Si el inmueble ya tiene ingresos mensuales debido al alquiler por habitaciones puedes ponerlo aquí">
                    <span className="text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </Tooltip>
                </Form.Label>
                <Flex alignItems="center">
                  <Form.Control
                    type="text"
                    className="pe-7 me-n6"
                    {...register('profit.rooms', {
                      required: true,
                      valueAsNumber: true
                    })}
                    value={rooms}
                  />
                  <span className="fw-semi-bold text-400">€/mes</span>
                </Flex>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>
                  Alquiler vacacional{' '}
                  <Tooltip title="Si el inmueble ya tiene ingresos mensuales por alquileres vacacionales puedes ponerlo aquí">
                    <span className="text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </Tooltip>
                </Form.Label>
                <Flex alignItems="center">
                  <Form.Control
                    type="text"
                    className="pe-7 me-n6"
                    {...register('profit.vacation', {
                      required: true,
                      valueAsNumber: true
                    })}
                    value={vacation}
                  />
                  <span className="fw-semi-bold text-400">€/mes</span>
                </Flex>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

AssetProfit.propTypes = {
  asset: PropTypes.object,
  register: PropTypes.func.isRequired
};

export default AssetProfit;

import React from 'react';
import corner4 from 'assets/img/illustrations/corner-4.png';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Background from 'components/common/Background';

const CreateAssetHeader = () => {
  return (
    <Card className="mb-3">
      <Background
        image={corner4}
        className="bg-card d-none d-md-block rounded"
      />
      <Card.Body className="position-relative">
        <Row>
          <Col xl={10}>
            <h3>Crea un nuevo inmueble</h3>
            <p className="mb-0">
              En esta sección, podrás ingresar toda la información necesaria
              para crear un nuevo inmueble en nuestra plataforma. Proporcione
              datos como la ubicación, características, y fotografías para que
              los posibles compradores o arrendatarios puedan conocer todas las
              ventajas de tu propiedad. ¿Quieres editar tu inmueble existente?
              Dirígete a <Link to="/account#MyAssets">tus propiedades</Link>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateAssetHeader;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import AppContext from 'context/Context';
import bg from 'assets/img/generic/home3.jpg';

const AssetNotFound = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  return (
    <>
      <Background
        image={bg}
        overlay={isDark ? '0' : false}
        position="center bottom -10vw"
        className="position-fixed"
        style={{ backgroundColor: '#e9e8ed' }}
      />
      <Card className="text-center h-100">
        <Card.Body className="p-5">
          <div className="display-1 text-300 fs-error pt-4 position-relative">
            <FontAwesomeIcon icon="building" />
            <FontAwesomeIcon
              icon="question-circle"
              className="fs-8 position-absolute top-0 ms-n4 text-400"
            />
          </div>
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            Vaya, no hemos podido encontrar el inmueble
          </p>
          <hr />
          <p>Pero tenemos muchos inmuebles más que seguro que te interesarán</p>
          <Button as={Link} to="/assets">
            Explorar inmuebles
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default AssetNotFound;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import GoogleMap from 'components/map/GoogleMap';
import { Rate } from 'components/assets/Asset';
import { stripePaymentLinks } from 'helpers/defines';
import { getColor } from 'helpers/utils';
import { UserContext } from 'context/Context';

const AssetLocation = ({ asset }) => {
  const { me } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  let { area, geocode } = asset || {};
  const { info, locality } = area || {};
  const { population } = info?.location || {};
  const { geometry } = geocode || {};
  const { location } = geometry || {};

  return (
    <div>
      <FalconCardHeader
        title={
          <Flex alignItems="center">
            <h5>Ubicación: {locality}</h5>
            <Rate
              className="ms-2"
              circleClass="w-16px h-16px"
              circleActiveProps={{ strokeWidth: 8 }}
              showNumber={false}
              rating={info?.result?.index}
            />
          </Flex>
        }
        titleTag="div"
      />
      <div className="mt-3 overflow-hidden rounded">
        <GoogleMap
          key="AssetLocationMap"
          initialCenter={location}
          initialZoom={17}
          disableDefaultUI={true}
          tilt={45}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false
          }}
          circle={{
            radius: 230,
            center: location,
            strokeColor: getColor('primary'),
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: getColor('primary'),
            fillOpacity: 0.2
          }}
          mapTypeId="hybrid"
          className="rounded-soft position-relative"
          style={{ minHeight: '23.75rem' }}
        />
      </div>
      <div className="py-3">
        <div className="ms-2">
          <p className="m-0">{info?.result?.message}</p>
        </div>
        <Collapse in={open}>
          <div className="mt-4">
            <Card bg="soft-primary" className="mb-2 shadow-none">
              <Card.Body>
                <h6>Información general</h6>
                <SoftBadge className="bg-transparent text-wrap text-start">
                  <Flex alignItems="center" className="gap-2">
                    <FontAwesomeIcon icon="location-dot" />
                    <span>
                      {Intl.NumberFormat('es-ES').format(population)} habitantes
                    </span>
                  </Flex>
                </SoftBadge>
                {info?.location?.data?.map((item, index) => (
                  <div key={`Location-${locality}-${index}`}>
                    <SoftBadge className="bg-transparent text-wrap text-start">
                      <Flex alignItems="center" className="gap-2">
                        <FontAwesomeIcon icon="location-dot" />
                        <span>{item}</span>
                      </Flex>
                    </SoftBadge>
                  </div>
                ))}
              </Card.Body>
            </Card>
            <Card bg="soft-warning" className="mb-2 shadow-none">
              <Card.Body>
                <h6>Economía</h6>
                {info?.economy?.data?.map((item, index) => (
                  <div key={`Economy-${locality}-${index}`}>
                    <SoftBadge
                      bg="warning"
                      className="bg-transparent text-wrap text-start"
                    >
                      <Flex alignItems="center" className="gap-2">
                        <FontAwesomeIcon icon="coins" />
                        <span>{item}</span>
                      </Flex>
                    </SoftBadge>
                  </div>
                ))}
              </Card.Body>
            </Card>
            <Card bg="soft-secondary" className="mb-2 shadow-none">
              <Card.Body>
                <h6>Mercado inmobiliario</h6>
                <div>
                  <SoftBadge
                    bg="secondary"
                    className="bg-transparent text-wrap text-start"
                  >
                    <Flex alignItems="center" className="gap-2">
                      <FontAwesomeIcon icon="house" />
                      <span>{info?.market?.offer}</span>
                    </Flex>
                  </SoftBadge>
                </div>
                <div>
                  <SoftBadge
                    bg="secondary"
                    className="bg-transparent text-wrap text-start"
                  >
                    <Flex alignItems="center" className="gap-2">
                      <FontAwesomeIcon icon="house" />
                      <span>{info?.market?.demand}</span>
                    </Flex>
                  </SoftBadge>
                </div>
              </Card.Body>
            </Card>
            <Flex className="w-100 gap-2">
              <div className="w-50">
                <Card bg="soft-success" className="h-100 mb-2 shadow-none">
                  <Card.Body>
                    <h6>Puntos a favor</h6>
                    {info?.pros?.map?.((item, index) => (
                      <div key={`Pros-${locality}-${index}`} className="mb-1">
                        <SoftBadge
                          bg="success"
                          className="bg-transparent text-wrap text-start"
                        >
                          <Flex alignItems="center" className="gap-2">
                            <FontAwesomeIcon icon="thumbs-up" />
                            <span>{item}</span>
                          </Flex>
                        </SoftBadge>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </div>
              <div className="w-50">
                <Card bg="soft-danger" className="h-100 mb-2 shadow-none">
                  <Card.Body>
                    <h6>Puntos en contra</h6>
                    {info?.cons?.map?.((item, index) => (
                      <div key={`Cons-${locality}-${index}`} className="mb-1">
                        <SoftBadge
                          bg="danger"
                          className="bg-transparent text-wrap text-start"
                        >
                          <Flex alignItems="center" className="gap-2">
                            <FontAwesomeIcon icon="thumbs-down" />
                            <span>{item}</span>
                          </Flex>
                        </SoftBadge>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </div>
            </Flex>
          </div>
        </Collapse>
      </div>
      <Flex className="gap-2">
        <Button
          variant="outline-primary"
          onClick={() => setOpen(open => !open)}
        >
          {open ? 'Menos info' : 'Más info'}
        </Button>
        <Button
          as={Link}
          to={`${stripePaymentLinks.evaluate}?locale=es&prefilled_email=${
            me?.email || ''
          }&client_reference_id=${
            me?.data?.stripeId || ''
          }&utm_source=webapp&utm_content=detailLocation`}
          target="_blank"
        >
          Lo visitamos por ti
        </Button>
      </Flex>
    </div>
  );
};

AssetLocation.propTypes = {
  asset: PropTypes.object
};

export default AssetLocation;

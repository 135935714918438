import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import RentContent from './RentContent';
import FlippingHouseContent from './FlippingHouseContent';
import CalculatorProvider from './CalculatorProvider';
import AssetGallery from '../AssetGallery';

const contents = {
  rent: RentContent,
  'flipping-house': FlippingHouseContent
};

const CalculatorWrapper = ({ asset }) => {
  const { menu = 'rent' } = useParams();
  const CalculatorContent = contents[menu] || 'div';

  return (
    <CalculatorProvider asset={asset}>
      <hr className="border-0 mt-0" />
      {asset?.id && <AssetGallery {...(asset || {})} />}
      <CalculatorContent />
    </CalculatorProvider>
  );
};

CalculatorWrapper.propTypes = {
  asset: PropTypes.object,
  at: PropTypes.string
};

export default CalculatorWrapper;

import React, { useContext, useMemo } from 'react';
import GoogleMap from 'components/map/GoogleMap';
import { AssetsContext } from 'context/Context';
import { defaultCenter } from 'helpers/defines';

const AssetMap = () => {
  const { assetsState, filteredSources } = useContext(AssetsContext);
  const { assets = [] } = assetsState || {};

  const filteredAssets = useMemo(() => {
    if (!assets) {
      return null;
    }

    return assets
      .map(asset => {
        const { geocode, id: key } = asset;
        const { geometry } = geocode || {};
        const { location: position } = geometry || {};
        return { ...asset, key, position };
      })
      .filter(
        ({ position, source }) =>
          position &&
          (!filteredSources?.length ||
            filteredSources.some(({ value }) => value === source))
      );
  }, [assets, filteredSources]);

  return (
    <GoogleMap
      // mapId="d80bb10e2c9f3cec"
      at="AssetMap"
      initialCenter={defaultCenter}
      initialZoom={10}
      darkStyle="Cobalt"
      disableDefaultUI={true}
      gestureHandling={'greedy'}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      }}
      assets={filteredAssets}
      className="assets-map rounded-soft position-relative min-vh-50"
      style={{ minHeight: '23.75rem' }}
      showInfoWindow={false}
    />
  );
};

export default AssetMap;

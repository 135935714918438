import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Collapse, Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import { UserContext } from 'context/Context';

const RegistrationFormContent = ({ id = 'register', onRegister, hasLabel }) => {
  const { getPrices, register } = useContext(UserContext);
  const [isRegistering, setRegistering] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    confirmPassword: '',
    isAccepted: false
  });

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      const { name, email, password, phone, confirmPassword } = formData;
      if (password !== confirmPassword) {
        toast.error('Las contraseñas no coinciden', {
          theme: 'colored'
        });
        return;
      }
      setRegistering(true);
      await register(email, password, { name, phone, onRegister });
    } catch (error) {
      setRegistering(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    getPrices?.();
  }, []);

  return (
    <Form id={id} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Nombre' : ''}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
          required
        />
      </Form.Group>

      <Row className="g-3 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Email</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Email' : ''}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="email"
            required
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Teléfono</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Teléfono' : ''}
            value={formData.phone}
            name="phone"
            onChange={handleFieldChange}
            type="tel"
            required
          />
        </Form.Group>
      </Row>

      <Row className="g-3 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Contraseña</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Contraseña' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
            minLength={8}
            autoComplete="new-password"
            required
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirma contraseña</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirma contraseña' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
            required
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
            required
          />
          <Form.Check.Label className="form-label">
            Acepto los{' '}
            <Link to="/terms" target="_blank">
              términos
            </Link>{' '}
            y la{' '}
            <Link to="/privacy-policy" target="_blank">
              política de privacidad
            </Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button className="w-100" type="submit" disabled={isRegistering}>
          Registrarse
        </Button>
      </Form.Group>
    </Form>
  );
};

const RegistrationForm = props => {
  const { open: openProp = false } = props;
  const [open, setOpen] = useState(openProp);
  return (
    <>
      <SocialAuthButtons {...props} />
      <Divider className="mt-4">
        <Button
          variant="link"
          className="p-0 fs--1"
          disabled={openProp}
          onClick={() => setOpen(true)}
          aria-controls="registration-form"
          aria-expanded={open}
        >
          o regístrate con email y contraseña
        </Button>
      </Divider>
      <Collapse in={open}>
        <div id="registration-form" className="pt-3">
          <RegistrationFormContent {...props} />
        </div>
      </Collapse>
    </>
  );
};

RegistrationFormContent.propTypes = {
  id: PropTypes.string,
  hasLabel: PropTypes.bool,
  onRegister: PropTypes.func
};

RegistrationForm.propTypes = {
  onRegister: PropTypes.func,
  open: PropTypes.bool
};

export default RegistrationForm;

import React from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';

const ModalVideoContent = ({ currentVideo, setCurrentVideo }) => {
  return (
    <Modal
      show={!!currentVideo?.src}
      size="xl"
      onHide={() => setCurrentVideo({})}
      className="video-popup"
      contentClassName="bg-transparent border-0"
      backdropClassName="video-popup-backdrop"
      centered
    >
      <CloseButton
        variant="white"
        onClick={() => setCurrentVideo({})}
        className="video-popup-close-btn"
      />
      <Modal.Body className="p-0 rounded overflow-hidden">
        <video
          poster={currentVideo?.image}
          className="d-block"
          width="100%"
          height="100%"
          controls
          autoPlay
        >
          <source src={currentVideo?.src} type="video/mp4" />
        </video>
      </Modal.Body>
    </Modal>
  );
};

ModalVideoContent.propTypes = {
  currentVideo: PropTypes.bool,
  setCurrentVideo: PropTypes.func
};

export default ModalVideoContent;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import GoogleMap from 'components/map/GoogleMap';
import { UserContext } from 'context/Context';
import { defaultCenter } from 'helpers/defines';

const AssetLocationPreference = () => {
  const { me, updateData } = useContext(UserContext);
  const { data = {} } = me || {};
  const { assetLocation = [] } = data || {};
  const { geocode } = assetLocation || {};
  const { formatted_address: address = '', geometry } = geocode || {};
  const { location } = geometry || {};
  const center = location || defaultCenter;

  const handleSearch = async ({ target, value }) => {
    let geocode = value;
    if (!geocode) {
      updateData({ assetLocation: { geocode } });
      return;
    }
    let { geometry, name } = geocode || {};
    if (!geometry) {
      const geocoder = new window.google.maps.Geocoder();
      const { results } = (await geocoder.geocode({ address: name })) || {};
      const filteredResults = results.filter(({ formatted_address: address }) =>
        address.match(/(España|Spain)/)
      );
      const [_geocode] = filteredResults || [];
      const { formatted_address: address, geometry: _geometry } =
        _geocode || {};
      geocode = _geocode;
      geometry = _geometry;
      target.value = address;
    }
    if (!geometry) {
      return;
    }
    const { bounds, location, viewport } = geometry || {};
    const center = location?.toJSON?.() || location;
    const boundsProps = {
      northeast: bounds?.getNorthEast?.().toJSON() || bounds?.northeast,
      southwest: bounds?.getSouthWest?.().toJSON() || bounds?.southwest
    };
    const viewportProps = {
      northeast: viewport?.getNorthEast?.().toJSON() || viewport?.northeast,
      southwest: viewport?.getSouthWest?.().toJSON() || viewport?.southwest
    };
    bounds && (geocode.geometry.bounds = boundsProps);
    location && (geocode.geometry.location = center);
    viewport && (geocode.geometry.viewport = viewportProps);
    updateData({ assetLocation: { geocode } });
  };

  return (
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6>Zona de inmuebles deseada</h6>
      </Card.Header>
      <Card.Body className="pt-2">
        <GoogleMap
          key={`LocationPreference-${JSON.stringify({ center })}`}
          initialCenter={center}
          initialZoom={10}
          onSearch={handleSearch}
          searchProps={{ defaultValue: address }}
          mapClassName="assets-map border rounded h-100px min-h-100px"
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false
          }}
          autocompleteClassName="mb-2"
          showInfoWindow={false}
        />
      </Card.Body>
    </Card>
  );
};

AssetLocationPreference.propTypes = {
  asset: PropTypes.object,
  setValue: PropTypes.func
};

export default AssetLocationPreference;

import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
import PlansTable from 'components/pricing/PlansTable';
import AppContext, { UserContext } from 'context/Context';
import { free } from 'components/UserProvider';

const Subscription = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { me, billingPortalUrl, getBillingPortalUrl, subscription } =
    useContext(UserContext);
  const { plan: currentPrice = me?.uid && free } = subscription || {};
  const { interval: currentInterval } = currentPrice || {};
  const [interval, setInterval] = useState(currentInterval || 'year');

  const handleChangeInterval = event => {
    const { target } = event;
    const { checked } = target;
    setInterval(checked ? 'year' : 'month');
  };

  useEffect(() => {
    if (!currentInterval) {
      return;
    }
    setInterval(currentInterval);
  }, [currentInterval]);

  useEffect(() => {
    if (!me?.uid || billingPortalUrl) {
      return;
    }
    getBillingPortalUrl();
  }, [me?.uid]);

  return (
    <>
      <Section
        className={classNames('pt-5', {
          light: !isDark,
          dark: isDark
        })}
        overlay={isDark ? '0' : false}
      >
        <Flex direction="column" alignItems="center" justifyContent="start">
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="w-100 mt-n3"
          >
            <h1 className="mt-4 fw-light">Suscripción</h1>
            <Flex justifyContent="center" alignItems="center">
              <Form.Label className="m-0">Mensual</Form.Label>
              <Form.Check
                type="switch"
                className="ms-2"
                checked={interval === 'year'}
                onChange={handleChangeInterval}
              />
              <Form.Label className="m-0">Anual</Form.Label>
            </Flex>
          </Flex>
          <Flex
            className="flex-grow-1 pt-5 w-100"
            alignItems="center"
            justifyContent="center"
          >
            <PlansTable interval={interval} />
          </Flex>
        </Flex>
      </Section>
    </>
  );
};

export default Subscription;

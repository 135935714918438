import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import allExpenses from 'data/expenses.json';
import allFlippingHouseIncomes from 'data/flipping-house-incomes.json';
import allRentIncomes from 'data/rent-incomes.json';
import { CalculatorContext } from 'context/Context';
import ccaa from 'data/ccaa.json';

const CalculatorProvider = ({ asset, children }) => {
  const [area, setArea] = useState();
  const [mortgage, setMortgage] = useState(null);
  const [expenses, setExpenses] = useState(
    allExpenses.filter(({ required }) => required)
  );
  const { amount: priceAmount } =
    expenses.find(({ key }) => key === 'price') || {};
  const [flippingHouseIncomes, setFlippingHouseIncomes] = useState(
    allFlippingHouseIncomes.filter(({ required }) => required)
  );
  const [rentIncomes, setRentIncomes] = useState(
    allRentIncomes.filter(({ required }) => required)
  );
  const recurringExpenses = expenses.reduce(
    (total, { amount, recurring }) =>
      total + (!recurring || isNaN(amount) ? 0 : amount),
    0
  );
  const totalExpenses = expenses.reduce(
    (total, { amount }) => total + (isNaN(amount) ? 0 : amount),
    0
  );
  const totalFlippingHouseIncomes = flippingHouseIncomes.reduce(
    (total, { amount }) => total + (isNaN(amount) ? 0 : amount),
    0
  );
  const totalRentIncomes = rentIncomes.reduce(
    (total, { amount, multiplier = 1 }) =>
      total + (isNaN(amount) ? 0 : amount * multiplier),
    0
  );
  const { area: assetArea, geocode, price, reform } = asset || {};

  const addExpense = labelToAdd => {
    const newExpense = allExpenses.find(
      ({ label }) => label === labelToAdd
    ) || { label: labelToAdd, amount: 0 };
    setExpenses(expenses => [...expenses, newExpense]);
  };

  const deleteExpense = indexToDelete => {
    setExpenses(expenses =>
      expenses.filter((expense, index) => index !== indexToDelete)
    );
  };

  const addFlippingHouseIncome = labelToAdd => {
    const newIncome = allFlippingHouseIncomes.find(
      ({ label }) => label === labelToAdd
    ) || { label: labelToAdd, amount: 0 };
    setFlippingHouseIncomes(incomes => [...incomes, newIncome]);
  };

  const deleteFlippingHouseIncome = indexToDelete => {
    setFlippingHouseIncomes(incomes =>
      incomes.filter((income, index) => index !== indexToDelete)
    );
  };

  const addRentIncome = labelToAdd => {
    const newIncome = allRentIncomes.find(
      ({ label }) => label === labelToAdd
    ) || { label: labelToAdd, amount: 0 };
    setRentIncomes(incomes => [...incomes, newIncome]);
  };

  const deleteRentIncome = indexToDelete => {
    setRentIncomes(incomes =>
      incomes.filter((income, index) => index !== indexToDelete)
    );
  };

  const setAreaByShortName = shortName => {
    const { label, tax } =
      (shortName && ccaa.find(ca => ca.short_name === shortName)) || {};
    setArea({ label, tax, shortName });
    const amount = priceAmount * ((tax || 0) / 100);
    setExpenses(expenses =>
      expenses.map(expense =>
        expense?.key === 'itp'
          ? {
              ...expense,
              amount: Math.round(amount)
            }
          : expense
      )
    );
  };

  useEffect(() => {
    if (typeof area === 'undefined' || isNaN(price)) {
      return;
    }
    const amount = priceAmount * ((area?.tax || 0) / 100);
    setExpenses(expenses =>
      expenses.map(expense =>
        expense?.key === 'itp'
          ? {
              ...expense,
              amount: Math.round(amount)
            }
          : expense
      )
    );
  }, [priceAmount, area?.tax]);

  useEffect(() => {
    if (!assetArea?.prices) {
      return;
    }
    setFlippingHouseIncomes(incomes =>
      incomes.map(income =>
        income?.key === 'sale'
          ? {
              ...income,
              amount: assetArea?.prices?.avgPurchasePrice || 0
            }
          : income
      )
    );
    setRentIncomes(incomes =>
      incomes.map(income =>
        income?.key === 'monthrent'
          ? {
              ...income,
              amount: assetArea?.prices?.avgRentPrice || 0
            }
          : income
      )
    );
  }, [assetArea?.prices]);

  useEffect(() => {
    if (!geocode) {
      return;
    }
    const { address_components: addressComponents } = geocode || {};
    const { short_name: assetShortName } =
      addressComponents?.find(({ types }) =>
        types.includes('administrative_area_level_1')
      ) || {};
    setAreaByShortName(assetShortName);
  }, [geocode]);

  useEffect(() => {
    if (!price) {
      return;
    }
    setExpenses(expenses =>
      expenses.map(expense =>
        expense?.key === 'price'
          ? {
              ...expense,
              amount: price
            }
          : expense
      )
    );
  }, [price]);

  useEffect(() => {
    if (!reform) {
      return;
    }
    const reformExpense = allExpenses.find(({ key }) => key === 'reform') || {};
    setExpenses(expenses =>
      expenses.some(({ key }) => key === 'reform')
        ? expenses.map(expense =>
            expense?.key === 'reform'
              ? {
                  ...expense,
                  amount: reform.amount || 0
                }
              : expense
          )
        : [
            ...expenses,
            {
              ...reformExpense,
              amount: reform.amount || 0
            }
          ]
    );
  }, [reform]);

  // console.log('CALCULATOR >>>', {
  //   area,
  //   asset,
  //   expenses,
  //   flippingHouseIncomes,
  //   mortgage,
  //   rentIncomes
  // });

  return (
    <CalculatorContext.Provider
      value={{
        addExpense,
        addFlippingHouseIncome,
        addRentIncome,
        area,
        asset,
        deleteExpense,
        deleteFlippingHouseIncome,
        deleteRentIncome,
        expenses,
        flippingHouseIncomes,
        mortgage,
        rentIncomes,
        recurringExpenses,
        setAreaByShortName,
        setExpenses,
        setFlippingHouseIncomes,
        setMortgage,
        setRentIncomes,
        totalExpenses,
        totalFlippingHouseIncomes,
        totalRentIncomes
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};

CalculatorProvider.propTypes = {
  asset: PropTypes.object,
  children: PropTypes.node.isRequired
};

export default CalculatorProvider;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Image, Row } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import Flex from 'components/common/Flex';
import defaultImage from 'assets/img/illustrations/corner-4.png';

const AssetGallery = ({ images: imagesRaw = [], source }) => {
  let images = imagesRaw.map(image => {
    return source === 'fotocasa'
      ? `${image.split('?')[0]}?rule=web_1200x0`
      : image;
  });
  images = images?.length ? [...new Set(images)] : [defaultImage];

  return (
    <FalconLightBoxGallery
      images={images}
      className="rounded overflow-hidden position-relative"
    >
      {setImgIndex => (
        <>
          <Row className={classNames({ 'g-1': images.length > 1 })}>
            <Col
              className="max-vh-50"
              xs={classNames({
                6: images.length > 1,
                12: images.length === 1
              })}
            >
              <Flex className="bg-700 min-h-100">
                <Image
                  className={classNames(
                    'transition-base hover-opacity-75 w-100 fit-cover cursor-pointer',
                    {
                      'h-100 min-h-100 max-vh-50': images.length <= 2
                    }
                  )}
                  src={images[0]}
                  fluid
                  onClick={() => setImgIndex(0)}
                />
              </Flex>
            </Col>
            <Col className="m-0 p-0 max-vh-50">
              <Row className="g-1 w-100 h-100 m-0 p-0">
                {images.slice(1, 5).map((image, index) => (
                  <Col
                    className={classNames('min-w-50 overflow-hidden', {
                      'max-vh-25': images.length > 4
                    })}
                    key={`Image-${image}`}
                  >
                    <Flex className="bg-700 w-100 h-100 position-relative">
                      <Image
                        className={classNames(
                          'transition-base hover-opacity-75 min-w-100 min-h-100 fit-cover cursor-pointer'
                        )}
                        src={image}
                        fluid
                        onClick={() => setImgIndex(index + 1)}
                      />
                      {index === 3 && !!(images.length - 5) && (
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          className="bg-700 bg-opacity-50 pointer-none position-absolute w-100 h-100 top-0 start-0 z-index-2"
                        >
                          <span className="text-white fs-md-3 fw-bold">
                            + {images.length - 5} fotos
                          </span>
                        </Flex>
                      )}
                    </Flex>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </FalconLightBoxGallery>
  );
};

AssetGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string
};

export default AssetGallery;

import React, { useState } from 'react';
import Hero from './Hero';
import Courses from './Courses';
import Cta from './Cta';
import ModalVideoContent from './ModalVideoContent';
import courses from 'data/e-learning/courses';

const Learning = () => {
  const [currentVideo, setCurrentVideo] = useState({});
  return (
    <>
      <Hero courses={courses} setCurrentVideo={setCurrentVideo} />
      <Courses courses={courses} setCurrentVideo={setCurrentVideo} />
      <Cta />
      <ModalVideoContent
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
      />
    </>
  );
};

export default Learning;

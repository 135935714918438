import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import UserInfo from './UserInfo';
import UserIntro from './UserIntro';
import UserAssets from './UserAssets';
import useFetchAssetsByUserCount from 'hooks/useFetchAssetsByUserCount';
import useFetchFollowersCount from 'hooks/useFetchFollowersCount';

const UserProfile = () => {
  const db = useFirestore();
  const { userId } = useParams();
  const [assetsCount, setAssetsCount] = useState(0);
  // const [followersCount, setFollowersCount] = useState(0);
  const fetchAssetsCount = useFetchAssetsByUserCount(userId);
  const followersCount = useFetchFollowersCount(userId);
  let profileRef = doc(db, 'none', 'none');
  if (userId) {
    profileRef = doc(db, 'users', userId);
  }
  const { data: profile = {} } = useFirestoreDocData(profileRef);

  useEffect(() => {
    fetchAssetsCount(userId).then(result => {
      setAssetsCount(result.data().count);
    });
  }, [fetchAssetsCount, userId]);

  return (
    <Row className="g-3 mt-0">
      <Col lg={4} xl={3}>
        <div className="sticky-top">
          <UserInfo
            assetsCount={assetsCount}
            followersCount={followersCount}
            profile={profile}
          />
        </div>
      </Col>
      <Col lg={8} xl={9}>
        <UserIntro profile={profile} />
        <UserAssets assetsCount={assetsCount} profile={profile} />
      </Col>
    </Row>
  );
};

export default UserProfile;

/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from '../Flex';
import AppContext from 'context/Context';

export const AdvanceTableFooter = ({
  page,
  pageSize,
  pageIndex,
  rowCount,
  setPageSize,
  canPreviousPage,
  canNextPage,
  viewAllBtn,
  nextPage,
  previousPage,
  rowInfo,
  perPage,
  rowsPerPageSelection,
  navButtons,
  rowsPerPageOptions = [5, 10, 20, 50, 999999999],
  className
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const [isAllVisible, setIsAllVisible] = useState(false);
  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-end'
      )}
    >
      <Flex alignItems="center" className="fs--1">
        {rowInfo && (
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              {pageSize * pageIndex + 1} - {pageSize * pageIndex + page.length}{' '}
              de {rowCount}
            </span>
            {viewAllBtn && (
              <>
                <Button
                  variant="link"
                  size="sm"
                  className="ms-2 py-2 px-0 fw-semi-bold"
                  onClick={event => {
                    event.stopPropagation();
                    setIsAllVisible(!isAllVisible);
                    setPageSize(isAllVisible ? perPage : rowCount);
                  }}
                >
                  Ver {isAllVisible ? 'menos' : 'todo'}
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="ms-1 fs--2"
                  />
                </Button>
              </>
            )}
          </p>
        )}
        {rowsPerPageSelection && (
          <>
            <p className="mb-0 mx-2">Filas por página:</p>
            <Form.Select
              size="sm"
              className="w-auto"
              onChange={e => setPageSize(Number(e.target.value))}
              defaultValue={pageSize}
            >
              {rowsPerPageOptions.map((value, index) =>
                index < rowsPerPageOptions.length - 1 ? (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ) : (
                  <option value={value} key={value}>
                    Todos
                  </option>
                )
              )}
            </Form.Select>
          </>
        )}
      </Flex>
      {navButtons && (
        <Flex>
          <Button
            variant="falcon-default"
            size="sm"
            className="ms-1 ms-sm-2"
            disabled={!canPreviousPage}
            onClick={() => {
              previousPage();
            }}
          >
            <FontAwesomeIcon icon={`chevron-${isRTL ? 'right' : 'left'}`} />
          </Button>
          <Button
            variant="falcon-default"
            size="sm"
            className="ms-1 ms-sm-2"
            disabled={!canNextPage}
            onClick={() => {
              nextPage();
            }}
          >
            <FontAwesomeIcon icon={`chevron-${isRTL ? 'left' : 'right'}`} />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default AdvanceTableFooter;

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { Button, Carousel, Modal } from 'react-bootstrap';
import CalculatorWrapper from 'components/assets/rentability-calculator/CalculatorWrapper';
import Loader from 'components/common/Loader';
import Logo from 'components/common/Logo';
import LogoLoader from 'components/common/LogoLoader';
import NotAvailable from 'components/common/NotAvailable';
import SoftBadge from 'components/common/SoftBadge';
import { AssetsContext, UserContext } from 'context/Context';
import { sources } from 'data/assets/assetData';

export const DeletedModal = ({ asset }) => {
  const { source } = asset || {};
  const sourceObj = sources.find(({ value }) => value === source);
  return (
    <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-5">
        <Logo className="w-200px mx-auto" />
        <div className="text-center">
          <h5>El inmueble ya no está disponible en {sourceObj?.label}</h5>
          <Button as={Link} color="primary" className="mt-3" to="/assets">
            Volver
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DeletedModal.propTypes = {
  asset: PropTypes.object
};

export const LoadingModal = ({ asset }) => {
  const { images } = asset || {};
  return (
    <Modal
      show={true}
      contentClassName="h-320px"
      dialogClassName="w-350px"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-5">
        <Logo className="w-200px mx-auto" />
        <Carousel
          className="h-100px w-100 d-flex align-items-center justify-content-center"
          controls={false}
          indicators={false}
          interval={10000}
        >
          {images?.length < 3 && (
            <Carousel.Item>
              <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
                Cargando imágenes
              </h5>
            </Carousel.Item>
          )}
          <Carousel.Item>
            <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
              Analizando inmueble
            </h5>
          </Carousel.Item>
          <Carousel.Item>
            <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
              Calculando coste de reforma
            </h5>
          </Carousel.Item>
          <Carousel.Item>
            <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
              Calculando rentabilidades
            </h5>
          </Carousel.Item>
          <Carousel.Item>
            <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
              Analizando puntos a favor y en contra
            </h5>
          </Carousel.Item>
          <Carousel.Item>
            <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
              Calculando análisis económico
            </h5>
          </Carousel.Item>
          <Carousel.Item>
            <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
              Puntuando el potencial de inversión
            </h5>
          </Carousel.Item>
        </Carousel>
        <div className="mt-3">
          <Loader />
        </div>
      </Modal.Body>
    </Modal>
  );
};

LoadingModal.propTypes = {
  asset: PropTypes.object
};

const RentabilityCalculator = () => {
  const {
    loadingSources,
    scrapAsset,
    selectedAsset: asset,
    selectAsset
  } = useContext(AssetsContext);
  const {
    getAvailableSources,
    getAvailableViews,
    subscription,
    views,
    viewsStatus
  } = useContext(UserContext);
  const { source, assetId } = useParams();
  const isLoading = loadingSources?.[source] || false;
  const id = `${source}-${assetId}`;
  const availableSources = getAvailableSources();
  const availableViews = getAvailableViews();
  const sourceObj = availableSources.find(({ value }) => value === source);
  const isAvailable = !!sourceObj;
  const isAvailableByLimit =
    views.some(({ assetId }) => assetId === id) || availableViews !== 0;

  const loadAsset = async () => {
    const { updatedAt } = asset;
    const diff = dayjs(updatedAt).diff(new Date(), 'days');
    (diff > 1 || !asset?.ai) && (await scrapAsset(asset));
  };

  useEffect(() => {
    if (!isAvailable || !id) {
      return;
    }
    selectAsset(id);
  }, [id]);

  useEffect(() => {
    if (
      viewsStatus !== 'success' ||
      !isAvailableByLimit ||
      !asset?.id ||
      asset?.deleted ||
      source === 'inversorpro'
    ) {
      return;
    }
    loadAsset();
  }, [asset?.id, viewsStatus]);

  // console.log('ASSET >>>', asset);

  if (typeof subscription === 'undefined' || viewsStatus !== 'success') {
    return <LogoLoader className="min-vh-100 mt-n6" />;
  }

  if (!isAvailable) {
    return (
      <NotAvailable className="min-vh-100 mt-n6">
        La visualización de un inmueble de <span>{sourceObj?.label}</span>
        <br />
        está disponible a partir del{' '}
        <SoftBadge bg="400">Plan Estándar</SoftBadge>
      </NotAvailable>
    );
  }

  if (!isAvailableByLimit) {
    return (
      <NotAvailable className="min-vh-100 mt-n6">
        Has llegado al límite de visualizaciones mensuales.
        <br />
        Puedes tener visualizaciones ilimitadas contratando el{' '}
        <SoftBadge bg="400">Plan Pro</SoftBadge>
      </NotAvailable>
    );
  }

  return (
    <>
      {!asset?.id ? (
        <LogoLoader className="min-vh-100 mt-n6" />
      ) : (
        <>
          <Helmet>
            <title>{asset.name} | Inversor Pro</title>
            <meta name="title" content={`${asset.name} | Inversor Pro`} />
            <meta name="description" content={asset.description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:title"
              content={`${asset.name} | Inversor Pro`}
            />
            <meta property="og:description" content={asset.description} />
            <meta property="og:image" content={asset.images?.[0]} />
          </Helmet>
          {asset?.deleted ? (
            <DeletedModal asset={asset} />
          ) : (
            (isLoading || !asset?.ai) && <LoadingModal asset={asset} />
          )}
          <CalculatorWrapper asset={asset} />
        </>
      )}
    </>
  );
};

export default RentabilityCalculator;

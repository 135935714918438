import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { CalculatorContext } from 'context/Context';

const FlippingHouseRentability = () => {
  const {
    recurringExpenses = 0,
    totalExpenses = 0,
    totalFlippingHouseIncomes: totalIncomes = 0
  } = useContext(CalculatorContext);
  const amount = new Intl.NumberFormat('es-ES', { maximumFractionDigits: 1 });
  const fixedExpenses = totalExpenses - recurringExpenses;
  const net = Math.max(
    0,
    ((totalIncomes - fixedExpenses) / fixedExpenses) * 100
  );

  return (
    <Card className="border overflow-hidden mt-3">
      <Card.Header className="pb-0">
        <Card.Title>
          <small>Rentabilidad</small>
        </Card.Title>
      </Card.Header>
      <Card.Body className="pt-2">
        <div>
          <Flex alignItems="center" justifyContent="between" className="gap-4">
            <Card.Text className="m-0">Neta</Card.Text>
            <Card.Title className="m-0">
              {!isNaN(net) ? `${amount.format(net)}%` : '-'}
            </Card.Title>
          </Flex>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FlippingHouseRentability;

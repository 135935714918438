import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { Card, Col, Row } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import IconButton from 'components/common/IconButton';
import { UserContext } from 'context/Context';

const UserInfo = ({ assetsCount, followersCount, profile }) => {
  const { followUser, me, unfollowUser } = useContext(UserContext);
  const { avatar, name = 'Inversor Pro' } = profile || {};
  const isFollowed =
    me?.following?.some(({ followingId }) => followingId === profile?.id) ||
    false;

  const handleFollow = () => {
    followUser(profile);
  };
  const handleUnfollow = () => {
    unfollowUser(profile);
  };

  return (
    <Card>
      <Card.Body>
        <Row className="g-3 align-items-center">
          <Col md={6} lg={12} className="text-center">
            <Avatar
              className="profile-avatar"
              rounded="1"
              src={avatar && avatar}
              name={name}
              size="4xl"
            />
          </Col>
          <Col md={6} lg={12}>
            <Row className="row-cols-1 g-0">
              <Col className="text-center">
                <h4>{name}</h4>
                {/* <h5 className="mb-1 text-800 fs-0">Artist | Comic Writer</h5>
                <p className="mb-0 fs--1">
                  National Comics Publications, NY, USA
                </p> */}
              </Col>
              {me?.uid !== profile?.id && (
                <Col className="mt-4 mt-md-5 mt-lg-4 order-md-1 order-lg-0">
                  <IconButton
                    variant="falcon-default"
                    size="md"
                    icon="comment"
                    className="mb-2 w-100"
                    iconClassName="me-1"
                  >
                    Mensaje
                  </IconButton>
                  <IconButton
                    variant={isFollowed ? 'outline-primary' : 'primary'}
                    size="md"
                    icon={isFollowed ? 'thumbs-down' : 'thumbs-up'}
                    className="w-100"
                    iconClassName="me-1"
                    onClick={isFollowed ? handleUnfollow : handleFollow}
                  >
                    {isFollowed ? 'Dejar de seguir' : 'Seguir'}
                  </IconButton>
                </Col>
              )}
              <Col className="mt-4 mt-md-5 mt-lg-4">
                <Row className="text-center">
                  <Col xs={6}>
                    <FontAwesomeIcon
                      icon={['far', 'building']}
                      className="mb-2"
                      size="xl"
                    />
                    <h4>
                      <CountUp
                        start={0}
                        end={assetsCount}
                        duration={1}
                        className="text-700"
                        separator="."
                      />
                    </h4>
                    <h6 className="fw-normal mb-0">Inmuebles</h6>
                  </Col>
                  <Col xs={6}>
                    <FontAwesomeIcon
                      icon={['far', 'thumbs-up']}
                      className="mb-2"
                      size="xl"
                    />
                    <h4>
                      <CountUp
                        start={0}
                        end={followersCount}
                        duration={1}
                        className="text-700"
                        separator="."
                      />
                    </h4>
                    <h6 className="fw-normal mb-0">Followers</h6>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

UserInfo.propTypes = {
  assetsCount: PropTypes.number,
  followersCount: PropTypes.number,
  profile: PropTypes.object
};

export default UserInfo;

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import Logo from 'components/common/Logo';

const LogoLoader = ({ children, className }) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classNames('w-100 h-100 text-center', className)}
    >
      <div>
        <Logo width={256} />
        {children}
        <Flex
          alignItems="center"
          justifyContent="center"
          className="w-100 h-100"
        >
          <div className="py-3">
            <Loader />
          </div>
        </Flex>
      </div>
    </Flex>
  );
};

LogoLoader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default LogoLoader;

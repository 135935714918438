import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { getColor, getCurrencyFormat } from 'helpers/utils';
import { tooltipCurrencyFormatter } from 'helpers/echart-utils';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  MarkLineComponent,
  MarkPointComponent,
  CanvasRenderer,
  LegendComponent
]);

const onChartReadyCallback = chart => {
  chart?.resize();
};

const getOption = ({ labels, data: { longTerm, rooms, vacation } }) => ({
  legend: {
    top: 0,
    textStyle: {
      color: getColor('gray-700')
    }
  },
  tooltip: {
    trigger: 'axis',
    padding: [16, 24],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    borderRadius: 16,
    textStyle: getColor('dark'),
    borderWidth: 1,
    formatter: tooltipCurrencyFormatter,
    transitionDuration: 0.2,
    axisPointer: {
      type: 'none'
    }
  },
  xAxis: {
    type: 'category',
    data: labels,
    splitLine: {
      show: false
    },
    axisLabel: {
      color: getColor('gray-700')
    },

    axisLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300')
      }
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: getColor('gray-300')
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-700'),
      formatter: value =>
        getCurrencyFormat(value, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      name: 'Alquiler a largo plazo',
      type: 'line',
      data: longTerm,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('red'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('red')
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      }
    },
    {
      name: 'Alquiler por habitaciones',
      type: 'line',
      data: rooms,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('primary')
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      }
    },
    {
      name: 'Alquiler vacacional',
      type: 'line',
      data: vacation,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('warning'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('warning')
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      }
    }
  ],
  grid: { right: '5%', left: '12%', bottom: '10%', top: 60 }
});

const AssetProfitChart = ({ asset }) => {
  const chart = useRef();
  const { profit } = asset || {};
  const { longTerm, rooms, vacation } = profit || {};
  const longTermMonthly = (longTerm || 0) / 12;
  const roomsMonthly = (rooms || 0) / 12;
  const vacationMonthly = (vacation || 0) / 12;
  const years = 5;
  const { labels, data } = [...new Array(years * 12)].reduce(
    ({ labels, data }, none, index) => {
      const label = dayjs().add(index, 'month');
      return {
        labels: [...labels, label.format('MMMM YYYY')],
        data: {
          longTerm: [
            ...data.longTerm,
            (data.longTerm[index - 1] || 0) +
              (longTermMonthly +
                parseInt(longTermMonthly * label.diff(dayjs(), 'year') * 0.03))
          ],
          rooms: [
            ...data.rooms,
            (data.rooms[index - 1] || 0) +
              (roomsMonthly +
                parseInt(roomsMonthly * label.diff(dayjs(), 'year') * 0.03))
          ],
          vacation: [
            ...data.vacation,
            (data.vacation[index - 1] || 0) +
              (vacationMonthly +
                parseInt(vacationMonthly * label.diff(dayjs(), 'year') * 0.03))
          ]
        }
      };
    },
    { labels: [], data: { longTerm: [], rooms: [], vacation: [] } }
  );

  useEffect(() => {
    onChartReadyCallback(chart.current);
  }, [asset?.ai]);

  return (
    <>
      <FalconCardHeader
        className="ps-0"
        titleTag="h6"
        title="Ingreso acumulado"
      />
      <ReactEChartsCore
        ref={chart}
        echarts={echarts}
        className="w-100 max-w-100"
        option={getOption({ labels, data })}
        style={{ height: '21.88rem' }}
        onChartReady={onChartReadyCallback}
      />
    </>
  );
};

AssetProfitChart.propTypes = {
  asset: PropTypes.object
};

const AssetProfitInfo = ({ asset }) => {
  const { profit, rooms: numRooms } = asset || {};
  const { longTerm, rooms, vacation } = profit || {};
  const longTermMonthly = (longTerm || 0) / 12;
  const roomsMonthly = (rooms || 0) / 12;
  const vacationMonthly = (vacation || 0) / 12;

  return (
    <Row className="g-3 mt-3">
      <Col>
        <Card className="badge badge-soft-danger w-100 shadow-none">
          <Card.Body>
            <h6>Alquiler a largo plazo</h6>
            <h5>
              {getCurrencyFormat(longTermMonthly, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              /mes
            </h5>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="badge badge-soft-primary w-100 shadow-none">
          <Card.Body>
            <h6>Alquiler por habitaciones</h6>
            <h5>
              {numRooms} x{' '}
              {Intl.NumberFormat('es-ES').format(
                parseInt(roomsMonthly / numRooms),
                10
              )}{' '}
              ≈{' '}
              {getCurrencyFormat(roomsMonthly, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              /mes
            </h5>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="badge badge-soft-warning w-100 shadow-none">
          <Card.Body>
            <h6>Alquiler vacacional</h6>
            <h5>
              {getCurrencyFormat(vacationMonthly, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              /mes
            </h5>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

AssetProfitInfo.propTypes = {
  asset: PropTypes.object
};

const getOptionAmortizationChart = ({
  labels,
  data: { longTerm, rooms, vacation, cost }
}) => ({
  legend: {
    top: 0,
    textStyle: {
      color: getColor('gray-700')
    }
  },
  tooltip: {
    trigger: 'axis',
    padding: [16, 24],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    borderRadius: 16,
    textStyle: getColor('dark'),
    borderWidth: 1,
    formatter: props => tooltipCurrencyFormatter(props, { avg: false }),
    transitionDuration: 0.2,
    axisPointer: {
      type: 'none'
    }
  },
  xAxis: {
    type: 'category',
    data: labels,
    splitLine: {
      show: false
    },
    axisLabel: {
      color: getColor('gray-700')
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300')
      }
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: getColor('gray-300')
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-700'),
      formatter: value =>
        getCurrencyFormat(value, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      name: 'Precio de compra',
      type: 'line',
      data: cost,
      itemStyle: {
        color: getColor('success'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('success'),
        type: 'dashed'
      },
      showSymbol: false,
      symbol: 'triangle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      }
    },
    {
      name: 'Alquiler a largo plazo',
      type: 'line',
      data: longTerm,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('red'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('red')
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      },
      markLine: {
        data: [
          [
            {
              name: ' ',
              xAxis: longTerm.findIndex(value => value > cost[0]),
              yAxis: 0
            },
            {
              name: ' ',
              xAxis: longTerm.findIndex(value => value > cost[0]),
              yAxis: cost[0]
            }
          ]
        ],
        lineStyle: {
          color: getColor('red')
        }
      }
      // markPoint: {
      //   data: [
      //     {
      //       name: ' ',
      //       xAxis: longTerm.findIndex(value => value > cost[0]),
      //       yAxis: cost[0]
      //     }
      //   ],
      //   symbol: 'arrow',
      //   symbolRotate: 180,
      //   symbolSize: 20,
      //   itemStyle: {
      //     color: getColor('red')
      //   }
      // }
    },
    {
      name: 'Alquiler por habitaciones',
      type: 'line',
      data: rooms,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('primary')
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      },
      markLine: {
        data: [
          [
            {
              name: ' ',
              xAxis: rooms.findIndex(value => value > cost[0]),
              yAxis: 0
            },
            {
              name: ' ',
              xAxis: rooms.findIndex(value => value > cost[0]),
              yAxis: cost[0]
            }
          ]
        ],
        lineStyle: {
          color: getColor('primary')
        }
      }
      // markPoint: {
      //   data: [
      //     {
      //       name: ' ',
      //       xAxis: rooms.findIndex(value => value > cost[0]),
      //       yAxis: cost[0]
      //     }
      //   ],
      //   symbol: 'arrow',
      //   symbolRotate: 180,
      //   symbolSize: 20,
      //   itemStyle: {
      //     color: getColor('primary')
      //   }
      // }
    },
    {
      name: 'Alquiler vacacional',
      type: 'line',
      data: vacation,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('warning'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('warning')
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      },
      markLine: {
        data: [
          [
            {
              name: ' ',
              xAxis: vacation.findIndex(value => value > cost[0]),
              yAxis: 0
            },
            {
              name: ' ',
              xAxis: vacation.findIndex(value => value > cost[0]),
              yAxis: cost[0]
            }
          ]
        ],
        lineStyle: {
          color: getColor('warning')
        }
      }
      // markPoint: {
      //   data: [
      //     {
      //       name: labels[vacation.findIndex(value => value > cost[0])],
      //       xAxis: vacation.findIndex(value => value > cost[0]),
      //       yAxis: cost[0]
      //     }
      //   ],
      //   label: {},
      //   symbol: 'arrow',
      //   symbolRotate: 180,
      //   symbolSize: 20,
      //   itemStyle: {
      //     color: getColor('warning')
      //   }
      // }
    }
  ],
  grid: { right: '5%', left: '12%', bottom: '10%', top: 60 }
});

const AssetAmortization = ({ asset }) => {
  const chart = useRef();
  const { price: purchaisePrice, profit } = asset || {};
  const { longTerm, rooms, vacation } = profit || {};
  const longTermMonthly = (longTerm || 0) / 12;
  const roomsMonthly = (rooms || 0) / 12;
  const vacationMonthly = (vacation || 0) / 12;
  const info = { longTerm: 0, rooms: 0, vacation: 0 };
  const sum = { longTerm: 0, rooms: 0, vacation: 0 };
  const cost = purchaisePrice;
  let years = 1;

  const longTermValue = typeof longTerm === 'number' && longTerm;
  const roomsValue = typeof rooms === 'number' && rooms;
  const vacationValue = typeof vacation === 'number' && vacation;

  if (longTermValue && roomsValue && vacationValue) {
    while (!info.longTerm || !info.rooms || !info.vacation) {
      sum.longTerm =
        years * (longTermValue + longTermValue * ((years - 1) * 0.03));
      if (!info.longTerm && sum.longTerm > cost) {
        info.longTerm = years;
      }
      sum.rooms = years * (roomsValue + roomsValue * ((years - 1) * 0.03));
      if (!info.rooms && sum.rooms > cost) {
        info.rooms = years;
      }
      sum.vacation =
        years * (vacationValue + vacationValue * ((years - 1) * 0.03));
      if (!info.vacation && sum.vacation > cost) {
        info.vacation = years;
      }
      years++;
    }
  }

  const total = info.longTerm + info.rooms + info.vacation;
  const { labels, data } = [...new Array((years + 2) * 12)].reduce(
    ({ labels, data }, none, index) => {
      const label = dayjs().add(index, 'month');
      return {
        labels: [...labels, label.format('MMMM YYYY')],
        data: {
          longTerm: [
            ...data.longTerm,
            (data.longTerm[index - 1] || 0) +
              (longTermMonthly +
                parseInt(longTermMonthly * label.diff(dayjs(), 'year') * 0.03))
          ],
          rooms: [
            ...data.rooms,
            (data.rooms[index - 1] || 0) +
              (roomsMonthly +
                parseInt(roomsMonthly * label.diff(dayjs(), 'year') * 0.03))
          ],
          vacation: [
            ...data.vacation,
            (data.vacation[index - 1] || 0) +
              (vacationMonthly +
                parseInt(vacationMonthly * label.diff(dayjs(), 'year') * 0.03))
          ],
          cost: [...data.cost, cost]
        }
      };
    },
    { labels: [], data: { longTerm: [], rooms: [], vacation: [], cost: [] } }
  );

  const min = Math.min(info.longTerm, info.rooms, info.vacation);

  useEffect(() => {
    onChartReadyCallback(chart.current);
  }, [asset?.ai]);

  return (
    <>
      <FalconCardHeader
        className="ps-0"
        titleTag="h6"
        title="Tiempo de amortización"
      />

      <ReactEChartsCore
        ref={chart}
        echarts={echarts}
        className="w-100 max-w-100"
        option={getOptionAmortizationChart({
          labels: labels.slice((min - 2) * 12),
          data: {
            longTerm: data.longTerm.slice((min - 2) * 12),
            rooms: data.rooms.slice((min - 2) * 12),
            vacation: data.vacation.slice((min - 2) * 12),
            cost: data.cost.slice((min - 2) * 12)
          }
        })}
        style={{ height: '21.88rem' }}
        onChartReady={onChartReadyCallback}
      />
      <ProgressBar
        className="overflow-visible mt-7 mb-5 rounded-3"
        style={{ height: '12px' }}
      >
        <ProgressBar
          now={(info.longTerm * 100) / total}
          className="overflow-visible rounded-end-0 position-relative badge-soft-danger"
          label={
            <>
              <span className="text-500 fw-bold mt-n6 px-2 text-truncate">
                Alquiler a largo plazo
              </span>
              <h5>{info.longTerm} años</h5>
            </>
          }
        />
        <ProgressBar
          now={(info.rooms * 100) / total}
          className="overflow-visible rounded-0 position-relative badge-soft-primary"
          label={
            <>
              <span className="text-500 fw-bold mt-n6 px-2 text-truncate">
                Alquiler por habitaciones
              </span>
              <h5>{info.rooms} años</h5>
            </>
          }
        />
        <ProgressBar
          now={(info.vacation * 100) / total}
          className="overflow-visible rounded-start-0 position-relative badge-soft-warning"
          label={
            <>
              <span className="text-500 fw-bold mt-n6 px-2 text-truncate">
                Alquiler vacacional
              </span>
              <h5>{info.vacation} años</h5>
            </>
          }
        />
      </ProgressBar>
    </>
  );
};

AssetAmortization.propTypes = {
  asset: PropTypes.object
};

const AssetEconomic = ({ asset }) => {
  const { ai, profit } = asset || {};
  const { state } = ai || {};
  return (
    <div>
      <FalconCardHeader className="mb-3" title="Análisis económico" />
      {!profit ? (
        <div className="py-3">No hay datos</div>
      ) : !state || state < 40 ? (
        <div className="py-3 avoid-break-inside">
          <SoftBadge bg="danger">
            <Flex alignItems="center" className="gap-2">
              <FontAwesomeIcon icon="exclamation-triangle" />
              <span>En el estado actual el piso no tiene rentabilidad</span>
            </Flex>
          </SoftBadge>
        </div>
      ) : (
        <div className="py-3">
          <div className="avoid-break-inside">
            <AssetProfitChart asset={asset} />
            <AssetProfitInfo asset={asset} />
          </div>
          <hr className="mt-4" />
          <div className="avoid-break-inside">
            <AssetAmortization asset={asset} />
          </div>
          <hr className="border-0 mb-0" />
          <Card.Body className="fs--1 text-500">
            <p>* Se ha tenido el cuenta el incremento del 3% anual</p>
          </Card.Body>
        </div>
      )}
    </div>
  );
};

AssetEconomic.propTypes = {
  asset: PropTypes.object
};

export default AssetEconomic;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import GoogleMap from 'components/map/GoogleMap';

const defaultCenter = {
  lat: 40,
  lng: -3
};
const defaultZoom = 6;

const AssetLocation = ({ asset: assetProp, setValue }) => {
  const [center, setCenter] = useState();

  const handleSearch = async ({ target, value }) => {
    let geocode = value;
    let { geometry, name } = geocode || {};
    if (!geometry) {
      const geocoder = new window.google.maps.Geocoder();
      const { results } = (await geocoder.geocode({ address: name })) || {};
      const filteredResults = results.filter(({ formatted_address: address }) =>
        address.match(/(España|Spain)/)
      );
      const [_geocode] = filteredResults || [];
      const { formatted_address: address, geometry: _geometry } =
        _geocode || {};
      geocode = _geocode;
      geometry = _geometry;
      target.value = address;
    }
    if (!geometry) {
      return;
    }
    const { bounds, location, viewport } = geometry || {};
    const center = location?.toJSON?.() || location;
    const boundsProps = {
      northeast: bounds?.getNorthEast?.().toJSON() || bounds?.northeast,
      southwest: bounds?.getSouthWest?.().toJSON() || bounds?.southwest
    };
    const viewportProps = {
      northeast: viewport?.getNorthEast?.().toJSON() || viewport?.northeast,
      southwest: viewport?.getSouthWest?.().toJSON() || viewport?.southwest
    };
    bounds && (geocode.geometry.bounds = boundsProps);
    location && (geocode.geometry.location = center);
    viewport && (geocode.geometry.viewport = viewportProps);
    setCenter(center);
    setValue('geocode', geocode);
  };

  const asset = useMemo(
    () => ({ ...assetProp, position: center }),
    [assetProp, center]
  );
  const { geocode } = asset || {};
  const { formatted_address: address, geometry } = geocode || {};
  const { location } = geometry || {};

  useEffect(() => {
    location && setCenter(location);
  }, [location]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-light">
        Ubicación del inmueble
      </Card.Header>
      <Card.Body>
        <GoogleMap
          initialCenter={center || defaultCenter}
          initialZoom={center ? 17 : defaultZoom}
          onSearch={handleSearch}
          searchProps={{ defaultValue: address, required: true }}
          mapClassName="assets-map border rounded mt-2 min-h-300px h-400px"
          // mapTypeId="hybrid"
          assets={center && [asset]}
          autocompleteClassName="mb-2"
          showInfoWindow={true}
        />
      </Card.Body>
    </Card>
  );
};

AssetLocation.propTypes = {
  asset: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default AssetLocation;

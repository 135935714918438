/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */
import { getUnique } from 'helpers/utils';

export const assetReducer = (state, action) => {
  const { type, payload } = action;
  const filteredAssets = (payload.filters || state.filters || [])
    .reduce(
      (assets, filterOption) => {
        const { name, value } = filterOption;
        return assets.filter(asset => {
          if (Array.isArray(value)) {
            const [min, max] = value;
            return asset[name] > min && asset[name] < max;
          } else if (['rating', 'rooms'].includes(name)) {
            return asset[name] >= value;
          } else if (['source'].includes(name)) {
            return (payload.filters || state.filters || [])
              .map(({ value }) => value)
              .includes(asset[name]);
          } else {
            return asset[name] === value;
          }
        });
      },
      getUnique([...(payload.assets || []), ...state.primaryAssets]).filter(
        asset =>
          (asset.free || !asset.deleted) &&
          (!payload.assetsToDelete?.some(({ id }) => id === asset.id) || true)
      )
    )
    .sort((a, b) => {
      let { sponsored: sponsoredA } = a;
      let { sponsored: sponsoredB } = b;
      sponsoredA = sponsoredA || null;
      sponsoredB = sponsoredB || null;
      if (sponsoredA !== sponsoredB) {
        return sponsoredA >= sponsoredB ? -1 : 1;
      }
      const sortBy = payload.sortBy || state.sortBy;
      if ((payload.order || state.order) === 'asc') {
        return a[sortBy] <= b[sortBy] ? -1 : 1;
      } else {
        return a[sortBy] >= b[sortBy] ? -1 : 1;
      }
    })
    .filter(asset =>
      `${asset.areaName}-${asset.name}`
        .toUpperCase()
        .includes(
          (payload.searchedText || state.searchedText)?.toUpperCase() || ''
        )
    );

  switch (type) {
    case 'SET_ASSETS':
      const updatedAssets = getUnique([
        ...payload.assets,
        ...state.primaryAssets
      ]);
      return {
        ...state,
        primaryAssets: updatedAssets,
        assets: filteredAssets
      };

    case 'DELETE_ASSETS':
      const afterDeletedAssets = state.primaryAssets.filter(
        asset => !payload.assetsToDelete?.some(({ id }) => id === asset.id)
      );
      return {
        ...state,
        primaryAssets: afterDeletedAssets,
        assets: filteredAssets.filter(
          asset => !payload.assetsToDelete?.some(({ id }) => id === asset.id)
        )
      };

    case 'SET_FILTERS':
      return {
        ...state,
        assets: filteredAssets,
        filters: payload.filters
      };
    case 'SORT_ASSET':
      return {
        ...state,
        primaryAssets: state.primaryAssets.filter(
          ({ areaId }) => areaId !== btoa(state.searchedText)
        ),
        assets: filteredAssets.filter(
          ({ areaId }) => areaId !== btoa(state.searchedText)
        ),
        order: payload.order,
        sortBy: payload.sortBy
      };
    case 'SEARCH_ASSET':
      return {
        ...state,
        assets: filteredAssets,
        searchedText: payload.searchedText
      };
    case 'ADD_TO_CART':
      return {
        ...state,
        cartItems: [...state.cartItems, payload.asset]
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter(
          asset => asset.id !== payload.asset.id
        )
      };
    case 'ADD_TO_FAVOURITES':
      return {
        ...state,
        assets: state.assets.map(asset =>
          asset.id === payload.asset.id
            ? { ...asset, favorite: asset.favorite + 1 }
            : asset
        ),
        favouriteItems: [...state.favouriteItems, payload.asset]
      };
    case 'REMOVE_FROM_FAVOURITES':
      return {
        ...state,
        assets: state.assets.map(asset =>
          asset.id === payload.asset.id
            ? { ...asset, favorite: asset.favorite - 1 }
            : asset
        ),
        favouriteItems: state.favouriteItems.filter(
          asset => asset.id !== payload.asset.id
        )
      };
    default:
      return state;
  }
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from 'react-beautiful-dnd';
import Asset from 'components/assets/Asset';
import AppContext, { FavouritesContext } from 'context/Context';
import 'dayjs/locale/es';

const TaskDropMenu = ({ id }) => {
  const { removeCandidate } = useContext(FavouritesContext);

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const handleRemoveTaskCard = () => {
    removeCandidate(id);
  };

  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      align="end"
      className="font-sans-serif"
    >
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
      >
        <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-2" align={isRTL ? 'start' : 'end'}>
        <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
          Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const FavouriteCard = ({ asset, id, index }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    asset?.id &&
      navigate(
        `/assets/asset-details/${asset?.id.replace('-', '/')}?from=favourites`
      );
  };
  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : ''
  });

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={handleClick}
          >
            <Asset
              asset={asset}
              headerClass="h-100px rounded-bottom-0"
              bodyClass="px-3"
              from="favourites"
            />
          </Card>
        </div>
      )}
    </Draggable>
  );
};

TaskDropMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

FavouriteCard.propTypes = {
  asset: PropTypes.object,
  id: PropTypes.string,
  index: PropTypes.number
};

export default FavouriteCard;

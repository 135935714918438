import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AssetsProvider from 'components/AssetsProvider';
import UserProvider from 'components/UserProvider';
import NavbarStandard from 'components/landing/NavbarHome';
import FooterStandard from 'components/landing/FooterHome';
import AppContext from 'context/Context';

const LandingLayout = () => {
  const { setConfig } = useContext(AppContext);
  const location = useLocation();
  const { pathname } = location;
  const [, page] = pathname.match(/\/([^/]*)/) || [];

  useEffect(() => {
    window.scrollTo(0, 0);
    setConfig('isFluid', !page);
    setConfig('navbarPosition', 'top');
  }, [pathname]);

  return (
    <UserProvider>
      <AssetsProvider>
        <NavbarStandard />
        <Outlet />
        <FooterStandard />
      </AssetsProvider>
    </UserProvider>
  );
};

export default LandingLayout;

import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, Nav, Dropdown } from 'react-bootstrap';
import Login from 'components/authentication/simple/Login';
import Tooltip from 'components/common/Tooltip';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import AppContext, { UserContext } from 'context/Context';

const LandingRightSideNavItem = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  const { me } = useContext(UserContext);

  return (
    <Nav navbar className="ms-auto">
      <Nav.Item as={'li'} className="d-flex">
        <Nav.Link
          className="d-flex align-items-center justify-content-center theme-switch-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <Tooltip
            title={isDark ? 'Cambiar al tema claro' : 'Cambiar al tema oscuro'}
            placement="bottom"
          >
            <span className="d-flex align-items-center text-body">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="me-2"
              />
              <p className="d-lg-none mb-0">
                {isDark ? 'Cambiar al tema claro' : 'Cambiar al tema oscuro'}
              </p>
            </span>
          </Tooltip>
        </Nav.Link>
      </Nav.Item>

      {me?.uid ? (
        <ProfileDropdown />
      ) : (
        <Dropdown>
          <Dropdown.Toggle
            as={Link}
            className="nav-link text-body fw-semi-bold"
          >
            Inicia sesión
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-end dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
            <Card className="navbar-card-login shadow-none">
              <Card.Body className="fs--1 fw-normal p-4">
                <Login />
              </Card.Body>
            </Card>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Nav>
  );
};

export default LandingRightSideNavItem;

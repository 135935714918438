import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import equal from 'fast-deep-equal';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  FloatingLabel,
  Form,
  Image,
  InputGroup
} from 'react-bootstrap';
import { assetFilters } from 'data/assets/assetData';
import { getCurrencyFormat, slugifyText } from 'helpers/utils';
import AssetSort from './AssetSort';
import Flex from 'components/common/Flex';
import Popover from 'components/common/Popover';
import RangeSlider from 'components/common/RangeSlider';
import SoftBadge from 'components/common/SoftBadge';
import { AssetsContext, UserContext } from 'context/Context';

const CheckFilterItem = ({
  filter,
  filterOptions,
  handleFilterOptions,
  handleClick,
  isOffcanvas
}) => {
  const content = (
    <>
      {filter.options &&
        filter.options.map((option, i) => (
          <div
            key={slugifyText(option.label)}
            className={classNames('pt-3', { 'border-top': i > 0 })}
            onClick={handleClick}
          >
            <Form.Check
              type={option.type}
              className={classNames(
                'position-relative form-check d-flex ps-0',
                {
                  'opacity-50': option.disabled
                }
              )}
            >
              <Form.Check.Label
                className="fs--1 flex-1 text-truncate"
                htmlFor={`filter-${slugifyText(filter.label)}-${slugifyText(
                  option.label
                )}`}
              >
                {option.icon && (
                  <FontAwesomeIcon
                    icon={option.icon}
                    className={`me-3 ${option.iconShrink ? 'fs--2' : 'fs--1'}`}
                  />
                )}
                {option.svg && (
                  <Image
                    src={option.svg}
                    width={16}
                    alt=""
                    className="me-3 rounded"
                  />
                )}
                {option.label}
              </Form.Check.Label>

              {option.disabled && (
                <FontAwesomeIcon
                  icon="lock"
                  className="text-black position-absolute end-0 z-index-2 mt-1"
                />
              )}
              <Form.Check.Input
                className={classNames({
                  'opacity-0': option.disabled
                })}
                disabled={option.disabled}
                type={option.type}
                onChange={e => handleFilterOptions(e)}
                checked={filterOptions.some(
                  ({ value }) => `${option.value}` === `${value}`
                )}
                id={`filter-${slugifyText(filter.label)}-${slugifyText(
                  option.label
                )}`}
                name={option.name}
                value={option.value}
                data-label={option.label}
              />
            </Form.Check>
          </div>
        ))}
    </>
  );

  const thisFilterOptions = filterOptions.filter(
    ({ name }) => name === filter?.options?.[0]?.name
  );

  return isOffcanvas ? (
    <Card className="shadow-none mb-3 order-2">
      <Card.Header className="pb-0">
        <h6 className="text-uppercase text-400">
          {filter.label}
          {` (${thisFilterOptions.length})`}
        </h6>
      </Card.Header>
      <Card.Body className="pt-0">{content}</Card.Body>
    </Card>
  ) : (
    <Popover placement="bottom" popoverClass="min-w-220px" content={content}>
      <Button variant="outline-secondary" className="border-0 shadow-none">
        {filter.label}
        {!!thisFilterOptions.length && (
          <SoftBadge bg="secondary" className="ms-2">
            {filter?.options?.[0].type === 'radio'
              ? !!parseInt(thisFilterOptions[0].value) &&
                thisFilterOptions[0].label
              : thisFilterOptions.length}
          </SoftBadge>
        )}
      </Button>
    </Popover>
  );
};

CheckFilterItem.propTypes = {
  index: PropTypes.number,
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  handleFilterOptions: PropTypes.func,
  handleClick: PropTypes.func,
  filterOptions: PropTypes.array,
  isOffcanvas: PropTypes.bool
};

const RangeFilterItem = ({
  filter,
  filterOptions,
  handleFilterOptions,
  isOffcanvas
}) => {
  const { label, options, unit } = filter || {};
  const { name, value: min } = options?.[0] || {};
  const max = options?.[options?.length - 1]?.value;
  const maxLabel = options?.[options?.length - 1]?.label;
  const nameFilterOptions = filterOptions.filter(({ name: n }) => n === name);
  const filterValue = nameFilterOptions?.length
    ? nameFilterOptions[0].value
    : options.map(({ value }) => value);
  const [values, setValues] = useState(filterValue);

  const handleChange = values => {
    setTimeout(
      () =>
        setValues(
          values.map(value => parseInt(value?.replace?.('.', '') || value, 10))
        ),
      0
    );
  };

  const handleFinalChange = values => {
    setValues(values);
    values = values.map(value =>
      parseInt(value?.replace?.('.', '') || value, 10)
    );
    const value = values?.[0] === min && values?.[1] === max ? null : values;
    const label = `${value
      ?.map(
        value =>
          `${
            name === 'price'
              ? getCurrencyFormat(value, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })
              : value
          }`
      )
      .join(' - ')}${`${value?.[1] || value}` === `${max}` ? '+' : ''}${
      unit ? ` ${unit}` : ''
    }`;

    const target = {
      type: 'range',
      dataset: { label },
      name,
      value
    };
    handleFilterOptions({ target });
  };

  useEffect(() => {
    if (equal(filterValue, values)) {
      return;
    }
    setValues(filterValue);
  }, [filterOptions]);

  const content = (
    <>
      <div className="px-2 mb-2">
        <RangeSlider
          draggableTrack
          min={min}
          max={max}
          values={values}
          onChange={handleChange}
          onFinalChange={handleFinalChange}
          showLabel={false}
        />
      </div>
      <InputGroup>
        <FloatingLabel label="Desde">
          <Form.Control
            value={
              name === 'price'
                ? getCurrencyFormat(values[0] || min, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : `${values[0] || min}${unit && unit}`
            }
            onChange={({ target }) => handleChange([target.value, values[1]])}
            onBlur={({ target }) =>
              handleFinalChange([target.value, values[1]])
            }
          />
        </FloatingLabel>
        <FloatingLabel label="Hasta">
          <Form.Control
            value={
              values[1] === max
                ? maxLabel
                : name === 'price'
                ? getCurrencyFormat(values[1] || max, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : `${values[1] || max}${unit && unit}`
            }
            onChange={({ target }) => handleChange([values[0], target.value])}
            onBlur={({ target }) =>
              handleFinalChange([values[0], target.value])
            }
          />
        </FloatingLabel>
      </InputGroup>
    </>
  );

  const thisFilterOptions = filterOptions.filter(
    ({ name }) => name === filter?.options?.[0]?.name
  );

  return isOffcanvas ? (
    <Card className="shadow-none mb-3 order-3">
      <Card.Header className="pb-0">
        <h6 className="text-uppercase text-400">{label}</h6>
      </Card.Header>
      <Card.Body className="pt-0">{content}</Card.Body>
    </Card>
  ) : (
    <Popover placement="bottom" content={content}>
      <Button variant="outline-secondary" className="border-0 shadow-none">
        {label}
        {!!thisFilterOptions.length && (
          <SoftBadge bg="secondary" className="ms-2">
            {thisFilterOptions[0].label}
          </SoftBadge>
        )}
      </Button>
    </Popover>
  );
};

RangeFilterItem.propTypes = {
  index: PropTypes.number,
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  handleFilterOptions: PropTypes.func,
  filterOptions: PropTypes.array,
  isOffcanvas: PropTypes.bool
};

const SortFilterItem = ({ filter, isOffcanvas }) => {
  return isOffcanvas ? (
    <Card className="shadow-none mb-3 order-1">
      <Card.Header className="pb-0">
        <h6 className="text-uppercase text-400">{filter.label}</h6>
      </Card.Header>
      <Card.Body className="pt-0">
        <AssetSort />
      </Card.Body>
    </Card>
  ) : (
    <Popover placement="bottom" content={<AssetSort />}>
      <Button variant="outline-secondary border-0">{filter.label}</Button>
    </Popover>
  );
};

SortFilterItem.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  isOffcanvas: PropTypes.bool
};

const filterItemsType = {
  range: RangeFilterItem,
  sort: SortFilterItem
};

const AssetFilters = ({ isOffcanvas }) => {
  const { assetsDispatch, filterOptions, setFilterOptions } =
    useContext(AssetsContext);
  const { getAvailableSources, me, setUpgradeModal } = useContext(UserContext);
  const { budget: { amount: budget } = {} } = me?.data || {};

  const handleFilterOptions = e => {
    const {
      type,
      dataset: { label },
      name,
      value,
      checked
    } = e.target;

    if (type === 'checkbox') {
      let options = [...filterOptions];
      options = options.filter(option => option.value !== value);

      checked && options.push({ label, name, value });
      setFilterOptions(options);
    } else if (type === 'radio') {
      const isExists = filterOptions.some(el => el.name === name);
      isExists
        ? setFilterOptions(
            filterOptions.map(el =>
              el.name === name ? { ...el, label, value } : el
            )
          )
        : setFilterOptions([...filterOptions, { label, name, value }]);
    } else if (type === 'range') {
      const isExists = filterOptions.some(el => el.name === name);
      isExists
        ? setFilterOptions(
            filterOptions
              .map(el => (el.name === name ? { ...el, label, value } : el))
              .filter(({ value }) => value)
          )
        : setFilterOptions(
            [...filterOptions, { label, name, value }].filter(
              ({ value }) => value
            )
          );
    }
  };

  useEffect(() => {
    const priceFilter = filterOptions.find(({ name }) => name === 'price');
    if (!budget) {
      return;
    }
    const minBudget = budget - 0.5 * budget;
    const maxBudget = budget + 0.2 * budget;
    if (
      priceFilter?.value?.[0] === minBudget &&
      priceFilter?.value?.[1] === maxBudget
    ) {
      return;
    }

    const label = `${getCurrencyFormat(minBudget, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })} - ${getCurrencyFormat(maxBudget, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
    const value = [minBudget, maxBudget];
    setFilterOptions(
      [
        ...filterOptions.filter(({ name }) => name !== 'price'),
        { label, name: 'price', value }
      ].filter(({ value }) => value)
    );
  }, [budget]);

  const handleClick = event => {
    const { currentTarget } = event || {};
    const { textContent } = currentTarget || {};
    const { disabled } = currentTarget?.querySelector('input') || {};
    if (disabled) {
      setUpgradeModal({
        open: true,
        message: (
          <>
            El portal {textContent} está disponible a partir del{' '}
            <SoftBadge bg="400">Plan Estándar</SoftBadge>
          </>
        )
      });
    }
  };

  useEffect(() => {
    assetsDispatch({
      type: 'SET_FILTERS',
      payload: {
        filters: filterOptions
      }
    });
  }, [filterOptions]);

  return (
    <SimpleBarReact className="overflow-y-hidden">
      <Flex
        className="gap-1"
        direction={classNames({ column: isOffcanvas })}
        alignItems={classNames({ center: !isOffcanvas })}
        justifyContent={classNames({ center: !isOffcanvas })}
      >
        {assetFilters.map((filter, index) => {
          const FilterItem = filterItemsType[filter?.type] || CheckFilterItem;
          if (filter.name === 'sources') {
            const availableSources = getAvailableSources();
            filter.options = filter.options.map(option => ({
              ...option,
              disabled: !availableSources.some(
                ({ value }) => value === option.value
              )
            }));
          }
          return (
            <FilterItem
              key={slugifyText(filter.label)}
              index={index}
              filter={filter}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              handleFilterOptions={handleFilterOptions}
              handleClick={handleClick}
              isOffcanvas={isOffcanvas}
            />
          );
        })}
      </Flex>
    </SimpleBarReact>
  );
};

AssetFilters.propTypes = {
  setShow: PropTypes.func,
  isOffcanvas: PropTypes.bool
};

export default AssetFilters;

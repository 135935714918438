import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Flex from 'components/common/Flex';
import NavbarStandard from 'components/landing/NavbarStandard';
import FooterStandard from 'components/landing/FooterStandard';
import AppContext from 'context/Context';

const LandingLayout = () => {
  const { setConfig } = useContext(AppContext);
  const location = useLocation();
  const { pathname } = location;
  const [, page] = pathname.match(/\/([^/]*)/) || [];

  useEffect(() => {
    window.scrollTo(0, 0);
    setConfig('isFluid', !page);
    setConfig('navbarPosition', 'top');
  }, [pathname]);

  return (
    <Flex direction="column" className="min-vh-100">
      <NavbarStandard />
      <div className="flex-grow-1">
        <Outlet />
      </div>
      <FooterStandard />
    </Flex>
  );
};

export default LandingLayout;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const pre = 'Mira lo que he visto en Inversor.Pro';

const socialShares = [
  {
    icon: 'whatsapp',
    color: 'whatsapp',
    url: 'https://api.whatsapp.com/send?text='
  }
];

const AssetBanner = ({ asset }) => {
  const { id } = asset || {};

  return (
    <>
      <Row className="mt-3">
        <Col className="position-relative">
          <div>
            <small>Ref: {id}</small>
          </div>
          <Flex alignItems="center" className="gap-2 mb-3">
            <h2 className="fw-bold m-0">{asset.name}</h2>
            <Flex className="gap-2 ms-md-2">
              {socialShares.map(({ icon, color, url }) => {
                const [source, numId] = id?.split('-') || [];
                const link = `http://inversor.pro/a/${source}/${numId}?from=report-whatsapp`;
                const to = `${url}${pre} ${link}`;
                return (
                  <Button
                    key={`${id}-${icon}`}
                    variant="falcon-default"
                    className="border w-32px h-32px p-0 d-flex align-items-center justify-content-center shadow-none"
                    as={Link}
                    to={to}
                  >
                    <FontAwesomeIcon
                      icon={['fab', `${icon}`]}
                      className={`mr-1 text-${color}`}
                    />
                  </Button>
                );
              })}
            </Flex>
          </Flex>

          <p className="mb-0 fw-medium text-pre-wrap">{asset?.description}</p>
        </Col>
      </Row>
    </>
  );
};

AssetBanner.propTypes = {
  asset: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
    rating: PropTypes.number,
    review: PropTypes.number,
    rooms: PropTypes.number,
    size: PropTypes.number,
    source: PropTypes.string,
    textContent: PropTypes.string
  })
};

export default AssetBanner;

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel, CloseButton, Modal } from 'react-bootstrap';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import dayjs from 'dayjs';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import Logo from 'components/common/Logo';
import SoftBadge from 'components/common/SoftBadge';
import AssetDetail from 'components/assets/AssetDetail';
import { AssetsContext, FavouritesContext } from 'context/Context';
import 'dayjs/locale/es';

const DeletedModal = () => {
  return (
    <div className="position-absolute rounded start-0 top-0 w-100 h-100 z-index-2">
      <Flex
        alignItems="center"
        justifyContent="center"
        className="sticky-top rounded bg-200 bg-opacity-50 w-100 vh-100 pt-8"
      >
        <SoftBadge bg="danger" className="fs-2">
          NO DISPONIBLE
        </SoftBadge>
      </Flex>
    </div>
  );
};

const LoadingModal = ({ asset }) => {
  const { ai, images } = asset;
  return (
    <Flex
      alignItems="start"
      justifyContent="center"
      className="position-absolute start-0 top-0 w-100 h-100 pt-8 z-index-2"
    >
      <div className="bg-white rounded p-5">
        <Logo className="w-200px mx-auto" />
        <Carousel
          className="h-100px w-100 d-flex align-items-center justify-content-center"
          controls={false}
          indicators={false}
          interval={10000}
        >
          <Carousel.Item>
            <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
              Comprobando disponibilidad
            </h5>
          </Carousel.Item>
          {images?.length < 3 && (
            <Carousel.Item>
              <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
                Cargando imágenes
              </h5>
            </Carousel.Item>
          )}
          {!ai && (
            <Carousel.Item>
              <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
                Analizando inmueble
              </h5>
            </Carousel.Item>
          )}
        </Carousel>
        <div className="mt-3">
          <Loader />
        </div>
      </div>
    </Flex>
  );
};

LoadingModal.propTypes = {
  asset: PropTypes.object
};

const AssetModal = ({ asset: assetProp, show }) => {
  const db = useFirestore();
  const { formatAsset, loadingSources, scrapAsset } = useContext(AssetsContext);
  const { showAssetModal } = useContext(FavouritesContext);
  let assetRef = doc(db, 'none', 'none');
  if (assetProp?.id) {
    assetRef = doc(db, 'assets', assetProp.id);
  }
  const { data } = useFirestoreDocData(assetRef);
  const asset = formatAsset(data);
  const { source, updatedAt } = asset || {};
  const isLoading = loadingSources?.[source] || false;

  const handleClose = () => {
    showAssetModal(false);
  };

  const loadAsset = async () => {
    const diff = dayjs(updatedAt).diff(new Date(), 'days');
    (diff > 1 || !asset?.ai) && (await scrapAsset(asset));
  };

  useEffect(() => {
    if (!asset?.id || asset?.deleted) {
      return;
    }
    loadAsset();
  }, [asset?.id]);

  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="min-vw-80"
    >
      <Modal.Body>
        <AssetDetail asset={asset || assetProp} at="favourites" />
        {asset?.deleted ? (
          <DeletedModal asset={asset || assetProp} />
        ) : (
          (isLoading || (!asset?.ai && !assetProp?.ai)) && (
            <LoadingModal asset={asset || assetProp} />
          )
        )}
      </Modal.Body>
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-2">
        <CloseButton
          onClick={handleClose}
          className="btn btn-sm btn-circle d-flex flex-center transition-base"
        />
      </div>
    </Modal>
  );
};

AssetModal.propTypes = {
  asset: PropTypes.object,
  show: PropTypes.bool
};

export default AssetModal;

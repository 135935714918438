import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getCurrencyFormat } from 'helpers/utils';

const AssetSummary = ({ asset }) => {
  const { price: purchaisePrice, profit, reform } = asset || {};
  const { amount: reformPrice, profit: reformProfit } = reform || {};
  const { longTerm } = profit || {};
  const { longTerm: reformLongTerm } = reformProfit || {};
  let info = { longTerm: 0 };
  let sum = { longTerm: 0 };
  const cost = purchaisePrice;
  const reformCost = purchaisePrice + reformPrice;
  let amortYears = 1;
  let reformAmortYears = 1;

  if (longTerm) {
    while (!info.longTerm) {
      sum.longTerm =
        amortYears * (longTerm + longTerm * ((amortYears - 1) * 0.03));
      if (!info.longTerm && sum.longTerm > cost) {
        info.longTerm = amortYears;
      }
      amortYears++;
    }
  }
  info = { longTerm: 0 };
  sum = { longTerm: 0 };
  if (reformLongTerm) {
    while (!info.longTerm) {
      sum.longTerm =
        reformAmortYears *
        (reformLongTerm + reformLongTerm * ((reformAmortYears - 1) * 0.03));
      if (!info.longTerm && sum.longTerm > reformCost) {
        info.longTerm = reformAmortYears;
      }
      reformAmortYears++;
    }
  }

  return (
    <Card className="border">
      <Card.Body>
        <Row className="g-3">
          <Col>
            <div className="badge badge-transparent w-100 h-100">
              <Flex
                direction="column"
                justifyContent="between"
                className="h-100"
              >
                <h1>💶</h1>
                <h6 className="text-wrap">Ingresos anuales aprox.</h6>
                <h5>
                  {longTerm
                    ? getCurrencyFormat(longTerm, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    : '-'}
                </h5>
              </Flex>
            </div>
          </Col>
          <Col>
            <div className="badge badge-transparent w-100 h-100">
              <Flex
                direction="column"
                justifyContent="between"
                className="h-100"
              >
                <h1>⌛</h1>
                <h6 className="text-wrap">Tiempo de amortización aprox.</h6>
                <h5>{longTerm ? `${amortYears} años` : '-'}</h5>
              </Flex>
            </div>
          </Col>
          <Col>
            <div className="badge badge-transparent w-100 h-100">
              <Flex
                direction="column"
                justifyContent="between"
                className="h-100"
              >
                <h1>🚧</h1>
                <h6 className="text-wrap">Coste de reforma</h6>
                <h5>
                  {reformPrice
                    ? getCurrencyFormat(reformPrice, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    : '-'}
                </h5>
              </Flex>
            </div>
          </Col>
          {!!reformPrice && (
            <>
              <Col>
                <div className="badge badge-transparent w-100 h-100">
                  <Flex
                    direction="column"
                    justifyContent="between"
                    className="h-100"
                  >
                    <h1>💶</h1>
                    <h6 className="text-wrap">
                      Ingresos anuales aprox. tras reforma
                    </h6>
                    <h5>
                      {reformLongTerm
                        ? getCurrencyFormat(reformLongTerm, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        : '-'}
                    </h5>
                  </Flex>
                </div>
              </Col>
              <Col>
                <div className="badge badge-transparent w-100 h-100">
                  <Flex
                    direction="column"
                    justifyContent="between"
                    className="h-100"
                  >
                    <h1>⌛</h1>
                    <h6 className="text-wrap">Tiempo de amortización aprox.</h6>
                    <h5>{longTerm ? `${reformAmortYears} años` : '-'}</h5>
                  </Flex>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

AssetSummary.propTypes = {
  asset: PropTypes.object
};

export default AssetSummary;

export const CAPITAL_GAIN_MIN_STATE = 40;
export const MAX_VALUABLE_SIZE = 120;
export const PROFIT_ROOMS_MULT = 1.35;
export const PROFIT_VACATION_MULT = 1.8;
export const REFORM_PRICE_M2 = 500;

export const defaultCenter = { lat: 38.8330778, lng: -0.6274254 };

export const stripePaymentLinks = {
  report: 'https://buy.stripe.com/4gwaEG7Mngqn7Zu28i',
  expert: 'https://buy.stripe.com/28o9AC6Ij7TR6Vq14f',
  negotiate: 'https://buy.stripe.com/3csbIK6Ij6PNenS4gs',
  evaluate: 'https://buy.stripe.com/dR6dQSfeP8XV3Je14h'
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import AssetMarker from './AssetMarker';
import Asset from 'components/assets/Asset';
import { getColor } from 'helpers/utils';

/**
 * The ClusteredMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredMarkers = ({
  assets,
  selectedAsset: selectedAssetProp = null
}) => {
  const [markers, setMarkers] = useState({});
  const [selectedAsset, setSelectedAsset] = useState(selectedAssetProp);
  const map = useMap();
  const color = useRef(getColor('primary'));

  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({
      map,
      renderer: {
        render: ({ count, position }) => {
          // use d3-interpolateRgb to interpolate between red and blue
          // create svg url with fill color
          const svg = window.btoa(`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" r="86" fill="#ffffff" opacity=".6" />
              <circle cx="120" cy="120" r="70" fill="${color.current}" />
            </svg>
          `);
          // create marker using svg icon
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(70, 70)
            },
            label: {
              text: String(count),
              color: 'rgba(255,255,255,0.9)',
              fontSize: '14px',
              fontWeight: 'bold'
            },
            // adjust zIndex to be above other markers
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count
          });
        }
      }
    });
  }, [!!map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));

    return () => {
      clusterer.clearMarkers();
    };
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker, key) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { ...newMarkers } = markers;
        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedAsset(null);
  }, []);

  const handleMarkerClick = useCallback(asset => {
    setSelectedAsset(asset);
  }, []);

  useEffect(() => {
    setSelectedAsset(selectedAssetProp);
  }, [selectedAssetProp]);

  return (
    <>
      {assets.map((asset, index) => {
        const { key = `Marker-${index}` } = asset;
        return (
          <AssetMarker
            key={key}
            asset={asset}
            onClick={handleMarkerClick}
            setMarkerRef={setMarkerRef}
          />
        );
      })}

      {selectedAsset && (
        <InfoWindow
          anchor={markers[selectedAsset.key]}
          onCloseClick={handleInfoWindowClose}
        >
          {assets?.length === 1 ? (
            <Asset
              key={`InfoWindow-Asset-${JSON.stringify(assets[0])}`}
              asset={assets[0]}
              at="map"
              headerClass="flex-grow-1 rounded-0"
              headerTopClass="justify-content-start"
              bodyClass="px-3 rounded-0"
              className="max-w-300px border-0 rounded-0"
            />
          ) : (
            <SimpleBarReact className="overflow-x-hidden w-220px max-h-500px">
              {assets
                .filter(
                  ({ position }) =>
                    JSON.stringify(position) ===
                    JSON.stringify(selectedAsset.position)
                )
                .map((asset, index) => {
                  const { id = `Asset-${index}` } = asset;
                  return (
                    <Asset
                      key={`InfoWindow-Asset-${id}`}
                      asset={asset}
                      at="map"
                      headerClass="flex-grow-1 rounded-0"
                      headerTopClass="justify-content-start"
                      bodyClass="px-3 rounded-0"
                      className="max-w-300px border-0 rounded-0"
                    />
                  );
                })}
            </SimpleBarReact>
          )}
        </InfoWindow>
      )}
    </>
  );
};

ClusteredMarkers.propTypes = {
  assets: PropTypes.array,
  selectedAsset: PropTypes.object
};

export default ClusteredMarkers;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Card } from 'react-bootstrap';
import { Rate } from 'components/assets/Asset';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import useBreakpoints from 'hooks/useBreakpoints';

const Bottombar = ({ asset, inViewport }) => {
  const {
    config: { navbarCollapsed }
  } = useContext(AppContext);
  const { breakpoints } = useBreakpoints();
  return (
    <Card
      className={classNames('bottom-bar rounded-0 border-top d-lg-none', {
        hide: inViewport || navbarCollapsed,
        show: !(inViewport || navbarCollapsed),
        'pb-6': breakpoints.down('md')
      })}
    >
      <Card.Body className="py-2">
        <Flex className="gap-3 flex-between-center">
          <Rate {...asset} circleClass="w-100 h-100" />
          <Button variant="primary" size="lg" className="fs-0 flex-1">
            Solicitar informe
          </Button>
        </Flex>
      </Card.Body>
    </Card>
  );
};

Bottombar.propTypes = {
  asset: PropTypes.object,
  inViewport: PropTypes.bool
};

export default Bottombar;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Range, getTrackBackground } from 'react-range';
import useThumbOverlap from 'hooks/useThumbOverlap';
import { getCurrencyFormat } from 'helpers/utils';

const COLORS = ['#d8e2ef', '#5ac2e5', '#d8e2ef'];

const THUMB_SIZE = 18;

function ThumbLabel({ rangeRef, values, index, max }) {
  const [labelValue, labelStyle] = useThumbOverlap(
    rangeRef,
    values,
    index,
    1,
    ' - ',
    value =>
      `${getCurrencyFormat(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}${value === `${max}` ? ' +' : ''}`
  );
  return (
    <div
      data-label={index}
      className="card position-absolute bottom-100 mb-1 border px-2 py-1 fs--1 fw-bold text-nowrap"
      style={{
        ...labelStyle
      }}
    >
      {labelValue}
    </div>
  );
}

ThumbLabel.propTypes = {
  rangeRef: PropTypes.object,
  values: PropTypes.array,
  index: PropTypes.number,
  max: PropTypes.number
};

const LabeledMergeCustom = ({
  values: valuesProp = [0, 100],
  onChange = () => {},
  min = 0,
  max = 10,
  step = 1,
  rtl,
  showLabel = true,
  ...props
}) => {
  const [values, setValues] = React.useState(valuesProp);
  const rangeRef = React.useRef();

  const handleValuesChange = values => {
    setValues(values);
    onChange(values);
  };

  useEffect(() => {
    setValues(valuesProp);
  }, [valuesProp]);

  const Thumb = ({ props: thumbProps, index, isDragged }) => (
    <div
      {...thumbProps}
      className="card border"
      style={{
        ...thumbProps.style,
        height: `${THUMB_SIZE}px`,
        width: `${THUMB_SIZE}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {showLabel && (
        <ThumbLabel
          rangeRef={rangeRef.current}
          values={values}
          index={index}
          max={max}
        />
      )}
      <div
        className={classNames('w-50 h-50 rounded', {
          'bg-primary': isDragged,
          'bg-200': !isDragged
        })}
      />
    </div>
  );

  const Track = ({ props: trackProps, children }) => (
    <div
      onMouseDown={trackProps.onMouseDown}
      onTouchStart={trackProps.onTouchStart}
      style={{
        ...trackProps.style,
        height: '36px',
        display: 'flex',
        width: '100%'
      }}
    >
      <div
        ref={trackProps.ref}
        style={{
          height: '5px',
          width: '100%',
          borderRadius: '4px',
          background: getTrackBackground({
            values: values,
            colors: COLORS,
            min,
            max,
            rtl
          }),
          alignSelf: 'center'
        }}
      >
        {children}
      </div>
    </div>
  );
  Thumb.propTypes = {
    props: PropTypes.object,
    index: PropTypes.number,
    isDragged: PropTypes.bool
  };
  Track.propTypes = {
    props: PropTypes.object,
    children: PropTypes.node
  };
  return (
    <Range
      {...props}
      ref={rangeRef}
      values={values}
      onChange={handleValuesChange}
      renderThumb={Thumb}
      renderTrack={Track}
      step={step}
      min={min}
      max={max}
      rtl={rtl}
    />
  );
};

LabeledMergeCustom.propTypes = {
  values: PropTypes.array,
  setValues: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  rtl: PropTypes.bool,
  showLabel: PropTypes.bool,
  onChange: PropTypes.func
};

const RangeSlider = LabeledMergeCustom;

export default RangeSlider;

import React, { useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import FalconLink from 'components/common/FalconLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FavouritesContext } from 'context/Context';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = data => ({
  color: [
    getColor('primary'),
    getColor('info'),
    getColor('warning'),
    getColor('success')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: params =>
      `<strong>${params.data.title}:</strong> ${params.data.value}`
  },
  legend: {
    show: false
  },
  series: [
    {
      type: 'pie',
      radius: '70%',
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      center: ['50%', '50%'],
      data
    }
  ]
});

const onChartReadyCallback = chart => {
  chart?.resize();
};

const FavouritesColumns = () => {
  const {
    kanbanState: { kanbanItems = [] }
  } = useContext(FavouritesContext);
  const chart = useRef();

  const totalItems = kanbanItems.reduce(
    (sum, { items }) => sum + items.length,
    0
  );
  const favouritesData = kanbanItems.map(({ id, items = [], title }) => {
    const percentage = totalItems
      ? Math.round((items.length * 100) / totalItems, 10)
      : 0;
    const type = percentage > 0 ? 'success' : 'danger';
    return {
      id,
      title,
      color: 'primary',
      value: items.length,
      badge: {
        type,
        content: `${Math.abs(percentage)}%`
      }
    };
  });

  useEffect(() => {
    onChartReadyCallback(chart.current);
  }, []);

  return (
    <Card className="h-100 font-sans-serif">
      <FalconCardHeader
        light
        title="CRM de favoritos"
        titleTag="h6"
        className="py-2"
        endEl={
          <FalconLink
            title="Favoritos"
            className="px-0 fw-medium"
            to="/favourites"
          />
        }
      />
      <Card.Body className="p-0">
        <Flex direction="column" justifyContent="between">
          <ReactEChartsCore
            ref={chart}
            echarts={echarts}
            option={getOptions(favouritesData)}
            style={{ height: 300 }}
            onChartReady={onChartReadyCallback}
          />
          <SimpleBarReact className="mb-4 max-h-300px">
            <ul className="list-unstyled m-0">
              {favouritesData.map((item, index) => (
                <li
                  key={`Column-${item.id}-${index}`}
                  className={index % 2 === 0 ? 'bg-light' : ''}
                >
                  <Flex
                    wrap="wrap"
                    alignItems="center"
                    justifyContent="between"
                    className="gap-2 fs--2 p-x1"
                  >
                    <h6 className="mb-0">
                      <span className="fs-xxl--1 fs-lg--2">{item.title}</span>
                      <SoftBadge bg={item.badge.type} pill className="ms-2">
                        {item.badge.icon && (
                          <FontAwesomeIcon
                            icon={item.badge.icon}
                            className="me-1"
                          />
                        )}
                        {item.badge.content}
                      </SoftBadge>
                    </h6>
                    <p className="text-600 mb-0">{item.value} inmuebles</p>
                  </Flex>
                </li>
              ))}
            </ul>
          </SimpleBarReact>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default FavouritesColumns;

import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import { Link, useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Carousel,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner
} from 'react-bootstrap';
import Asset, { Rate } from 'components/assets/Asset';
import AssetMap from './AssetMap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import SoftBadge from 'components/common/SoftBadge';
import { sources } from 'data/assets/assetData';
import SearchBox from 'components/navbar/top/SearchBox';
import SourceLoading from 'components/navbar/top/SourceLoading';
import { AssetsContext, UserContext } from 'context/Context';
import { getColor, getCurrencyFormat } from 'helpers/utils';
import useBreakpoints from 'hooks/useBreakpoints';
import useVisibilityObserver from 'hooks/useVisibilityObserver';
import check from 'assets/img/animated-icons/check-primary-light.json';

const AreaInfo = ({
  area,
  assetsCount = 0,
  filteredAssetsCount = null,
  searchedText
}) => {
  const { breakpoints } = useBreakpoints();
  const { avgPurchaseM2, avgRentM2, avgPurchasePrice, avgRentPrice } =
    area || {};
  const areaIndex = (avgRentM2 * 12) / avgPurchaseM2;
  const areaRating = Math.max(
    Math.min(Math.round((areaIndex * 100 * 100) / 12), 100),
    0
  );
  const banner = useRef();
  const { isVisible: inViewport = true, observer } = useVisibilityObserver(
    banner,
    '100px'
  );
  useEffect(() => {
    return () => {
      observer && banner.current && observer.unobserve(banner.current);
    };
  }, [observer]);

  // console.log('[Assets:filteredAssetsCount] >>>', filteredAssetsCount);

  return (
    <>
      {breakpoints.up('xxl') && (
        <Alert
          show={!inViewport}
          variant="white"
          className="dark__bg-1100 rounded-0 border-0 border-bottom position-sticky top-0 transition-base z-index-1016"
        >
          <Row>
            <Col
              xs={12}
              md={true}
              as={Flex}
              alignContent="end"
              justifyContent="start"
            >
              <div className="mt-auto">
                {filteredAssetsCount !== null && (
                  <small>
                    Estas viendo {filteredAssetsCount} inmuebles con los filtros
                    seleccionados
                  </small>
                )}
                <h5 className="mb-0">
                  {assetsCount} inmuebles en {searchedText?.split(',').shift()}
                </h5>
              </div>
            </Col>
            <Col xs={12} md="auto" xl="auto" direction="column">
              <small>Precio medio de compra por m²</small>
              <h5 className="mb-0">
                {getCurrencyFormat(avgPurchaseM2, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </h5>
            </Col>
            <Col xs={12} md="auto" xl="auto" direction="column">
              <small>Precio medio de alquiler por m²</small>
              <h5 className="mb-0">
                {getCurrencyFormat(avgPurchasePrice, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </h5>
            </Col>
            <Col xs={12} md="auto" xl="auto" direction="column">
              <small>Valor medio de compra</small>
              <h5 className="mb-0">
                {getCurrencyFormat(avgPurchasePrice, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </h5>
            </Col>
            <Col xs={12} md="auto" xl="auto" direction="column">
              <small>Valor medio de alquiler</small>
              <h5 className="mb-0">
                {getCurrencyFormat(avgRentPrice, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </h5>
            </Col>
            <Col md="auto" as={Flex} alignItems="end">
              <Rate
                rating={areaRating}
                circleClass="w-20px h-20px"
                circleActiveProps={{ strokeWidth: 8 }}
                circleWrapperProps={{ strokeWidth: 8 }}
                showNumber={false}
              />
            </Col>
          </Row>
        </Alert>
      )}
      <div className="p-4 position-relative z-index-1016" ref={banner}>
        <Card className="bg-200 overflow-hidden shadow-none">
          <Card.Header className="position-relative">
            <Row className="position-relative">
              <Col md="auto" className="px-4 z-index-1">
                {filteredAssetsCount !== null && (
                  <p className="text-600 fs--1 text-truncate mb-1">
                    Estas viendo {filteredAssetsCount} inmuebles con los filtros
                    seleccionados
                  </p>
                )}
                <h3 className="mt-2 mb-1">
                  {assetsCount} inmuebles en {searchedText?.split(',').shift()}
                </h3>
                <Row>
                  <Col xs={12} md="auto" className="overflow-hidden pt-3">
                    <div>
                      <p className="text-600 fs--1 text-truncate mb-1">
                        Precio medio de compra por m²
                      </p>
                      <h4 className="text-800 mb-0">
                        {getCurrencyFormat(avgPurchaseM2, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </h4>
                    </div>
                    <div className="mt-2">
                      <p className="text-600 fs--1 text-truncate mb-1">
                        Precio medio de alquiler por m²
                      </p>
                      <h4 className="text-800 mb-0">
                        {getCurrencyFormat(avgRentM2, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </h4>
                    </div>
                  </Col>
                  <Col xs={12} md="auto" className="overflow-hidden pt-3">
                    <div>
                      <p className="text-600 fs--1 text-truncate mb-1">
                        Valor medio de compra
                      </p>
                      <h4 className="text-800 mb-0">
                        {getCurrencyFormat(avgPurchasePrice, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </h4>
                    </div>
                    <div className="mt-2">
                      <p className="text-600 fs--1 text-truncate mb-1">
                        Valor medio de alquiler
                      </p>
                      <h4 className="text-800 mb-0">
                        {getCurrencyFormat(avgRentPrice, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="p-0" />
              <Col md="auto" className="p-3">
                <Flex justifyContent="end" className="position-relative">
                  <Flex direction="column" alignItems="center">
                    <p className="position-absolute text-700 text-center fs--1 fw-semi-bold z-index-1 mt-5 pt-2 w-100 text-center">
                      Valoración del área
                    </p>
                    <Rate
                      className="justify-content-start"
                      rating={areaRating}
                      circleClass="w-150px h-150px overflow-visible"
                      circleActiveClass="opacity-25"
                      circleWrapperClass="opacity-25"
                      circleActiveProps={{
                        strokeWidth: 32,
                        r: 40,
                        style: { transform: 'rotate(30deg)' }
                      }}
                      circleWrapperProps={{
                        stroke: getColor('300'),
                        strokeWidth: 32,
                        r: 40
                      }}
                      showNumber={false}
                      showText={true}
                      textClass="fw-semi-bold"
                      textProps={{ style: { fontSize: '0.4em' }, y: 24 }}
                    />
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <ul className="mb-0 list-unstyled"></ul>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

AreaInfo.propTypes = {
  area: PropTypes.object,
  assetsCount: PropTypes.number,
  filteredAssetsCount: PropTypes.number,
  searchedText: PropTypes.string
};

const AddAsset = () => {
  const { createAssetFromUrl } = useContext(AssetsContext);
  const { setUpgradeModalContent, setUpgradeModalShow, subscription } =
    useContext(UserContext);
  const isFree = subscription === null;
  const input = useRef();
  const [isLoading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const creatingAssetsToast = useRef(null);
  const regex = new RegExp(`(${sources.map(({ value }) => value).join('|')})`);
  const [source] = url.match(regex) || [];

  const notifyCreation = () => {
    const { color, label, svg } =
      sources.find(({ value }) => value === source) || {};
    creatingAssetsToast.current = toast(
      () => (
        <Flex>
          Creando inmueble de {label}
          <Flex
            alignItems="center"
            justifyContent="center"
            className="position-relative"
          >
            <Avatar src={svg} size="s" className="p-1" />
            <Spinner
              className="position-absolute w-20px h-20px opacity-75"
              style={{ color }}
            />
          </Flex>
        </Flex>
      ),
      {
        type: toast.TYPE.INFO,
        autoClose: false
      }
    );
  };

  const handleInputChange = event => {
    const { value } = event.currentTarget;
    setUrl(value);
  };

  const handleAddAssetFromUrl = async () => {
    const url = input.current.value;
    if (isFree) {
      setUrl('');
      setUpgradeModalContent(
        <>
          Los inmuebles de portales se pueden añadir
          <br />a partir del <SoftBadge bg="400">Plan Estándar</SoftBadge>
        </>
      );
      setUpgradeModalShow(true);
      return;
    }

    setLoading(true);

    notifyCreation();

    const asset = await createAssetFromUrl(url);
    setUrl('');
    input.current.value = '';
    const [source, id] = asset?.id?.split('-') || [];
    setLoading(false);
    toast.dismiss(creatingAssetsToast.current);

    source &&
      id &&
      toast.success(
        <>
          <div>El inmueble se ha añadido con éxito</div>
          <Button
            className="mt-2"
            size="sm"
            variant="falcon-success"
            as={Link}
            to={`/assets/asset-details/${source}/${id}`}
          >
            Ver
          </Button>
        </>,
        {
          autoClose: false
        }
      );
  };

  return (
    <Dropdown
      className="d-flex justify-content-center w-100 font-sans-serif btn-reveal-trigger"
      drop="up-centered"
    >
      <Dropdown.Toggle
        variant="dark"
        className="position-fixed bottom-70px mb-md-n5 mb-0 shadow-lg z-index-1016"
      >
        Añadir inmueble
      </Dropdown.Toggle>
      <Dropdown.Menu className="min-w-300px shadow-lg position-fixed">
        <div className="px-3">
          <Dropdown.Header className="px-0">
            <Flex alignItems="center" className="gap-2">
              {sources
                .filter(({ value }) => value !== 'inversorpro')
                .map(({ label, svg, value }) => (
                  <Avatar
                    key={`Add-asset-source-${label}`}
                    src={svg}
                    name={label}
                    size="m"
                    className={classNames({
                      'desaturate opacity-50': value !== source,
                      'scale-120': value === source
                    })}
                  />
                ))}
            </Flex>
          </Dropdown.Header>
          <Flex direction="column" className="gap-2 mt-3">
            <Form.Control
              ref={input}
              placeholder="URL del inmueble"
              onChange={handleInputChange}
              value={url}
              autoFocus
            />
            <Button
              disabled={!source || isLoading}
              onClick={handleAddAssetFromUrl}
            >
              {isLoading ? <Loader /> : 'Añadir'}
            </Button>
            <div className="text-center">
              <Link to="/assets/asset-create">Añadir inmueble propio</Link>
            </div>
          </Flex>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const AssetsLoader = () => {
  const {
    areaId,
    areas,
    assetsState: { searchedText, sortBy },
    filteredSources
  } = useContext(AssetsContext);
  const area = areas?.find(({ id }) => id === areaId);
  const sortLabels = {
    price: 'precio',
    rating: 'puntuación',
    rooms: 'número de habitaciones',
    size: 'tamaño'
  };
  const [location] = searchedText?.split(',')?.[0]?.split('/') || [
    searchedText
  ];

  return (
    <Card className="bg-transparent shadow-none">
      <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
        {!area ? (
          <Carousel
            key="areaLoader"
            className="h-100px w-100 d-flex align-items-center justify-content-center"
            controls={false}
            indicators={false}
            interval={10000}
          >
            <Carousel.Item>
              <FontAwesomeIcon icon="globe-europe" className="fs-6 mb-3" />
              <h5 className="text-center m-0">Buscando área: {location}</h5>
            </Carousel.Item>
            <Carousel.Item>
              <FontAwesomeIcon icon="atlas" className="fs-6 mb-3" />
              <h5 className="text-center m-0">
                Extrayendo datos de {location}
              </h5>
            </Carousel.Item>
            <Carousel.Item>
              <FontAwesomeIcon icon="rocket" className="fs-6 mb-3" />
              <h5 className="text-center m-0">
                Analizando potencial de inversión en {location}
              </h5>
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel
            key="assetsLoader"
            className="h-100px w-100 d-flex align-items-center justify-content-center"
            controls={false}
            indicators={false}
            interval={10000}
          >
            <Carousel.Item>
              <Flex
                className="mb-2"
                alignItems="center"
                justifyContent="center"
              >
                {filteredSources.map(({ svg, value }) => {
                  return (
                    <Avatar
                      key={`assetsLoader-source-${value}`}
                      src={svg}
                      size="3xl"
                      className="p-1"
                    />
                  );
                })}
              </Flex>
              <h5 className="text-center m-0">
                Buscando inmuebles en los principales portales
              </h5>
            </Carousel.Item>
            <Carousel.Item>
              <FontAwesomeIcon
                icon="arrow-down-short-wide"
                className="fs-6 mb-3"
              />
              <h5 className="text-center m-0">
                Ordenando inmuebles por {sortLabels[sortBy]}
              </h5>
            </Carousel.Item>
          </Carousel>
        )}
      </Card.Body>
    </Card>
  );
};

const AssetsNextPageLoader = () => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <Flex justifyContent="center">
        <SourceLoading size="3xl" />
      </Flex>
      <Carousel
        className="h-100px w-100 d-flex align-items-center justify-content-center"
        controls={false}
        indicators={false}
        interval={10000}
      >
        <Carousel.Item>
          <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
            Cargando más inmuebles
          </h5>
        </Carousel.Item>
        <Carousel.Item>
          <h5 className="text-center m-0 d-flex align-items-center justify-content-center h-100px">
            Analizando inmueble
          </h5>
        </Carousel.Item>
      </Carousel>
      <div className="mt-3">
        <Loader />
      </div>
    </Flex>
  );
};

const Assets = () => {
  const { breakpoints } = useBreakpoints();
  const {
    areaId,
    areas,
    areasStatus,
    assetsCount,
    assetsState: {
      assets,
      filters,
      // order,
      primaryAssets,
      searchedText
      // sortBy
    },
    fetchMore,
    filteredSources,
    getPosition,
    hasFinished,
    isLoading,
    sourcesPage: sourcesPageProp
  } = useContext(AssetsContext);
  const sourcesPage =
    Object.entries(sourcesPageProp)
      .filter?.(
        ([key, value]) =>
          !filteredSources?.length ||
          (filteredSources.some(({ value }) => value === key) && [key, value])
      )
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) || {};
  const { subscription } = useContext(UserContext);
  const isFree = subscription === null;
  const targetElRef = useRef();
  const { search } = useLocation();
  const { isVisible: inViewport } = useVisibilityObserver(targetElRef, '100px');
  const area = areas?.find(({ id }) => id === areaId);
  // const areaAssets = primaryAssets
  //   .filter(asset => asset.areaId === areaId)
  //   .sort((a, b) => {
  //     if (a.sponsored !== b.sponsored) {
  //       return a.sponsored > b.sponsored ? -1 : 1;
  //     }
  //     if (order === 'asc') {
  //       return a[sortBy] < b[sortBy] ? -1 : 1;
  //     } else {
  //       return a[sortBy] > b[sortBy] ? -1 : 1;
  //     }
  //   });
  // const lastAssetPrice = areaAssets?.[areaAssets.length - 1]?.price || 0;
  // const maxPriceFilter =
  //   filters.find(({ name }) => name === 'price')?.value?.[1] || 0;
  // const stopFetching = sortBy === 'price' && lastAssetPrice > maxPriceFilter;

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      // if (stopFetching) {
      //   return;
      // }
      if (assetsCount && mounted && inViewport && !hasFinished) {
        fetchMore();
      }
    }, 100);
    return () => {
      mounted = false;
    };
  }, [inViewport, JSON.stringify(primaryAssets)]);

  useEffect(() => {
    if (areasStatus !== 'success' || assets.length || searchedText || search) {
      return;
    }
    getPosition();
  }, [areasStatus, assets.length, searchedText, search]);

  return (
    <Row className="m-0 p-0 g-0 h-0 flex-grow-1">
      <Col
        xl={9}
        xxl={8}
        className="overflow-hidden position-relative h-100 p-0"
        as={Flex}
        direction="column"
      >
        {isFree && (
          <Alert className="rounded-0 border-0 border-bottom m-0">
            <Flex alignItems="center" justifyContent="between">
              <div>Suscríbete y desbloquea todos los portales</div>
              <Button
                variant="falcon-warning"
                size="sm"
                as={Link}
                to="/pricing"
              >
                <Flex alignItems="center">
                  <FontAwesomeIcon icon="crown" className="me-2" />
                  <span className="fw-semi-bold">Hazte Pro</span>
                </Flex>
              </Button>
            </Flex>
          </Alert>
        )}
        <SimpleBarReact className="h-0 flex-grow-1 overflow-x-hidden">
          {searchedText && area && (
            <AreaInfo
              area={area}
              assetsCount={assetsCount}
              filteredAssetsCount={filters?.length ? assets?.length : null}
              searchedText={searchedText}
            />
          )}
          <Row className="px-4 pt-4 mb-3 gx-5 gy-4">
            {!isFree && !searchedText ? (
              <Card className="bg-transparent shadow-none min-h-500px mb-6">
                <Card.Body className="text-center">
                  <div className="fs--1">
                    <FontAwesomeIcon icon="search" className="fs-6 mb-3" />
                    <h5>Busca inmuebles por localidad</h5>
                    <Flex
                      className="mt-4"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <SearchBox
                        at="assets"
                        dropdownProps={{ flip: false }}
                        size="xl"
                      />
                    </Flex>
                  </div>
                </Card.Body>
              </Card>
            ) : !!assetsCount || isFree ? (
              <>
                {assets.map(asset => (
                  <Col
                    key={`Asset-${asset.id}`}
                    xs={12}
                    sm={6}
                    lg={4}
                    xl={3}
                    className="asset-wrapper"
                  >
                    <Asset asset={asset} />
                  </Col>
                ))}
                {!!assetsCount && isFree && (
                  <div className="p-3">
                    <Alert>
                      <Flex alignItems="center" justifyContent="between">
                        <div>Suscríbete y amplía tu búsqueda</div>
                        <Button
                          variant="falcon-warning"
                          size="sm"
                          as={Link}
                          to="/pricing"
                        >
                          <Flex alignItems="center">
                            <FontAwesomeIcon icon="crown" className="me-2" />
                            <span className="fw-semi-bold">Hazte Pro</span>
                          </Flex>
                        </Button>
                      </Flex>
                    </Alert>
                    <Lottie
                      animationData={check}
                      className="m-auto w-180px mb-5"
                      loop={false}
                    />
                  </div>
                )}
              </>
            ) : !isLoading ? (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5>No se han encontrado inmuebles</h5>
                    <p className="mb-0">
                      La búsqueda no ha tenido ningún resultado. Inténtalo de
                      nuevo.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <AssetsLoader />
            )}
          </Row>
          {!isFree && (
            <div
              ref={targetElRef}
              className={classNames('text-center p-4 mb-6', {
                'd-none': !searchedText
              })}
            >
              {/* {!stopFetching && */}
              {(!Object.values(sourcesPage).length &&
                !filteredSources?.length) ||
              Object.values(sourcesPage).length <
                filteredSources.filter(({ value }) => value !== 'inversorpro')
                  ?.length ||
              Object.values(sourcesPage).some(page => page !== null) ? (
                hasFinished ? (
                  <AssetsNextPageLoader />
                ) : (
                  <div>
                    <Loader />
                  </div>
                )
              ) : (
                <Lottie
                  animationData={check}
                  className="m-auto w-180px"
                  loop={false}
                />
              )}
            </div>
          )}
        </SimpleBarReact>
        <Flex justifyContent="center">
          <AddAsset />
        </Flex>
      </Col>
      {breakpoints.up('xl') && (
        <Col xl={3} xxl={4}>
          <div className="h-100 border-start">
            <AssetMap />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Assets;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import MultiSelect from 'components/common/MultiSelect';
import { UserContext } from 'context/Context';

const options = [
  'Pisos',
  'Casas',
  '1 habitación',
  '2 habitaciones',
  '3 habitaciones',
  '4 habitaciones',
  '5 o más habitaciones',
  '1 baño',
  '2 baños',
  '3 o más baños',
  'Más de 20.000€',
  'Más de 50.000€',
  'Más de 100.000€',
  'Más de 150.000€',
  'Más de 200.000€',
  'Menos de 20.000€',
  'Menos de 50.000€',
  'Menos de 100.000€',
  'Menos de 150.000€',
  'Menos de 200.000€',
  'Con ascensor',
  'Fachada exterior',
  'Armarios empotrados',
  'Aire acondicionado',
  'Terraza',
  'Balcón',
  'Trastero',
  'Piscina',
  'Zona verde'
];

const AssetPreferences = () => {
  const { me, updateData } = useContext(UserContext);
  const { data = {} } = me || {};
  const { assetFeatures = [] } = data || {};
  const features = assetFeatures?.map(label => ({ label, value: label }));

  const handleChange = value => {
    const assetFeatures = value.map(({ value }) => value);
    updateData({ assetFeatures });
  };

  return (
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6>Características de inmuebles deseadas</h6>
      </Card.Header>
      <Card.Body className="pt-2">
        <MultiSelect
          options={options?.map(label => ({ label, value: label }))}
          onChange={handleChange}
          placeholder="Añade características deseadas"
          value={features || []}
        />
      </Card.Body>
    </Card>
  );
};

AssetPreferences.propTypes = {
  asset: PropTypes.object,
  setValue: PropTypes.func
};

export default AssetPreferences;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { getColor } from 'helpers/utils';
import useBreakpoints from 'hooks/useBreakpoints';
import { ReactComponent as LogoColor } from 'assets/img/logos/logo.svg';
import { ReactComponent as LogoWhite } from 'assets/img/logos/logo_white.svg';
import { ReactComponent as LogoMin } from 'assets/img/logos/logo_min.svg';

const Logo = ({
  at,
  width,
  height,
  className,
  component: Component,
  isDark: isDarkProp,
  to = '/',
  ...rest
}) => {
  const { breakpoints } = useBreakpoints();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const primaryColor = getColor('primary');

  const Logo = Component
    ? Component
    : breakpoints.down('md') && at !== 'auth'
    ? LogoMin
    : (typeof isDarkProp !== 'undefined' ? isDarkProp : isDark)
    ? LogoWhite
    : LogoColor;

  const Wrapper = to ? Link : 'div';

  return (
    <Wrapper
      to={to}
      className={classNames(
        'text-decoration-none z-index-2',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <Logo
          fill={primaryColor}
          className="me-2"
          alt="Logo"
          height={height}
          width={width}
        />
      </div>
    </Wrapper>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  component: PropTypes.node,
  isDark: PropTypes.bool,
  textClass: PropTypes.string,
  to: PropTypes.string
};

Logo.defaultProps = { at: 'auth', height: 30 };

export default Logo;

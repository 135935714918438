import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import Fuse from 'fuse.js';
import SimpleBarReact from 'simplebar-react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';
import useAssets from 'hooks/useAssets';
import useBreakpoints from 'hooks/useBreakpoints';
// import comunities from 'data/ccaa.json';
import localities from 'data/poblaciones.json';
import regions from 'data/provincias.json';

const AREAS = [
  // ...comunities.map(comunity => ({
  //   ...comunity,
  //   icon: 'circle',
  //   iconClass: 'fs--2',
  //   type: 'comunity',
  //   typeLabel: 'Comunidad'
  // })),
  // ...regions.map(region => ({
  //   ...region,
  //   icon: ['far', 'circle'],
  //   iconClass: 'fs--2',
  //   parentLabel: comunities.find(({ code }) => code === region.parent_code)
  //     ?.label,
  //   type: 'region',
  //   typeLabel: 'Provincia'
  // })),
  ...localities.map(locality => ({
    ...locality,
    _label: locality.label.normalize('NFD').replace(/[\u0300-\u036F]/g, ''),
    icon: 'map-marker-alt',
    parentLabel: regions.find(({ code }) => code === locality.parent_code)
      ?.label,
    type: 'locality',
    typeLabel: 'Localidad'
  }))
];

const fuse = new Fuse(AREAS || [], {
  includeMatches: true,
  threshold: 0.0,
  // ignoreLocation: true,
  keys: ['_label']
});

const SearchBox = ({ at, dropdownProps, size = 'sm' }) => {
  const user = JSON.parse(Cookies.get('__fbu') || '{}');
  const isSignedIn = !!user.uid;
  const {
    createSearch,
    setUpgradeModalContent,
    setUpgradeModalShow,
    subscription
  } = useContext(UserContext);
  const navigate = useNavigate();
  const { handleSearch } = useAssets();
  const { breakpoints } = useBreakpoints();
  const [searchInputValue = '', setSearchInputValue] = useState();
  const [results, setResults] = useState([]);
  const inputEl = useRef(null);
  const searchRef = useRef(null);
  const { q: query } = useParams();
  const notAvailableEvents = {};
  const isAvailable = !!subscription;

  if (!isAvailable && window.top === window.self) {
    notAvailableEvents.onFocus = event => {
      setUpgradeModalContent(
        <>
          La búsqueda de inmuebles por zona
          <br />
          está disponible a partir del{' '}
          <SoftBadge bg="400">Plan Estándar</SoftBadge>
        </>
      );
      setUpgradeModalShow(true);
      event.target.blur();
    };
  }

  const handleSubmit = useCallback(
    (event, value) => {
      event?.preventDefault();
      const [{ item: { label = '', parentLabel = '' } = {} } = {}] =
        results || [];
      value = !event ? value : label ? `${label}, ${parentLabel}` : '';
      setSearchInputValue(value);
      if (!isSignedIn) {
        if (value) {
          window.self !== window.top
            ? (window.top.location = `${process.env.REACT_APP_FRONT_URL}/authentication/login?q=${value}`)
            : navigate(`/authentication/login?q=${value}`);
        }
        return;
      }
      handleSearch(value);
      createSearch(value);
      inputEl.current?.blur();

      if (at !== 'assets') {
        window.self !== window.top
          ? (window.top.location = `${process.env.REACT_APP_FRONT_URL}/assets?q=${value}`)
          : navigate(`/assets?q=${value}`);
      } else {
        window.self !== window.top
          ? (window.top.location = `${process.env.REACT_APP_FRONT_URL}/assets`)
          : navigate('/assets');
      }
    },
    [results, searchInputValue]
  );

  const handleClear = useCallback(event => {
    event.stopPropagation();
    handleSubmit(null, '');
    setResults([]);
  }, []);

  const handleChange = useCallback(
    value => {
      setSearchInputValue(value);
      searchRef.current && clearTimeout(searchRef.current);
      if (value?.length < 2) {
        searchRef.current = null;
        setResults([]);
        return;
      }
      searchRef.current = setTimeout(() => {
        setResults(
          fuse?.search?.(
            value.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
          ) || []
        );
        searchRef.current = null;
      }, 200);
    },
    [fuse]
  );

  const handleItemClick = useCallback(
    value => {
      setSearchInputValue(value);
      const result = fuse?.search(
        value.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
      );
      setResults(result);
      handleSubmit(null, value);
    },
    [fuse]
  );

  const handleSelect = (value, event) => {
    event?.preventDefault();
    event?.stopPropagation();
  };

  useEffect(() => {
    if (!query || query !== searchInputValue) {
      return;
    }
    handleItemClick(query);
  }, [query]);

  return (
    <Dropdown
      className={classNames('search-box', { 'w-100': breakpoints.down('md') })}
      onSelect={handleSelect}
    >
      <Dropdown.Toggle as="div" data-toggle="dropdown" bsPrefix="toggle">
        <Form className="position-relative" onSubmit={handleSubmit}>
          <Form.Control
            ref={inputEl}
            type="search"
            placeholder="Buscar..."
            aria-label="Search"
            className="bg-200 text-900 rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => handleChange(target.value)}
            size={size}
            {...notAvailableEvents}
          />
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {searchInputValue && (
            <div className="search-box-close-btn-container">
              <FalconCloseButton size="sm" noOutline onClick={handleClear} />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      <Dropdown.Menu className="m-0" {...(dropdownProps || {})}>
        <SimpleBarReact className="py-3" style={{ maxHeight: '24rem' }}>
          {!results?.length &&
            (searchInputValue ? (
              <Dropdown.Header className="text-center">
                <FontAwesomeIcon icon="ban" className="me-1 fs--1" />
                Sin resultados
              </Dropdown.Header>
            ) : (
              <Dropdown.Header className="text-center">
                <FontAwesomeIcon icon="map-marker-alt" className="me-1 fs--1" />
                Busca por zona
              </Dropdown.Header>
            ))}
          {results?.map(
            (
              {
                item: {
                  code,
                  icon,
                  iconClass = '',
                  label,
                  // name,
                  parentLabel = '',
                  parent_code: parentCode = '0',
                  type,
                  typeLabel
                },
                matches
              },
              index
            ) => {
              // const areaArr = name.split(', ');
              // const [label1, label2, label3] = areaArr;
              // const typeLabel =
              //   areaArr.length === 3
              //     ? 'Barrio'
              //     : areaArr.length === 2
              //     ? 'Localidad'
              //     : 'Provincia';
              // const icon =
              //   areaArr.length === 3
              //     ? 'map-marker-alt'
              //     : areaArr.length === 2
              //     ? 'map-marker-alt'
              //     : ['far', 'circle'];
              const name = `${label}, ${parentLabel}`;
              const { indices } = matches[0];
              return (
                <Dropdown.Item
                  as={Button}
                  key={`${type}-${parentCode}-${code}-${label}`}
                  // key={`${label}`}
                  className={classNames(
                    'fs--1 px-x1 py-1 hover-primary shadow-none',
                    {
                      'border-top': index > 0
                    }
                  )}
                  onClick={event => handleItemClick(name, event)}
                >
                  {label.substring(0, indices[0][0])}
                  <span className="fw-bold">
                    {label.substring(indices[0][0], indices[0][1] + 1)}
                  </span>
                  {label.substring(indices[0][1] + 1)}
                  {parentLabel && (
                    <span className="text-500">, {parentLabel}</span>
                  )}
                  {/* {label3 && <span className="text-500">, {label3}</span>} */}
                  <Flex alignItems="center" className="text-400 p-1">
                    <FontAwesomeIcon icon={icon} className={iconClass} />
                    <span className="text-uppercase fs--2 ms-1">
                      {typeLabel}
                    </span>
                  </Flex>
                </Dropdown.Item>
              );
            }
          )}
        </SimpleBarReact>
      </Dropdown.Menu>
    </Dropdown>
  );
};

SearchBox.propTypes = {
  at: PropTypes.string,
  dropdownProps: PropTypes.object,
  size: PropTypes.string
};

export default SearchBox;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import { AssetsContext, UserContext } from 'context/Context';
import AssetGallery from './AssetGallery';

const pre = 'Mira lo que he visto en Inversor.Pro';
const share = what => {
  const link = document.createElement('a');
  link.href = what;
  link.target = '_blank';
  link.click();
};

const socialShares = [
  {
    label: 'WhatsApp',
    icon: 'whatsapp',
    color: 'whatsapp',
    onClick: id => {
      const [source, numId] = id?.split('-') || [];
      const link = `http://inversor.pro/a/${source}/${numId}?from=detail-whatsapp`;
      const what = `https://api.whatsapp.com/send?text=${pre} ${link}`;
      share(what);
    }
  },
  {
    icon: 'facebook-f',
    color: 'primary'
  },
  {
    icon: 'twitter',
    color: 'twitter'
  },
  {
    icon: 'google-plus-g',
    color: 'google-plus'
  },
  {
    icon: 'linkedin-in',
    color: 'info'
  }
];

const AssetBanner = ({ asset }) => {
  const { me, toggleFavorite } = useContext(UserContext);
  const { createAssetReport } = useContext(AssetsContext);
  const [creatingReport, setCreatingReport] = useState(false);
  const [showExcerpt, setShowExcerpt] = useState(false);
  const { pathname, search } = useLocation();
  const { source, assetId } = useParams();
  const [, from] = search.match(/from=(.*)/) || [];
  const { createdBy, id, status } = asset || {};
  const { favourites = [] } = me || {};
  const isFavourite = favourites.some(({ assetId }) => assetId === id);
  let to = from ? `/${from}` : `/${pathname?.split('/')?.[1] || ''}`;
  to = `${to}#${id}`;
  const backButton = to !== pathname;

  const handlePdfClick = async asset => {
    setCreatingReport(true);
    await createAssetReport(asset);
    setCreatingReport(false);
  };

  const handleFavClick = asset => {
    toggleFavorite(asset);
  };

  return (
    <>
      <AssetGallery {...(asset || {})} />
      <Row className="mt-3">
        <Col xl={8} className="position-relative">
          <Flex alignItems="center" className="gap-2 mb-3">
            {backButton && (
              <IconButton
                variant="falcon-default"
                icon="arrow-left"
                size="sm"
                to={to}
                as={Link}
              >
                Volver
              </IconButton>
            )}
            {status === 'archived' && createdBy !== me?.uid && (
              <SoftBadge bg="warning" className="fs-0">
                Archivado
              </SoftBadge>
            )}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="calculator"
              as={Link}
              to={`/assets/rentability-calculator/${source}/${assetId}${search}`}
            >
              Calculadora
            </IconButton>
            <div className="flex-grow-1" />
            <Tooltip title="Generar informe">
              <Button
                variant="falcon-default"
                className="w-32px h-32px p-0 d-flex align-items-center justify-content-center"
                disabled={creatingReport}
                onClick={() => handlePdfClick(asset)}
              >
                {creatingReport ? (
                  <Spinner
                    variant="primary"
                    className="border-2 w-16px h-16px"
                  />
                ) : (
                  <FontAwesomeIcon icon={['far', 'file-pdf']} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Añadir a favoritos">
              <Button
                variant="falcon-default"
                className="w-32px h-32px p-0 d-flex align-items-center justify-content-center"
                onClick={() => handleFavClick(asset)}
              >
                <FontAwesomeIcon
                  icon={isFavourite ? 'heart' : ['far', 'heart']}
                  className={classNames({
                    'text-danger': isFavourite
                  })}
                />
              </Button>
            </Tooltip>
            <Flex className="gap-2">
              {socialShares.map(
                ({ icon, color, label, onClick }) =>
                  onClick && (
                    <Tooltip
                      key={`${id}-${icon}`}
                      title={`Compartir por ${label}`}
                    >
                      <Button
                        variant="falcon-default"
                        className="w-32px h-32px p-0 d-flex align-items-center justify-content-center"
                        onClick={() => onClick?.(id)}
                      >
                        <FontAwesomeIcon
                          icon={['fab', `${icon}`]}
                          className={`mr-1 text-${color}`}
                        />
                      </Button>
                    </Tooltip>
                  )
              )}
            </Flex>
          </Flex>
          <h2 className="fw-bold">{asset.name}</h2>
          <p className="mb-0 fw-medium text-pre-wrap">
            {showExcerpt
              ? asset?.description
              : `${asset?.description?.substring(0, 152)}...`}
            <Button
              variant="link"
              size="sm"
              className="text-info p-0"
              onClick={() => setShowExcerpt(!showExcerpt)}
            >
              Leer {showExcerpt ? 'menos' : 'más'}
            </Button>
          </p>
        </Col>
      </Row>
    </>
  );
};

AssetBanner.propTypes = {
  asset: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
    rating: PropTypes.number,
    review: PropTypes.number,
    rooms: PropTypes.number,
    size: PropTypes.number,
    source: PropTypes.string,
    textContent: PropTypes.string
  })
};

export default AssetBanner;

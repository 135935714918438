import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import image from 'assets/img/icons/spot-illustrations/navbar-vertical.png';

const NotAvailable = ({ children, className, onButtonClick }) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classNames('w-100 h-100 p-3 text-center', className)}
    >
      <img src={image} alt="upgrade" width={200} style={{}} />
      <div className="p-4">{children}</div>
      <Button as={Link} onClick={onButtonClick} to="/subscription">
        Ver mi suscripción
      </Button>
    </Flex>
  );
};

NotAvailable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onButtonClick: PropTypes.func
};

export default NotAvailable;

import React, { useRef } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';

const PrivacyPolicy = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  return (
    <>
      <PageHeader
        title="Política de privacidad"
        description="Política de privacidad de protección de datos personales"
        className="mb-3"
      />
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2">
          <Card className="mb-3">
            <Card.Body>
              <h6 id="intro" className="text-primary" ref={sectionRefs[0]}>
                Introducción
              </h6>
              <p>
                Inversor.Pro (en adelante, "Inversorpro") desea brindarle
                algunos detalles sobre cómo tratamos los datos personales que
                nos proporciona cuando interactúa con nosotros. Así, esta
                política de privacidad se aplica a todas las personas que nos
                proporcionan o nos han proporcionado datos personales por
                cualquier medio, concretamente cuando vea una referencia a esta
                política, incluso en un formulario, sitio web, aplicación móvil,
                etc.
              </p>
              <p>
                Hemos llamado a este documento "Cómo tratamos sus datos" porque
                es importante para nosotros que esta información sea lo más
                accesible posible para usted, pero en caso de que prefiera los
                nombres más tradicionales, este documento es la Política de
                Privacidad de nuestra empresa.
              </p>
              <p>
                Los datos que decidas facilitarnos (en el pasado o en el futuro)
                a través de cualquiera de los medios dispuestos, los estás
                facilitando libremente, y aceptas que estos datos personales
                serán objeto del tratamiento correspondiente en cada caso en
                función del consentimiento y la finalidad para la que nos lo ha
                proporcionado.
              </p>
              <p>
                De acuerdo con lo dispuesto en el Reglamento (UE) 2016/679, de
                27 de abril, Reglamento General de Protección de Datos (en
                adelante, "RGPD"), debemos facilitarle información relativa al
                tratamiento de sus datos que vamos a realizar para tal fin. que
                dispone de toda la información necesaria para darnos su
                consentimiento válido. Por ello es imprescindible que lea
                atentamente la información que se detalla a continuación.
              </p>
              <hr className="my-4" />
              <h6 id="forwhat" className="text-primary" ref={sectionRefs[1]}>
                ¿Para qué trataremos tus datos?
              </h6>
              <p>
                Utilizamos los datos personales que nos facilitas en los
                diferentes formularios habilitados al efecto para gestionar los
                productos y servicios que ofrecemos en Inversorpro. Estos datos
                nos permiten mantener la relación con los usuarios, así como la
                gestión, administración, prestación y mejora de nuestros
                servicios, y sin ellos no podríamos prestar servicios. Por lo
                que, si no deseas que procesemos tus datos, no podrás utilizar
                nuestros servicios.
              </p>
              <p>
                Si existe una relación contractual, también le enviaremos
                información sobre nuestros productos y servicios. Si no desea
                recibir información sobre nuestros servicios o en algún momento
                desea dejar de recibirla, por favor háganoslo saber enviándonos
                un correo electrónico a la siguiente dirección
                info@inversor.pro, o por correo postal al domicilio registrado
                de Inversorpro. oficina como se indicó anteriormente.
              </p>
              <p>
                Para poder ofrecerte productos y servicios acordes a tus
                intereses, mejorar tu experiencia de usuario, etc., crearemos un
                “perfil de usuario” en base a la información que nos facilitas.
                En base a esta información, podemos tomar decisiones
                automatizadas para mejorar el servicio que ofrecemos, darle
                recomendaciones de compras o enviarle información comercial
                relacionada con sus intereses. Siempre podrás oponerte a dicho
                tratamiento automatizado en el apartado correspondiente de tu
                perfil de usuario.
              </p>
              <hr className="my-4" />
              <h6 id="why" className="text-primary" ref={sectionRefs[2]}>
                ¿Por qué utilizamos tus datos personales?
              </h6>
              <p>
                La base legal para el tratamiento de tus datos dependerá del rol
                que desempeñes y de la forma en que interactúes con nosotros, ya
                seas cliente, usuario de nuestros servicios, persona que presta
                servicios a un proveedor o cliente, etc. En cualquiera de estos
                casos:
              </p>
              <ul>
                <li>
                  Necesitamos procesar su información personal para poder
                  brindarle el servicio, para los fines descritos anteriormente.
                </li>
                <li>
                  Cuando nos haya dado su consentimiento para utilizar sus datos
                  de una manera particular marcando explícitamente una casilla
                  de consentimiento al completar un formulario.
                </li>
                <li>
                  Por intereses legítimos, para la gestión de la empresa y de
                  nuestros servicios, siempre que estos intereses no sean
                  anulados por sus derechos. En este sentido, Inversorpro
                  persigue varios intereses legítimos al utilizar sus datos
                  personales:
                </li>
                <li>Mejorar y personalizar nuestro servicio.</li>
                <li>Para garantizar la seguridad de nuestro servicio.</li>
                <li>Para garantizar la protección de nuestros usuarios.</li>
                <li>
                  Desarrollar nuevas funcionalidades y servicios para nuestros
                  usuarios.
                </li>
              </ul>
              <hr className="my-4" />
              <h6 id="who" className="text-primary" ref={sectionRefs[3]}>
                ¿A quién cedemos tus datos?
              </h6>
              <p>
                No revelamos sus datos personales a nadie. Sólo revelaremos tus
                datos personales si estamos obligados a hacerlo por ley o si
                previamente nos has dado tu permiso expreso para hacerlo.
              </p>
              <p>
                Para poder prestar nuestros servicios, necesitamos trabajar con
                empresas que procesan sus datos en nuestro nombre (proveedores
                de hosting, proveedores de correo electrónico, etc.), como parte
                de la prestación de un servicio que tenemos contratado con
                ellos, con quienes firmamos los correspondientes contratos de
                encargados del tratamiento y exigirles el cumplimiento de la
                normativa en materia de datos personales.
              </p>
              <hr className="my-4" />
              <h6 id="time" className="text-primary" ref={sectionRefs[4]}>
                ¿Cuánto tiempo conservaremos tus datos?
              </h6>
              <p>
                Para cada operación de tratamiento para la que nos haya dado su
                consentimiento, conservaremos los datos durante el tiempo
                necesario para garantizar la prestación del servicio y cumplir
                con nuestras obligaciones legales.
              </p>
              <p>
                Los datos personales proporcionados se conservarán mientras
                usted continúe manteniendo una relación con nosotros y continúe
                suscribiéndose a nuestro sitio web; hasta que solicites darte de
                baja y eliminar los datos; por un período de cuatro (4) años a
                partir de la última confirmación de interés; etc.).
              </p>
              <p>
                Podrá solicitar la cancelación de la Newsletter en cualquier
                momento que lo considere oportuno.
              </p>
              <p>
                Una vez que se haya dado de baja del boletín, bloquearemos sus
                datos y los conservaremos solo si así lo exige la ley aplicable.
              </p>
              <p>
                Puede solicitar la cancelación de su cuenta en cualquier
                momento.
              </p>
              <p>
                Una vez que su cuenta haya sido cancelada, bloquearemos sus
                datos y los conservaremos solo si así lo exige la ley aplicable.
              </p>
              <hr className="my-4" />
              <h6 id="how" className="text-primary" ref={sectionRefs[5]}>
                ¿Cómo almacenamos tus datos?
              </h6>
              <p>
                En cumplimiento de nuestra obligación de confidencialidad
                respecto de los datos personales recabados, así como de nuestro
                deber de guardarlos, nos comprometemos a adoptar las medidas de
                seguridad necesarias para evitar su modificación, alteración,
                pérdida, tratamiento o acceso no autorizado, de acuerdo con lo
                dispuesto en el RGPD.
              </p>
              <p>
                En Inversorpro reconocemos y tomamos muy en serio nuestra
                responsabilidad de proteger sus datos personales. Es por ello
                que mantenemos los niveles de seguridad de protección de datos
                personales de acuerdo con el RGPD y la Ley Orgánica 3/2018, de 5
                de diciembre, de Protección de Datos Personales y garantía de
                los derechos digitales (en adelante, la "LOPDGDD" para su siglas
                en español), y hemos establecido todos los medios técnicos a
                nuestro alcance para evitar la pérdida, mal uso, alteración,
                acceso no autorizado y robo de los datos que nos haya
                facilitado, sin perjuicio de informarle que se aplican medidas
                de seguridad en Internet. No es 100% infalible.
              </p>
              <p>
                La seguridad de tu información también depende de ti. Usted es
                responsable de mantener la confidencialidad de la contraseña que
                utiliza para acceder al Servicio. Es importante que no compartas
                tu contraseña con nadie.
              </p>
              <hr className="my-4" />
              <h6 id="control" className="text-primary" ref={sectionRefs[6]}>
                ¿Cómo puedes controlar el tratamiento de tus datos?
              </h6>
              <p>
                Una vez más, le recordamos que podrá ejercitar sus derechos de
                acceso, rectificación, supresión y oposición, así como los de
                limitación y portabilidad, en cualquiera de las direcciones que
                le hemos indicado al principio o en la siguiente dirección de
                correo electrónico info@inversor.pro, adjuntando fotocopia de su
                DNI e indicando qué actuación nos solicita.
              </p>
              <p>
                El ejercicio de todos estos derechos es gratuito y, en aras de
                la transparencia, le facilitamos más información sobre estos
                derechos:
              </p>
              <hr className="my-4" />

              <h6 id="access" className="text-primary" ref={sectionRefs[7]}>
                Acceso, Rectificación y Supresión
              </h6>

              <p>
                Podrás consultar tus datos personales accediendo a tu cuenta de
                usuario en el apartado de datos personales o a través de
                cualquiera de los medios de contacto facilitados.
              </p>

              <hr className="my-4" />
              <h6 id="oposition" className="text-primary" ref={sectionRefs[8]}>
                Oposición
              </h6>

              <p>
                En determinadas circunstancias y por motivos relacionados con su
                situación particular, podrá oponerse al tratamiento de sus
                datos. Inversorpro dejará de tratar los datos, salvo por motivos
                legítimos imperiosos, para el ejercicio o la defensa de posibles
                reclamaciones, o para el cumplimiento de nuestras obligaciones
                legales.
              </p>
              <p>Limitación de procesamiento</p>
              <p>
                En determinadas circunstancias podrá solicitar que limitemos el
                tratamiento de sus datos, en cuyo caso únicamente los
                conservaremos para cumplir con nuestras obligaciones legales.
              </p>

              <hr className="my-4" />
              <h6
                id="portability"
                className="text-primary"
                ref={sectionRefs[9]}
              >
                Portabilidad
              </h6>

              <p>
                En caso de solicitud, podrá recibir, en formato electrónico, los
                datos personales que nos haya proporcionado.
              </p>

              <hr className="my-4" />
              <h6
                id="retirement"
                className="text-primary"
                ref={sectionRefs[10]}
              >
                Retiro del consentimiento
              </h6>

              <p>
                Podrá retirar su consentimiento en cualquier momento sin que
                ello afecte a la licitud del tratamiento. Si solicita la
                eliminación de sus datos, dejaremos de procesar sus datos, pero
                tenga en cuenta que ya no podremos brindarle servicios y que, en
                ciertos casos en los que tengamos la base legal pertinente,
                continuaremos procesando su información. después de haber
                retirado su consentimiento y solicitado la eliminación de su
                información.
              </p>
              <p>
                También le informamos que tiene derecho a presentar una
                reclamación ante la Agencia Española de Protección de Datos
                www.aepd.es. En cualquier caso, debido a nuestro fuerte
                compromiso con la privacidad, le invitamos a contactarnos
                directamente para resolver cualquier cuestión relacionada con
                sus datos personales; estaremos encantados de poder ayudarle.
              </p>

              <hr className="my-4" />
              <h6 id="modify" className="text-primary" ref={sectionRefs[11]}>
                Modificación de esta política de datos personales
              </h6>

              <p>
                Inversorpro se reserva el derecho de modificar la presente
                política para adaptarla a futuras novedades legislativas o
                jurisprudenciales, así como a prácticas de la industria,
                informando previamente a los usuarios a través de nuestro sitio
                web. Le recomendamos que consulte esta página de vez en cuando
                para revisar la versión más reciente. En la parte superior de
                este documento indicamos la fecha de la última actualización.
              </p>
              <p>
                El nuevo aviso de privacidad entrará en vigor al momento de su
                publicación. Si no está de acuerdo con el aviso revisado, puede
                ejercer cualquiera de sus derechos o dejar de utilizar nuestro
                servicio. Si continúa accediendo o utilizando nuestros servicios
                después de que dichos cambios entren en vigor, acepta estar
                sujeto a la nueva política de privacidad.
              </p>
              <p>
                En ningún caso dichos cambios afectarán las decisiones que haya
                tomado sobre cómo Inversorpro puede tratar su información
                personal. Si en algún momento deseamos utilizar su información
                de una manera diferente a la indicada en el momento de la
                recopilación, se lo notificaremos por correo electrónico,
                solicitando su consentimiento.
              </p>
              <p>
                Inversorpro también se reserva el derecho de dar por terminada
                la prestación de servicios que presta a aquellos usuarios que
                incumplan los términos y condiciones legales de uso, así como la
                legislación vigente.
              </p>
              <p>
                Inversorpro SL. Hemos ejecutado un acuerdo de protocolo de datos
                dentro de la empresa que garantiza que todos los datos de
                Inversorpro se traten y gestionen de conformidad con el RGPD.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="ps-lg-2">
          <div className="sticky-sidebar top-110px">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">Índice</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  <Nav.Item>
                    <Nav.Link
                      href="#intro"
                      className="nav-link px-0 py-1"
                      active={activeSection === 0}
                    >
                      Introducción
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#forwhat"
                      className="nav-link px-0 py-1"
                      active={activeSection === 1}
                    >
                      ¿Para qué trataremos tus datos?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#why"
                      className="nav-link px-0 py-1"
                      active={activeSection === 2}
                    >
                      ¿Por qué utilizamos tus datos personales?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#who"
                      className="nav-link px-0 py-1"
                      active={activeSection === 3}
                    >
                      ¿A quién cedemos tus datos?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#time"
                      className="nav-link px-0 py-1"
                      active={activeSection === 4}
                    >
                      ¿Cuánto tiempo conservaremos tus datos?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#how"
                      className="nav-link px-0 py-1"
                      active={activeSection === 5}
                    >
                      ¿Cómo almacenamos tus datos?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#control"
                      className="nav-link px-0 py-1"
                      active={activeSection === 6}
                    >
                      ¿Cómo puedes controlar el tratamiento de tus datos?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#access"
                      className="nav-link px-0 py-1"
                      active={activeSection === 7}
                    >
                      Acceso, Rectificación y Supresión
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#oposition"
                      className="nav-link px-0 py-1"
                      active={activeSection === 8}
                    >
                      Oposición
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#portability"
                      className="nav-link px-0 py-1"
                      active={activeSection === 9}
                    >
                      Portabilidad
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#retirement"
                      className="nav-link px-0 py-1"
                      active={activeSection === 10}
                    >
                      Retiro del consentimiento
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#modify"
                      className="nav-link px-0 py-1"
                      active={activeSection === 11}
                    >
                      Modificación de esta política de datos personales
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrivacyPolicy;

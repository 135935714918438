import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Placeholder } from 'react-bootstrap';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import { sources } from 'data/assets/assetData';
import { AssetsContext, UserContext } from 'context/Context';
import { getColor, getCurrencyFormat } from 'helpers/utils';

export const Rate = ({
  circleActiveClass,
  circleActiveProps,
  circleClass,
  circleWrapperClass,
  circleWrapperProps,
  className,
  numberClass,
  rating,
  showNumber = true,
  showText = false,
  textClass,
  textProps
}) => {
  rating = parseInt(rating, 10);
  const index = Math.round((rating * 4) / 100);
  const text =
    index === 0
      ? 'muy malo'
      : index === 1
      ? 'malo'
      : index === 2
      ? 'normal'
      : index === 3
      ? 'bueno'
      : index === 4
      ? 'muy bueno'
      : '';

  const borderColor = getColor('300');
  return (
    <div className={classNames('inversorpro-rating', className)}>
      {isNaN(rating) ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          className={circleClass}
          style={{ width: 40, height: 40 }}
        >
          <Flex
            className={classNames(
              'border border-200 border-3 rounded',
              circleClass
            )}
            alignItems="center"
            justifyContent="center"
            style={{ width: 34, height: 34 }}
          >
            <FontAwesomeIcon icon="clock" className="d-block text-300" />
          </Flex>
        </Flex>
      ) : (
        <svg
          className={classNames('circle-chart', circleClass)}
          viewBox="0 0 40 40"
          width="40"
          height="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className={classNames(
              'circle-chart__background',
              circleWrapperClass
            )}
            stroke={borderColor}
            strokeWidth="4"
            fill="none"
            cx="20"
            cy="20"
            r="16"
            {...(circleWrapperProps || {})}
          />
          <circle
            className={classNames('circle-chart__circle', circleActiveClass)}
            stroke={`var(--rate-${index})`}
            strokeWidth="4"
            strokeDasharray={`${rating},100`}
            strokeLinecap="round"
            fill="none"
            cx="20"
            cy="20"
            r="16"
            {...(circleActiveProps || {})}
          />
          <g className="circle-chart__info">
            {showNumber && (
              <text
                className={classNames(
                  'circle-chart__percent fw-bold',
                  numberClass
                )}
                x="20"
                y={showText ? 17.5 : 20}
                alignmentBaseline="central"
                textAnchor="middle"
                fontSize="14"
              >
                {rating}
              </text>
            )}
            {showText && (
              <text
                className={classNames(
                  'circle-chart__text text-pre-wrap',
                  textClass
                )}
                x="20"
                y="26.5"
                alignmentBaseline="central"
                textAnchor="middle"
                fontSize="3.5"
                {...(textProps || {})}
              >
                {text}
              </text>
            )}
          </g>
        </svg>
      )}
    </div>
  );
};

Rate.propTypes = {
  circleActiveClass: PropTypes.string,
  circleActiveProps: PropTypes.object,
  circleClass: PropTypes.string,
  circleWrapperClass: PropTypes.string,
  circleWrapperProps: PropTypes.object,
  className: PropTypes.string,
  numberClass: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showNumber: PropTypes.bool,
  showText: PropTypes.bool,
  textClass: PropTypes.string,
  textProps: PropTypes.object
};

export const CapitalGain = ({
  className,
  capitalGain,
  showIcon,
  showText,
  valueClass
}) => {
  return (
    <Tooltip
      className={classNames('cursor-default z-index-2', {
        'w-0 flex-grow-1 ': !showIcon
      })}
      title="Ganancia por venta directa"
    >
      {showText && (
        <small className="mb-1 text-truncate text-uppercase fs--2 max-w-100">
          Venta directa
        </small>
      )}
      <Flex
        className={classNames('gap-2', className)}
        direction={showIcon ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
      >
        {showIcon && <FontAwesomeIcon icon="money-bill" size="xs" />}

        <h6
          className={classNames(
            'mb-0 text-truncate max-w-100 text-inherit',
            valueClass
          )}
        >
          {capitalGain
            ? getCurrencyFormat(capitalGain, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
            : '-'}
        </h6>
      </Flex>
    </Tooltip>
  );
};

CapitalGain.propTypes = {
  capitalGain: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  valueClass: PropTypes.string
};

export const FlippingHouse = ({
  className,
  flippingHouse,
  showIcon,
  showText,
  valueClass
}) => {
  return (
    <Tooltip
      className={classNames('cursor-default z-index-2', {
        'w-0 flex-grow-1 ': !showIcon
      })}
      title="Ganancia por venta tras reforma"
    >
      {showText && (
        <small className="mb-1 text-truncate text-uppercase fs--2 max-w-100">
          Venta tras reforma
        </small>
      )}
      <Flex
        className={classNames('gap-2', className)}
        direction={showIcon ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
      >
        {showIcon && <FontAwesomeIcon icon="money-bill-trend-up" size="xs" />}

        <h6
          className={classNames(
            'mb-0 text-truncate max-w-100 text-inherit',
            valueClass
          )}
        >
          {flippingHouse
            ? getCurrencyFormat(flippingHouse, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
            : '-'}
        </h6>
      </Flex>
    </Tooltip>
  );
};

FlippingHouse.propTypes = {
  className: PropTypes.string,
  flippingHouse: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  valueClass: PropTypes.string
};

export const LongTermRentability = ({
  className,
  rentability,
  showIcon,
  showText,
  valueClass
}) => {
  const { longTerm: longTermRentability } = rentability || {};
  return (
    <Tooltip
      className={classNames('cursor-default z-index-2', {
        'w-0 flex-grow-1 ': !showIcon
      })}
      title="Rentabilidad alquiler a largo plazo"
    >
      {showText && (
        <small className="mb-1 text-truncate text-uppercase fs--2 max-w-100">
          Largo plazo
        </small>
      )}
      <Flex
        className={classNames('gap-2', className)}
        direction={showIcon ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
      >
        {showIcon && <FontAwesomeIcon icon="home" size="xs" />}

        <h6
          className={classNames(
            'mb-0 text-truncate max-w-100 text-inherit',
            valueClass
          )}
        >
          {longTermRentability}
          {typeof longTermRentability === 'number' && '%'}
        </h6>
      </Flex>
    </Tooltip>
  );
};

LongTermRentability.propTypes = {
  className: PropTypes.string,
  rentability: PropTypes.object,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  valueClass: PropTypes.string
};

export const RoomRentability = ({
  className,
  rentability,
  showIcon,
  showText,
  valueClass
}) => {
  const { rooms: roomRentability } = rentability || {};
  return (
    <Tooltip
      className={classNames('cursor-default z-index-2', {
        'w-0 flex-grow-1 ': !showIcon
      })}
      title="Rentabilidad alquiler por habitaciones"
    >
      {showText && (
        <small className="mb-1 text-truncate text-uppercase fs--2 max-w-100">
          Por habitaciones
        </small>
      )}
      <Flex
        className={classNames('gap-2', className)}
        direction={showIcon ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
      >
        {showIcon && <FontAwesomeIcon icon="door-closed" size="xs" />}

        <h6
          className={classNames(
            'mb-0 text-truncate max-w-100 text-inherit',
            valueClass
          )}
        >
          {roomRentability}
          {typeof roomRentability === 'number' && '%'}
        </h6>
      </Flex>
    </Tooltip>
  );
};

RoomRentability.propTypes = {
  className: PropTypes.string,
  rentability: PropTypes.object,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  valueClass: PropTypes.string
};

export const VacationRentability = ({
  className,
  rentability,
  showIcon,
  showText,
  valueClass
}) => {
  const { vacation: vacationRentability } = rentability || {};
  return (
    <Tooltip
      className={classNames('cursor-default z-index-2', {
        'w-0 flex-grow-1 ': !showIcon
      })}
      title="Rentabilidad alquiler vacacional"
    >
      {showText && (
        <small className="mb-1 text-truncate text-uppercase fs--2 max-w-100">
          Vacacional
        </small>
      )}
      <Flex
        className={classNames('gap-2', className)}
        direction={showIcon ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
      >
        {showIcon && <FontAwesomeIcon icon="suitcase-rolling" size="xs" />}

        <h6
          className={classNames(
            'mb-0 text-truncate max-w-100 text-inherit',
            valueClass
          )}
        >
          {vacationRentability}
          {typeof vacationRentability === 'number' && '%'}
        </h6>
      </Flex>
    </Tooltip>
  );
};

VacationRentability.propTypes = {
  className: PropTypes.string,
  rentability: PropTypes.object,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  valueClass: PropTypes.string
};

export const Asset = ({
  asset: assetProp,
  at,
  bodyClass,
  className,
  from,
  headerClass
}) => {
  const { formatAsset, scrapAsset } = useContext(AssetsContext);
  const { getAvailableSources, me, setUpgradeModal, toggleFavorite, views } =
    useContext(UserContext);
  const availableSources = getAvailableSources();
  const db = useFirestore();
  const navigate = useNavigate();
  let assetRef = doc(db, 'none', 'none');
  if (assetProp?.id && at !== 'preview') {
    assetRef = doc(db, 'assets', assetProp.id);
  }
  const { data: cloudData, status: dbStatus } = useFirestoreDocData(assetRef);
  const data = cloudData || assetProp;
  const asset = formatAsset(data);
  const {
    createdBy,
    currentProfit,
    deleted,
    id,
    images,
    link,
    name,
    price,
    rooms,
    size,
    source,
    sponsored,
    status
  } = asset || {};
  let creatorRef = doc(db, 'none', 'none');
  if (createdBy) {
    creatorRef = doc(db, 'users', createdBy);
  }
  const { data: creatorData } = useFirestoreDocData(creatorRef);
  const { avatar: creatorAvatar, name: creatorName } = creatorData || {};

  const {
    data: { budget: { amount: budget = 0 } = {} } = {},
    favourites = []
  } = me || {};
  const extraBudget = price - budget > 0 ? price - budget : 0;
  const extraBudgetPercent = parseInt((extraBudget * 100) / budget, 10);
  const isAvailable = availableSources.some(({ value }) => value === source);
  const isFavourite = favourites.some(({ assetId }) => assetId === id);
  const isViewed = views.some(({ assetId }) => assetId === id);
  const sourceObj = sources.find(({ value }) => value === source);
  const rating = isAvailable ? asset?.rating : 100;
  const handleFavClick = asset => {
    toggleFavorite(asset);
  };

  const handleClick = () => {
    if (!id) {
      return;
    }
    const assetLink = `/assets/asset-details/${source}/${id.replace(
      `${source}-`,
      ''
    )}`;
    if (!isAvailable) {
      setUpgradeModal({
        open: true,
        message: (
          <>
            El portal {sourceObj?.label} está disponible a partir del{' '}
            <SoftBadge bg="400">Plan Estándar</SoftBadge>
          </>
        )
      });
      return;
    }
    navigate(assetLink);
  };

  const handleError = () => {
    !asset.deleted && scrapAsset(asset);
  };

  if (assetProp?.id && dbStatus === 'success' && !id) {
    return null;
  }

  return (
    <div className="position-relative">
      {!!sponsored && (
        <small className="fw-semi-bold text-600 position-absolute bottom-100 pb-1">
          Destacado
        </small>
      )}
      <Card
        id={`${id}`}
        className={classNames('asset shadow-none dark__bg-1100', className, {
          'not-available': !isAvailable,
          favourite: isFavourite,
          'cursor-default': from !== 'favourites',
          'cursor-pointer': from === 'favourites'
        })}
      >
        {(!id || status === 'draft') && (
          <div className="position-absolute w-100 h-100 z-index-1016" />
        )}
        {assetProp?.id && !id ? (
          <Placeholder animation="glow">
            <Placeholder xs={12} className="rounded h-180px" />
          </Placeholder>
        ) : (
          <>
            <div
              className={classNames('rounded overflow-hidden', headerClass, {
                'bg-dark': !isAvailable
              })}
            >
              <div
                className={classNames(
                  'bg-200 h-180px min-w-220px max-w-100',
                  headerClass,
                  {
                    'blur-4': !isAvailable,
                    'd-flex align-items-center justify-content-center':
                      !images?.length
                  }
                )}
              >
                {images?.[0] ? (
                  <Card.Img
                    src={images?.[0]}
                    className={classNames('fit-cover w-100 h-100', headerClass)}
                    alt={name}
                    onError={handleError}
                  />
                ) : (
                  <Logo height={20} className="mt-4" />
                )}
              </div>

              {isAvailable && deleted && (
                <Flex
                  className={classNames(
                    'w-100 position-absolute top-0 start-0 bg-dark bg-opacity-50',
                    {
                      'h-180px': from !== 'favourites',
                      'h-100px': from === 'favourites'
                    }
                  )}
                  alignItems="center"
                  justifyContent="center"
                >
                  <SoftBadge bg="danger">NO DISPONIBLE</SoftBadge>
                </Flex>
              )}
            </div>
            <Flex
              direction="column"
              alignItems="start"
              className={classNames(
                'position-absolute rounded w-100 h-180px',
                headerClass,
                {
                  'bg-white bg-opacity-75': !isAvailable
                }
              )}
              tag="header"
            >
              <Flex
                alignItems="start"
                justifyContent={at === 'map' ? 'start' : 'between'}
                className="px-3 py-2 w-100 z-index-2"
              >
                <div
                  className={classNames(
                    'bg-white me-2 rounded transition-base',
                    {
                      'blur-2': !isAvailable
                    }
                  )}
                >
                  <Tooltip
                    className="d-block"
                    title="Puntuación a pie de calle (sin analizar el inmueble)"
                  >
                    <Rate
                      {...asset}
                      rating={rating}
                      circleClass="w-32px h-32px"
                    />
                  </Tooltip>
                </div>
                <Flex>
                  <a
                    href={isAvailable ? link : ''}
                    className={classNames('d-block px-2 py-1 text-white', {
                      'hover-scale-120': isAvailable
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={event => {
                      event.stopPropagation();
                    }}
                  >
                    {sourceObj?.svg ? (
                      <Avatar
                        src={sourceObj.svg}
                        size="m"
                        className="d-block border border-2 border-white"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-white"
                        icon="arrow-up-right-from-square"
                      />
                    )}
                  </a>
                  <Button
                    alignItems="center"
                    justifyContent="center"
                    className="p-1 hover-scale-120"
                    variant="link"
                    onClick={event => {
                      event.stopPropagation();
                      handleFavClick(asset);
                    }}
                    as={Flex}
                  >
                    <FontAwesomeIcon
                      className="text-white position-relative z-index-2"
                      icon={['far', 'heart']}
                      size="xl"
                    />
                    <FontAwesomeIcon
                      className={classNames('position-absolute z-index-1', {
                        'opacity-50 text-black': !isFavourite,
                        'text-danger': isFavourite
                      })}
                      icon="heart"
                      size="xl"
                    />
                  </Button>
                </Flex>
              </Flex>

              <Flex
                className="flex-grow-1 gap-2 w-100 py-2 px-3"
                alignItems="end"
                justifyContent="end"
              >
                {creatorName && (
                  <>
                    <Link to={`/profile/${createdBy}`}>
                      <Avatar
                        rounded="1"
                        src={creatorAvatar}
                        name={creatorName}
                        className="d-block hover-scale-105 h-60px w-auto"
                      />
                    </Link>
                    <div className="flex-grow-1" />
                  </>
                )}
                <Flex
                  className="gap-1"
                  direction="column"
                  alignItems="end"
                  justifyContent="end"
                >
                  {!!currentProfit && (
                    <SoftBadge className="bg-white badge-soft-border-opacity text-success">
                      Con rentabilidad
                    </SoftBadge>
                  )}
                  {!!budget && !!extraBudget && (
                    <SoftBadge className="bg-white badge-soft-border-opacity text-500">
                      {extraBudgetPercent}% más caro
                    </SoftBadge>
                  )}
                </Flex>
                {isViewed && (
                  <Tooltip title="Visto este mes">
                    <SoftBadge className="bg-white badge-soft-border-opacity p-1 text-warning">
                      <FontAwesomeIcon
                        className="position-relative z-index-2"
                        icon="eye"
                      />
                    </SoftBadge>
                  </Tooltip>
                )}
              </Flex>
              {!isAvailable && (
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  className="w-100 h-100 pt-4 position-absolute"
                >
                  <FontAwesomeIcon icon="lock" size="lg" />
                  <small className="d-block mt-1 fw-semi-bold text-center">
                    Suscríbete para poder ver
                    <br />
                    los inmuebles de {sourceObj?.label}
                  </small>
                  <Button
                    variant="falcon-warning"
                    className="mt-2 fs--2 shadow-none bg-white bg-opacity-25 border-0"
                    size="sm"
                    as={Link}
                    to="/pricing"
                  >
                    <Flex alignItems="center">
                      <FontAwesomeIcon icon="crown" className="me-2" />
                      <span className="fw-semi-bold">Hazte Pro</span>
                    </Flex>
                  </Button>
                </Flex>
              )}
            </Flex>
          </>
        )}
        <Card.Body className={classNames('bg- px-0', bodyClass)}>
          {assetProp?.id && !id ? (
            <>
              <Placeholder as={Flex} animation="glow" className="gap-2">
                <Placeholder bg="black" xs={5} className="rounded" />
                <div className="flex-grow-1" />
                <Placeholder bg="secondary" xs={2} className="rounded" />
                <Placeholder bg="secondary" xs={2} className="rounded" />
              </Placeholder>
              <Placeholder animation="glow">
                <Placeholder bg="black" xs={7} className="rounded mt-2" />
              </Placeholder>
              <Placeholder animation="glow">
                <Placeholder.Button
                  variant="outline-secondary"
                  xs={6}
                  aria-hidden="true"
                  className="mt-3 btn-sm"
                />
              </Placeholder>
            </>
          ) : (
            <>
              <Flex
                className="gap-2 mb-2"
                justifyContent="between"
                alignItems="center"
              >
                <h6 className="m-0 fw-semi-bold">
                  {getCurrencyFormat(price, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                </h6>
                <div className="flex-grow-1" />
                <Flex alignItems="center" className="gap-2">
                  {!!rooms && (
                    <SoftBadge bg="secondary">{rooms} hab.</SoftBadge>
                  )}
                  {!!size && <SoftBadge bg="secondary">{size} m²</SoftBadge>}
                </Flex>
              </Flex>
              <Flex alignItems="center" className="gap-2">
                <h6 className="text-truncate text-truncate" title={name}>
                  {name}
                </h6>
              </Flex>
              {from !== 'favourites' && (
                <div className="mt-1">
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleClick}
                  >
                    {!isAvailable && (
                      <FontAwesomeIcon icon="lock" className="me-2" />
                    )}
                    Ver ficha
                  </Button>
                </div>
              )}
            </>
          )}
          {/* <Flex
          alignItems="end"
          justifyContent="between"
          className="gap-1 w-100 p-2"
        >
          {state > 40 ? (
            <CapitalGain {...asset} showIcon={true} />
          ) : (
            <FlippingHouse {...asset} showIcon={true} />
          )}
          <LongTermRentability {...asset} showIcon={true} />
          <RoomRentability {...asset} showIcon={true} />
        </Flex> */}
        </Card.Body>
      </Card>
    </div>
  );
};

Asset.propTypes = {
  asset: PropTypes.object,
  at: PropTypes.string,
  bodyClass: PropTypes.string,
  className: PropTypes.string,
  from: PropTypes.string,
  headerClass: PropTypes.string
};

export default Asset;

import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import Flex from 'components/common/Flex';
import { CalculatorContext } from 'context/Context';
import allIncomes from 'data/flipping-house-incomes.json';
import { getCurrencyFormat } from 'helpers/utils';
import useVisibilityObserver from 'hooks/useVisibilityObserver';

const NoOptionsMessage = ({ className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.NoOptionsMessage
      {...rest}
      className={classNames(className, { 'p-0': size === 'sm' })}
    >
      Añade un ingreso custom
    </components.NoOptionsMessage>
  );
};

NoOptionsMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem'
  }),
  placeholder: base => ({
    ...base,
    padding: '6px 0 6px 0'
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const AddIncome = () => {
  const { addFlippingHouseIncome, flippingHouseIncomes: incomes = [] } =
    useContext(CalculatorContext);
  const options = allIncomes
    .filter(({ label }) => !incomes.some(e => e.label === label))
    .sort((i1, i2) => (i1.label < i2.label ? -1 : 1));
  const creatable = useRef();

  const handleExpenseChange = expense => {
    if (!expense) {
      return;
    }
    addFlippingHouseIncome(expense?.value);
    setTimeout(() => {
      creatable.current?.clearValue();
      creatable.current?.blur();
    }, 10);
  };

  const handleCreateExpense = newExpense => {
    addFlippingHouseIncome(newExpense);
  };

  return (
    <tr>
      <td className="border-0 p-0" colSpan={2} valign="middle">
        <Flex alignItems="center" className="p-3 gap-2 w-100">
          <div className="flex-grow-1">
            <Creatable
              ref={creatable}
              menuPlacement="auto"
              options={options?.map(({ label, ...rest }) => ({
                label,
                ...rest,
                value: label
              }))}
              components={{ NoOptionsMessage }}
              placeholder="Añade un ingreso"
              menuPortalTarget={document.body}
              classNamePrefix="react-select"
              onChange={handleExpenseChange}
              onCreateOption={handleCreateExpense}
              styles={styles}
            />
          </div>
        </Flex>
      </td>
    </tr>
  );
};

const Income = ({ income, index }) => {
  const {
    deleteFlippingHouseIncome: deleteIncome,
    flippingHouseIncomes: incomes = [],
    setFlippingHouseIncomes: setIncomes
  } = useContext(CalculatorContext);
  const { amount, key, label } = income || {};

  const handleChange = ({ target }) => {
    const { value } = target;
    const newIncomes = [...incomes];
    newIncomes[index].amount = parseFloat(value);
    setIncomes(newIncomes);
  };

  return (
    <tr>
      <td valign="middle">{label}</td>
      <td valign="middle">
        <Flex alignItems="center" justifyContent="end" className="gap-2">
          <Flex alignItems="center" className="position-relative">
            <Form.Control
              className="text-end pe-5 me-n4"
              type="text"
              placeholder="0"
              value={amount}
              onChange={handleChange}
            />
            <span className="position-absolute end-0 me-3">€</span>
          </Flex>
          {!key && (
            <Button
              variant="falcon-danger"
              size="sm"
              onClick={() => deleteIncome(index)}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          )}
        </Flex>
      </td>
    </tr>
  );
};

Income.propTypes = {
  income: PropTypes.object,
  index: PropTypes.number
};

const Totals = () => {
  const { totalFlippingHouseIncomes: totalIncomes = 0 } =
    useContext(CalculatorContext);
  return (
    <tr>
      <td
        className="bg-200 border-0 border-top shadow-none text-end"
        colSpan={2}
        valign="middle"
      >
        <Card.Title>
          <small>Total ingresos</small>
        </Card.Title>
        <Card.Title
          className={classNames({
            'text-500': totalIncomes === 0,
            'text-success': totalIncomes > 0
          })}
        >
          <FontAwesomeIcon className="me-2" icon="caret-up" />
          {getCurrencyFormat(totalIncomes, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
        </Card.Title>
      </td>
    </tr>
  );
};

const FlippingHouseIncomes = () => {
  const targetElRef = useRef();
  const { flippingHouseIncomes: incomes = [] } = useContext(CalculatorContext);
  const { observer } = useVisibilityObserver(targetElRef, '100px');

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="border">
        <Card.Body className="p-0 overflow-hidden rounded">
          <Table className="m-0" striped>
            <thead>
              <tr>
                <th className="bg-200 border-bottom" colSpan={2}>
                  Ingresos
                </th>
              </tr>
            </thead>
            <tbody>
              {incomes.map((income, index) => (
                <Income
                  key={`Income-${index}-${income?.label}`}
                  income={income}
                  index={index}
                />
              ))}
              <AddIncome />
              <Totals />
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default FlippingHouseIncomes;

import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Flex from 'components/common/Flex';
import RegistrationForm from 'components/authentication/RegistrationForm';
import bgImg from 'assets/img/generic/dashboard-alt.jpg';
import bgImgDark from 'assets/img/generic/dashboard-alt-dark.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import AppContext from 'context/Context';

const Registration = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { search } = useLocation();
  const [, area] =
    (search && decodeURIComponent(search)?.match(/q=(.*),/)) || [];
  const image = isDark ? bgImgDark : bgImg;
  return (
    <AuthSplitLayout bgProps={{ image, overlay: '3', position: 'left top' }}>
      {area && (
        <Flex
          direction="column"
          alignItems="center"
          className="py-4 px-6 w-100 text-center"
        >
          <p className="mb-5">
            Hemos encontrado algunos inmuebles
            <br />
            con más del 10% de rentabilidad en
            <h5>{area}</h5>
          </p>
          <h4 className="mb-3">Regístrate gratis y disfruta de:</h4>
          <ul className="list-unstyled text-start">
            <li>✅ Búsqueda de inmuebles</li>
            <li>✅ Acceso a rentabilidades</li>
            <li>✅ Valoración de inmuebles</li>
          </ul>
        </Flex>
      )}
      <Flex direction="column" alignItems="center" justifyContent="between">
        <h4>Crea una cuenta</h4>
        <p className="fs--1">
          <span className="fw-semi-bold">¿Tienes una cuenta? </span>
          <Link to="/authentication/login">Inicia sesión</Link>
        </p>
      </Flex>
      <RegistrationForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default Registration;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import { getColor, hexToRgba } from 'helpers/utils';

const windowHeight = window.innerHeight;

const NavbarStandard = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const { pathname } = useLocation();
  const handleNavbarTransparency = useCallback(() => {
    const scrollTop = window.scrollY;
    let alpha = (scrollTop / windowHeight) * 2;
    alpha >= 1 && (alpha = 1);
    const hexColor = getColor('body-bg');
    const rgbaColor = hexToRgba(hexColor, alpha);
    document.getElementsByClassName('navbar-theme')[0].style.backgroundColor =
      rgbaColor;
  }, [isDark, getColor('body')]);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, [isDark]);

  return (
    <Navbar
      variant={isDark ? 'light' : 'dark'}
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme py-4', {
        'fixed-top': pathname === '/',
        'sticky-top': pathname !== '/',
        'bg-white': !navbarCollapsed && !isDark,
        'bg-dark': !navbarCollapsed && isDark
      })}
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Flex alignItems="center">
            <Logo id="logo" at="navbar-top" />
          </Flex>
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />

        <Navbar.Collapse className="scrollbar">
          <Nav className="justify-content-end w-100 me-lg-4 gap-4">
            <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Marker, CollisionBehavior, Pin } from '@vis.gl/react-google-maps';
import { darken, getColor } from 'helpers/utils';

export const AssetMarker = props => {
  const { asset, onClick, setMarkerRef } = props;
  const backgroundColor = getColor('primary');
  const borderColor = darken(getColor('primary'), 50);

  const handleClick = useCallback(() => onClick(asset), [onClick, asset]);
  const ref = useCallback(
    marker => setMarkerRef(marker, asset.key),
    [setMarkerRef, asset.key]
  );

  return (
    <Marker
      position={asset.position}
      ref={ref}
      collisionBehavior={CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL}
      onClick={handleClick}
    >
      <Pin
        background={backgroundColor}
        borderColor={borderColor}
        glyphColor={borderColor}
      />
    </Marker>
  );
};

AssetMarker.propTypes = {
  asset: PropTypes.object,
  onClick: PropTypes.func,
  setMarkerRef: PropTypes.func
};

export default AssetMarker;

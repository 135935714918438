import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Image, Ratio } from 'react-bootstrap';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import playicon from 'assets/img/icons/play.svg';

const Course = ({
  index,
  title,
  description,
  children,
  image,
  src,
  setCurrentVideo
}) => {
  const inverse = index % 2 !== 0;
  return (
    <Row
      id={`course${index + 1}`}
      className={classNames('flex-center py-6', {
        'bg-200': inverse
      })}
    >
      <Col
        md={{ order: inverse ? 2 : 0, span: 6 }}
        lg={5}
        xl={4}
        className={classNames({ 'pe-lg-6': inverse, 'ps-lg-6': !inverse })}
      >
        <Ratio aspectRatio="16x9" className="rounded overflow-hidden">
          <div className="position-absolute text-center">
            <Background image={image} className="overlay" />
            <Flex
              className="cursor-pointer position-relative w-100 h-100"
              alignItems="center"
              justifyContent="center"
              onClick={() => setCurrentVideo({ image, src })}
            >
              <Image src={playicon} alt={`${title} play icon`} width={60} />
            </Flex>
          </div>
        </Ratio>
      </Col>
      <Col md lg={5} xl={4} className="mt-4 mt-md-0 p-5">
        <h5 className="text-primary">
          <FontAwesomeIcon icon="graduation-cap" className="me-2" />
          Clase {index + 1}
        </h5>
        <h3>{title}</h3>
        <p>{description}</p>
        {children}
      </Col>
    </Row>
  );
};

Course.propTypes = {
  isFirst: PropTypes.bool,
  index: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  src: PropTypes.string,
  setCurrentVideo: PropTypes.func,
  children: PropTypes.node
};

export default Course;

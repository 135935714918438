import { useContext, useState } from 'react';
import { AssetsContext } from 'context/Context';

const useAssets = asset => {
  const {
    assetsDispatch,
    assetsState: { order, sortBy: sortByProp },
    isInCart,
    isInFavouriteItems
  } = useContext(AssetsContext);
  const [isAsc, setIsAsc] = useState(order === 'asc');
  const [sortBy, setSortBy] = useState(sortByProp);

  const handleSearch = async searchedText => {
    assetsDispatch?.({
      type: 'SEARCH_ASSET',
      payload: {
        searchedText
      }
    });
  };

  const handleSort = ({ isAsc, sortBy }) => {
    assetsDispatch?.({
      type: 'SORT_ASSET',
      payload: {
        sortBy,
        order: isAsc ? 'asc' : 'desc'
      }
    });
  };

  const handleSortByChange = sortValue => {
    setSortBy(sortValue);
    handleSort({ isAsc, sortBy: sortValue });
  };

  const handleIsAscClick = isAsc => {
    setIsAsc(isAsc);
    handleSort({ isAsc, sortBy });
  };

  const handleAddToCart = () => {
    assetsDispatch?.({
      type: isInCart(asset.id) ? 'REMOVE_FROM_CART' : 'ADD_TO_CART',
      payload: { asset }
    });
  };

  const handleFavouriteClick = () => {
    assetsDispatch({
      type: isInFavouriteItems(asset.id)
        ? 'REMOVE_FROM_FAVOURITES'
        : 'ADD_TO_FAVOURITES',
      payload: { asset }
    });
  };

  return {
    handleAddToCart,
    handleFavouriteClick,
    handleSearch,
    handleIsAscClick,
    handleSortByChange,
    isAsc
  };
};

export default useAssets;

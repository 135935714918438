import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/dashboard-alt.jpg';
import bgImgDark from 'assets/img/generic/dashboard-alt-dark.jpg';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';

const Login = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { search } = useLocation();
  const [, area] =
    (search && decodeURIComponent(search)?.match(/q=(.*),/)) || [];
  const image = isDark ? bgImgDark : bgImg;
  return (
    <AuthSplitLayout bgProps={{ image, overlay: '3', position: 'left top' }}>
      {area && (
        <Flex
          direction="column"
          alignItems="center"
          className="py-4 px-6 w-100 text-center"
        >
          <p className="mb-5">
            Hemos encontrado algunos inmuebles
            <br />
            con más del 10% de rentabilidad en
            <h5>{area}</h5>
          </p>
          <h4 className="mb-3">Regístrate gratis y disfruta de:</h4>
          <ul className="list-unstyled text-start">
            <li>✅ Búsqueda de inmuebles</li>
            <li>✅ Acceso a rentabilidades</li>
            <li>✅ Valoración de inmuebles</li>
          </ul>
        </Flex>
      )}
      <Flex direction="column" alignItems="center" justifyContent="between">
        <h4>Inicia sesión</h4>
        <p className="fs--1">
          <span className="fw-semi-bold">¿No tienes cuenta? </span>
          <Link to="/authentication/register">Regístrate gratis</Link>
        </p>
      </Flex>
      <LoginForm id="login" layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default Login;

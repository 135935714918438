import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import debounce from 'just-debounce-it';
import { diff } from 'deep-object-diff';
import Fuse from 'fuse.js';
import { toast } from 'react-toastify';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
  query,
  setDoc,
  updateDoc
} from '@firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes
} from '@firebase/storage';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useStorage
} from 'reactfire';
import { AssetsContext, UserContext } from 'context/Context';
import { assetReducer } from 'reducers/assetReducer';
import { assetFilters as filtersData, sources } from 'data/assets/assetData';
import {
  CAPITAL_GAIN_MIN_STATE,
  PROFIT_ROOMS_MULT,
  PROFIT_VACATION_MULT,
  REFORM_PRICE_M2
} from 'helpers/defines';
import { download, getCurrentPosition, getValuableSize } from 'helpers/utils';
import useFetchAssets from 'hooks/useFetchAssets';
import useFetchAssetsCount from 'hooks/useFetchAssetsCount';

const itemsPerPage = 20;
const AssetsProvider = ({ children }) => {
  const db = useFirestore();
  const storage = useStorage();
  const { getAvailableSources, goToCheckout, me, subscription } =
    useContext(UserContext);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [hasFinished, setHasFinished] = useState(false);
  const [sourcesPage, setSourcesPage] = useState({});
  const [loadingSources, setLoadingSource] = useState({});
  const isLoading =
    typeof loadingSources === 'boolean'
      ? loadingSources
      : Object.values(loadingSources).some(isLoading => isLoading);
  const initData = {
    initAssets: [],
    assets: [],
    primaryAssets: [],
    filters: [],
    sortBy: subscription === null ? '' : 'price',
    order: 'asc'
  };
  const [assetsState, assetsDispatch] = useReducer(assetReducer, initData);
  const {
    filters: assetsFilter,
    primaryAssets,
    assets: statedAssets,
    order,
    searchedText,
    sortBy
  } = assetsState;
  const sourcesFilter = assetsFilter?.filter(({ name }) => name === 'source');
  const availableSources = getAvailableSources?.() || sources;
  const filteredSources = availableSources.filter(({ value }) =>
    sourcesFilter.some(source => source?.value === value)
  );
  const scrapSources = (
    filteredSources?.length ? filteredSources : sources
  ).filter(({ value }) => value !== 'inversorpro');
  const areasQuery = query(collection(db, 'areas'));
  const { data: dataAreas = [], status: areasStatus } =
    useFirestoreCollectionData(areasQuery);
  const areas = dataAreas.filter(({ name }) => name?.split(', ').length > 1);
  const areaId = btoa(searchedText).replace(/\//g, '') || 'none';
  const [page, setPage] = useState(0);
  const cursors = useRef(new Map());
  const { data: dataAssets } = useFetchAssets(areaId, {
    cursor: cursors.current.get(page),
    filteredSources,
    itemsPerPage,
    order: sortBy && orderBy(sortBy, order)
  });
  const fetchAssetsCount = useFetchAssetsCount();
  const [assetsCount, setAssetsCount] = useState();
  const [selectedAssetId, selectAsset] = useState();
  let selectedAssetRef = doc(db, 'none', 'none');
  if (selectedAssetId) {
    selectedAssetRef = doc(db, 'assets', selectedAssetId);
  }
  const { data: selectedAssetData } = useFirestoreDocData(selectedAssetRef);

  useEffect(() => {
    // when the component mounts, we store the tasks count in the state
    fetchAssetsCount({ areaId, filteredSources }).then(result => {
      setAssetsCount(result.data().count);
    });
  }, [fetchAssetsCount, filteredSources, areaId]);

  const assets = useMemo(() => {
    return statedAssets.filter(({ source }) =>
      sources.some(({ value }) => value.includes(source))
    );
  }, [statedAssets]);

  // callback called when changing page
  const fetchMore = useCallback(() => {
    // console.log('[fetchMore:page]', page);
    if (page > Math.ceil(assetsCount / itemsPerPage)) {
      // console.log('NO MORE PAGES');
      setHasFinished(true);
      return;
    }
    setPage(page => {
      const nextPage = page + 1;
      // first, we save the last document as page's cursor
      dataAssets &&
        cursors.current.set(
          nextPage,
          dataAssets.docs[dataAssets.docs.length - 1]
        );

      // then we update the state with the next page's number
      return nextPage;
    });
  }, [assetsCount, dataAssets, page]);

  const formatAsset = data => {
    if (!data) {
      return;
    }
    const {
      ai,
      area: assetArea,
      areaId,
      areaName,
      geocode,
      price: assetPrice,
      profit: currentProfit,
      rooms,
      size: assetSize
    } = data || {};
    const size = getValuableSize(assetSize);
    let { occupancy, reform: reformList, state = 100 } = ai || {};
    occupancy = typeof occupancy === 'number' ? occupancy : 70;
    const reformPrice = reformList?.length
      ? (assetSize *
          REFORM_PRICE_M2 *
          (100 - (state < 32 ? state - 100 : state))) /
        100
      : 0;
    const { lat, lng } = geocode?.geometry?.location || {};
    const location = lat && `${lat}, ${lng}`;

    const area =
      areaId || areaName
        ? areas.find(
            ({ id }) => id === (areaId || btoa(areaName).replace(/\//g, ''))
          )
        : assetArea;
    const { avgPurchaseM2, avgRentM2, avgRentPrice } = area || {};
    const price = assetPrice + reformPrice;
    let capitalGain =
      state < CAPITAL_GAIN_MIN_STATE
        ? 0
        : avgPurchaseM2
        ? avgPurchaseM2 * (reformPrice ? state / 100 : 1) * size - assetPrice
        : '-';
    capitalGain = capitalGain > 0 ? capitalGain : 0;
    let flippingHouse =
      !!reformList?.length && avgPurchaseM2
        ? avgPurchaseM2 * size - price
        : '-';
    flippingHouse = flippingHouse > 0 ? flippingHouse : 0;

    const profitAfterReform = {
      longTerm: avgRentM2 * size * 12,
      rooms: (avgRentPrice / 3) * rooms * PROFIT_ROOMS_MULT * 12,
      vacation: (avgRentM2 * size * PROFIT_VACATION_MULT * 12 * occupancy) / 100
    };
    const profitBeforeReform = {
      longTerm: state < 40 ? 0 : (state / 100) * profitAfterReform.longTerm,
      rooms: state < 40 ? 0 : (state / 100) * profitAfterReform.rooms,
      vacation: state < 40 ? 0 : (state / 100) * profitAfterReform.vacation
    };
    const profit = {
      longTerm:
        currentProfit?.longTerm * 12 ||
        (!reformPrice
          ? profitAfterReform?.longTerm
          : profitBeforeReform?.longTerm),
      rooms:
        currentProfit?.rooms * 12 ||
        (!reformPrice ? profitAfterReform?.rooms : profitBeforeReform?.rooms),
      vacation:
        currentProfit?.vacation * 12 ||
        (!reformPrice
          ? profitAfterReform?.vacation
          : profitBeforeReform?.vacation)
    };
    const reform = {
      amount: reformPrice,
      items: ai?.reform,
      profit: profitAfterReform,
      rentability: {
        longTerm: parseInt((profitAfterReform.longTerm / price) * 100, 10),
        rooms: parseInt((profitAfterReform.rooms / price) * 100, 10),
        vacation: parseInt((profitAfterReform.vacation / price) * 100, 10)
      }
    };

    const rentability = {
      longTerm: parseInt((profit.longTerm / assetPrice) * 100, 10),
      rooms: parseInt((profit.rooms / assetPrice) * 100, 10),
      vacation: parseInt((profit.vacation / assetPrice) * 100, 10)
    };

    const asset = {
      ...data,
      area,
      capitalGain,
      flippingHouse,
      location,
      profit,
      currentProfit,
      reform,
      rentability
    };

    return asset;
  };

  const createAssetFromUrl = async url => {
    const sourceObj = sources.find(source => url.includes(source.url));
    const { value: source } = sourceObj || {};
    if (!source) {
      toast.error('La URL no es válida', {
        theme: 'colored'
      });
      return;
    }
    try {
      setLoadingSource(loading => ({ ...loading, [source]: true }));
      const asset = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/createAssetFromUrl`,
          {
            method: 'POST',
            body: JSON.stringify({ url })
          }
        )
      ).json();
      setLoadingSource(loading => ({ ...loading, [source]: false }));
      return asset;
    } catch (error) {
      console.error(error);
      setLoadingSource(loading => ({ ...loading, [source]: false }));
    }
  };

  const createAssetReport = async asset => {
    const { id } = asset;
    const [source, assetId] = `${id}`.split('-');
    try {
      const blob = await (
        await fetch(`${process.env.REACT_APP_FIREBASE_URL}/createReport`, {
          method: 'POST',
          body: JSON.stringify({ source, assetId }),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${me.accessToken}`
          }
        })
      ).blob();
      download(blob, `inversorpro-${id}.pdf`);
      return blob;
    } catch (error) {
      console.error(error);
      toast.error(
        'Ha habido algún problema, si persiste contacta con info@inversor.pro',
        {
          theme: 'colored'
        }
      );
      return;
    }
  };

  const scrapArea = async (areaName, { assets = true } = {}) => {
    if (!areaName) {
      return;
    }
    const fuse = new Fuse(areas || [], {
      includeMatches: true,
      threshold: 0,
      distance: 0,
      keys: ['name']
    });
    const [result] = fuse?.search?.(areaName) || [];
    const { item } = result || {};
    const { name = areaName } = item || {};

    if (item) {
      assets && scrapAssets({ area: item });
      return item;
    }

    try {
      scrapSources.map(async ({ value: source }) => {
        setLoadingSource(loading => ({ ...loading, [source]: true }));
      });
      const area = await (
        await fetch(`${process.env.REACT_APP_FIREBASE_URL}/getArea?q=${name}`)
      ).json();
      area?.name &&
        assetsDispatch({
          type: 'SEARCH_ASSET',
          payload: {
            searchedText: area?.name
          }
        });
      // scrapAssets({ area });
      return area;
    } catch (error) {
      console.error(error);
      scrapSources.map(async ({ value: source }) => {
        setLoadingSource(loading => ({ ...loading, [source]: false }));
      });
    }
  };

  const scrapAsset = async asset => {
    const { id, link, source } = asset;
    setLoadingSource(loading => ({ ...loading, [source]: true }));
    try {
      await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/getItem?id=${id}&url=${link}`
      );
    } catch (error) {
      console.error(error);
    }
    setLoadingSource(loading => ({ ...loading, [source]: false }));
  };

  const scrapAssets = async ({ area, nextPage }) => {
    const areaName = area?.name;
    if (!areaName || !subscription) {
      return;
    }
    try {
      const extraParams = {};
      extraParams.sort = { order, type: sortBy };
      const filters = assetsFilter.reduce(
        (filters, { name, value: valueProp }) => {
          const { options } =
            filtersData.find(f => (f.name || f.options?.[0]?.name) === name) ||
            {};
          const { value: min } = options?.[0] || {};
          const { value: max } = options?.[options?.length - 1] || {};
          const [minValue, maxValue] = Array.isArray(valueProp)
            ? valueProp
            : [];
          const value = Array.isArray(valueProp)
            ? [
                minValue === min ? null : minValue,
                maxValue === max ? null : maxValue
              ]
            : isNaN(parseInt(valueProp))
            ? valueProp
            : parseInt(valueProp);
          return { ...filters, [name]: value };
        },
        {}
      );
      filters && (extraParams.filters = filters);
      await Promise.all(
        scrapSources.map(async ({ value: source }) => {
          // if (loadingSources.source) {
          //   return;
          // }
          const sourcePage = sourcesPage?.[source];
          if (sourcePage === null) {
            return;
          }
          setLoadingSource(loading => ({ ...loading, [source]: true }));
          let page = sourcesPage?.[source] || 0;
          page = page + (nextPage ? 1 : 0);
          page = Math.max(1, page);
          const sourceExtraParams = { ...extraParams };
          page && (sourceExtraParams.page = page);
          // console.log('SEARCH >>>', source, sourceExtraParams);
          let json = {};
          try {
            const result = await fetch(
              `${
                process.env.REACT_APP_FIREBASE_URL
              }/search?q=${areaName}&source=${source}${
                Object.keys(extraParams).length
                  ? `&extraParams=${JSON.stringify(sourceExtraParams)}`
                  : ''
              }`
            );
            json = await result.json();
          } catch (error) {
            json = null;
          }
          // console.log('json >>>', json);
          const { [source]: { assets } = {} } = json || {};
          cursors.current = new Map();
          setSourcesPage(sourcesPage => ({
            ...sourcesPage,
            [source]: assets && !assets.length ? null : page
          }));
          setLoadingSource(loading => ({ ...loading, [source]: false }));
        })
      );
    } catch (error) {
      console.error(error);
      await Promise.all(
        filteredSources.map(async ({ value: source }) => {
          setLoadingSource(loading => ({ ...loading, [source]: false }));
        })
      );
    }
  };

  const scrapNextPage = async () => {
    const area = areas.find(({ id }) => id === areaId);
    // console.log('NEXT PAGE >>>');
    await scrapAssets({ area, nextPage: true });
  };

  const getAsset = async assetId => {
    const assetRef = await getDoc(doc(db, 'assets', assetId));
    return await assetRef.data();
  };

  const createAsset = async (params = {}) => {
    const { id } = params;
    const data = {
      createdBy: me?.uid,
      ...params
    };
    const asset = await setDoc(doc(db, 'assets', id), data);
    return asset;
  };

  const publishAsset = async asset => {
    const { id: assetId, geocode } = asset;
    const { formatted_address: address, private: isPrivate } = geocode || {};

    const [, areaName] = address?.match(/, \d* ([^,]*,[^,]*),[^,]*$/) || [];
    const area = await scrapArea(areaName);
    const params = {
      ...asset,
      areaId: area?.id,
      areaName: area?.name,
      status: isPrivate ? 'private' : 'public'
    };
    await (assetId ? updateAsset(asset, params) : createAsset(params));
    updateAsset(asset, {
      status: 'public',
      publishedAt: new Date().toISOString()
    });
  };

  const updateAsset = async (asset, params = {}) => {
    const { images } = params;
    if (images) {
      params.images = await Promise.all(
        images.map(async image => {
          if (typeof image === 'string') {
            return image;
          }
          const options = {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 1920
          };
          const path = `assets/${asset?.id}/${image.id}.png`;
          const compressedImage = await imageCompression(image, options);
          await uploadImage(compressedImage, path);
          return await getDownloadURL(ref(storage, path));
        })
      );
    }
    const data = {
      updatedAt: new Date().toISOString(),
      ...params
    };
    await updateDoc(doc(db, 'assets', asset?.id), data);
    return asset;
  };

  const deleteAsset = async asset => {
    if (!asset?.id) {
      return;
    }
    const assetRef = ref(storage, `assets/${asset.id}`);
    const { items } = await listAll(assetRef);
    items.forEach(item => deleteObject(item));
    await deleteDoc(doc(db, 'assets', asset.id));
    assetsDispatch({
      type: 'DELETE_ASSETS',
      payload: {
        assetsToDelete: [asset]
      }
    });
  };

  const uploadImage = async (file, path) => {
    const imageRef = ref(storage, path);
    await uploadBytes(imageRef, file);
  };

  const assetsRaw = useMemo(() => {
    const areaAssets = primaryAssets.filter(asset => asset.areaId === areaId);
    // console.log('assetsRaw >>>', assetsCount, areaAssets?.length);
    if (areaAssets?.length && assetsCount <= areaAssets?.length) {
      // console.log('FINISH >>>');
      setHasFinished(true);
      scrapNextPage();
    } else {
      setHasFinished(false);
    }
    const data = dataAssets?.docs?.map(doc => doc.data()) ?? [];
    return data;
  }, [assetsCount, dataAssets]);

  useEffect(() => {
    const formattedAssets = assetsRaw.map(data => formatAsset(data));
    const diffs = Object.values(diff(primaryAssets, formattedAssets)).filter(
      value => value
    );
    if (!diffs.length) {
      return;
    }
    assetsDispatch({
      type: 'SET_ASSETS',
      payload: {
        assets: formattedAssets
      }
    });
  }, [assetsRaw]);

  const getPosition = debounce(
    async () => {
      if (areasStatus !== 'success') {
        return;
      }
      try {
        let locality = 'Madrid';
        try {
          const { lat, lng } = await getCurrentPosition();
          const location = { lat, lng };
          if (!window?.google?.maps) {
            return;
          }
          let { geocode } = me?.data?.assetLocation || {};
          if (!geocode) {
            const { results } =
              (await new window.google.maps.Geocoder().geocode({ location })) ||
              {};
            const _geocode =
              results.find(({ types }) => types.includes('locality')) || {};
            geocode = _geocode;
          }
          const { address_components: components = [], name } = geocode;
          const { long_name: _locality = '' } =
            components.find(({ types }) =>
              types.includes(
                subscription === null
                  ? 'administrative_area_level_2'
                  : 'locality'
              )
            ) || {};
          locality = name || _locality;
        } catch (error) {
          console.error(error);
        }

        const fuse = new Fuse(areas || [], {
          includeMatches: true,
          threshold: 0.3,
          keys: ['name']
        });
        const [result] = fuse?.search?.(locality) || [];
        const { item: { name = locality } = {} } = result || {};

        name &&
          assetsDispatch({
            type: 'SEARCH_ASSET',
            payload: {
              searchedText: name
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    1000,
    true
  );

  const isList = !!pathname.match(/^\/assets(\/)?$/);
  useEffect(() => {
    if (!isList && searchedText) {
      navigate('/assets');
    }
  }, [searchedText, areasStatus]);

  useEffect(() => {
    if (!searchedText) {
      return;
    }
    setPage(0);
    setSourcesPage({});
    setHasFinished(false);
  }, [JSON.stringify({ filterOptions, order, searchedText, sortBy })]);

  useEffect(() => {
    if (!searchedText) {
      return;
    }

    navigate(pathname);
    setPage(0);
    setSourcesPage({});

    const [locality, region] =
      searchedText
        ?.trim()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036F]/g, '')
        ?.split(', ') || [];

    const regex = new RegExp(
      `(^${locality
        ?.split('/')
        .map(part => `\\/?${part.trim()}`)
        .join('|')}).*, (${region
        ?.split('/')
        .map(part => `\\/?${part.trim()}`)
        .join('|')})$`,
      'i'
    );
    const [element] =
      Object.values(areas)
        .filter(({ name }) => {
          const text = name
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '');
          return text.match(regex);
        })
        .sort((e1, e2) => {
          const text1 = e1.name
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '');
          const text2 = e2.name
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '');
          const match1 = text1.match(regex);
          const match2 = text2.match(regex);
          return (match1?.index || 9999) < (match2?.index || 9999) ? -1 : 1;
        }) || [];
    const { name = searchedText } = element || {};

    // console.log('searchedText >>>', locality, name);
    if (name !== searchedText) {
      assetsDispatch({
        type: 'SEARCH_ASSET',
        payload: {
          searchedText: name
        }
      });
      return;
    }
    setTimeout(() => scrapArea(name), 100);
  }, [searchedText]);

  useEffect(() => {
    if (!pathname) {
      return;
    }
    // searchedText &&
    //   assetsDispatch({
    //     type: 'SEARCH_ASSET',
    //     payload: {
    //       searchedText: undefined
    //     }
    //   });
    !searchedText && pathname.match(/^\/assets\/?$/) && getPosition();
  }, [pathname.replace(/\/$/, '')]);

  useEffect(() => {
    const [, query] =
      (search && decodeURIComponent(search)?.match(/q=(.*)/)) || [];
    query &&
      assetsDispatch({
        type: 'SEARCH_ASSET',
        payload: {
          searchedText: query
        }
      });
  }, [search]);

  useEffect(() => {
    const [, plan, interval] = search?.match(/\?plan=(.*)&interval=(.*)/) || [];
    if (!plan || !me?.email) {
      return;
    }
    goToCheckout({ plan, interval });
  }, [search, me]);

  // console.log('ASSETS >>>', assetsState, assets);

  return (
    <AssetsContext.Provider
      value={{
        areaId,
        areas,
        areasStatus,
        assets,
        assetsCount,
        assetsState: { ...assetsState, assets },
        assetsDispatch,
        createAsset,
        createAssetFromUrl,
        createAssetReport,
        deleteAsset,
        filteredSources,
        filterOptions,
        formatAsset,
        getAsset,
        getPosition,
        isLoading,
        loadingSources,
        page,
        setPage,
        setFilterOptions,
        setShowFilterOffcanvas,
        sourcesPage,
        setSourcesPage,
        scrapAsset,
        showFilterOffcanvas,
        publishAsset,
        updateAsset,
        fetchMore,
        hasFinished,
        scrapArea,
        scrapAssets,
        setHasFinished,
        selectedAsset: selectedAssetId && formatAsset(selectedAssetData),
        selectAsset
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};

AssetsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AssetsProvider;

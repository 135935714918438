import React from 'react';
import PropTypes from 'prop-types';
import Course from './Course';
import { isIterableArray } from 'helpers/utils';

const Courses = ({ courses, setCurrentVideo }) => {
  return (
    <>
      <div>
        {isIterableArray(courses) &&
          courses.map((course, index) => (
            <Course
              key={`Course-${course.title}`}
              index={index}
              isFirst={index === 0}
              setCurrentVideo={setCurrentVideo}
              {...course}
            />
          ))}
      </div>
    </>
  );
};

Courses.propTypes = {
  courses: PropTypes.array,
  setCurrentVideo: PropTypes.func
};

export default Courses;

import { useContext } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import {
  collection,
  limit,
  startAt,
  // orderBy,
  query,
  where
} from 'firebase/firestore';
import { UserContext } from 'context/Context';

function useFetchAssetsByUser(userId, { itemsPerPage, order, page } = {}) {
  const firestore = useFirestore();
  const { subscription } = useContext(UserContext);
  const isAvailable = !!subscription;
  // collection path
  const assetsCollection = userId ? 'assets' : 'none';

  // create default constraints
  const constraints = [
    userId && where('createdBy', '==', userId),
    where('status', '==', 'public'),
    // orderBy('sponsored', 'desc'),
    order,
    limit(itemsPerPage)
  ].filter(constraint => constraint);
  // if cursor is not undefined (e.g. not initial query)
  // we pass it as a constraint
  if (isAvailable && page) {
    constraints.push(startAt(page * itemsPerPage + 1));
  }

  const collectionRef = collection(firestore, assetsCollection);

  const assetsQuery = query(collectionRef, ...constraints);

  return useFirestoreCollection(assetsQuery, {
    idField: 'id'
  });
}

export default useFetchAssetsByUser;

import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import { FavouritesContext } from 'context/Context';
import AssetModal from './AssetModal';
import { AssetsContext, UserContext } from 'context/Context';
import { kanbanReducer } from 'reducers/kanbanReducer';
import {
  addDoc,
  collection,
  doc,
  query,
  updateDoc,
  where,
  writeBatch
} from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const defaultKanbanItems = [
  {
    id: 'favourites',
    title: 'Favoritos',
    unit: 'favorito',
    items: []
  }
];

const kanbanStartColumns = ['Visitados', 'Ofertados', 'Negociación', 'Firma'];

const FavouritesProvider = ({ children }) => {
  const db = useFirestore();
  const [isLoading] = useState(false);
  const { formatAsset } = useContext(AssetsContext);
  const { me } = useContext(UserContext);
  const {
    data: { initColumnsCreated = false } = {},
    favourites: userFavourites = []
  } = me || {};
  const [assetModal, showAssetModal] = useState();
  const initData = {
    kanbanItems: defaultKanbanItems
  };
  let assetsQuery = query(collection(db, 'none'));
  let columnsQuery = query(collection(db, 'none'));
  if (me?.uid) {
    assetsQuery = query(
      collection(db, 'assets'),
      where(
        'id',
        'in',
        userFavourites?.length
          ? userFavourites.map(({ assetId }) => assetId)
          : ['']
      )
    );
    columnsQuery = query(
      collection(db, 'columns'),
      where('userId', '==', me.uid)
    );
  }
  let { data: assetsRaw = [] } = useFirestoreCollectionData(assetsQuery);
  let { data: columns = [], status: columnsStatus } =
    useFirestoreCollectionData(columnsQuery);

  const assets = assetsRaw.map(data => formatAsset(data));
  const [kanbanState, kanbanDispatch] = useReducer(kanbanReducer, initData);

  const createColumn = useCallback(
    async ({ title }) => {
      const createdAt = new Date().toISOString();
      await addDoc(collection(db, 'columns'), {
        userId: me?.uid,
        createdAt,
        title
      });
    },
    [me?.uid]
  );

  const removeColumn = async id => {
    let index =
      userFavourites?.filter(({ column }) => column === 'favourites').length ||
      0;
    const batch = writeBatch(db);
    const favouritesToMove = userFavourites.filter(
      ({ column }) => column === id
    );
    favouritesToMove.forEach(card => {
      batch.update(doc(db, 'favourites', card?.id), {
        column: 'favourites',
        index
      });
      index++;
    });
    batch.delete(doc(db, 'columns', id));
    await batch.commit();
  };

  const reorderCards = async ({ source, destination }) => {
    const batch = writeBatch(db);
    source?.items.forEach((card, index) => {
      card.index !== index &&
        batch.update(doc(db, 'favourites', card.id), { index });
    });
    destination?.items.forEach((card, index) => {
      (card.index !== index || card.column !== destination.column) &&
        batch.update(doc(db, 'favourites', card.id), {
          column: destination.column,
          index
        });
    });
    await batch.commit();
  };

  // const updateCard = async (card, params) => {
  //   const updatedAt = new Date().toISOString();
  //   const collection = 'favourites';
  //   const cardRef = await updateDoc(doc(db, collection, card?.NO_ID_FIELD), {
  //     updatedAt,
  //     ...params
  //   });
  //   return cardRef;
  // };

  const updateColumn = async (column, params) => {
    const updatedAt = new Date().toISOString();
    const columnRef = await updateDoc(doc(db, 'columns', column?.NO_ID_FIELD), {
      ...params,
      updates: [
        ...(column.updates || []),
        {
          params,
          at: updatedAt,
          by: me?.uid
        }
      ]
    });
    return columnRef;
  };

  const createStartColumns = async () => {
    const batch = writeBatch(db);
    const createdAt = new Date().toISOString();
    kanbanStartColumns.forEach(async (title, index) => {
      batch.set(doc(db, 'columns', `${me?.uid}-${title.toLowerCase()}`), {
        userId: me?.uid,
        createdAt,
        title,
        index
      });
    });
    batch.update(doc(db, 'users', me?.uid), {
      initColumnsCreated: true
    });
    await batch.commit();
  };

  useEffect(() => {
    if (initColumnsCreated || columnsStatus !== 'success' || columns?.length) {
      return;
    }
    createStartColumns();
  }, [columns, columnsStatus]);

  useEffect(() => {
    if (!me?.uid) {
      return;
    }

    const favouritesColumn = {
      id: 'favourites',
      name: 'Favoritos',
      title: 'Favoritos',
      items: userFavourites
        ?.filter(({ column = 'favourites' }) => column === 'favourites')
        .sort(({ index: i1 }, { index: i2 }) => (i1 < i2 ? -1 : 1))
        .map(params => ({
          id: params?.NO_ID_FIELD,
          ...params,
          asset: assets.find(({ id }) => id === params?.assetId)
        })),
      unit: 'favorito'
    };

    const lists =
      (columns?.length ? columns : defaultKanbanItems)
        .sort((c1, c2) =>
          `${c1.createdAt}-${c1.index || 0}` <
          `${c2.createdAt}-${c2.index || 0}`
            ? -1
            : 1
        )
        .map(params => ({
          id: params?.NO_ID_FIELD,
          name: params?.title,
          title: params?.title,
          items:
            userFavourites
              ?.filter(({ column }) => column === params?.NO_ID_FIELD)
              .map(params => ({
                id: params?.NO_ID_FIELD,
                ...params,
                asset: assets.find(({ id }) => id === params?.assetId)
              })) || [],
          ...params
        })) || [];

    diff(kanbanState?.kanbanItems, [favouritesColumn, ...lists]) &&
      kanbanDispatch({
        type: 'RESET_KANBAN_COLUMNS',
        payload: [favouritesColumn, ...lists]
      });
  }, [me?.uid, userFavourites, assets?.length, columns]);

  // console.log(
  //   'FAVOURITES >>>',
  //   { assets, userFavourites, kanbanState },
  //   kanbanState.kanbanItems
  // );

  return (
    <FavouritesContext.Provider
      value={{
        assetModal,
        createColumn,
        isLoading,
        kanbanDispatch,
        kanbanState,
        removeColumn,
        reorderCards,
        showAssetModal,
        updateColumn
      }}
    >
      {children}
      <AssetModal show={!!assetModal} asset={assetModal || {}} />
    </FavouritesContext.Provider>
  );
};

FavouritesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FavouritesProvider;

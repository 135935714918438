import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Button, Card, Dropdown } from 'react-bootstrap';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';

const AssetImages = ({ asset, setValue }) => {
  const [images, setImages] = useState(asset?.images);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setImages(
        acceptedFiles.map(file => {
          const url = URL.createObjectURL(file);
          const [id] = url.match(/[^/]*$/) || [];
          return Object.assign(file, { id, preview: url });
        })
      );
    }
  });

  useEffect(() => {
    images &&
      JSON.stringify(images) !== JSON.stringify(asset?.images) &&
      setValue('images', images);
  }, [images]);

  useEffect(() => {
    asset?.images &&
      JSON.stringify(images) !== JSON.stringify(asset?.images) &&
      setImages(asset?.images);
  }, [asset?.images]);

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0 d-inline-block">Imágenes del inmueble</h5>
      </Card.Header>
      <Card.Body className="bg-light">
        <div
          {...getRootProps({
            className: 'dropzone-area position-relative py-4'
          })}
        >
          <input
            {...getInputProps({
              className:
                'd-block position-absolute opacity-0 start-50 top-50 w-16px h-16px z-index--1',
              required: true,
              multiple: true
            })}
            required
          />
          <div className="fs--1">
            <img src={cloudUpload} alt="" width={20} className="me-2" />
            <span className="d-none d-lg-inline">
              Arrastra tus imágenes aquí
              <br />
              o,{' '}
            </span>
            <Button variant="link" size="sm" className="p-0 fs--1">
              Selecciónalas
            </Button>
          </div>
        </div>
        {!!images?.length && (
          <Flex className="gap-2 mt-2" wrap="wrap">
            {images?.map?.(image => {
              let { name, path, preview } = image;
              if (typeof image === 'string') {
                preview = image;
                path = image;
              }
              return (
                <Card
                  key={`File-${preview}`}
                  className="asset flex-grow-1 shadow-none w-120px max-h-100px"
                >
                  <Card.Img
                    src={preview}
                    className="rounded d-block fit-cover bg-200 h-100 w-100"
                    alt={path}
                  />
                  <Flex
                    direction="column"
                    alignItems="start"
                    justifyContent="between"
                    className="position-absolute rounded w-100 h-100"
                  >
                    <Flex
                      alignItems="start"
                      justifyContent="end"
                      className="px-2 py-2 w-100 z-index-2"
                      tag="header"
                    >
                      <CardDropdown btnRevealClass="bg-white">
                        <div className="py-2">
                          <Dropdown.Item
                            className="text-danger"
                            onClick={() => {
                              const newImages = images.filter(
                                image => (image.path || image) !== path
                              );
                              setImages(newImages);
                            }}
                            to={'#!'}
                          >
                            Eliminar
                          </Dropdown.Item>
                        </div>
                      </CardDropdown>
                    </Flex>
                    {name && (
                      <Flex
                        alignItems="start"
                        justifyContent="start"
                        className="px-2 pt-4 pb-2 w-100 z-index-1"
                        tag="footer"
                      >
                        <h6 className="text-truncate text-white">{name}</h6>
                      </Flex>
                    )}
                  </Flex>
                </Card>
              );
            })}
          </Flex>
        )}
      </Card.Body>
    </Card>
  );
};

AssetImages.propTypes = {
  asset: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default AssetImages;
